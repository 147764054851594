import React, { useState, useEffect } from "react";
import Header from '../app/TopAppBar';
import { Typography, Grid, Skeleton, Card } from "@mui/material";
import DashboardCards from "./Cards";
import ChartComponent from "./Chart";
import API from "../Services/API";
import axios from "axios";

const Dashboard = () => {
  const [graphData, setGraphData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [allMocData, setAllMocData] = useState([])
  const [allMocCount,setAllMocCount]=useState('0');
  const [completedMocCount,setCompletedMocCount]=useState('0');
  const [pendingMocCount,setPendingMocCount]=useState('0');
  const [resubmittedMocCount,setResubmittedMocCount]=useState('0');
  const [rejectedMocCount,setRejectedMocCount]=useState('0');

  const storedUserData = JSON.parse(localStorage.getItem("userDatas"));
  const access_token = JSON.parse(localStorage.getItem("accesstoken"));
  const profileName = storedUserData.FName + " " + storedUserData.LName;
  const user = storedUserData.SGID;
  const userName = storedUserData.name;
  
  const loadDashboardData=() =>{
    try {
      axios
        .get(API.GET_DASHBOARD_DATA, {
          headers: {
            Authorization: `Bearer ${access_token}`
          }
        })
        .then(function (response) {
          if (response.status === 200) {
            if (response.data && response.data.length > 0) {
              console.log("response.data 26773",response.data )
              const filteredResubmittedData = response.data.filter((item) => {
                if((item.submission_status==="resubmitted" && item.implement_id===null)||item.ci_implement_status==="resubmitted"){
                  return true
                }
                else{
                  return false
                }
              })
              const filteredRejectedData = response.data.filter((item) => {
                if((item.submission_status==="rejected" && item.implement_id===null)||item.ci_implement_status==="rejected"){
                  return true
                }
                else{
                  return false
                }
              })
              const filterCompletedData=response.data.filter((item) => {
                if((item.conclusion==="Non Critical" && item.ci_implement_status==="approved")||(item.conclusion==="Quality Head" && item.ci_implement_status==="approved" && item.ci_quality_approval_status===true && (item.ci_plant_check==="No"||(item.ci_plant_check==="Yes" && item.ci_plant_approval_status===true)))||(item.conclusion==="Safety Head" && item.ci_implement_status==="approved" && item.ci_safety_approval_status===true && (item.ci_plant_check==="No"||(item.ci_plant_check==="Yes" && item.ci_plant_approval_status===true)))){
                  return true
                }
                else{
                  return false
                }
              })
              const filteredApprovalData = response.data.filter(
                (item) =>
                  !filterCompletedData.includes(item) &&
                  !filteredRejectedData.includes(item) &&
                  !filteredResubmittedData.includes(item)
              );
              
              localStorage.setItem("completedMoc",filterCompletedData.length)
              localStorage.setItem("pendingMoc",filteredApprovalData.length)
              localStorage.setItem("resubmittedMoc",filteredResubmittedData.length)
              localStorage.setItem("rejectedMoc",filteredRejectedData.length)
              localStorage.setItem("allMoc",filterCompletedData.length+filteredApprovalData.length+filteredResubmittedData.length+filteredRejectedData.length);
              const updatedApprovalData = filteredApprovalData.map(item => ({
                ...item,
                final_status: "pending"
              }));
              const updatedProgressData = filteredResubmittedData.map(item => ({
                ...item,
                final_status: "Resubmitted"
              }));
              const updatedRejectedData = filteredRejectedData.map(item => ({
                ...item,
                final_status: "rejected"
              }));
              const updatedCompletedData = filterCompletedData.map(item => ({
                ...item,
                final_status: "completed"
              }));
              const filtermocs=filterCompletedData.map(item=>item.moc_no)
              console.log("filtermocs",filtermocs)
              const b = [...updatedApprovalData, ...updatedProgressData, ...updatedRejectedData, ...updatedCompletedData]
              console.log("filteredResubmittedData",filteredResubmittedData)
              console.log("filteredRejectedData",filteredRejectedData)
              console.log("filterrrcompleteddd",filterCompletedData)
              console.log("bgs", b)
              setAllMocData(b)
              // Assuming you have the data stored in localStorage as described
              const completedMocCounts = localStorage.getItem("completedMoc") || 0;
              const pendingMocCounts = localStorage.getItem("pendingMoc") || 0;
              const resubmittedMocCounts = localStorage.getItem("resubmittedMoc") || 0;
              const rejectedMocCounts = localStorage.getItem("rejectedMoc") || 0;
              setAllMocCount(localStorage.getItem("allMoc") || 0)
              setCompletedMocCount(completedMocCounts)
              setPendingMocCount(pendingMocCounts)
              setResubmittedMocCount(resubmittedMocCounts)
              setRejectedMocCount(rejectedMocCounts)
              const seriesData = {
                "All Moc": {
                  "Pending Moc": parseInt(pendingMocCounts),
                  "Resubmittted Moc": parseInt(resubmittedMocCounts),
                  "Completed Moc": parseInt(completedMocCounts),
                  "Rejected Moc": parseInt(rejectedMocCounts),
                },
              };

              setGraphData(seriesData);
  }
}
        })
        .catch(function (error) {
          setAllMocCount('0')
          setCompletedMocCount('0')
          setPendingMocCount('0')
          setResubmittedMocCount('0')
          setRejectedMocCount('0')
          const seriesData = {
            "All Moc": {
              "Pending Moc": parseInt(0),
              "Resubmittted Moc": parseInt(0),
              "Completed Moc": parseInt(0),
              "Rejected Moc": parseInt(0),
            },
          };

          setGraphData(seriesData);
        })
        .finally(() => {
          setIsLoading(false);
        });
      } catch (error) {
        setAllMocCount('0')
        setCompletedMocCount('0')
        setPendingMocCount('0')
        setResubmittedMocCount('0')
        setRejectedMocCount('0')
        const seriesData = {
          "All Moc": {
            "Pending Moc": parseInt(0),
            "Resubmittted Moc": parseInt(0),
            "Completed Moc": parseInt(0),
            "Rejected Moc": parseInt(0),
          },
        };

        setGraphData(seriesData);
          console.error("An error occurred:", error);
        }
      }

  useEffect(
    () => {
      // loadMocData();
      loadDashboardData();
    }, []);

  // const allMocCount = localStorage.getItem("allMoc") || 0;
  // const completedMocCount = localStorage.getItem("completedMoc") || 0;
  // const pendingMocCount = localStorage.getItem("pendingMoc") || 0;
  // const resubmittedMocCount = localStorage.getItem("resubmittedMoc") || 0;
  // const rejectedMocCount = localStorage.getItem("rejectedMoc") || 0;

  return (
    <div style={{ justifyContent: 'center', padding: '16px' }}>
      {/* <Header pageTitle="Dashboard" isDashboard={true} /> */}
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography
            id="user_name"
            style={{ fontSize: "20px", fontWeight: 600 }}
          >
            Welcome {profileName}
          </Typography>
        </Grid>
      </Grid>
      {isLoading &&
        <div className="skeleton-dashboard" style={{ width: "100%" }}>
          <Grid container style={{ justifyContent: "space-between", marginTop: "30px" }}>
            <Skeleton style={{ width: "19%", height: 110, transform: "none" }} />
            <Skeleton style={{ width: "19%", height: 110, transform: "none" }} />
            <Skeleton style={{ width: "19%", height: 110, transform: "none" }} />
            <Skeleton style={{ width: "19%", height: 110, transform: "none" }} />
            <Skeleton style={{ width: "19%", height: 110, transform: "none" }} />
          </Grid>
          <Grid container style={{ justifyContent: "space-between", marginTop: "20px" }}>
            <Skeleton style={{ width: "85%", height: 400, transform: "none" }} />
          </Grid>
        </div>}

      <Grid item xs={12}>
        <Grid>
          <DashboardCards
            allMocCount={allMocCount}
            completedMocCount={completedMocCount}
            pendingMocCount={pendingMocCount}
            resubmittedMocCount={resubmittedMocCount}
            rejectedMocCount={rejectedMocCount}
            allMocData={allMocData}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid
            item
            xl={12}
            md={12}
            xs={12}
            className="pl-md"
            style={{ marginTop: "24px" }}
          >
            <Card
              style={{
                marginRight: "0px",
                boxShadow: "none",
                backgroundColor: "#FCFCFC",
                border: "1px solid #E8E8E8",
                padding: 10,
              }}
            >
              {Object.keys(graphData).length > 0 ? (
                <ChartComponent series={graphData} />
              ) : (
                <div>No data available</div>
              )}
            </Card>
          </Grid>
        </Grid>
      </Grid>

    </div>
  );
}

export default Dashboard;