import React, { useRef, useState, useEffect } from "react";
import { Typography, InputLabel, TextField, Button, CircularProgress,FormControlLabel,Radio,FormControl,RadioGroup } from "@mui/material";
import Header from "../app/TopAppBar";
import { useNavigate } from "react-router-dom";
import CloudUpload from "../Asset/Icons/file_upload_icon.svg";
import axios from "axios";
import { trialsData, editMocData } from "../recoil/atoms";
import { useRecoilState } from "recoil";
import API from "../Services/API";
import ImageSlider from "../Inputs/ImageSlider";
import Swal from "sweetalert2";
import EmailPopup from "../Pages/EmailPopup";
import { handleSend } from "../Pages/EmailView";

const TrialsMoc = ({ handleDocumentTab }) => {
  const fileInputRefBefore = useRef(null);
  const fileInputRefAfter = useRef(null);
  const [trials, setTrials] = useRecoilState(trialsData);
  // const [selectedImages, setSelectedImages] = useState([]);
  // const [, setCaptureBeforeActive] = useState(false);
  // const [, setCaptureAfterActive] = useState(false);
  // const [, setImagePathBefore] = useState("");
  // const [, setImagePathAfter] = useState("");
  const navigate = useNavigate();
  const [isEdit] = useState(false);
  // const [, setCloudBeforeActive] = useState(false);
  const [fileUploadBefore] = useState([]);
  // const [fileUploadAfter] = useState([]);
  const [beforeImage, setBeforeImage] = useState([]);
  const [errorMessageBeforeImage, setErrorMessageBeforeImage] = useState('');
  const [errorMessageafterImage, setErrorMessageAfterImage] = useState('');
  const [afterImage, setAfterImage] = useState([]);
  const [uploadFileBefore, setUploadFileBefore] = useState([]);
  const [uploadFileAfter, setUploadFileAfter] = useState([]);
  // const [, setAllImagesData] = useState([]);
  const [, setAnchorEl] = useState(null);
  const [isEditMoc] = useRecoilState(editMocData);
  const [anchorE2, setAnchorE2] = useState(null);
  const [save, setSave] = useState(false);
  const [saveMessage, setSaveMessage] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showSubmitPopup, setShowSubmitPopup] = useState(false);
  const [showUpdatePopup, setShowUpdatePopup] = useState(false);
  const [showApprovePopup, setShowApprovePopup] = useState(false);
  const [showRejectPopup, setShowRejectPopup] = useState(false);
  const [showResubmitPopup, setShowResubmitPopup] = useState(false);
  const [initialLoad, setInitialLoad] = useState(false);
  const [selectedBeforeFiles, setSelectedBeforeFiles] = useState(null);
  // const [selectedAfterFiles, setSelectedAfterFiles] = useState(null);

  const access_token = JSON.parse(localStorage.getItem("accesstoken"));

  useEffect(() => {
    const handlePopup = (setter, delay) => {
      setter(true);
      const timer = setTimeout(() => {
        setter(false);
      }, delay);
      return () => clearTimeout(timer);
    };
    const handlePopups = (showPopup, setShowPopup) => {
      if (showPopup) {
        setInitialLoad(true);
        setTimeout(() => {
          setInitialLoad(false);
          handlePopup(setShowPopup, 1000);
        }, 2000);
      }
    };
    handlePopups(showSuccessPopup, setShowSuccessPopup);
    handlePopups(showUpdatePopup, setShowUpdatePopup);
    handlePopups(showSubmitPopup, setShowSubmitPopup);
    handlePopups(showApprovePopup, setShowApprovePopup);
    handlePopups(showRejectPopup, setShowRejectPopup);
    handlePopups(showResubmitPopup, setShowResubmitPopup);
  }, [showSuccessPopup, showUpdatePopup, showSubmitPopup, showApprovePopup, showRejectPopup, showResubmitPopup]);

  // const handleTabChange = (event, newValue) => {
  //   // Your implementation here
  // };
  //Azure storage code
  // const handleCaptureBeforeGallery = () => {
  //   if (fileInputRefBefore.current) {
  //     fileInputRefBefore.current.click();
  //   }
  // };

  const mocDetData = JSON.parse(localStorage.getItem("mocDataByID"));
  const qualityHeadName = mocDetData.mocForm.quality_name;
  const safetyHeadName = mocDetData.mocForm.safety_name;
  const plantHeadName = mocDetData.mocForm.plant_name;
  const storedUserData = JSON.parse(localStorage.getItem("userDatas"));
  const sponsorName = storedUserData.N1FName + " " + storedUserData.N1LName;
  const userSGID = storedUserData.SGID;
  const userName = storedUserData.FName + " " + storedUserData.LName;
  const userRole = storedUserData.role;
  let disableCondition;
  let mocAdminName;
  let disablePlant;
  if (isEditMoc) {
    disableCondition = (mocDetData.changeImplementation?.id 
      ? ((userSGID === mocDetData.mocForm.user_id) && mocDetData.changeImplementation.implement_status === 'rejected') 
      : (userSGID === mocDetData.mocForm.user_id)) 
      ? false 
      : true;
    mocAdminName = mocDetData.mocForm.authority_name;
    disablePlant = (userSGID === mocDetData?.mocForm?.user_id
      //  && !(userRole === "Quality Head" || userRole === "Safety Head" || userRole === "Plant Head")
    ) || storedUserData.name === mocDetData?.mocForm?.plant_name ? true : false;
  }
console.log("disableCondition",disableCondition)
  const handleCaptureAfterGallery = () => {
    if (fileInputRefAfter.current) {
      fileInputRefAfter.current.captureAttribute = 'gallery'; // Specify 'gallery' to open the gallery
      fileInputRefAfter.current.click(); // Trigger the input element to open the gallery
      fileInputRefAfter.current.onchange = (e) => {
        const selectedFiles = e.target.files;
        const selectedfilesarray = Array.from(e.target.files)
        if (selectedFiles.length > 0) {
          // Process the selected images using the setUploadedBeforeFile function
          const hasInvalidFile = selectedfilesarray.map(file => !file.type.startsWith("image/")).some(isInvalid => isInvalid);
          if (hasInvalidFile) {
            setErrorMessageAfterImage('Please upload a valid image');
          }
          else {
            setErrorMessageAfterImage('');
            setUploadedAfterFile({ target: { files: selectedFiles } });
          }

        }
      };
      fileInputRefAfter.current.value = null;
    }
  };

  const handleCaptureBeforeGallery = () => {
    // Open the gallery for image selection
    if (fileInputRefBefore.current) {
      fileInputRefBefore.current.captureAttribute = 'gallery'; // Specify 'gallery' to open the gallery
      fileInputRefBefore.current.click(); // Trigger the input element to open the gallery
      fileInputRefBefore.current.onchange = (e) => {
        const selectedFiles = e.target.files;
        const selectedfilesarray = Array.from(e.target.files)
        if (selectedFiles.length > 0) {
          // Process the selected images using the setUploadedBeforeFile function
          const hasInvalidFile = selectedfilesarray.map(file => !file.type.startsWith("image/")).some(isInvalid => isInvalid);
          if (hasInvalidFile) {
            setErrorMessageBeforeImage('Please upload a valid image');
          } else {
            setErrorMessageBeforeImage(''); // Clear the error message if all files are valid
            setUploadedBeforeFile({ target: { files: selectedFiles } });
          }

        }
      };
      fileInputRefBefore.current.value = null;
    }
  };

  const handlePopover = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const uploadBlobImagesAPI = async () => {
    const images = fileUploadBefore;
    try {
      const response = await axios.post(
        API.UPLOAD_IMAGES,
        { images },
        {
          headers: {
            Authorization: `Bearer ${access_token}`
          }
        }
      );
      const uploadResponse = response.data;

      if (!uploadResponse) {
        throw new Error("Invalid response format for image upload");
      }

      return uploadResponse; // Return the image names
    } catch (error) {
      console.error("Failed to save area:", error);
      throw error; // Rethrow the error to handle it in the calling function
    }
  };

  // const uploadBlobAfterImagesAPI = async () => {
  //   const images = fileUploadAfter;
  //   try {
  //     const response = await axios.post(API.UPLOAD_IMAGES, { images },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${access_token}`
  //         }
  //       }
  //     );
  //     const uploadResponse = response.data;

  //     if (!uploadResponse) {
  //       throw new Error("Invalid response format for image upload");
  //     }

  //     return uploadResponse; // Return the image names
  //   } catch (error) {
  //     console.error("Failed to save area:", error);
  //     throw error; // Rethrow the error to handle it in the calling function
  //   }
  // };

  //Azure storage code
  // const handleTrialsSaveClick = async () => {
  //   try {
  //     // Upload before images
  //     const uploadResponse = await uploadBlobImagesAPI(fileUploadBefore);
  //     const before_comments = trials.before_comments;
  //     const after_comments = trials.after_comments;
  //     // Process before image data before sending to the server
  //     const imageDataBefore = uploadResponse.map((image, index) => {
  //       const dynamicKey = `img_${index + 1}`;
  //       return {
  //         [dynamicKey]: image.name,
  //       };
  //     });
  //     const { admin_remarks, quality_remarks, safety_remarks } = trials;
  //     // Save before images to the server
  //     const trialsResponse = await axios.post(API.INSERT_TRIALS, {
  //       before: imageDataBefore,
  //       before_comments: before_comments,
  //       after_comments: after_comments,
  //       admin_remarks: admin_remarks,
  //       quality_remarks: quality_remarks,
  //       safety_remarks: safety_remarks,
  //       trials_status: "pending",
  //     },
  //     {
  //       headers: {
  //         Authorization: `Bearer ${access_token}`
  //       }
  //     });
  //     if (trialsResponse.status === 200) {
  //       const trialsID = trialsResponse.data.trialId; // Accessing the returned trial ID
  //       localStorage.setItem("trialsID", trialsID);
  //       await updateMocApprovals(trialsID);
  //       setSave(true);
  //       setShowSuccessPopup(true);
  //     }

  //     If there are after images, upload and save them
  //     if (imageDataBefore.length > 0) {
  //       const afterUploadResponse = await uploadBlobAfterImagesAPI(fileUploadAfter);
  //       const after_comments = trials.after_comments;

  //       // Process after image data before sending to the server
  //       const imageDataAfter = afterUploadResponse.map((image, index) => {
  //         const dynamicKey = `img_after_${index + 1}`;
  //         return {
  //           [dynamicKey]: image.name,
  //           comments: after_comments,
  //         };
  //       });
  //       // Save after images to the server
  //       await axios.post(API.INSERT_TRIALS, {
  //         before: imageDataAfter,
  //         comments: after_comments,
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${access_token}`
  //         }
  //       });
  //     }
  //     Navigate to the next page or handle other logic
  //     navigate("/trialsapproval");
  //   } catch (error) {
  //     console.error("Error saving before:", error);
  //     // Handle error here
  //   }
  // };

  // const handleTrialsSaveClick = async (event) => {
  //   try {
  //     event.preventDefault();
  //     if (!selectedBeforeFiles) return;
  //     const formData = new FormData();
  //     for (let i = 0; i < selectedBeforeFiles.length; i++) {
  //       formData.append('images', selectedBeforeFiles[i]);
  //     }
  //     const before_comments = trials.before_comments;
  //     const after_comments = trials.after_comments;
  //     try {
  //       const uploadResponse = await axios.post(API.UPLOAD_IMAGES, formData, {
  //         headers: {
  //           'Content-Type': 'multipart/form-data',
  //           Authorization: `Bearer ${access_token}`
  //         }
  //       });
  //       let beforeImages;
  //       if (uploadResponse.status === 200) {
  //         beforeImages = uploadResponse.data.filePaths;
  //       }
  //       const { admin_remarks, quality_remarks, safety_remarks } = trials;
  // Save before images to the server
  // const trialsResponse = await axios.post(API.INSERT_TRIALS, {
  //   before: beforeImages,
  //   before_comments: before_comments,
  //   after: uploadFileAfter,
  //   after_comments: after_comments,
  //   admin_remarks: admin_remarks,
  //   quality_remarks: quality_remarks,
  //   safety_remarks: safety_remarks,
  //   trials_status: "pending",
  // },
  // {
  //   headers: {
  //     Authorization: `Bearer ${access_token}`
  //   }
  // });
  //       if (trialsResponse.status === 200) {
  //         const trialsID = trialsResponse.data.trialId; // Accessing the returned trial ID
  //         localStorage.setItem("trialsID", trialsID);
  //         await updateMocApprovals(trialsID);
  //         setSave(true);
  //         setShowSuccessPopup(true);
  //       }
  //     } catch (error) {
  //       alert('Error uploading files');
  //     }
  //   } catch (error) {
  //     console.error("Error saving before:", error);
  //   }
  // };
  const trialsApprID = mocDetData?.trials?.id;

  const handleTrialsSaveClick = async (event) => {
    try {
      const { before_comments, after_comments, admin_remarks, quality_remarks, safety_remarks, plant_remarks,plant_check } = trials;
      // Save before images to the server
      const trialsResponse = await axios.post(API.INSERT_TRIALS, {
        before_comments: before_comments,
        after_comments: after_comments,
        trials_status: "pending",
        admin_remarks: admin_remarks,
        quality_remarks: quality_remarks,
        safety_remarks: safety_remarks,
        plant_remarks: plant_remarks,
        plant_check:plant_check,
        before: uploadFileBefore,
        after: uploadFileAfter,
      },
        {
          headers: {
            Authorization: `Bearer ${access_token}`
          }
        });
      if (trialsResponse.status === 200) {
        const trialsID = trialsResponse.data.identifiers[0].id; // Accessing the returned trial ID
        localStorage.setItem("trialsID", trialsID);
        await updateMocApprovals(trialsID);
        setSave(true);
        setShowSuccessPopup(true);
      }
    } catch (error) {
      console.error("Error saving before:", error);
    }
  };


  // const generateImagesArray = async (files) => {
  //   const beforeImageData = [];

  //   const compressBase64 = (base64, quality) => {
  //     const image = new Image();
  //     image.src = base64;
  //     const canvas = document.createElement("canvas");
  //     const ctx = canvas.getContext("2d");

  //     // Set the canvas dimensions to the image dimensions
  //     canvas.width = image.width;
  //     canvas.height = image.height;

  //     // Draw the image onto the canvas
  //     ctx.drawImage(image, 0, 0, image.width, image.height);

  //     // Convert the canvas content to base64 with reduced quality
  //     return canvas.toDataURL("image/jpeg", quality);
  //   };

  //   // Function to create a closure for the FileReader callback
  //   const processFile = (file, index, quality = 0.8) => {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       const imageType = file.type.split("/")[1];
  //       const imageBase64 = compressBase64(reader.result, quality);
  //       let imageName;

  //       imageName = `image_${Date.now() + index}.${imageType}`;
  //       const imageObject = {
  //         imageType,
  //         imageBase64,
  //         imageName,
  //       };

  //       beforeImageData.push(imageObject);

  //       setBeforeImage((prevImages) => [
  //         ...prevImages,
  //         URL.createObjectURL(file),
  //       ]);

  //       setFileUploadBefore((prevImages) => [...prevImages, imageObject]);
  //       setAllImagesData((prevImagesData) => [...prevImagesData, imageObject]);
  //     };

  //     reader.readAsDataURL(file);
  //   };

  //   // Loop through the selected files and process each file
  //   for (let i = 0; i < files.length; i++) {
  //     processFile(files[i], i);
  //   }
  // };

  // const generateImagesArrayafter = async (fileAfter) => {
  //   const afterImageData = [];

  //   const compressBase64 = (base64, quality) => {
  //     const image = new Image();
  //     image.src = base64;
  //     const canvas = document.createElement("canvas");
  //     const ctx = canvas.getContext("2d");

  //     // Set the canvas dimensions to the image dimensions
  //     canvas.width = image.width;
  //     canvas.height = image.height;

  //     // Draw the image onto the canvas
  //     ctx.drawImage(image, 0, 0, image.width, image.height);

  //     // Convert the canvas content to base64 with reduced quality
  //     return canvas.toDataURL("image/jpeg", quality);
  //   };

  //   // Function to create a closure for the FileReader callback
  //   const processFile = (file, index, quality = 0.8) => {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       const imageType = file.type.split("/")[1];
  //       const imageBase64 = compressBase64(reader.result, quality);
  //       let imageName;

  //       imageName = `image_${Date.now() + index}.${imageType}`;
  //       const imageObject = {
  //         imageType,
  //         imageBase64,
  //         imageName,
  //       };

  //       afterImageData.push(imageObject);

  //       setAfterImage((prevImages) => [
  //         ...prevImages,
  //         URL.createObjectURL(file),
  //       ]);

  //       setFileUploadAfter((prevImages) => [...prevImages, imageObject]);
  //       setAllImagesData((prevImagesData) => [...prevImagesData, imageObject]);
  //     };

  //     reader.readAsDataURL(file);
  //   };

  //   // Loop through the selected files and process each file
  //   for (let i = 0; i < fileAfter.length; i++) {
  //     processFile(fileAfter[i], i);
  //   }
  // };

  //Azure storage code
  // const setUploadedBeforeFile = async (event) => {
  //   const files = event.target.files;
  //   if (files.length > 0) {
  //     await generateImagesArray(files);
  //   } else {
  //     setImagePathBefore("");
  //   }
  // };

  // const setUploadedAfterFile = async (event) => {
  //   const fileAfter = event.target.files;
  //   if (fileAfter) {
  //     await generateImagesArrayafter(fileAfter);
  //   } else {
  //     setImagePathAfter("");
  //   }
  // };

  const displayImageBefore = (files) => {
    const imageBeforeArray = [];
    Array.from(files).forEach(file => {
      const reader = new FileReader();
      reader.onload = (e) => {
        // Set the selected image URLs
        imageBeforeArray.push(e.target.result);
        if (imageBeforeArray.length === files.length) {
          setBeforeImage((prevBeforeImage) => [...prevBeforeImage, ...imageBeforeArray])
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const handleFileChange = (event) => {
    setSelectedBeforeFiles(event.target.files);
  };

  const setUploadedBeforeFile = async (event) => {
    const files = event.target.files;
    displayImageBefore(files);
    if (files.length > 0) {
      const form = new FormData();
      Array.from(files).forEach(file => {
        form.append('file', file, file.name);
      });

      try {
        const response = await axios.post(API.UPLOAD_IMAGES, form, {
          headers: {
            Authorization: `Bearer ${access_token}`
          }
        });
        const result = response.data.filePaths;
        setUploadFileBefore((UploadFileBefore) => [...UploadFileBefore, ...result]);
      } catch (error) {
        // Handle errors
        console.error('Failed to upload images:', error);
      }
    }
  };

  const displayImageAfter = (files) => {
    const imageAfterArray = [];
    Array.from(files).forEach(file => {
      const reader = new FileReader();
      reader.onload = (e) => {
        // Set the selected image URLs
        imageAfterArray.push(e.target.result);
        if (imageAfterArray.length === files.length) {
          setAfterImage((prevBeforeImage) => [...prevBeforeImage, ...imageAfterArray])
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const setUploadedAfterFile = async (event) => {
    const files = event.target.files;
    displayImageAfter(files);
    if (files.length > 0) {
      const form = new FormData();
      Array.from(files).forEach(file => {
        form.append('file', file, file.name);
      });

      try {
        const response = await axios.post(API.UPLOAD_IMAGES, form, {
          headers: {
            Authorization: `Bearer ${access_token}`
          }
        });
        const result = response.data.filePaths;
        setUploadFileAfter((UploadFileAfter) => [...UploadFileAfter, ...result]);
      } catch (error) {
        // Handle errors
        console.error('Failed to upload images:', error);
      }
    }
  };

  // const handleTrialsBeforeCancel = () => {
  //   setCaptureBeforeActive(false);
  //   setImagePathBefore("");
  //   navigate(`/trials`);
  // };

  // const handleTrialsAfterCancel = () => {
  //   setCaptureAfterActive(false);
  //   setImagePathAfter("");
  //   navigate(`/trials`);
  // };

  const handleInputChange = (field, value) => {
    setTrials((prevPlan) => {
      const updatedPlan = typeof prevPlan === "object" ? { ...prevPlan } : {};
      updatedPlan[field] = value;
      return updatedPlan;
    });
  };
  const handleRadioChange = (answer) => {
    setTrials((prev) => ({
      ...prev,
      plant_check: answer,
    }));
  
  };

  // const handleImageSelect = (event) => {
  //   // Assuming this function handles the selection of images
  //   const files = event.target.files;
  //   const urls = [];
  //   for (let i = 0; i < files.length; i++) {
  //     const url = URL.createObjectURL(files[i]);
  //     urls.push(url);
  //   }
  //   setSelectedImages(urls);
  //   setCloudBeforeActive(false);
  //   setCaptureBeforeActive(false);
  // };

  const handleDeleteIconClick = (imageUrl, index) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this image!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "red",
      cancelButtonColor: "gray",
      confirmButtonText: "Yes, delete it",
    }).then((result) => {
      if (result.isConfirmed) {
        setBeforeImage((prevImages) => {
          const newImageList = [...prevImages];
          newImageList.splice(index, 1);
          return newImageList;
        });
        setUploadFileBefore((prevImages) => {
          const newImageList = [...prevImages];
          newImageList.splice(index, 1);
          return newImageList;
        });

        Swal.fire("Deleted!", "Your image has been deleted.", "success");
      }
    });
  };
  const handleAfterDeleteIconClick = (imageUrl, index) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this image!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "red",
      cancelButtonColor: "gray",
      confirmButtonText: "Yes, delete it",
    }).then((result) => {
      if (result.isConfirmed) {
        setAfterImage((prevImages) => {
          const newImageList = [...prevImages];
          newImageList.splice(index, 1);
          return newImageList;
        });
        setUploadFileAfter((prevImages) => {
          const newImageList = [...prevImages];
          newImageList.splice(index, 1);
          return newImageList;
        });

        Swal.fire("Deleted!", "Your image has been deleted.", "success");
      }
    });
  };

  const updateMocApprovals = async () => {
    const updatedMocID = mocDetData.moc_id;
    const approverSGID = storedUserData.N1SGID;
    const userSGID = storedUserData.SGID;
    const userAppName = storedUserData.FName + " " + storedUserData.LName;
    const mocApprovalID = localStorage.getItem("mocApprovalID");
    const actionID = localStorage.getItem("actionPlanID");
    const trialsAppID = localStorage.getItem("trialsID");
    try {
      const mocData = {
        id: mocApprovalID,
        moc_id: updatedMocID,
        approver_sgid: approverSGID,
        user_sgid: userSGID,
        user_approval_name: userAppName,
        actionplan_id: actionID,
        trials_id: trialsAppID,
        status: "pending",
      };
      //console.log(mocData, "mocData678")
      const mocResponse = await axios.put(
        `${API.UPDATE_MOC_APPROVALS}?id=${mocApprovalID}`,
        mocData,
        {
          headers: {
            Authorization: `Bearer ${access_token}`
          }
        }
      );
      if (mocResponse.status === 200) {
        setSave(true);
        setShowSuccessPopup(true);
      }
    } catch (error) {
      console.error("An error occurred in insertIntoMocTable:", error);
    }
  };

  let from_email = [];
  let to_email = [];
  const userInfo = JSON.parse(localStorage.getItem("allUsersData"));
  function getEmailsOfQualityHeads(allUsersData) {
    const qualityHeadEmails = [];

    allUsersData?.forEach(user => {
      if (user.role === "Quality Head") {
        qualityHeadEmails.push(user.email);
      }
    });

    return qualityHeadEmails;
  }

  // Retrieve quality head emails from stored all users data
  const qualityHeadEmails = getEmailsOfQualityHeads(userInfo);

  function getEmailsOfSafetyHeads(allUsersData) {
    const safetyHeadEmails = [];

    allUsersData?.forEach(user => {
      if (user.role === "Safety Head") {
        safetyHeadEmails.push(user.email);
      }
    });

    return safetyHeadEmails;
  }

  // Retrieve quality head emails from stored all users data
  const safetyHeadEmails = getEmailsOfSafetyHeads(userInfo);

  function getEmailsOfPlantHeads(allUsersData) {
    const plantHeadEmails = [];

    allUsersData?.forEach(user => {
      if (user.role === "Plant Head") {
        plantHeadEmails.push(user.email);
      }
    });

    return plantHeadEmails;
  }

  // Retrieve quality head emails from stored all users data
  const plantHeadEmails = getEmailsOfPlantHeads(userInfo);

  if (storedUserData) {
    from_email = [storedUserData?.MailId]
    to_email = [];
    if (mocDetData?.mocForm?.conclusion === "Quality Head") {
      if (!isEditMoc) {
        to_email.push(storedUserData?.N1MailId);
      }

      else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "pending" || mocDetData?.mocForm?.submission_status === "approved") && !mocDetData?.mocForm?.admin_approval_status) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "resubmitted") && !mocDetData?.mocForm?.admin_approval_status) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && mocDetData?.mocForm?.submission_status === "rejected" && !mocDetData?.mocForm?.admin_approval_status) {
        to_email.push(storedUserData?.N1MailId);
      }

      else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "rejected") && mocDetData?.mocForm?.admin_approval_status && !mocDetData?.mocForm?.quality_approval_status) {
        to_email.push(qualityHeadEmails);
      }

      else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "approved") && mocDetData?.mocForm?.admin_approval_status && !mocDetData?.trials_id && !mocDetData?.implement_id) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "approved") && mocDetData?.mocForm?.admin_approval_status && mocDetData?.mocForm?.quality_approval_status && !mocDetData?.mocForm?.plant_approval_status) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "resubmitted") && mocDetData?.mocForm?.admin_approval_status && !mocDetData?.mocForm?.quality_approval_status) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "resubmitted") && mocDetData?.mocForm?.admin_approval_status && mocDetData?.mocForm?.quality_approval_status && !mocDetData?.mocForm?.plant_approval_status) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "rejected") && mocDetData?.mocForm?.admin_approval_status && mocDetData?.mocForm?.quality_approval_status && !mocDetData?.mocForm?.plant_approval_status) {
        to_email.push(plantHeadEmails);
      }

      else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "approved") && mocDetData?.mocForm?.admin_approval_status && mocDetData?.mocForm?.quality_approval_status && mocDetData?.mocForm?.plant_approval_status && !mocDetData?.trials_id && !mocDetData?.implement_id) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && mocDetData?.trials_id && mocDetData?.trials?.trials_status === "rejected" && !mocDetData?.trials?.admin_approval_status) {
        to_email.push(storedUserData?.N1MailId);
      }

      else if (isEditMoc && mocDetData?.trials_id && mocDetData?.trials?.trials_status === "resubmitted" && !mocDetData?.trials?.admin_approval_status) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && mocDetData?.trials_id && mocDetData?.trials?.trials_status === "approved" && mocDetData?.trials?.admin_approval_status && !mocDetData?.implement_id) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && mocDetData?.trials_id && (mocDetData?.trials?.trials_status === "rejected") && mocDetData?.trials?.admin_approval_status && !mocDetData?.trials?.quality_approval_status) {
        to_email.push(qualityHeadEmails);
      }

      else if (isEditMoc && mocDetData?.trials_id && mocDetData?.trials?.trials_status === "resubmitted" && mocDetData?.trials?.admin_approval_status && !mocDetData?.trials?.quality_approval_status) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && mocDetData?.trials_id && (mocDetData?.trials?.trials_status === "rejected") && mocDetData?.trials?.admin_approval_status && mocDetData?.trials?.quality_approval_status && !mocDetData?.trials?.plant_approval_status) {
        to_email.push(plantHeadEmails);
      }

      else if (isEditMoc && mocDetData?.trials_id && mocDetData?.trials?.trials_status === "resubmitted" && mocDetData?.trials?.admin_approval_status && mocDetData?.trials?.quality_approval_status && !mocDetData?.trials?.plant_approval_status) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && mocDetData?.implement_id && (mocDetData?.changeImplementation?.implement_status === "rejected") && !mocDetData?.changeImplementation?.admin_approval_status) {
        to_email.push(storedUserData?.N1MailId);
      }

      else if (isEditMoc && mocDetData?.implement_id && (mocDetData?.changeImplementation?.implement_status === "pending") && !mocDetData?.changeImplementation?.admin_approval_status) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && mocDetData?.implement_id && (mocDetData?.changeImplementation?.implement_status === "resubmitted") && !mocDetData?.changeImplementation?.admin_approval_status) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && mocDetData?.implement_id && (mocDetData?.changeImplementation?.implement_status === "resubmitted") && !mocDetData?.changeImplementation?.admin_approval_status) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && mocDetData?.implement_id && (mocDetData?.changeImplementation?.implement_status === "approved") && mocDetData?.changeImplementation?.admin_approval_status) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && mocDetData?.implement_id && (mocDetData?.changeImplementation?.implement_status === "rejected") && mocDetData?.changeImplementation?.admin_approval_status && !mocDetData?.changeImplementation?.quality_approval_status) {
        to_email.push(qualityHeadEmails);
      }

      else if (isEditMoc && mocDetData?.implement_id && (mocDetData?.changeImplementation?.implement_status === "resubmitted") && mocDetData?.changeImplementation?.admin_approval_status && !mocDetData?.changeImplementation?.quality_approval_status) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && mocDetData?.implement_id && (mocDetData?.changeImplementation?.implement_status === "rejected") && mocDetData?.changeImplementation?.admin_approval_status && mocDetData?.changeImplementation?.quality_approval_status && !mocDetData?.changeImplementation?.plant_approval_status) {
        to_email.push(plantHeadEmails);
      }

      else if (isEditMoc && mocDetData?.implement_id && (mocDetData?.changeImplementation?.implement_status === "resubmitted") && mocDetData?.changeImplementation?.admin_approval_status && mocDetData?.changeImplementation?.quality_approval_status && !mocDetData?.changeImplementation?.plant_approval_status) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }
    }
    else if (mocDetData?.mocForm?.conclusion === "Safety Head") {
      if (!isEditMoc) {
        to_email.push(storedUserData?.N1MailId);
      }

      else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "pending" || mocDetData?.mocForm?.submission_status === "approved") && !mocDetData?.mocForm?.admin_approval_status) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "resubmitted") && !mocDetData?.mocForm?.admin_approval_status) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && mocDetData?.mocForm?.submission_status === "rejected" && !mocDetData?.mocForm?.admin_approval_status) {
        to_email.push(storedUserData?.N1MailId);
      }

      else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "rejected") && mocDetData?.mocForm?.admin_approval_status && !mocDetData?.mocForm?.safety_approval_status) {
        to_email.push(safetyHeadEmails);
      }

      else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "approved") && mocDetData?.mocForm?.admin_approval_status && !mocDetData?.trials_id && !mocDetData?.implement_id) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "approved") && mocDetData?.mocForm?.admin_approval_status && mocDetData?.mocForm?.safety_approval_status && !mocDetData?.mocForm?.plant_approval_status) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "resubmitted") && mocDetData?.mocForm?.admin_approval_status && !mocDetData?.mocForm?.safety_approval_status) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "resubmitted") && mocDetData?.mocForm?.admin_approval_status && mocDetData?.mocForm?.safety_approval_status && !mocDetData?.mocForm?.plant_approval_status) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "rejected") && mocDetData?.mocForm?.admin_approval_status && mocDetData?.mocForm?.safety_approval_status && !mocDetData?.mocForm?.plant_approval_status) {
        to_email.push(plantHeadEmails);
      }

      else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "approved") && mocDetData?.mocForm?.admin_approval_status && mocDetData?.mocForm?.safety_approval_status && mocDetData?.mocForm?.plant_approval_status && !mocDetData?.trials_id && !mocDetData?.implement_id) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && mocDetData?.trials_id && mocDetData?.trials?.trials_status === "rejected" && !mocDetData?.trials?.admin_approval_status) {
        to_email.push(storedUserData?.N1MailId);
      }

      else if (isEditMoc && mocDetData?.trials_id && mocDetData?.trials?.trials_status === "resubmitted" && !mocDetData?.trials?.admin_approval_status) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && mocDetData?.trials_id && mocDetData?.trials?.trials_status === "approved" && mocDetData?.trials?.admin_approval_status && !mocDetData?.implement_id) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && mocDetData?.trials_id && (mocDetData?.trials?.trials_status === "rejected") && mocDetData?.trials?.admin_approval_status && !mocDetData?.trials.safety_approval_status) {
        to_email.push(safetyHeadEmails);
      }

      else if (isEditMoc && mocDetData?.trials_id && mocDetData?.trials?.trials_status === "resubmitted" && mocDetData?.trials?.admin_approval_status && !mocDetData?.trials.safety_approval_status) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && mocDetData?.trials_id && (mocDetData?.trials?.trials_status === "rejected") && mocDetData?.trials?.admin_approval_status && mocDetData?.trials.safety_approval_status && !mocDetData?.trials?.plant_approval_status) {
        to_email.push(plantHeadEmails);
      }

      else if (isEditMoc && mocDetData?.trials_id && mocDetData?.trials?.trials_status === "resubmitted" && mocDetData?.trials?.admin_approval_status && mocDetData?.trials.safety_approval_status && !mocDetData?.trials?.plant_approval_status) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && mocDetData?.implement_id && (mocDetData?.changeImplementation?.implement_status === "rejected") && !mocDetData?.changeImplementation?.admin_approval_status) {
        to_email.push(storedUserData?.N1MailId);
      }

      else if (isEditMoc && mocDetData?.implement_id && (mocDetData?.changeImplementation?.implement_status === "pending") && !mocDetData?.changeImplementation?.admin_approval_status) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && mocDetData?.implement_id && (mocDetData?.changeImplementation?.implement_status === "resubmitted") && !mocDetData?.changeImplementation?.admin_approval_status) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && mocDetData?.implement_id && (mocDetData?.changeImplementation?.implement_status === "resubmitted") && !mocDetData?.changeImplementation?.admin_approval_status) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && mocDetData?.implement_id && (mocDetData?.changeImplementation?.implement_status === "approved") && mocDetData?.changeImplementation?.admin_approval_status) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && mocDetData?.implement_id && (mocDetData?.changeImplementation?.implement_status === "rejected") && mocDetData?.changeImplementation?.admin_approval_status && !mocDetData?.changeImplementation.safety_approval_status) {
        to_email.push(safetyHeadEmails);
      }

      else if (isEditMoc && mocDetData?.implement_id && (mocDetData?.changeImplementation?.implement_status === "resubmitted") && mocDetData?.changeImplementation?.admin_approval_status && !mocDetData?.changeImplementation.safety_approval_status) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && mocDetData?.implement_id && (mocDetData?.changeImplementation?.implement_status === "rejected") && mocDetData?.changeImplementation?.admin_approval_status && mocDetData?.changeImplementation.safety_approval_status && !mocDetData?.changeImplementation?.plant_approval_status) {
        to_email.push(plantHeadEmails);
      }

      else if (isEditMoc && mocDetData?.implement_id && (mocDetData?.changeImplementation?.implement_status === "resubmitted") && mocDetData?.changeImplementation?.admin_approval_status && mocDetData?.changeImplementation.safety_approval_status && !mocDetData?.changeImplementation?.plant_approval_status) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }
    }
    else if (mocDetData?.mocForm?.conclusion === "Non Critical") {
      if (!isEditMoc) {
        to_email.push(storedUserData?.N1MailId);
      }

      else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "pending" || mocDetData?.mocForm?.submission_status === "approved") && !mocDetData?.mocForm?.admin_approval_status) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "resubmitted") && !mocDetData?.mocForm?.admin_approval_status) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && mocDetData?.mocForm?.submission_status === "rejected" && !mocDetData?.mocForm?.admin_approval_status) {
        to_email.push(storedUserData?.N1MailId);
      }

      else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "approved") && mocDetData?.mocForm?.admin_approval_status && !mocDetData?.trials_id && !mocDetData?.implement_id) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && mocDetData?.trials_id && mocDetData?.trials?.trials_status === "rejected" && !mocDetData?.trials?.admin_approval_status) {
        to_email.push(storedUserData?.N1MailId);
      }

      else if (isEditMoc && mocDetData?.trials_id && (mocDetData?.trials?.trials_status === "pending") && !mocDetData?.trials?.admin_approval_status) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && mocDetData?.trials_id && mocDetData?.trials?.trials_status === "resubmitted" && !mocDetData?.trials?.admin_approval_status) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && mocDetData?.trials_id && mocDetData?.trials?.trials_status === "approved" && mocDetData?.trials?.admin_approval_status && !mocDetData?.implement_id) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && mocDetData?.implement_id && (mocDetData?.changeImplementation?.implement_status === "rejected") && !mocDetData?.changeImplementation?.admin_approval_status) {
        to_email.push(storedUserData?.N1MailId);
      }

      else if (isEditMoc && mocDetData?.implement_id && (mocDetData?.changeImplementation?.implement_status === "pending") && !mocDetData?.changeImplementation?.admin_approval_status) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && mocDetData?.implement_id && (mocDetData?.changeImplementation?.implement_status === "resubmitted") && !mocDetData?.changeImplementation?.admin_approval_status) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && mocDetData?.implement_id && (mocDetData?.changeImplementation?.implement_status === "resubmitted") && !mocDetData?.changeImplementation?.admin_approval_status) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && mocDetData?.implement_id && (mocDetData?.changeImplementation?.implement_status === "approved") && mocDetData?.changeImplementation?.admin_approval_status) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }
    }
    else if (mocDetData?.mocForm?.conclusion === "Safety & Quality") {
      if (!isEditMoc) {
        to_email.push(storedUserData?.N1MailId);
      }

      else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "pending" || mocDetData?.mocForm?.submission_status === "approved") && !mocDetData?.mocForm?.admin_approval_status) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "resubmitted") && !mocDetData?.mocForm?.admin_approval_status) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && mocDetData?.mocForm?.submission_status === "rejected" && !mocDetData?.mocForm?.admin_approval_status) {
        to_email.push(storedUserData?.N1MailId);
      }

      else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "rejected") && mocDetData?.mocForm?.admin_approval_status && !mocDetData?.mocForm?.quality_approval_status) {
        to_email.push(qualityHeadEmails);
      }

      else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "approved") && mocDetData?.mocForm?.admin_approval_status && !mocDetData?.trials_id && !mocDetData?.implement_id) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "approved") && mocDetData?.mocForm?.admin_approval_status && mocDetData?.mocForm?.quality_approval_status && !mocDetData?.mocForm?.plant_approval_status) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "resubmitted") && mocDetData?.mocForm?.admin_approval_status && !mocDetData?.mocForm?.quality_approval_status) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "resubmitted") && mocDetData?.mocForm?.admin_approval_status && mocDetData?.mocForm?.quality_approval_status && !mocDetData?.mocForm?.plant_approval_status) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "rejected") && mocDetData?.mocForm?.admin_approval_status && mocDetData?.mocForm?.quality_approval_status && !mocDetData?.mocForm?.safety_approval_status && !mocDetData?.mocForm?.plant_approval_status) {
        to_email.push(safetyHeadEmails);
      }

      else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "rejected") && mocDetData?.mocForm?.admin_approval_status && mocDetData?.mocForm?.quality_approval_status && mocDetData?.mocForm?.safety_approval_status && !mocDetData?.mocForm?.plant_approval_status) {
        to_email.push(plantHeadEmails);
      }

      else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "approved") && mocDetData?.mocForm?.admin_approval_status && mocDetData?.mocForm?.quality_approval_status && mocDetData?.mocForm?.safety_approval_status && mocDetData?.mocForm?.plant_approval_status && !mocDetData?.trials_id && !mocDetData?.implement_id) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      //2nd

      else if (isEditMoc && mocDetData?.trials_id && mocDetData?.trials?.trials_status === "rejected" && !mocDetData?.trials?.admin_approval_status) {
        to_email.push(storedUserData?.N1MailId);
      }

      else if (isEditMoc && mocDetData?.trials_id && mocDetData?.trials?.trials_status === "resubmitted" && !mocDetData?.trials?.admin_approval_status) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && mocDetData?.trials_id && mocDetData?.trials?.trials_status === "approved" && mocDetData?.trials?.admin_approval_status && !mocDetData?.implement_id) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && mocDetData?.trials_id && (mocDetData?.trials?.trials_status === "rejected") && mocDetData?.trials?.admin_approval_status && !mocDetData?.trials?.quality_approval_status) {
        to_email.push(qualityHeadEmails);
      }

      else if (isEditMoc && mocDetData?.trials_id && mocDetData?.trials?.trials_status === "resubmitted" && mocDetData?.trials?.admin_approval_status && !mocDetData?.trials?.quality_approval_status) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && mocDetData?.trials_id && (mocDetData?.trials?.trials_status === "rejected") && mocDetData?.trials?.admin_approval_status && mocDetData?.trials?.quality_approval_status && !mocDetData?.trials.safety_approval_status && !mocDetData?.trials?.plant_approval_status) {
        to_email.push(safetyHeadEmails);
      }

      else if (isEditMoc && mocDetData?.trials_id && (mocDetData?.trials?.trials_status === "rejected") && mocDetData?.trials?.admin_approval_status && mocDetData?.trials?.quality_approval_status && mocDetData?.trials.safety_approval_status && !mocDetData?.trials?.plant_approval_status) {
        to_email.push(plantHeadEmails);
      }

      else if (isEditMoc && mocDetData?.trials_id && mocDetData?.trials?.trials_status === "resubmitted" && mocDetData?.trials?.admin_approval_status && mocDetData?.trials?.quality_approval_status && !mocDetData?.trials?.plant_approval_status) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && mocDetData?.implement_id && (mocDetData?.changeImplementation?.implement_status === "rejected") && !mocDetData?.changeImplementation?.admin_approval_status) {
        to_email.push(storedUserData?.N1MailId);
      }

      else if (isEditMoc && mocDetData?.implement_id && (mocDetData?.changeImplementation?.implement_status === "pending") && !mocDetData?.changeImplementation?.admin_approval_status) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && mocDetData?.implement_id && (mocDetData?.changeImplementation?.implement_status === "resubmitted") && !mocDetData?.changeImplementation?.admin_approval_status) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && mocDetData?.implement_id && (mocDetData?.changeImplementation?.implement_status === "resubmitted") && !mocDetData?.changeImplementation?.admin_approval_status) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && mocDetData?.implement_id && (mocDetData?.changeImplementation?.implement_status === "approved") && mocDetData?.changeImplementation?.admin_approval_status) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && mocDetData?.implement_id && (mocDetData?.changeImplementation?.implement_status === "rejected") && mocDetData?.changeImplementation?.admin_approval_status && !mocDetData?.changeImplementation?.quality_approval_status) {
        to_email.push(qualityHeadEmails);
      }

      else if (isEditMoc && mocDetData?.implement_id && (mocDetData?.changeImplementation?.implement_status === "resubmitted") && mocDetData?.changeImplementation?.admin_approval_status && !mocDetData?.changeImplementation?.quality_approval_status) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }

      else if (isEditMoc && mocDetData?.implement_id && (mocDetData?.changeImplementation?.implement_status === "rejected") && mocDetData?.changeImplementation?.admin_approval_status && mocDetData?.changeImplementation?.quality_approval_status && !mocDetData?.changeImplementation.safety_approval_status && !mocDetData?.changeImplementation?.plant_approval_status) {
        to_email.push(safetyHeadEmails);
      }

      else if (isEditMoc && mocDetData?.implement_id && (mocDetData?.changeImplementation?.implement_status === "rejected") && mocDetData?.changeImplementation?.admin_approval_status && mocDetData?.changeImplementation?.quality_approval_status && !mocDetData?.changeImplementation?.plant_approval_status) {
        to_email.push(plantHeadEmails);
      }

      else if (isEditMoc && mocDetData?.implement_id && (mocDetData?.changeImplementation?.implement_status === "resubmitted") && mocDetData?.changeImplementation?.admin_approval_status && mocDetData?.changeImplementation?.quality_approval_status && !mocDetData?.changeImplementation?.plant_approval_status) {
        to_email.push(mocDetData?.mocForm?.createdby_email);
      }
    }
  }
  else {
    //console.log('storedUserData is empty');
  }

  const handleTrialsSubmitClick = () => {
    try {
      // handleSend(from_email, to_email);
      if (save || isEditMoc) {
        setTimeout(() => {
          navigate("/ManageMoc");
        }, 1000);
        setShowSubmitPopup(true);
      } else {
        setSaveMessage("Failed to save. Please try again.");
      }
    } catch (error) {
      console.error("Error fetching MOC details:", error);
    }
    // if (!anchorE2) {
    //   setAnchorE2(document.body);
    // }
  }

  //Azure storage code
  // const handleTrialsReSubmitClick = async () => {
  //   try {
  //     const trialApprovalId = localStorage.getItem("trialsID");
  //     // Upload before images
  //     const uploadResponse = await uploadBlobImagesAPI(fileUploadBefore);
  //     const before_comments = trials.before_comments;
  //     const after_comments = trials.after_comments;
  //     // Process before image data before sending to the server
  //     const imageDataBefore = uploadResponse.map((image, index) => {
  //       const dynamicKey = `img_${index + 1}`;
  //       return {
  //         [dynamicKey]: image.name,
  //       };
  //     });
  //     const { admin_remarks, quality_remarks, safety_remarks } = trials;
  //     const mocData = {
  //       before: imageDataBefore,
  //       before_comments: before_comments,
  //       after_comments: after_comments,
  //       admin_remarks: admin_remarks,
  //       quality_remarks: quality_remarks,
  //       safety_remarks: safety_remarks,
  //       trials_status: "resubmitted"
  //     };
  // const mocResponse = await axios.put(
  //   `${API.UPDATE_TRIALS}?id=${trialApprovalId}`,
  //   mocData,
  //   {
  //     headers: {
  //       Authorization: `Bearer ${access_token}`
  //     }
  //   }
  // );
  //     if (mocResponse.status === 200) {
  //       // setShowResubmitPopup(true);
  //       await insertMocApprovals("resubmitted", trialApprovalId);
  //     }
  //     if (!anchorE2) {
  //       setAnchorE2(document.body);
  //     }
  //     // if (save || isEditMoc) {
  //     //     setTimeout(() => {
  //     //         navigate("/ManageMoc");
  //     //     }, 4000);
  //     // } else {
  //     //     setSaveMessage("Failed to save. Please try again.");
  //     // }
  //   } catch (error) {
  //     console.error("Error handling trial approval resubmission:", error);
  //     // Handle the error here, e.g., show an error message to the user
  //   }
  // };

  // const handleTrialsReSubmitClick = async (event) => {
  //   try {
  //     const trialApprovalId = localStorage.getItem("trialsID");
  //     event.preventDefault();
  //     if (!selectedBeforeFiles) return;
  //     const formData = new FormData();
  //     for (let i = 0; i < selectedBeforeFiles.length; i++) {
  //       formData.append('images', selectedBeforeFiles[i]);
  //     }
  //     const before_comments = trials.before_comments;
  //     const after_comments = trials.after_comments;
  //     const { admin_remarks, quality_remarks, safety_remarks } = trials;
  //     try {
  //       const uploadResponse = await axios.post(API.UPLOAD_IMAGES, formData, {
  //         headers: {
  // 'Content-Type': 'multipart/form-data',
  // Authorization: `Bearer ${access_token}`
  //         }
  //       });
  //       let beforeImages;
  //       if (uploadResponse.status === 200) {
  //         beforeImages = uploadResponse.data.filePaths.map(path => path.substring(path.lastIndexOf('\\') + 1));
  //       }
  //       const mocData = {
  //         before: beforeImages,
  //         before_comments: before_comments,
  //         after: uploadFileAfter,
  //         after_comments: after_comments,
  //         admin_remarks: admin_remarks,
  //         quality_remarks: quality_remarks,
  //         safety_remarks: safety_remarks,
  //         trials_status: "resubmitted"
  //       };
  //       const mocResponse = await axios.put(
  //         `${API.UPDATE_TRIALS}?id=${trialApprovalId}`,
  //   mocData,
  //   {
  //     headers: {
  //       Authorization: `Bearer ${access_token}`
  //     }
  //   }
  // );
  //       if (mocResponse.status === 200) {
  //         // setShowResubmitPopup(true);
  //         await insertMocApprovals("resubmitted", trialApprovalId);
  //       }
  //       if (!anchorE2) {
  //         setAnchorE2(document.body);
  //       }
  //     } catch (error) {
  //       alert('Error uploading files');
  //     }
  //   } catch (error) {
  //     console.error("Error handling trial approval resubmission:", error);
  //   }
  // };

  const handleTrialsReSubmitClick = async (event) => {
    const trialsAppID = localStorage.getItem("trialsID");
    const admin = mocDetData.mocForm.admin;
    try {
      const before_comments = trials.before_comments;
      const after_comments = trials.after_comments;
      const { admin_remarks, quality_remarks, safety_remarks, plant_remarks } = trials;
      const trialAppData = {
        id: trialsApprID,
        before_comments: before_comments,
        after_comments: after_comments,
        admin_remarks: admin_remarks,
        quality_remarks: quality_remarks,
        safety_remarks: safety_remarks,
        plant_remarks: plant_remarks,
        plant_check:trials.plant_check,
        trials_status: "resubmitted",
        before: uploadFileBefore,
        after: uploadFileAfter,
        admin_approval_status: userSGID === admin ? true : mocDetData.trials.admin_approval_status,
        quality_approval_status: storedUserData.name === qualityHeadName ? true : mocDetData.trials.quality_approval_status,
        safety_approval_status: storedUserData.name === safetyHeadName ? true : mocDetData.trials.safety_approval_status,
        plant_approval_status: storedUserData.name === plantHeadName ? true : mocDetData.trials.plant_approval_status
      };
      const mocResponse = await axios.put(
        `${API.UPDATE_TRIALS}?id=${trialsApprID}`,
        trialAppData,
        {
          headers: {
            Authorization: `Bearer ${access_token}`
          }
        }
      );
      if (mocResponse.status === 200) {
        setShowResubmitPopup(true);
        await insertMocApprovals("resubmitted", trialsAppID);
        // handleSend(from_email, to_email);
        setTimeout(() => {
          navigate("/ManageMoc");
        }, 4000);
        // if (!anchorE2) {
        //   setAnchorE2(document.body);
        // }
      }
    } catch (error) {
      console.error("Error handling trial approval resubmission:", error);
    }
  };

  const insertMocApprovals = async (approvalStatus) => {
    const approverSGID = storedUserData.N1SGID;
    const userSGID = storedUserData.SGID;
    const userAppName = storedUserData.FName + " " + storedUserData.LName;
    const actionIds = mocDetData.actionplan_id;
    const updatedMocID = mocDetData.moc_id;
    const trialsAppID = mocDetData.trials_id;
    try {
      const mocData = {
        moc_id: updatedMocID,
        approver_sgid: approverSGID,
        user_sgid: userSGID,
        user_approval_name: userAppName,
        actionplan_id: actionIds,
        trials_id: trialsAppID,
        status: approvalStatus
      };
      const mocResponse = await axios.post(API.INSERT_MOC_APPROVALS, mocData, {
        headers: {
          Authorization: `Bearer ${access_token}`
        }
      });
      if (mocResponse.status === 200) {
        const mocApprovalID = mocResponse.data["identifiers"][0].id;
        localStorage.setItem("mocApprovalID", mocApprovalID);
      }
      // if (isEditMoc) {
      //   setTimeout(() => {
      //     navigate("/ManageMoc");
      //   }, 4000);
      // }
    } catch (error) {
      console.error("An error occurred in insertIntoMocTable:", error);
    }
  };

  // Azure storage code
  // const handleTrialsRejectClick = async () => {
  //   const storedMocDataString = localStorage.getItem("mocDataByID");
  //   const storedMocData = JSON.parse(storedMocDataString);
  //   const trialsAppID = storedMocData.trials_id;
  //   try {
  //     // if (!conclu.admin_remarks) {
  //     //     setErrors(true);
  //     //     setStepError(true);
  //     //     setSaveMessage(false);
  //     //     return;
  //     // } else {
  //     //     setErrors(false);
  //     //     setStepError(false);
  //     //     setSaveMessage(false);
  //     // }
  //     // Upload before images
  //     const uploadResponse = await uploadBlobImagesAPI(fileUploadBefore);
  //     const before_comments = trials.before_comments;
  //     const after_comments = trials.after_comments;
  //     // Process before image data before sending to the server
  //     const imageDataBefore = uploadResponse.map((image, index) => {
  //       const dynamicKey = `img_${index + 1}`;
  //       return {
  //         [dynamicKey]: image.name,
  //       };
  //     });
  //     const { admin_remarks, quality_remarks, safety_remarks } = trials;
  //     // Save before images to the server
  //     const trialAppData = {
  //       id: trialsAppID,
  //       // before: imageDataBefore,
  //       before: imageDataBefore,
  //       before_comments: before_comments,
  //       after_comments: after_comments,
  //       admin_remarks: admin_remarks,
  //       quality_remarks: quality_remarks,
  //       safety_remarks: safety_remarks,
  //       trials_status: "rejected"
  //     };
  //     const mocResponse = await axios.put(
  //       `${API.UPDATE_TRIALS}?id=${trialsAppID}`,
  //   trialAppData,
  //   {
  //     headers: {
  //       Authorization: `Bearer ${access_token}`
  //     }
  //   }
  // );
  //     if (mocResponse.status === 200) {
  //       // setShowRejectPopup(true);
  //       await insertMocApprovals("rejected", trialsAppID);
  //     }
  //     if (!anchorE2) {
  //       setAnchorE2(document.body);
  //     }
  //   } catch (error) {
  //     console.error("An error occurred in handleConclusionRejectClick:", error);
  //   }
  // }

  // const handleTrialsRejectClick = async () => {
  //   const storedMocDataString = localStorage.getItem("mocDataByID");
  //   const storedMocData = JSON.parse(storedMocDataString);
  //   const trialsAppID = storedMocData.trials_id;
  //   try {
  //     // if (!conclu.admin_remarks) {
  //     //     setErrors(true);
  //     //     setStepError(true);
  //     //     setSaveMessage(false);
  //     //     return;
  //     // } else {
  //     //     setErrors(false);
  //     //     setStepError(false);
  //     //     setSaveMessage(false);
  //     // }
  //     // Upload before images
  //     const uploadResponse = await uploadBlobImagesAPI(fileUploadBefore);
  //     const before_comments = trials.before_comments;
  //     const after_comments = trials.after_comments;
  //     // Process before image data before sending to the server
  //     const imageDataBefore = uploadResponse.map((image, index) => {
  //       const dynamicKey = `img_${index + 1}`;
  //       return {
  //         [dynamicKey]: image.name,
  //       };
  //     });
  //     const { admin_remarks, quality_remarks, safety_remarks } = trials;
  //     // Save before images to the server
  //     const trialAppData = {
  //       id: trialsAppID,
  //       // before: imageDataBefore,
  //       before: imageDataBefore,
  //       before_comments: before_comments,
  //       after_comments: after_comments,
  //       admin_remarks: admin_remarks,
  //       quality_remarks: quality_remarks,
  //       safety_remarks: safety_remarks,
  //       trials_status: "rejected"
  //     };
  //     const mocResponse = await axios.put(
  //       `${API.UPDATE_TRIALS}?id=${trialsAppID}`,
  //   trialAppData,
  //   {
  //     headers: {
  //       Authorization: `Bearer ${access_token}`
  //     }
  //   }
  // );
  //     if (mocResponse.status === 200) {
  //       // setShowRejectPopup(true);
  //       await insertMocApprovals("rejected", trialsAppID);
  //     }
  //     if (!anchorE2) {
  //       setAnchorE2(document.body);
  //     }
  //   } catch (error) {
  //     console.error("An error occurred in handleConclusionRejectClick:", error);
  //   }
  // }


  const handleTrialsRejectClick = async () => {
    try {
      const { before_comments, after_comments, admin_remarks, quality_remarks, safety_remarks, plant_remarks,plant_check } = trials;
      const trialAppData = {
        id: trialsApprID,
        before_comments: before_comments,
        after_comments: after_comments,
        admin_remarks: admin_remarks,
        quality_remarks: quality_remarks,
        safety_remarks: safety_remarks,
        plant_remarks: plant_remarks,
        plant_check:plant_check,
        trials_status: "rejected"
      };
      const mocResponse = await axios.put(
        `${API.UPDATE_TRIALS}?id=${trialsApprID}`,
        trialAppData,
        {
          headers: {
            Authorization: `Bearer ${access_token}`
          }
        }
      );
      if (mocResponse.status === 200) {
        setShowRejectPopup(true);
        await insertMocApprovals("rejected", trialsApprID);
        // handleSend(from_email, to_email);
        setTimeout(() => {
          navigate("/ManageMoc");
        }, 4000);
        // if (!anchorE2) {
        //   setAnchorE2(document.body);
        // }
      }
    } catch (error) {
      console.error("An error occurred in handleConclusionRejectClick:", error);
    }
  }

  // Azure storage code
  // const handleTrialsApproveClick = async () => {
  //   const trialsAppID = mocDetData.trials_id;
  //   try {
  //     // if (!conclu.admin_remarks) {
  //     //     setErrors(true);
  //     //     setStepError(true);
  //     //     setSaveMessage(false);
  //     //     return;
  //     // } else {
  //     //     setErrors(false);
  //     //     setStepError(false);
  //     //     setSaveMessage(false);
  //     // }
  //     // Upload before images
  //     const uploadResponse = await uploadBlobImagesAPI(fileUploadBefore);
  //     const before_comments = trials.before_comments;
  //     const after_comments = trials.after_comments;
  //     // Process before image data before sending to the server
  //     const imageDataBefore = uploadResponse.map((image, index) => {
  //       const dynamicKey = `img_${index + 1}`;
  //       return {
  //         [dynamicKey]: image.name,
  //       };
  //     });
  //     const { admin_remarks, quality_remarks, safety_remarks } = trials;
  //     // Save before images to the server
  //     const trialAppData = {
  //       id: trialsAppID,
  //       // before: imageDataBefore,
  //       before_comments: before_comments,
  //       after_comments: after_comments,
  //       admin_remarks: admin_remarks,
  //       quality_remarks: quality_remarks,
  //       safety_remarks: safety_remarks,
  //       trials_status: "approved"
  //     };
  //     const mocResponse = await axios.put(
  //       `${API.UPDATE_TRIALS}?id=${trialsAppID}`,
  //   trialAppData,
  //   {
  //     headers: {
  //       Authorization: `Bearer ${access_token}`
  //     }
  //   }
  // );
  //     if (mocResponse.status === 200) {
  //       // setShowApprovePopup(true);
  //       await insertMocApprovals("approved", trialsAppID);
  //     }
  //     if (!anchorE2) {
  //       setAnchorE2(document.body);
  //     }
  //   } catch (error) {
  //     console.error("An error occurred in handleConclusionRejectClick:", error);
  //   }
  // }

  const handleTrialsApproveClick = async () => {
    const admin = mocDetData.mocForm.admin;
    try {
      const before_comments = trials.before_comments;
      const after_comments = trials.after_comments;
      const { admin_remarks, quality_remarks, safety_remarks, plant_remarks,plant_check} = trials;
      const trialAppData = {
        id: trialsApprID,
        before_comments: before_comments,
        after_comments: after_comments,
        admin_remarks: admin_remarks,
        quality_remarks: quality_remarks,
        safety_remarks: safety_remarks,
        plant_remarks: plant_remarks,
        plant_check:plant_check,
        trials_status: "approved",
        before: uploadFileBefore,
        after: uploadFileAfter,
        admin_approval_status: userSGID === admin ? true : mocDetData.trials.admin_approval_status,
        quality_approval_status: storedUserData.name === qualityHeadName && mocDetData.trials.admin_approval_status ? true : mocDetData.trials.quality_approval_status,
        safety_approval_status: storedUserData.name === safetyHeadName && mocDetData.trials.admin_approval_status ? true : mocDetData.trials.safety_approval_status,
        plant_approval_status: storedUserData.name === plantHeadName && mocDetData.trials.admin_approval_status ? true : mocDetData.trials.plant_approval_status
      };
      const mocResponse = await axios.put(
        `${API.UPDATE_TRIALS}?id=${trialsApprID}`,
        trialAppData,
        {
          headers: {
            Authorization: `Bearer ${access_token}`
          }
        }
      );
      if (mocResponse.status === 200) {
        setShowApprovePopup(true);
        await insertMocApprovals("approved", trialsApprID);
        // handleSend(from_email, to_email);
        setTimeout(() => {
          navigate("/ManageMoc");
        }, 4000);
        // if (!anchorE2) {
        //   setAnchorE2(document.body);
        // }
      }
    } catch (error) {
      console.error("An error occurred in handleConclusionRejectClick:", error);
    }
  }

  useEffect(() => {
    if (isEditMoc) {
      const getTrials = async () => {
        const storedMocDataString = localStorage.getItem("mocDataByID");
        const storedMocData = JSON.parse(storedMocDataString);
        const trialsAppID = storedMocData.trials_id;
        try {
          const response = await axios.get(
            `${API.GET_TRIALS}?id=${trialsAppID}`,
            {
              headers: {
                Authorization: `Bearer ${access_token}`
              }
            });
          const trialsApproval = await response.data;
          setTrials(trialsApproval);
          const beforeimagenames = trialsApproval.before.map(path => path.split(/[\\/]/).pop());
          const afterimagenames = trialsApproval.after.map(path => path.split(/[\\/]/).pop());
          const response1 = await axios.post(
            API.SHOW_IMAGES,
            {
              beforeimagename: beforeimagenames
            },
            {
              headers: {
                Authorization: `Bearer ${access_token}`
              }
            }
          );
          setBeforeImage(response1.data)
          setUploadFileBefore(trialsApproval.before)
          const response2 = await axios.post(
            API.SHOW_IMAGES,
            {
              beforeimagename: afterimagenames
            },
            {
              headers: {
                Authorization: `Bearer ${access_token}`
              }
            }
          );
          setAfterImage(response2.data)
          setUploadFileAfter(trialsApproval.after)
        } catch (error) {
          console.error("Error fetching MOC details:", error);
        }
      };
      getTrials();
    }
  }, [setTrials, isEditMoc]);

  const handleCloseEmailPopup = () => {
    setAnchorE2(null);
  }

  const handleCancel = () => {
    navigate("/ManageMoc");
  }

  const handleNext = () => {
    handleDocumentTab();
  }
  // const handleTrialsNextClick = () => {
  //   handleDocumentTab();
  // }

  return (
    <div>
      {/* <Header pageTitle="MOC Action Sheet" /> */}
      <div style={{ paddingLeft: 20 }}>
        <Typography
          styl={{ fontSize: 18, marginTop: 50, fontWeight: 600, color: "#000" }}
        >
          DETAILS OF TRIALS
        </Typography>
        <Typography styl={{ fontSize: 14, marginTop: 50, fontWeight: 400 }}>
          (If Applicable And Carried Out)
        </Typography>
      </div>
      <div
        style={{
          marginTop: "10px",
          width: "90%",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          paddingLeft: 17,
        }}
      >
        {errorMessageBeforeImage && <p style={{ color: 'red', marginBottom: '16px' }}>{errorMessageBeforeImage}</p>}
        <input
          id="upload-image"
          multiple
          type="file"
          name="images"
          style={{ display: "none" }}
          onChange={handleFileChange}
          ref={fileInputRefBefore}
        />
        <InputLabel
          style={{
            fontWeight: 600,
            fontStyle: "normal",
            fontFamily: "Inter, sans-serif",
            color: "#000",
            fontSize: 16,
          }}
        >
          Before
        </InputLabel>
        <div
          style={{
            border: "2px dashed #B1174B",
            flex: 1,
            display: "flex",
            width: "100%",
            height: 300,
            marginTop: 5,
            marginRight: 20,
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "6px",
          }}
        >
          <input
            type="file"
            accept="image/*"
            // onChange={handleImageSelect}
            style={{ display: "none" }}
            id="image-upload"
            multiple
          />
          {!beforeImage.length > 0 ? (
            <>
              <label htmlFor="image-upload">
                <button
                  style={{
                    width: 30,
                    height: 30,
                    marginLeft: 10,
                    marginBottom: 10,
                    cursor: "pointer",
                    padding: 0,
                    border: "none",
                    background: "none",
                    cursor: disableCondition ? "not-allowed" : "pointer", // Change cursor style if disabled
                  }}
                  aria-label="Upload File"
                  disabled={disableCondition}
                >
                  <img
                    src={CloudUpload}
                    alt="Upload File"
                    style={{ width: "100%", 
                      height: "100%",
                      cursor: disableCondition ? "not-allowed" : "pointer",
                     }}
                    onClick={handleCaptureBeforeGallery}
                  />
                </button>
              </label>
              <Typography
                   onClick={!disableCondition ? handleCaptureBeforeGallery : undefined} 
                style={{
                  textAlign: "center",
                  marginLeft: 15,
                  color: "black",
                  fontWeight: 600,
                  width: 170,
                  fontSize: 16,
                  cursor: disableCondition ? "not-allowed" : "pointer", // Change cursor style if disabled
                }}
                disabled={disableCondition}
              >
                Click to Upload
              </Typography>
            </>
          ) : (
            <></>
          )}
        </div>

        <div style={{ marginTop: "30px" }}>
          {beforeImage.length > 0 && (
            <div>
              <ImageSlider
                images={beforeImage}
                isEdit={true}
                onDeleteIconClick={handleDeleteIconClick}
                handlePopover={handleCaptureBeforeGallery}
                disablecondition={disableCondition}
              />
            </div>
          )}
        </div>
        <div
          style={{
            marginTop: "5px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            paddingLeft: 3,
            paddingRight: 10,
          }}
        >
          <InputLabel
            style={{
              fontWeight: 500,
              fontStyle: "normal",
              fontFamily: "Inter, sans-serif",
              color: "#000",
              marginBottom: 5,
            }}
            htmlFor="department1"
          >
            Before Comments
          </InputLabel>
          <TextField
            style={{ width: "100%", marginTop: 10, background: "#FFFFFF" }}
            id="outlined-multiline-static"
            value={trials.before_comments}
            onChange={(e) =>
              handleInputChange("before_comments", e.target.value)
            }
            disabled={disableCondition}
          />
        </div>
      </div>
      <div
        style={{
          marginTop: "10px",
          width: "90%",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          paddingLeft: 17,
        }}
      >
        {errorMessageafterImage && <p style={{ color: 'red', marginBottom: '16px' }}>{errorMessageafterImage}</p>}
        <input
          id="upload-image"
          multiple
          type="file"
          style={{ display: "none" }}
          // onChange={setUploadedAfterFile}
          ref={fileInputRefAfter}
        />
        <InputLabel
          style={{
            fontWeight: 600,
            fontStyle: "normal",
            fontFamily: "Inter, sans-serif",
            color: "#000",
            fontSize: 16,
          }}
        >
          After
        </InputLabel>
        <div
          style={{
            border: "2px dashed #B1174B",
            flex: 1,
            display: "flex",
            width: "100%",
            height: 300,
            marginTop: 5,
            marginRight: 20,
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "6px",
          }}
        >
          <input
            type="file"
            accept="image/*"
            // onChange={handleImageSelect}
            style={{ display: "none" }}
            id="image-upload"
            multiple
          />
          {!afterImage.length > 0 ? (
            <>
              <label htmlFor="image-upload">
                <button
                  style={{
                    width: 30,
                    height: 30,
                    marginLeft: 10,
                    marginBottom: 10,
                    cursor: "pointer",
                    padding: 0,
                    border: "none",
                    background: "none",
                    cursor: disableCondition ? "not-allowed" : "pointer", // Change cursor style if disabled
                  }}
                  aria-label="Upload File"
                  disabled={disableCondition}
                >
                  <img
                    src={CloudUpload}
                    alt="Upload File"
                    style={{ width: "100%", height: "100%",
                      cursor: disableCondition ? "not-allowed" : "pointer", // Change cursor style if disabled
                     }}
                    onClick={handleCaptureAfterGallery}
                  />
                </button>
              </label>
              <Typography
                   onClick={!disableCondition ? handleCaptureAfterGallery : undefined} 
                style={{
                  textAlign: "center",
                  marginLeft: 15,
                  color: "black",
                  fontWeight: 600,
                  width: 170,
                  fontSize: 16,
                  cursor: disableCondition ? "not-allowed" : "pointer", // Change cursor style if disabled
                }}
                disabled={disableCondition}
              >
                Click to Upload
              </Typography>
            </>
          ) : (
            <></>
          )}
        </div>

        <div style={{ marginTop: "30px" }}>
          {afterImage.length > 0 && beforeImage.length <= 5 && (
            <div>
              <ImageSlider
                images={afterImage}
                isEdit={true}
                handlePopover={handleCaptureAfterGallery}
                onDeleteIconClick={handleAfterDeleteIconClick}
                disablecondition={disableCondition}
              />
            </div>
          )}
        </div>

        <div
          style={{
            marginTop: "5px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            paddingLeft: 3,
            paddingRight: 10,
          }}
        >
          <InputLabel
            style={{
              fontWeight: 500,
              fontStyle: "normal",
              fontFamily: "Inter, sans-serif",
              color: "#000",
              marginBottom: 5,
            }}
            htmlFor="department1"
          >
            After Comments
          </InputLabel>
          <TextField
            style={{ width: "100%", marginTop: 10, background: "#FFFFFF" }}
            id="outlined-multiline-static"
            value={trials.after_comments}
            onChange={(e) => handleInputChange("after_comments", e.target.value)}
            disabled={disableCondition}
          />
        </div>
      </div>
      {/* <div style={{
        marginTop: "10px",
        width: "90%",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        paddingLeft: 17,
      }}>
        <InputLabel
          style={{
            fontWeight: 500,
            fontStyle: "normal",
            fontFamily: "Inter, sans-serif",
            marginBottom: 5,
            marginTop: "10px",
            color: "#000000",
          }}
        >
          Approving Authority Name (N+1) *
        </InputLabel>
        <TextField
          style={{ width: "100%", background: "#FFFFFF" }}
          value={isEditMoc ? mocAdminName : sponsorName}
          id="name"
          variant="outlined"
          InputLabelProps={{ style: { fontWeight: 500, fontStyle: "normal" } }}
          disabled={true}
        />
        {
          isEditMoc ? (
            <>
              <InputLabel
                style={{
                  fontWeight: 500,
                  fontStyle: "normal",
                  fontFamily: "Inter, sans-serif",
                  color: "#000000",
                  marginTop: "10px"
                }}
              >
                (N+1) Remarks *
              </InputLabel>
              <TextField
                style={{ width: "100%", marginTop: 10, background: "#FFFFFF" }}
                value={trials.admin_remarks}
                onChange={(e) => handleInputChange("admin_remarks", e.target.value)}
                id="outlined-multiline-static"
                multiline
                rows={4}
                disabled={isEditMoc && (userSGID === mocDetData.mocForm.admin ? false : true)}
              />
            </>
          ) : null
        }
        {(isEditMoc && mocDetData?.trials?.admin_approval_status === true) &&
          mocDetData.mocForm.conclusion === "Quality Head" ?
          <div style={{
            marginTop: "10px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start"
          }}>
            <InputLabel
              style={{
                fontWeight: 500,
                fontStyle: "normal",
                fontFamily: "Inter, sans-serif",
                marginBottom: 5,
                color: "#000000",
                marginTop: "10px"
              }}
            >
              Approving Authority Name (Quality Head) *
            </InputLabel>
            <TextField
              style={{ width: "100%", background: "#FFFFFF" }}
              value={qualityHeadName}
              id="name"
              variant="outlined"
              InputLabelProps={{ style: { fontWeight: 500, fontStyle: "normal" } }}
              disabled={true}
            />
            <InputLabel
              style={{
                fontWeight: 500,
                fontStyle: "normal",
                fontFamily: "Inter, sans-serif",
                color: "#000000",
                marginTop: "10px"
              }}
            >
              (Quality Head) Remarks
            </InputLabel>
            <TextField
              style={{ width: "100%", marginTop: 10, background: "#FFFFFF" }}
              value={trials.quality_remarks}
              onChange={(e) => handleInputChange("quality_remarks", e.target.value)}
              id="outlined-multiline-static"
              multiline
              rows={4}
              // error={stepError && !trials.quality_remarks ? true : false}
              disabled={storedUserData.name === mocDetData.mocForm.quality_name && mocDetData?.trials?.quality_approval_status === false ? false : true}
            />
             <FormControl component="fieldset" style={{ marginTop: "10px" }}>
                <Typography>
                  Whether Plant Head approval required *
                </Typography>
                <RadioGroup
                  value={trials.plant_check}
                  onChange={(event) =>
                    handleRadioChange(event.target.value)
                  }
                  row
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio sx={{ color: "#B51849" }} />}
                    label="Yes"
                    disabled={disablePlant}
                  />
                  <FormControlLabel
                    value="No"
                    control={<Radio sx={{ color: "#B51849" }} />}
                    label="No"
                    disabled={disablePlant}
                  />
                </RadioGroup>
              </FormControl>
            {trials.plant_check === "Yes" &&
              <>
                <InputLabel
                  style={{
                    fontWeight: 500,
                    fontStyle: "normal",
                    fontFamily: "Inter, sans-serif",
                    marginBottom: 5,
                    marginTop: "10px",
                    color: "#000000",
                  }}
                >
                  Approving Authority Name (Plant Head) *
                </InputLabel>
                <TextField
                  style={{ width: "100%", background: "#FFFFFF" }}
                  value={plantHeadName}
                  id="name"
                  variant="outlined"
                  InputLabelProps={{ style: { fontWeight: 500, fontStyle: "normal" } }}
                  disabled={true}
                />
                <InputLabel
                  style={{
                    fontWeight: 500,
                    fontStyle: "normal",
                    fontFamily: "Inter, sans-serif",
                    color: "#000000",
                    marginTop: "10px"
                  }}
                >
                  (Plant Head) Remarks
                </InputLabel>
                <TextField
                  style={{ width: "100%", marginTop: 10, background: "#FFFFFF" }}
                  value={trials.plant_remarks}
                  onChange={(e) => handleInputChange("plant_remarks", e.target.value)}
                  id="outlined-multiline-static"
                  multiline
                  rows={4}
                  // error={stepError && !trials.plant_remarks ? true : false}
                  disabled={isEditMoc && (storedUserData.name === mocDetData.mocForm.plant_name && mocDetData?.trials?.plant_approval_status === false ? false : true)} />
              </>
            }
          </div>
          : mocDetData.mocForm.conclusion === "Safety Head" && mocDetData?.trials?.admin_approval_status === true ?
            <div style={{
              marginTop: "10px",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start"
            }}>
              <InputLabel
                style={{
                  fontWeight: 500,
                  fontStyle: "normal",
                  fontFamily: "Inter, sans-serif",
                  marginBottom: 5,
                  color: "#000000",
                  marginTop: "10px"
                }}
              >
                Approving Authority Name (Safety Head) *
              </InputLabel>
              <TextField
                style={{ width: "100%", background: "#FFFFFF" }}
                value={safetyHeadName}
                id="name"
                variant="outlined"
                InputLabelProps={{ style: { fontWeight: 500, fontStyle: "normal" } }}
                disabled={true}
              />
              <InputLabel
                style={{
                  fontWeight: 500,
                  fontStyle: "normal",
                  fontFamily: "Inter, sans-serif",
                  color: "#000000",
                  marginTop: "10px"
                }}
              >
                (Safety Head) Remarks
              </InputLabel>
              <TextField
                style={{ width: "100%", marginTop: 10, background: "#FFFFFF" }}
                value={trials.safety_remarks}
                onChange={(e) => handleInputChange("safety_remarks", e.target.value)}
                id="outlined-multiline-static"
                multiline
                rows={4}
                // error={stepError && !trials.safety_remarks ? true : false}
                disabled={storedUserData.name === mocDetData.mocForm.safety_name && mocDetData?.trials?.safety_approval_status === false ? false : true}
              />
              <FormControl component="fieldset" style={{ marginTop: "10px" }}>
                <Typography>
                  Whether Plant Head approval required *
                </Typography>
                <RadioGroup
                  value={trials.plant_check}
                  onChange={(event) =>
                    handleRadioChange(event.target.value)
                  }
                  row
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio sx={{ color: "#B51849" }} />}
                    label="Yes"
                    disabled={disablePlant}
                  />
                  <FormControlLabel
                    value="No"
                    control={<Radio sx={{ color: "#B51849" }} />}
                    label="No"
                    disabled={disablePlant}
                  />
                </RadioGroup>
              </FormControl>
            {trials.plant_check === "Yes" &&
                <>
                  <InputLabel
                    style={{
                      fontWeight: 500,
                      fontStyle: "normal",
                      fontFamily: "Inter, sans-serif",
                      marginBottom: 5,
                      marginTop: "10px",
                      color: "#000000",
                    }}
                  >
                    Approving Authority Name (Plant Head) *
                  </InputLabel>
                  <TextField
                    style={{ width: "100%", background: "#FFFFFF" }}
                    value={plantHeadName}
                    id="name"
                    variant="outlined"
                    InputLabelProps={{ style: { fontWeight: 500, fontStyle: "normal" } }}
                    disabled={true}
                  />
                  <InputLabel
                    style={{
                      fontWeight: 500,
                      fontStyle: "normal",
                      fontFamily: "Inter, sans-serif",
                      color: "#000000",
                      marginTop: "10px"
                    }}
                  >
                    (Plant Head) Remarks
                  </InputLabel>
                  <TextField
                    style={{ width: "100%", marginTop: 10, background: "#FFFFFF" }}
                    value={trials.plant_remarks}
                    onChange={(e) => handleInputChange("plant_remarks", e.target.value)}
                    id="outlined-multiline-static"
                    multiline
                    rows={4}
                    // error={stepError && !trials.plant_remarks ? true : false}
                    disabled={isEditMoc && (storedUserData.name === mocDetData.mocForm.plant_name && mocDetData?.trials?.plant_approval_status === false ? false : true)} />
                </>
              }
            </div>
            : mocDetData.mocForm.conclusion === "Safety & Quality" && mocDetData?.trials?.admin_approval_status === true ?
              <div style={{
                marginTop: "10px",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start"
              }}>
                <InputLabel
                  style={{
                    fontWeight: 500,
                    fontStyle: "normal",
                    fontFamily: "Inter, sans-serif",
                    marginBottom: 5,
                    color: "#000000",
                    marginTop: "10px"
                  }}
                >
                  Approving Authority Name (Quality Head) *
                </InputLabel>
                <TextField
                  style={{ width: "100%", background: "#FFFFFF" }}
                  value={qualityHeadName}
                  id="name"
                  variant="outlined"
                  InputLabelProps={{ style: { fontWeight: 500, fontStyle: "normal" } }}
                  disabled={true}
                />
                <InputLabel
                  style={{
                    fontWeight: 500,
                    fontStyle: "normal",
                    fontFamily: "Inter, sans-serif",
                    color: "#000000",
                    marginTop: "10px"
                  }}
                >
                  (Quality Head) Remarks
                </InputLabel>
                <TextField
                  style={{ width: "100%", marginTop: 10, background: "#FFFFFF" }}
                  value={trials.quality_remarks}
                  onChange={(e) => handleInputChange("quality_remarks", e.target.value)}
                  id="outlined-multiline-static"
                  multiline
                  rows={4}
                  // error={stepError && !trials.quality_remarks ? true : false}
                  disabled={storedUserData.name === mocDetData.mocForm.quality_name && mocDetData?.trials?.quality_approval_status === false ? false : true}
                />
                <InputLabel
                  style={{
                    fontWeight: 500,
                    fontStyle: "normal",
                    fontFamily: "Inter, sans-serif",
                    marginBottom: 5,
                    marginTop: "10px",
                    color: "#000000",
                  }}
                >
                  Approving Authority Name (Safety Head) *
                </InputLabel>
                <TextField
                  style={{ width: "100%", background: "#FFFFFF" }}
                  value={safetyHeadName}
                  id="name"
                  variant="outlined"
                  InputLabelProps={{ style: { fontWeight: 500, fontStyle: "normal" } }}
                  disabled={true}
                />
                <InputLabel
                  style={{
                    fontWeight: 500,
                    fontStyle: "normal",
                    fontFamily: "Inter, sans-serif",
                    color: "#000000",
                  }}
                >
                  (Safety Head) Remarks
                </InputLabel>
                <TextField
                  style={{ width: "100%", marginTop: 10, background: "#FFFFFF" }}
                  value={trials.safety_remarks}
                  onChange={(e) => handleInputChange("safety_remarks", e.target.value)}
                  id="outlined-multiline-static"
                  multiline
                  rows={4}
                  // error={stepError && !trials.safety_remarks ? true : false}
                  disabled={storedUserData.name === mocDetData.mocForm.safety_name && mocDetData?.trials?.safety_approval_status === false ? false : true} />
               <FormControl component="fieldset" style={{ marginTop: "10px" }}>
                <Typography>
                  Whether Plant Head approval required *
                </Typography>
                <RadioGroup
                  value={trials.plant_check}
                  onChange={(event) =>
                    handleRadioChange(event.target.value)
                  }
                  row
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio sx={{ color: "#B51849" }} />}
                    label="Yes"
                    disabled={disablePlant}
                  />
                  <FormControlLabel
                    value="No"
                    control={<Radio sx={{ color: "#B51849" }} />}
                    label="No"
                    disabled={disablePlant}
                  />
                </RadioGroup>
              </FormControl>
            {trials.plant_check === "Yes" &&
                  <>
                    <InputLabel
                      style={{
                        fontWeight: 500,
                        fontStyle: "normal",
                        fontFamily: "Inter, sans-serif",
                        marginBottom: 5,
                        marginTop: "10px",
                        color: "#000000",
                      }}
                    >
                      Approving Authority Name (Plant Head) *
                    </InputLabel>
                    <TextField
                      style={{ width: "100%", background: "#FFFFFF" }}
                      value={plantHeadName}
                      id="name"
                      variant="outlined"
                      InputLabelProps={{ style: { fontWeight: 500, fontStyle: "normal" } }}
                      disabled={true}
                    />
                    <InputLabel
                      style={{
                        fontWeight: 500,
                        fontStyle: "normal",
                        fontFamily: "Inter, sans-serif",
                        color: "#000000",
                      }}
                    >
                      (Plant Head) Remarks
                    </InputLabel>
                    <TextField
                      style={{ width: "100%", marginTop: 10, background: "#FFFFFF" }}
                      value={trials.plant_remarks}
                      onChange={(e) => handleInputChange("plant_remarks", e.target.value)}
                      id="outlined-multiline-static"
                      multiline
                      rows={4}
                      // // error={stepError && !trials.plant_remarks ? true : false}
                      disabled={isEditMoc && (storedUserData.name === mocDetData.mocForm.plant_name && mocDetData?.trials?.plant_approval_status === false ? false : true)} />
                  </>
                }
              </div> : null
        }
      </div> */}
      {
        saveMessage ? (
          <Typography variant="subtitle2" color="red">
            {""}
            Please Save before going to Submit
          </Typography>
        ) : null
      }
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "10px",
          alignItems: "center"
        }}>
        {initialLoad ? (
          <div className="popup-overlay">
            <div className="popup">
              <CircularProgress />
            </div>
          </div>
        ) : showSuccessPopup ? (
          <div className="popup-overlay">
            <div className="popup">
              <p>Data saved successfully!</p>
            </div>
          </div>
        ) : null}
        {initialLoad ? (
          <div className="popup-overlay">
            <div className="popup">
              <CircularProgress />
            </div>
          </div>
        ) : showSubmitPopup ? (
          <div className="popup-overlay">
            <div className="popup">
              <p>MOC Trials created successfully!</p>
            </div>
          </div>
        ) : null}
        {initialLoad ? (
          <div className="popup-overlay">
            <div className="popup">
              <CircularProgress />
            </div>
          </div>
        ) : showUpdatePopup ? (
          <div className="popup-overlay">
            <div className="popup">
              <p>Data updated successfully!</p>
            </div>
          </div>
        ) : null}
        {initialLoad ? (
          <div className="popup-overlay">
            <div className="popup">
              <CircularProgress />
            </div>
          </div>
        ) : showRejectPopup ? (
          <div className="popup-overlay">
            <div className="popup">
              <p>MOC Trials Rejected Successfully!</p>
            </div>
          </div>
        ) : null}
        {initialLoad ? (
          <div className="popup-overlay">
            <div className="popup">
              <CircularProgress />
            </div>
          </div>
        ) : showResubmitPopup ? (
          <div className="popup-overlay">
            <div className="popup">
              <p>MOC Trials Resubmitted Successfully!</p>
            </div>
          </div>
        ) : null}
        {initialLoad ? (
          <div className="popup-overlay">
            <div className="popup">
              <CircularProgress />
            </div>
          </div>
        ) : showApprovePopup ? (
          <div className="popup-overlay">
            <div className="popup">
              <p>MOC Trials Approved Successfully!</p>
            </div>
          </div>
        ) : null}

        {!isEditMoc || (isEditMoc && userSGID === mocDetData.mocForm.user_id && mocDetData.trials_id === null) ?
          <Button
            variant="contained"
            style={{
              backgroundColor: "#fff",
              fontSize: 20,
              color: "#B1174B",
              border: "1px solid #B1174B",
              textTransform: "none",
              width: "75%",
              marginTop: 100,
              marginRight: 5,
              marginBottom: 10,
              boxShadow: "none",
            }}
            onClick={handleTrialsSaveClick}>
            Save
          </Button>
          :
          (
            <>
            <Button
              variant="contained"
              style={{
                backgroundColor: "#fff",
                fontSize: 20,
                color: "#B1174B",
                border: "1px solid #B1174B",
                textTransform: "none",
                width: "75%",
                marginTop: 100,
                marginRight: 5,
                marginBottom: 10,
                boxShadow: "none",
              }}
              onClick={handleTrialsReSubmitClick}
              disabled={disableCondition}
            >
              Update
            </Button>
            {/* {anchorE2 && <EmailPopup anchorE2={anchorE2} setAnchorE2={setAnchorE2} closePopup={handleCloseEmailPopup} />} */}
          </>
          ) }
     <>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "#B1174B",
                      fontSize: 20,
                      color: "white",
                      textTransform: "none",
                      width: "75%",
                      marginTop: 100,
                      marginRight: 5,
                      marginBottom: 10,
                    }}
                    onClick={!isEditMoc || (isEditMoc && userSGID === mocDetData.mocForm.user_id && mocDetData.trials_id === null) ?handleTrialsSubmitClick:handleCancel}>
                     {
      !isEditMoc ||
      (isEditMoc && userSGID === mocDetData.mocForm.user_id && mocDetData.trials_id === null)
        ? "Submit"
        : "Cancel"
    }
                  </Button>
                  {/* {anchorE2 && <EmailPopup anchorE2={anchorE2} setAnchorE2={setAnchorE2} closePopup={handleCloseEmailPopup} />} */}
                </>
      </div>
    </div>
  );
};

export default TrialsMoc;