import API from "../Services/API";
import axios from "axios";

export const handleSend = async (cc_email, to_email, context) => {
    const access_token = JSON.parse(localStorage.getItem("accesstoken"));
    //console.log(to_email, "to_email6")
    try {
        await insertReportStatus(cc_email, to_email, context);
        const updateCmsDet = localStorage.getItem("cmsID");
        const storedUserData = JSON.parse(localStorage.getItem("userDatas"));
        const cmsApprovalID = localStorage.getItem("cmsApprovalID");
        const sendMailID = localStorage.getItem("sendMailID");

        const cmsData = {
            id: cmsApprovalID,
            cms_id: updateCmsDet
        };

        const mocResponse = await axios.put(`${API.UPDATE_CMS_APPROVALS}?id=${cmsApprovalID}`, cmsData, {
            headers: {
                Authorization: `Bearer ${access_token}`
            }
        }
        );

        if (mocResponse.status === 200) {
            // setSave(true);
            // setShowSuccessPopup(true);
        }
    } catch (error) {
        console.error("An error occurred in handleSend:", error);
    }
};

const insertReportStatus = async (cc_email, to_email, context) => {
    const cmsID = localStorage.getItem("cmsID");
    const access_token = JSON.parse(localStorage.getItem("accesstoken"));
    const userData = JSON.parse(localStorage.getItem("userDatas"));
    const cmsData = JSON.parse(localStorage.getItem("cmsData"));
    //console.log(cmsData, "cmsData49")
    const cmsNumberData = localStorage.getItem("cmsNumber");
    //console.log(userData, "userData50");
    // console.log(cmsData, "cmsData43", cmsNumberData, "cmsNumberData43");
    const cmsDetData = JSON.parse(localStorage.getItem("cmsDataByID"));
    // console.log(cmsDetData, "mocDetData534");
    const created_by = cmsData?.created_by;
    const functionalPerson = localStorage.getItem("functionalPerson");
    console.log(functionalPerson, "functionalPerson48");
    let messageText = '';

    if (context === 'alteration') {
        messageText = `Dear All,
    
        The form has been successfully completed, and the 4M change management process has been updated according to the requested changes.
    
        CMS request no. ${cmsNumberData} now requires your kind attention.
    
        CMS created by: ${created_by || cmsDetData?.cmsForm?.created_by}
    
        Click here to log in and view the complete change details: https://dev.sgmoc.saint-gobain.com/`;
    }
    else {
        messageText = `Hi,
    
        This is an automated notification regarding an update to our 4M change management process. Below are the details of the requested change:
    
        CMS request no. ${cmsNumberData} requires your kind attention.
    
        CMS created by: ${created_by || cmsDetData?.cmsForm?.created_by}
    
        Click here to log in and view the complete change details: https://dev.sgmoc.saint-gobain.com/`;
    }

    try {
        const emailFieldsData = {
            cc_email: cc_email,
            to_email: to_email,
            cms_id: cmsID,
            message: messageText,
        };
        //console.log(emailFieldsData, "emailFieldsData75")
        const response = await axios.post(API.INSERT_EMAIL_REPORT_CMS, emailFieldsData, {
            headers: {
                Authorization: `Bearer ${access_token}`
            }
        });
        if (response.status === 200) {
            const sendMailID = response.data["identifiers"][0].id;
            localStorage.setItem("sendMailID", sendMailID);
            //console.log(sendMailID, "sendMailID84")
            await sendEmail(cc_email, to_email, messageText);
        }
    } catch (error) {
        //console.log(error);
    }
};

const sendEmail = async (cc_email, to_email, message) => {
    const access_token = JSON.parse(localStorage.getItem("accesstoken"));
    const userData = JSON.parse(localStorage.getItem("userDatas"));
    const cmsDetData = JSON.parse(localStorage.getItem("cmsDataByID"));
    const cmsNumberData = localStorage.getItem("cmsNumber");
    try {
        const emailParameters = {
            cc_email: cc_email,
            to_email: to_email,
            subject: cmsNumberData,
            message: message
        }
        const response = await axios.post(API.SEND_MAIL_API_CMS, emailParameters, {
            headers: {
                Authorization: `Bearer ${access_token}`
            }
        });
    } catch (error) {
        console.error(error);
    }
};