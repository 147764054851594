import React, { useState, useEffect, useRef } from "react";
import Header from "../app/TopAppBar";
import { Grid, Typography, Radio, RadioGroup, FormControlLabel,FormControl, Button, Box, TextField, InputLabel, MenuItem, Select, CircularProgress, IconButton, Tooltip } from "@mui/material";
import { useRecoilState } from "recoil";
import { editMocData, implementAppData } from "../recoil/atoms";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import EmailPopup from "../Pages/EmailPopup";
import axios from "axios";
import API from "../Services/API";
import { useNavigate } from "react-router-dom";
import { handleSend } from "../Pages/EmailView";
import BackIcon from "../Asset/Icons/back_icon.svg";

const ImplementMOC = () => {
  const [attachment, setAttachment] = useState(null);
  const [uploadedDocument, setuploadedDocument] = useState("");
  const [showFileUpload, setShowFileUpload] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownValue, setDropdownValue] = useState("");
  const [showTextField, setShowTextField] = useState(false);
  const [plantHeadApprovalRequired, setPlantHeadApprovalRequired] = useState("");
  const [implementData, setImplementData] = useRecoilState(implementAppData);
  const [isEditMoc, setIsEditMoc] = useRecoilState(editMocData);
  const [anchorE2, setAnchorE2] = useState(null);
  const [save, setSave] = useState(false);
  const [saveMessage, setSaveMessage] = useState(false);
  const [errors, setErrors] = useState(false);
  const [documentError, setDocumentError] = useState(false);
  const [, setStepError] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showUpdatePopup, setShowUpdatePopup] = useState(false);
  const [showSubmitPopup, setShowSubmitPopup] = useState(false);
  const [showApprovePopup, setShowApprovePopup] = useState(false);
  const [showRejectPopup, setShowRejectPopup] = useState(false);
  const [showResubmitPopup, setShowResubmitPopup] = useState(false);
  const [initialLoad, setInitialLoad] = useState(false);
  const [showMenuItems, setShowMenuItems] = useState(false);
  const navigate = useNavigate();
  const fileInputRef = useRef(null);

  const access_token = JSON.parse(localStorage.getItem("accesstoken"));

  useEffect(() => {
    const handlePopup = (setter, delay) => {
      setter(true);
      const timer = setTimeout(() => {
        setter(false);
      }, delay);
      return () => clearTimeout(timer);
    };
    const handlePopups = (showPopup, setShowPopup) => {
      if (showPopup) {
        setInitialLoad(true);
        setTimeout(() => {
          setInitialLoad(false);
          handlePopup(setShowPopup, 1000);
        }, 2000);
      }
    };
    handlePopups(showSuccessPopup, setShowSuccessPopup);
    handlePopups(showUpdatePopup, setShowUpdatePopup);
    handlePopups(showSubmitPopup, setShowSubmitPopup);
    handlePopups(showApprovePopup, setShowApprovePopup);
    handlePopups(showRejectPopup, setShowRejectPopup);
    handlePopups(showResubmitPopup, setShowResubmitPopup);
  }, [showSuccessPopup, showUpdatePopup, showSubmitPopup, showApprovePopup, showRejectPopup, showResubmitPopup]);

  const userInfo = JSON.parse(localStorage.getItem("allUsersData"));
  const productcomplainceNames = userInfo
  .filter(item => item.role === 'Product Compliance')
  .map(item => item.name);

const productComplianceMailid = userInfo
  .filter(item => item.role === 'Product Compliance')
  .reduce((acc, item) => {
    acc[item.name] = item.MailId; // Assuming the email ID is stored in item.email
    return acc;
  }, {});
console.log("productComplianceMailid",productComplianceMailid)
  let plantHeadName = '';
  if (userInfo && userInfo.length > 0) {
    const plantHeadUser = userInfo.find(user => user.role === 'Plant Head');
    if (plantHeadUser) {
      plantHeadName = plantHeadUser.name;
    }
  }
  let from_email = [];
  let to_email = [];
console.log("iseditmoccccc",isEditMoc)
  const mocDetData = JSON.parse(localStorage.getItem("mocDataByID"));
  const storedUserData = JSON.parse(localStorage.getItem("userDatas"));
  const qualityHeadName = mocDetData?.mocForm?.quality_name;
  const safetyHeadName = mocDetData?.mocForm?.safety_name;
  const userSGID = storedUserData.SGID;
  const userName = storedUserData.FName + " " + storedUserData.LName;
  const sponsorName = storedUserData.N1FName + " " + storedUserData.N1LName;
  const userRole = storedUserData.role;

  let disableCondition;
  let mocAdminName;
  let disablePlant;
  if (isEditMoc) {
    disableCondition = userSGID === mocDetData?.mocForm?.user_id ? false : true;
    mocAdminName = mocDetData?.mocForm?.authority_name;
    disablePlant = ((userSGID === mocDetData?.mocForm?.user_id
      //  && !(userRole === "Quality Head" || userRole === "Safety Head" || userRole === "Plant Head")
    ) || storedUserData.name === mocDetData?.mocForm?.plant_name) || mocDetData?.changeImplementation?.quality_approval_status ===true || mocDetData?.changeImplementation?.safety_approval_status ===true ? true : false;
  }
  // const handleRequirementChange = (event) => {
  //   const value = event.target.value;
  //   setImplementData({
  //     ...implementData,
  //     customer_approval: value
  //   });
  //   setAttachment(null); // Reset attachment when switching between required/not required
  //   setShowDropdown(false); // Hide dropdown on requirement change
  // };

  let mailName;
  if(userSGID === mocDetData?.mocForm?.user_id && mocDetData?.changeImplementation?.admin_approval_status===false){
    mailName=mocDetData?.mocForm?.authority_name;
  }else if(userSGID===mocDetData?.mocForm?.admin && (mocDetData?.changeImplementation?.quality_approval_status===false || mocDetData?.changeImplementation?.safety_approval_status===false)){
  mailName=mocDetData?.mocForm?.conclusion==="Quality Head"?mocDetData?.mocForm?.quality_name:mocDetData?.mocForm?.conclusion==="Safety Head"?mocDetData?.mocForm?.safety_name:mocDetData?.user_approval_name
  if(mailName===mocDetData?.mocForm?.quality_name){
    to_email.push(userInfo.find(user => user.role === 'Quality Head').MailId)
  }else{
    to_email.push(userInfo.find(user => user.role === 'Safety Head').MailId)
  }
  }else if(storedUserData.name===mocDetData?.mocForm?.quality_name){
    mailName=implementData.plant_check==="Yes"?mocDetData?.mocForm?.plant_name:mocDetData?.user_approval_name
    to_email.push(userInfo.find(user => user.name === mocDetData?.mocForm?.plant_name).MailId)
  }else if(storedUserData.name===mocDetData?.mocForm?.safety_name){
    mailName=implementData.plant_check==="Yes"?mocDetData?.mocForm?.plant_name:mocDetData?.user_approval_name
    to_email.push(userInfo.find(user => user.name === mocDetData?.mocForm?.plant_name).MailId)
  }else{
    mailName=mocDetData?.user_approval_name;
  }
console.log("mailimplement",mailName)
  const handleFileUpload = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
      fileInputRef.current.onchange = (e) => {
        const selectedFiles = e.target.files;
        setUploadedFile({ target: { files: selectedFiles } });
      };
      fileInputRef.current.value = null;
    };
  };

  // const handleYesNoChange = (event) => {
  //   const value = event.target.value;
  //   if (value === "yes") {
  //     setShowDropdown(true);
  //   } else {
  //     setShowDropdown(false);
  //   }
  //   setDropdownValue(value); // Update dropdown value
  // };

  const handleRadioChange = (event) => {
    const answer = event.target.value;
    setImplementData((prev) => ({
      ...prev,
      customer_approval: answer
    }));
    setAttachment(null); // Reset attachment when switching between required/not required
    setShowFileUpload(answer === 'required');
  };
  const handleRadioPlantChange = (answer) => {
    setImplementData((prev) => ({
      ...prev,
      plant_check: answer,
    }));
    setErrors(false);
  };

  const handleChange = (value) => {
    setImplementData((prev) => ({
      ...prev,
      product_compliance: value
    }));
    setShowMenuItems(value === 'Yes'); // Show menu items if product compliance affects requirements
  };

  const handlePlantHeadApprovalChange = (event) => {
    const value = event.target.value;
    if (value === "yes") {
      setShowTextField(true);
    } else {
      setShowTextField(false);
    }
    setPlantHeadApprovalRequired(value); // Update plantHeadApprovalRequired value
  };

  // const handleInputChange = (field, value) => {
  //   setImplementData((prevPlan) => {
  //     const updatedPlan = typeof prevPlan === "object" ? { ...prevPlan } : {};
  //     updatedPlan[field] = value;
  //     return updatedPlan;
  //   });
  // };

  const handleInputChange = (e, fieldName) => {
    const inputValue = e.target.value;
    setErrors(false);
    setImplementData((prevConclu) => ({
      ...prevConclu,
      [fieldName]: inputValue,
    }));
  };

  const displayImageBefore = (files) => {
    const imageBeforeArray = [];
    Array.from(files).forEach(file => {
      const reader = new FileReader();
      reader.onload = (e) => {
        // Set the selected image URLs
        imageBeforeArray.push(e.target.result);
        if (imageBeforeArray.length === files.length) {
          // setBeforeImage((prevBeforeImage) => [...prevBeforeImage, ...imageBeforeArray])
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const setUploadedFile = async (event) => {
    const files = event.target.files;
    displayImageBefore(files);
    if (files.length > 0) {
      const form = new FormData();
      Array.from(files).forEach(file => {
        form.append('file', file, file.name);
      });

      try {
        const response = await axios.post(
          API.UPLOAD_DOCUMENTS,
          form,
          {
            headers: {
              Authorization: `Bearer ${access_token}`
            }
          }
        );

        const result = response.data.filePaths;
        setuploadedDocument(result[0]);
        // setBeforeImage((UploadFileBefore) => [...UploadFileBefore, ...result]);

      } catch (error) {
        // Handle errors
        console.error('Failed to upload images:', error);
      }
    }
  };

  const handleDelete = () => {
    // setBeforeImage((prevFiles) => prevFiles.filter((_, i) => i !== index));
    setuploadedDocument("");
  };

  const handleDownloadDocument = async () => {
    const parts = uploadedDocument.split(/[\\/]/);
    try {
      const response = await axios.get(
        API.DOWNLOAD_DOCUMENTS + `/${parts[parts.length - 1]}`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`
          },
          responseType: 'blob' // Ensure the response type is set to 'blob'
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', parts[parts.length - 1]); // Set the correct filename
      document.body.appendChild(link);
      link.click();
      link.remove();

      // Revoke the object URL after the file is downloaded to free up resources
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading the file", error);
    }

  };

  const handleImpSaveClick = async () => {
    try {
      let hasError = false;
      // Check if customer approval is selected
      if (!implementData.customer_approval) {
        setErrors(true);
        hasError = true;
      } else {
        setErrors(false);
      }
      // Check if document is uploaded when required
      if (implementData.customer_approval === 'required' && !uploadedDocument) {
        setDocumentError(true);
        hasError = true;
      } else {
        setDocumentError(false);
      }
      // If there are any errors, stop the execution
      if (hasError) {
        setStepError(true);
        setSaveMessage(false);
        return;
      } else {
        setInitialLoad(true);
        setStepError(false);
        setSaveMessage(false);
      }

      const changeImplementData = {
        implement_remarks: implementData.implement_remarks,
        customer_approval: implementData.customer_approval,
        product_compliance_check: implementData.product_compliance_check,
        product_compliance: implementData.product_compliance,
        implement_status: "pending",
        document_upload: uploadedDocument,
        plant_check:implementData.plant_check,
      };
      const response = await axios.post(API.INSERT_CHANGE_IMPLEMENT, changeImplementData, {
        headers: {
          Authorization: `Bearer ${access_token}`
        }
      });
      if (response.status === 200) {
        const changeImplementID = response.data.identifiers[0].id;
        localStorage.setItem("changeImplementID", changeImplementID);
        await updateMocApprovals(changeImplementID);
      }
    } catch (error) {
      console.error("An error occurred in insertIntoMocTable:", error);
    }
  }

  const updateMocApprovals = async () => {
    const updatedMocID = mocDetData.moc_id;
    const approverSGID = storedUserData.N1SGID;
    const userSGID = storedUserData.SGID;
    const mocApprovalID = localStorage.getItem("mocApprovalID");
    const actionID = localStorage.getItem("actionPlanID");
    const trialsAppID = localStorage.getItem("trialsID");
    const changeImplementID = localStorage.getItem("changeImplementID");
    try {
      const mocData = {
        id: mocApprovalID,
        moc_id: updatedMocID,
        approver_sgid: approverSGID,
        user_sgid: userSGID,
        actionplan_id: actionID,
        trials_id: trialsAppID,
        implement_id: changeImplementID,
        status: "pending",
      };
      const mocResponse = await axios.put(
        `${API.UPDATE_MOC_APPROVALS}?id=${mocApprovalID}`,
        mocData,
        {
          headers: {
            Authorization: `Bearer ${access_token}`
          }
        }
      );
      if (mocResponse.status === 200) {
        const fixedString = mocDetData?.mocForm?.cft_team.replace(/^{/, "[").replace(/}$/, "]");
        // Parse the corrected string
        const parsed = JSON.parse(fixedString);
        const filteredUsers = userInfo.filter((user) =>
          parsed.includes(user.MailId)
        );
        console.log("filteredUsers",filteredUsers)
        to_email.push(filteredUsers.map((user) => user.MailId))
      to_email.push(storedUserData?.N1MailId)
         const status =await handleSend(from_email,to_email,storedUserData?.N1FName +' '+ storedUserData?.N1LName,1);
        console.log("toototo",to_email)
     if (status === 200 && implementData.product_compliance_check === "Yes") {
      setInitialLoad(false);
            const approvalmailstatus=await handleSend(from_email,[filteredUsers.map((user) => user.MailId),productComplianceMailid[implementData.product_compliance]],mailName,5);
            if(approvalmailstatus===200){
              setTimeout(() => {
                navigate("/ManageMoc");
              }, 1000);
              setSave(true);
              setuploadedDocument("");
              // setShowSuccessPopup(true);
              setShowSubmitPopup(true);
            } 
          
          } else if(status === 200){
            setTimeout(() => {
              navigate("/ManageMoc");
            }, 1000);
            setSave(true);
            setuploadedDocument("");
            // setShowSuccessPopup(true);
            setShowSubmitPopup(true);
          }
       
      }
    } catch (error) {
      setInitialLoad(false)
      console.error("An error occurred in insertIntoMocTable:", error);
    }
  };

 
  function getEmailsOfQualityHeads(allUsersData) {
    const qualityHeadEmails = [];

    allUsersData?.forEach(user => {
      if (user.role === "Quality Head") {
        qualityHeadEmails.push(user.email);
      }
    });

    return qualityHeadEmails;
  }

  // Retrieve quality head emails from stored all users data
  const qualityHeadEmails = getEmailsOfQualityHeads(userInfo);

  function getEmailsOfSafetyHeads(allUsersData) {
    const safetyHeadEmails = [];

    allUsersData?.forEach(user => {
      if (user.role === "Safety Head") {
        safetyHeadEmails.push(user.email);
      }
    });

    return safetyHeadEmails;
  }

  // Retrieve quality head emails from stored all users data
  const safetyHeadEmails = getEmailsOfSafetyHeads(userInfo);

  function getEmailsOfPlantHeads(allUsersData) {
    const plantHeadEmails = [];

    allUsersData?.forEach(user => {
      if (user.role === "Plant Head") {
        plantHeadEmails.push(user.email);
      }
    });

    return plantHeadEmails;
  }

  // Retrieve quality head emails from stored all users data
  const plantHeadEmails = getEmailsOfPlantHeads(userInfo);

  if (storedUserData) {
    from_email = [storedUserData?.MailId]
    // if (mocDetData?.mocForm?.conclusion === "Quality Head") {
    //   if (!isEditMoc) {
    //     to_email.push(storedUserData?.N1MailId);
    //   }

    //   else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "pending" || mocDetData?.mocForm?.submission_status === "approved") && !mocDetData?.mocForm?.admin_approval_status) {
    //     to_email.push(mocDetData?.mocForm?.createdby_email);
    //   }

    //   else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "resubmitted") && !mocDetData?.mocForm?.admin_approval_status) {
    //     to_email.push(mocDetData?.mocForm?.createdby_email);
    //   }

    //   else if (isEditMoc && mocDetData?.mocForm?.submission_status === "rejected" && !mocDetData?.mocForm?.admin_approval_status) {
    //     to_email.push(storedUserData?.N1MailId);
    //   }

    //   else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "rejected") && mocDetData?.mocForm?.admin_approval_status && !mocDetData?.mocForm?.quality_approval_status) {
    //     to_email.push(qualityHeadEmails);
    //   }

    //   else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "approved") && mocDetData?.mocForm?.admin_approval_status && !mocDetData?.trials_id && !mocDetData?.implement_id) {
    //     to_email.push(mocDetData?.mocForm?.createdby_email);
    //   }

    //   else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "approved") && mocDetData?.mocForm?.admin_approval_status && mocDetData?.mocForm?.quality_approval_status && !mocDetData?.mocForm?.plant_approval_status) {
    //     to_email.push(mocDetData?.mocForm?.createdby_email);
    //   }

    //   else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "resubmitted") && mocDetData?.mocForm?.admin_approval_status && !mocDetData?.mocForm?.quality_approval_status) {
    //     to_email.push(mocDetData?.mocForm?.createdby_email);
    //   }

    //   else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "resubmitted") && mocDetData?.mocForm?.admin_approval_status && mocDetData?.mocForm?.quality_approval_status && !mocDetData?.mocForm?.plant_approval_status) {
    //     to_email.push(mocDetData?.mocForm?.createdby_email);
    //   }

    //   else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "rejected") && mocDetData?.mocForm?.admin_approval_status && mocDetData?.mocForm?.quality_approval_status && !mocDetData?.mocForm?.plant_approval_status) {
    //     to_email.push(plantHeadEmails);
    //   }

    //   else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "approved") && mocDetData?.mocForm?.admin_approval_status && mocDetData?.mocForm?.quality_approval_status && mocDetData?.mocForm?.plant_approval_status && !mocDetData?.trials_id && !mocDetData?.implement_id) {
    //     to_email.push(mocDetData?.mocForm?.createdby_email);
    //   }

    //   else if (isEditMoc && mocDetData?.trials_id && mocDetData?.trials?.trials_status === "rejected" && !mocDetData?.trials?.admin_approval_status) {
    //     to_email.push(storedUserData?.N1MailId);
    //   }

    //   else if (isEditMoc && mocDetData?.trials_id && mocDetData?.trials?.trials_status === "resubmitted" && !mocDetData?.trials?.admin_approval_status) {
    //     to_email.push(mocDetData?.mocForm?.createdby_email);
    //   }

    //   else if (isEditMoc && mocDetData?.trials_id && mocDetData?.trials?.trials_status === "approved" && mocDetData?.trials?.admin_approval_status && !mocDetData?.implement_id) {
    //     to_email.push(mocDetData?.mocForm?.createdby_email);
    //   }

    //   else if (isEditMoc && mocDetData?.trials_id && (mocDetData?.trials?.trials_status === "rejected") && mocDetData?.trials?.admin_approval_status && !mocDetData?.trials?.quality_approval_status) {
    //     to_email.push(qualityHeadEmails);
    //   }

    //   else if (isEditMoc && mocDetData?.trials_id && mocDetData?.trials?.trials_status === "resubmitted" && mocDetData?.trials?.admin_approval_status && !mocDetData?.trials?.quality_approval_status) {
    //     to_email.push(mocDetData?.mocForm?.createdby_email);
    //   }

    //   else if (isEditMoc && mocDetData?.trials_id && (mocDetData?.trials?.trials_status === "rejected") && mocDetData?.trials?.admin_approval_status && mocDetData?.trials?.quality_approval_status && !mocDetData?.trials?.plant_approval_status) {
    //     to_email.push(plantHeadEmails);
    //   }

    //   else if (isEditMoc && mocDetData?.trials_id && mocDetData?.trials?.trials_status === "resubmitted" && mocDetData?.trials?.admin_approval_status && mocDetData?.trials?.quality_approval_status && !mocDetData?.trials?.plant_approval_status) {
    //     to_email.push(mocDetData?.mocForm?.createdby_email);
    //   }

    //   else if (isEditMoc && mocDetData?.implement_id && (mocDetData?.changeImplementation?.implement_status === "rejected") && !mocDetData?.changeImplementation?.admin_approval_status) {
    //     to_email.push(storedUserData?.N1MailId);
    //   }

    //   else if (isEditMoc && mocDetData?.implement_id && (mocDetData?.changeImplementation?.implement_status === "pending") && !mocDetData?.changeImplementation?.admin_approval_status) {
    //     to_email.push(mocDetData?.mocForm?.createdby_email);
    //   }

    //   else if (isEditMoc && mocDetData?.implement_id && (mocDetData?.changeImplementation?.implement_status === "resubmitted") && !mocDetData?.changeImplementation?.admin_approval_status) {
    //     to_email.push(mocDetData?.mocForm?.createdby_email);
    //   }

    //   else if (isEditMoc && mocDetData?.implement_id && (mocDetData?.changeImplementation?.implement_status === "resubmitted") && !mocDetData?.changeImplementation?.admin_approval_status) {
    //     to_email.push(mocDetData?.mocForm?.createdby_email);
    //   }

    //   else if (isEditMoc && mocDetData?.implement_id && (mocDetData?.changeImplementation?.implement_status === "approved") && mocDetData?.changeImplementation?.admin_approval_status) {
    //     to_email.push(mocDetData?.mocForm?.createdby_email);
    //   }

    //   else if (isEditMoc && mocDetData?.implement_id && (mocDetData?.changeImplementation?.implement_status === "rejected") && mocDetData?.changeImplementation?.admin_approval_status && !mocDetData?.changeImplementation?.quality_approval_status) {
    //     to_email.push(qualityHeadEmails);
    //   }

    //   else if (isEditMoc && mocDetData?.implement_id && (mocDetData?.changeImplementation?.implement_status === "resubmitted") && mocDetData?.changeImplementation?.admin_approval_status && !mocDetData?.changeImplementation?.quality_approval_status) {
    //     to_email.push(mocDetData?.mocForm?.createdby_email);
    //   }

    //   else if (isEditMoc && mocDetData?.implement_id && (mocDetData?.changeImplementation?.implement_status === "rejected") && mocDetData?.changeImplementation?.admin_approval_status && mocDetData?.changeImplementation?.quality_approval_status && !mocDetData?.changeImplementation?.plant_approval_status) {
    //     to_email.push(plantHeadEmails);
    //   }

    //   else if (isEditMoc && mocDetData?.implement_id && (mocDetData?.changeImplementation?.implement_status === "resubmitted") && mocDetData?.changeImplementation?.admin_approval_status && mocDetData?.changeImplementation?.quality_approval_status && !mocDetData?.changeImplementation?.plant_approval_status) {
    //     to_email.push(mocDetData?.mocForm?.createdby_email);
    //   }
    // }
    // else if (mocDetData?.mocForm?.conclusion === "Safety Head") {
    //   if (!isEditMoc) {
    //     to_email.push(storedUserData?.N1MailId);
    //   }

    //   else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "pending" || mocDetData?.mocForm?.submission_status === "approved") && !mocDetData?.mocForm?.admin_approval_status) {
    //     to_email.push(mocDetData?.mocForm?.createdby_email);
    //   }

    //   else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "resubmitted") && !mocDetData?.mocForm?.admin_approval_status) {
    //     to_email.push(mocDetData?.mocForm?.createdby_email);
    //   }

    //   else if (isEditMoc && mocDetData?.mocForm?.submission_status === "rejected" && !mocDetData?.mocForm?.admin_approval_status) {
    //     to_email.push(storedUserData?.N1MailId);
    //   }

    //   else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "rejected") && mocDetData?.mocForm?.admin_approval_status && !mocDetData?.mocForm?.safety_approval_status) {
    //     to_email.push(safetyHeadEmails);
    //   }

    //   else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "approved") && mocDetData?.mocForm?.admin_approval_status && !mocDetData?.trials_id && !mocDetData?.implement_id) {
    //     to_email.push(mocDetData?.mocForm?.createdby_email);
    //   }

    //   else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "approved") && mocDetData?.mocForm?.admin_approval_status && mocDetData?.mocForm?.safety_approval_status && !mocDetData?.mocForm?.plant_approval_status) {
    //     to_email.push(mocDetData?.mocForm?.createdby_email);
    //   }

    //   else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "resubmitted") && mocDetData?.mocForm?.admin_approval_status && !mocDetData?.mocForm?.safety_approval_status) {
    //     to_email.push(mocDetData?.mocForm?.createdby_email);
    //   }

    //   else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "resubmitted") && mocDetData?.mocForm?.admin_approval_status && mocDetData?.mocForm?.safety_approval_status && !mocDetData?.mocForm?.plant_approval_status) {
    //     to_email.push(mocDetData?.mocForm?.createdby_email);
    //   }

    //   else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "rejected") && mocDetData?.mocForm?.admin_approval_status && mocDetData?.mocForm?.safety_approval_status && !mocDetData?.mocForm?.plant_approval_status) {
    //     to_email.push(plantHeadEmails);
    //   }

    //   else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "approved") && mocDetData?.mocForm?.admin_approval_status && mocDetData?.mocForm?.safety_approval_status && mocDetData?.mocForm?.plant_approval_status && !mocDetData?.trials_id && !mocDetData?.implement_id) {
    //     to_email.push(mocDetData?.mocForm?.createdby_email);
    //   }

    //   else if (isEditMoc && mocDetData?.trials_id && mocDetData?.trials?.trials_status === "rejected" && !mocDetData?.trials?.admin_approval_status) {
    //     to_email.push(storedUserData?.N1MailId);
    //   }

    //   else if (isEditMoc && mocDetData?.trials_id && mocDetData?.trials?.trials_status === "resubmitted" && !mocDetData?.trials?.admin_approval_status) {
    //     to_email.push(mocDetData?.mocForm?.createdby_email);
    //   }

    //   else if (isEditMoc && mocDetData?.trials_id && mocDetData?.trials?.trials_status === "approved" && mocDetData?.trials?.admin_approval_status && !mocDetData?.implement_id) {
    //     to_email.push(mocDetData?.mocForm?.createdby_email);
    //   }

    //   else if (isEditMoc && mocDetData?.trials_id && (mocDetData?.trials?.trials_status === "rejected") && mocDetData?.trials?.admin_approval_status && !mocDetData?.trials.safety_approval_status) {
    //     to_email.push(safetyHeadEmails);
    //   }

    //   else if (isEditMoc && mocDetData?.trials_id && mocDetData?.trials?.trials_status === "resubmitted" && mocDetData?.trials?.admin_approval_status && !mocDetData?.trials.safety_approval_status) {
    //     to_email.push(mocDetData?.mocForm?.createdby_email);
    //   }

    //   else if (isEditMoc && mocDetData?.trials_id && (mocDetData?.trials?.trials_status === "rejected") && mocDetData?.trials?.admin_approval_status && mocDetData?.trials.safety_approval_status && !mocDetData?.trials?.plant_approval_status) {
    //     to_email.push(plantHeadEmails);
    //   }

    //   else if (isEditMoc && mocDetData?.trials_id && mocDetData?.trials?.trials_status === "resubmitted" && mocDetData?.trials?.admin_approval_status && mocDetData?.trials.safety_approval_status && !mocDetData?.trials?.plant_approval_status) {
    //     to_email.push(mocDetData?.mocForm?.createdby_email);
    //   }

    //   else if (isEditMoc && mocDetData?.implement_id && (mocDetData?.changeImplementation?.implement_status === "rejected") && !mocDetData?.changeImplementation?.admin_approval_status) {
    //     to_email.push(storedUserData?.N1MailId);
    //   }

    //   else if (isEditMoc && mocDetData?.implement_id && (mocDetData?.changeImplementation?.implement_status === "pending") && !mocDetData?.changeImplementation?.admin_approval_status) {
    //     to_email.push(mocDetData?.mocForm?.createdby_email);
    //   }

    //   else if (isEditMoc && mocDetData?.implement_id && (mocDetData?.changeImplementation?.implement_status === "resubmitted") && !mocDetData?.changeImplementation?.admin_approval_status) {
    //     to_email.push(mocDetData?.mocForm?.createdby_email);
    //   }

    //   else if (isEditMoc && mocDetData?.implement_id && (mocDetData?.changeImplementation?.implement_status === "resubmitted") && !mocDetData?.changeImplementation?.admin_approval_status) {
    //     to_email.push(mocDetData?.mocForm?.createdby_email);
    //   }

    //   else if (isEditMoc && mocDetData?.implement_id && (mocDetData?.changeImplementation?.implement_status === "approved") && mocDetData?.changeImplementation?.admin_approval_status) {
    //     to_email.push(mocDetData?.mocForm?.createdby_email);
    //   }

    //   else if (isEditMoc && mocDetData?.implement_id && (mocDetData?.changeImplementation?.implement_status === "rejected") && mocDetData?.changeImplementation?.admin_approval_status && !mocDetData?.changeImplementation.safety_approval_status) {
    //     to_email.push(safetyHeadEmails);
    //   }

    //   else if (isEditMoc && mocDetData?.implement_id && (mocDetData?.changeImplementation?.implement_status === "resubmitted") && mocDetData?.changeImplementation?.admin_approval_status && !mocDetData?.changeImplementation.safety_approval_status) {
    //     to_email.push(mocDetData?.mocForm?.createdby_email);
    //   }

    //   else if (isEditMoc && mocDetData?.implement_id && (mocDetData?.changeImplementation?.implement_status === "rejected") && mocDetData?.changeImplementation?.admin_approval_status && mocDetData?.changeImplementation.safety_approval_status && !mocDetData?.changeImplementation?.plant_approval_status) {
    //     to_email.push(plantHeadEmails);
    //   }

    //   else if (isEditMoc && mocDetData?.implement_id && (mocDetData?.changeImplementation?.implement_status === "resubmitted") && mocDetData?.changeImplementation?.admin_approval_status && mocDetData?.changeImplementation.safety_approval_status && !mocDetData?.changeImplementation?.plant_approval_status) {
    //     to_email.push(mocDetData?.mocForm?.createdby_email);
    //   }
    // }
    // else if (mocDetData?.mocForm?.conclusion === "Non Critical") {
    //   if (!isEditMoc) {
    //     to_email.push(storedUserData?.N1MailId);
    //   }

    //   else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "pending" || mocDetData?.mocForm?.submission_status === "approved") && !mocDetData?.mocForm?.admin_approval_status) {
    //     to_email.push(mocDetData?.mocForm?.createdby_email);
    //   }

    //   else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "resubmitted") && !mocDetData?.mocForm?.admin_approval_status) {
    //     to_email.push(mocDetData?.mocForm?.createdby_email);
    //   }

    //   else if (isEditMoc && mocDetData?.mocForm?.submission_status === "rejected" && !mocDetData?.mocForm?.admin_approval_status) {
    //     to_email.push(storedUserData?.N1MailId);
    //   }

    //   else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "approved") && mocDetData?.mocForm?.admin_approval_status && !mocDetData?.trials_id && !mocDetData?.implement_id) {
    //     to_email.push(mocDetData?.mocForm?.createdby_email);
    //   }

    //   else if (isEditMoc && mocDetData?.trials_id && mocDetData?.trials?.trials_status === "rejected" && !mocDetData?.trials?.admin_approval_status) {
    //     to_email.push(storedUserData?.N1MailId);
    //   }

    //   else if (isEditMoc && mocDetData?.trials_id && (mocDetData?.trials?.trials_status === "pending") && !mocDetData?.trials?.admin_approval_status) {
    //     to_email.push(mocDetData?.mocForm?.createdby_email);
    //   }

    //   else if (isEditMoc && mocDetData?.trials_id && mocDetData?.trials?.trials_status === "resubmitted" && !mocDetData?.trials?.admin_approval_status) {
    //     to_email.push(mocDetData?.mocForm?.createdby_email);
    //   }

    //   else if (isEditMoc && mocDetData?.trials_id && mocDetData?.trials?.trials_status === "approved" && mocDetData?.trials?.admin_approval_status && !mocDetData?.implement_id) {
    //     to_email.push(mocDetData?.mocForm?.createdby_email);
    //   }

    //   else if (isEditMoc && mocDetData?.implement_id && (mocDetData?.changeImplementation?.implement_status === "rejected") && !mocDetData?.changeImplementation?.admin_approval_status) {
    //     to_email.push(storedUserData?.N1MailId);
    //   }

    //   else if (isEditMoc && mocDetData?.implement_id && (mocDetData?.changeImplementation?.implement_status === "pending") && !mocDetData?.changeImplementation?.admin_approval_status) {
    //     to_email.push(mocDetData?.mocForm?.createdby_email);
    //   }

    //   else if (isEditMoc && mocDetData?.implement_id && (mocDetData?.changeImplementation?.implement_status === "resubmitted") && !mocDetData?.changeImplementation?.admin_approval_status) {
    //     to_email.push(mocDetData?.mocForm?.createdby_email);
    //   }

    //   else if (isEditMoc && mocDetData?.implement_id && (mocDetData?.changeImplementation?.implement_status === "resubmitted") && !mocDetData?.changeImplementation?.admin_approval_status) {
    //     to_email.push(mocDetData?.mocForm?.createdby_email);
    //   }

    //   else if (isEditMoc && mocDetData?.implement_id && (mocDetData?.changeImplementation?.implement_status === "approved") && mocDetData?.changeImplementation?.admin_approval_status) {
    //     to_email.push(mocDetData?.mocForm?.createdby_email);
    //   }
    // }
    // else if (mocDetData?.mocForm?.conclusion === "Safety & Quality") {
    //   if (!isEditMoc) {
    //     to_email.push(storedUserData?.N1MailId);
    //   }

    //   else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "pending" || mocDetData?.mocForm?.submission_status === "approved") && !mocDetData?.mocForm?.admin_approval_status) {
    //     to_email.push(mocDetData?.mocForm?.createdby_email);
    //   }

    //   else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "resubmitted") && !mocDetData?.mocForm?.admin_approval_status) {
    //     to_email.push(mocDetData?.mocForm?.createdby_email);
    //   }

    //   else if (isEditMoc && mocDetData?.mocForm?.submission_status === "rejected" && !mocDetData?.mocForm?.admin_approval_status) {
    //     to_email.push(storedUserData?.N1MailId);
    //   }

    //   else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "rejected") && mocDetData?.mocForm?.admin_approval_status && !mocDetData?.mocForm?.quality_approval_status) {
    //     to_email.push(qualityHeadEmails);
    //   }

    //   else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "approved") && mocDetData?.mocForm?.admin_approval_status && !mocDetData?.trials_id && !mocDetData?.implement_id) {
    //     to_email.push(mocDetData?.mocForm?.createdby_email);
    //   }

    //   else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "approved") && mocDetData?.mocForm?.admin_approval_status && mocDetData?.mocForm?.quality_approval_status && !mocDetData?.mocForm?.plant_approval_status) {
    //     to_email.push(mocDetData?.mocForm?.createdby_email);
    //   }

    //   else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "resubmitted") && mocDetData?.mocForm?.admin_approval_status && !mocDetData?.mocForm?.quality_approval_status) {
    //     to_email.push(mocDetData?.mocForm?.createdby_email);
    //   }

    //   else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "resubmitted") && mocDetData?.mocForm?.admin_approval_status && mocDetData?.mocForm?.quality_approval_status && !mocDetData?.mocForm?.plant_approval_status) {
    //     to_email.push(mocDetData?.mocForm?.createdby_email);
    //   }

    //   else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "rejected") && mocDetData?.mocForm?.admin_approval_status && mocDetData?.mocForm?.quality_approval_status && !mocDetData?.mocForm?.safety_approval_status && !mocDetData?.mocForm?.plant_approval_status) {
    //     to_email.push(safetyHeadEmails);
    //   }

    //   else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "rejected") && mocDetData?.mocForm?.admin_approval_status && mocDetData?.mocForm?.quality_approval_status && mocDetData?.mocForm?.safety_approval_status && !mocDetData?.mocForm?.plant_approval_status) {
    //     to_email.push(plantHeadEmails);
    //   }

    //   else if (isEditMoc && (mocDetData?.mocForm?.submission_status === "approved") && mocDetData?.mocForm?.admin_approval_status && mocDetData?.mocForm?.quality_approval_status && mocDetData?.mocForm?.safety_approval_status && mocDetData?.mocForm?.plant_approval_status && !mocDetData?.trials_id && !mocDetData?.implement_id) {
    //     to_email.push(mocDetData?.mocForm?.createdby_email);
    //   }

    //   //2nd

    //   else if (isEditMoc && mocDetData?.trials_id && mocDetData?.trials?.trials_status === "rejected" && !mocDetData?.trials?.admin_approval_status) {
    //     to_email.push(storedUserData?.N1MailId);
    //   }

    //   else if (isEditMoc && mocDetData?.trials_id && mocDetData?.trials?.trials_status === "resubmitted" && !mocDetData?.trials?.admin_approval_status) {
    //     to_email.push(mocDetData?.mocForm?.createdby_email);
    //   }

    //   else if (isEditMoc && mocDetData?.trials_id && mocDetData?.trials?.trials_status === "approved" && mocDetData?.trials?.admin_approval_status && !mocDetData?.implement_id) {
    //     to_email.push(mocDetData?.mocForm?.createdby_email);
    //   }

    //   else if (isEditMoc && mocDetData?.trials_id && (mocDetData?.trials?.trials_status === "rejected") && mocDetData?.trials?.admin_approval_status && !mocDetData?.trials?.quality_approval_status) {
    //     to_email.push(qualityHeadEmails);
    //   }

    //   else if (isEditMoc && mocDetData?.trials_id && mocDetData?.trials?.trials_status === "resubmitted" && mocDetData?.trials?.admin_approval_status && !mocDetData?.trials?.quality_approval_status) {
    //     to_email.push(mocDetData?.mocForm?.createdby_email);
    //   }

    //   else if (isEditMoc && mocDetData?.trials_id && (mocDetData?.trials?.trials_status === "rejected") && mocDetData?.trials?.admin_approval_status && mocDetData?.trials?.quality_approval_status && !mocDetData?.trials.safety_approval_status && !mocDetData?.trials?.plant_approval_status) {
    //     to_email.push(safetyHeadEmails);
    //   }

    //   else if (isEditMoc && mocDetData?.trials_id && (mocDetData?.trials?.trials_status === "rejected") && mocDetData?.trials?.admin_approval_status && mocDetData?.trials?.quality_approval_status && mocDetData?.trials.safety_approval_status && !mocDetData?.trials?.plant_approval_status) {
    //     to_email.push(plantHeadEmails);
    //   }

    //   else if (isEditMoc && mocDetData?.trials_id && mocDetData?.trials?.trials_status === "resubmitted" && mocDetData?.trials?.admin_approval_status && mocDetData?.trials?.quality_approval_status && !mocDetData?.trials?.plant_approval_status) {
    //     to_email.push(mocDetData?.mocForm?.createdby_email);
    //   }

    //   else if (isEditMoc && mocDetData?.implement_id && (mocDetData?.changeImplementation?.implement_status === "rejected") && !mocDetData?.changeImplementation?.admin_approval_status) {
    //     to_email.push(storedUserData?.N1MailId);
    //   }

    //   else if (isEditMoc && mocDetData?.implement_id && (mocDetData?.changeImplementation?.implement_status === "pending") && !mocDetData?.changeImplementation?.admin_approval_status) {
    //     to_email.push(mocDetData?.mocForm?.createdby_email);
    //   }

    //   else if (isEditMoc && mocDetData?.implement_id && (mocDetData?.changeImplementation?.implement_status === "resubmitted") && !mocDetData?.changeImplementation?.admin_approval_status) {
    //     to_email.push(mocDetData?.mocForm?.createdby_email);
    //   }

    //   else if (isEditMoc && mocDetData?.implement_id && (mocDetData?.changeImplementation?.implement_status === "resubmitted") && !mocDetData?.changeImplementation?.admin_approval_status) {
    //     to_email.push(mocDetData?.mocForm?.createdby_email);
    //   }

    //   else if (isEditMoc && mocDetData?.implement_id && (mocDetData?.changeImplementation?.implement_status === "approved") && mocDetData?.changeImplementation?.admin_approval_status) {
    //     to_email.push(mocDetData?.mocForm?.createdby_email);
    //   }

    //   else if (isEditMoc && mocDetData?.implement_id && (mocDetData?.changeImplementation?.implement_status === "rejected") && mocDetData?.changeImplementation?.admin_approval_status && !mocDetData?.changeImplementation?.quality_approval_status) {
    //     to_email.push(qualityHeadEmails);
    //   }

    //   else if (isEditMoc && mocDetData?.implement_id && (mocDetData?.changeImplementation?.implement_status === "resubmitted") && mocDetData?.changeImplementation?.admin_approval_status && !mocDetData?.changeImplementation?.quality_approval_status) {
    //     to_email.push(mocDetData?.mocForm?.createdby_email);
    //   }

    //   else if (isEditMoc && mocDetData?.implement_id && (mocDetData?.changeImplementation?.implement_status === "rejected") && mocDetData?.changeImplementation?.admin_approval_status && mocDetData?.changeImplementation?.quality_approval_status && !mocDetData?.changeImplementation.safety_approval_status && !mocDetData?.changeImplementation?.plant_approval_status) {
    //     to_email.push(safetyHeadEmails);
    //   }

    //   else if (isEditMoc && mocDetData?.implement_id && (mocDetData?.changeImplementation?.implement_status === "rejected") && mocDetData?.changeImplementation?.admin_approval_status && mocDetData?.changeImplementation?.quality_approval_status && !mocDetData?.changeImplementation?.plant_approval_status) {
    //     to_email.push(plantHeadEmails);
    //   }

    //   else if (isEditMoc && mocDetData?.implement_id && (mocDetData?.changeImplementation?.implement_status === "resubmitted") && mocDetData?.changeImplementation?.admin_approval_status && mocDetData?.changeImplementation?.quality_approval_status && !mocDetData?.changeImplementation?.plant_approval_status) {
    //     to_email.push(mocDetData?.mocForm?.createdby_email);
    //   } 
    // }
    // else if (mocDetData?.implement_id) {
    //   if (implementData.product_compliance_check === "Yes") {
    //     const email = productComplianceMailid[implementData.product_compliance];
    //     to_email.push(email);
    // }
    //   }
  }
  else {
    //console.log('storedUserData is empty');
  }

  const handleImpSubmitClick = async () => {
    try {
      const fixedString = mocDetData?.mocForm?.cft_team.replace(/^{/, "[").replace(/}$/, "]");
      // Parse the corrected string
      const parsed = JSON.parse(fixedString);
      const filteredUsers = userInfo.filter((user) =>
        parsed.includes(user.MailId)
      );
      console.log("filteredUsers",filteredUsers)
      to_email.push(filteredUsers.map((user) => user.MailId))
    to_email.push(storedUserData?.N1MailId)
       const status =await handleSend(from_email,to_email,storedUserData?.N1FName +' '+ storedUserData?.N1LName,1);
      console.log("toototo",to_email)
   if (status === 200 && implementData.product_compliance_check === "Yes") {
          const approvalmailstatus=await handleSend(from_email,[filteredUsers.map((user) => user.MailId),productComplianceMailid[implementData.product_compliance]],mailName,5);
          if(approvalmailstatus===200){
            setTimeout(() => {
              navigate("/ManageMoc");
            }, 1000);
            setShowSubmitPopup(true);
          } 
        
        } else if(status === 200){
          setTimeout(() => {
            navigate("/ManageMoc");
          }, 1000);
          setShowSubmitPopup(true);
        }
      // if (!anchorE2) {
      //   setAnchorE2(document.body);
      // }
    } catch (error) {
      console.error("Error fetching MOC details:", error);
      setSaveMessage("Failed to save. Please try again.");
    }
  }

  const handleImpReSubmitClick = async () => {
    const changeImplementID = localStorage.getItem("changeImplementID");
    const fixedString = mocDetData?.mocForm?.cft_team.replace(/^{/, "[").replace(/}$/, "]");
    // Parse the corrected string
    const parsed = JSON.parse(fixedString);
    const filteredUsers = userInfo.filter((user) =>
      parsed.includes(user.MailId)
    );
    console.log("filteredUsers",filteredUsers)
    to_email.push(filteredUsers.map((user) => user.MailId))
    console.log("toototo",to_email)
    const productCompliance = "Diwakar";
    try {
      let hasError = false;
      // Check if customer approval is selected
      if (!implementData.customer_approval) {
        setErrors(true);
        hasError = true;
      } else {
        setErrors(false);
      }
      // Check if document is uploaded when required
      if (implementData.customer_approval === 'required' && !uploadedDocument) {
        setDocumentError(true);
        hasError = true;
      } else {
        setDocumentError(false);
      }
      // If there are any errors, stop the execution
      if (hasError) {
        setStepError(true);
        setSaveMessage(false);
        return;
      } else {
        setInitialLoad(true);
        setStepError(false);
        setSaveMessage(false);
      }

      const changeImplementData = {
        implement_remarks: implementData.implement_remarks,
        customer_approval: implementData.customer_approval,
        admin_remarks: implementData.admin_remarks,
        quality_remarks: implementData.quality_remarks,
        product_compliance_check: implementData.product_compliance_check,
        product_compliance: implementData.product_compliance,
        plant_remarks: implementData.plant_remarks,
        safety_remarks: implementData.safety_remarks,
        implement_status: "resubmitted",
        document_upload: uploadedDocument,
        plant_check:implementData.plant_check,
      };
      const response = await axios.put(
        `${API.UPDATE_CHANGE_IMPLEMENT}?id=${changeImplementID}`, changeImplementData,
        {
          headers: {
            Authorization: `Bearer ${access_token}`
          }
        }
      );
      if (response.status === 200) {
        setShowResubmitPopup(true);
        setuploadedDocument("");
        await insertMocApprovals("resubmitted", changeImplementID);
        const status = await handleSend(from_email, to_email,mailName,1);
        setInitialLoad(false);
        if(status){
          setTimeout(() => {
            navigate("/ManageMoc");
          }, 1000);
        }
      
        // if (!anchorE2) {
        //   setAnchorE2(document.body);
        // }
      }
    } catch (error) {
      setInitialLoad(false);
      console.error("An error occurred in insertIntoMocTable:", error);
    }
  }

  const handleImpRejectClick = async () => {
    setInitialLoad(true);
    const implementID = mocDetData.implement_id;
    const fixedString = mocDetData?.mocForm?.cft_team.replace(/^{/, "[").replace(/}$/, "]");
    // Parse the corrected string
    const parsed = JSON.parse(fixedString);
    const filteredUsers = userInfo.filter((user) =>
      parsed.includes(user.MailId)
    );
    console.log("filteredUsers",filteredUsers)
    to_email.push(filteredUsers.map((user) => user.MailId))
    console.log("toototo",to_email)
    const productCompliance = "Diwakar";
    try {
      const changeImpData = {
        id: implementID,
        customer_approval: implementData.customer_approval,
        admin_remarks: implementData.admin_remarks,
        quality_remarks: implementData.quality_remarks,
        product_compliance_check: implementData.product_compliance_check,
        product_compliance: implementData.product_compliance,
        plant_remarks: implementData.plant_remarks,
        safety_remarks: implementData.safety_remarks,
        implement_status: "rejected",
        document_upload: uploadedDocument,
        plant_check:implementData.plant_check,
      };
      const mocResponse = await axios.put(
        `${API.UPDATE_CHANGE_IMPLEMENT}?id=${implementID}`,
        changeImpData,
        {
          headers: {
            Authorization: `Bearer ${access_token}`
          }
        }
      );
      if (mocResponse.status === 200) {
        setInitialLoad(false);
        setShowRejectPopup(true);
        setuploadedDocument("");
        await insertMocApprovals("rejected", implementID);
               const status = await handleSend(from_email, [filteredUsers.map((user) => user.MailId),mocDetData?.mocForm?.createdby_email],mocDetData?.mocForm?.created_by,3);
        if(status===200){
          setTimeout(() => {
            navigate("/ManageMoc");
          }, 1000);
        }
      
        // if (!anchorE2) {
        //   setAnchorE2(document.body);
        // }
      }
    } catch (error) {
      setInitialLoad(false);
      console.error("An error occurred in insertIntoMocTable:", error);
    }
  }

  const handleImpApproveClick = async () => {
    setInitialLoad(true);
    const implementID = mocDetData.implement_id;
    const admin = mocDetData?.mocForm?.admin;
    const fixedString = mocDetData?.mocForm?.cft_team.replace(/^{/, "[").replace(/}$/, "]");
    // Parse the corrected string
    const parsed = JSON.parse(fixedString);
    const filteredUsers = userInfo.filter((user) =>
      parsed.includes(user.MailId)
    );
    console.log("filteredUsers",filteredUsers)
    to_email.push(filteredUsers.map((user) => user.MailId))
    console.log("toototo",to_email)
    const productCompliance = "Diwakar";
    try {
      const changeImpData = {
        id: implementID,
        customer_approval: implementData.customer_approval,
        admin_remarks: implementData.admin_remarks,
        quality_remarks: implementData.quality_remarks,
        product_compliance_check: implementData.product_compliance_check,
        product_compliance: implementData.product_compliance,
        plant_remarks: implementData.plant_remarks,
        safety_remarks: implementData.safety_remarks,
        implement_status: "approved",
        document_upload: uploadedDocument,
        admin_approval_status: userSGID === admin ? true : mocDetData.changeImplementation.admin_approval_status,
        quality_approval_status: storedUserData.name === qualityHeadName && mocDetData.changeImplementation.admin_approval_status ? true : mocDetData.changeImplementation.quality_approval_status,
        safety_approval_status: storedUserData.name === safetyHeadName && mocDetData.changeImplementation.admin_approval_status ? true : mocDetData.changeImplementation.safety_approval_status,
        plant_approval_status: storedUserData.name === plantHeadName && mocDetData.changeImplementation.admin_approval_status ? true : mocDetData.changeImplementation.plant_approval_status,
        plant_check:implementData.plant_check,
      };
      const mocResponse = await axios.put(
        `${API.UPDATE_CHANGE_IMPLEMENT}?id=${implementID}`,
        changeImpData,
        {
          headers: {
            Authorization: `Bearer ${access_token}`
          }
        }
      );
      if (mocResponse.status === 200) {
        setInitialLoad(false);
        setShowApprovePopup(true);
        setuploadedDocument("");
        await insertMocApprovals("approved", implementID);
            const status = await handleSend(from_email, [filteredUsers.map((user) => user.MailId),mocDetData?.mocForm?.createdby_email],mocDetData?.mocForm?.created_by,2);
              if (status === 200 && (mocDetData?.mocForm?.conclusion!=="Non Critical"&& ((mocDetData?.mocForm?.conclusion==="Quality Head" && changeImpData.plant_check!=="No" && changeImpData.plant_approval_status===false)||(mocDetData?.mocForm?.conclusion==="Safety Head" && changeImpData.plant_check!=="No" && changeImpData.plant_approval_status===false)))) {
                const mailstatus=await handleSend(from_email, to_email,mailName,1);
                if(mailstatus===200){
                  setTimeout(() => {
                    navigate("/ManageMoc");
                  }, 1000);
                }
              }else if(status===200){
                setTimeout(() => {
                  navigate("/ManageMoc");
                }, 1000);
              }
      
        // if (!anchorE2) {
        //   setAnchorE2(document.body);
        // }
      }
    } catch (error) {
      setInitialLoad(false)
      console.error("An error occurred in insertIntoMocTable:", error);
    }
  }

  const insertMocApprovals = async (approvalStatus, changeImplementID) => {
    const storedUserData = JSON.parse(localStorage.getItem("userDatas"));
    const approverSGID = storedUserData.N1SGID;
    const userSGID = storedUserData.SGID;
    const userAppName = storedUserData.FName + " " + storedUserData.LName;
    const actionIds = mocDetData.actionplan_id;
    const updatedMocID = mocDetData.moc_id;
    const trialsAppID = mocDetData.trials_id;
    var documentsID = mocDetData.documents_id;
    try {
      const mocData = {
        moc_id: updatedMocID,
        approver_sgid: approverSGID,
        user_sgid: userSGID,
        user_approval_name: userAppName,
        actionplan_id: actionIds,
        trials_id: trialsAppID,
        documents_id: documentsID,
        implement_id: changeImplementID,
        status: approvalStatus
      };
      const response = await axios.post(API.INSERT_MOC_APPROVALS, mocData, {
        headers: {
          Authorization: `Bearer ${access_token}`
        }
      });
      if (response.status === 200) {
        const mocApprovalID = response.data["identifiers"][0].id;
        localStorage.setItem("mocApprovalID", mocApprovalID);
      }
    } catch (error) {
      console.error("An error occurred in insertIntoMocTable:", error);
    }
  };

  // const handleImpNextClick = async () => {
  //   try {
  //     navigate("/ManageMoc");
  //   } catch (error) {
  //     console.error("An error occurred in insertIntoMocTable:", error);
  //   }
  // }

  const handleCloseEmailPopup = () => {
    setAnchorE2(null);
  }

  useEffect(() => {
    const mocDetData = JSON.parse(localStorage.getItem("mocDataByID"));
    if (isEditMoc) {
      const getChangeImplement = async () => {
        const implementID = mocDetData.implement_id;
        if (implementID === null || implementID === undefined || implementID === "") {
          setImplementData([]);
          setIsEditMoc(false);
        } else {
          try {
            const response = await axios.get(
              `${API.GET_CHANGE_IMPLEMENT}?id=${implementID}`,
              {
                headers: {
                  Authorization: `Bearer ${access_token}`
                }
              });
            const implementApproval = await response.data;
            setImplementData(implementApproval);
            setIsEditMoc(true);
            setuploadedDocument(implementApproval.document_upload)
            setShowFileUpload(implementApproval.customer_approval === 'required')
          } catch (error) {
            console.error("Error fetching MOC details:", error);
          }
        }
      };
      getChangeImplement();
    }
  }, [mocDetData.implement_id, setImplementData, setIsEditMoc]);

  const handleImpCancel = () => {
    navigate("/ManageMoc");
  }

  const productCompliance = "Diwakar";
  console.log("mocDetDatamocDetData",mocDetData)
  console.log("moccheckedit",isEditMoc&&mocDetData?.mocForm?.conclusion === "Quality Head" && mocDetData?.changeImplementation?.admin_approval_status === true && mocDetData?.changeImplementation?.product_compliance_check==="Yes")

  return (
    <div>
      {/* <Header pageTitle="Implement MOC" /> */}
      <Tooltip title="Back">
        <IconButton
          edge="start"
          color="inherit"
          aria-label="back"
          onClick={handleImpCancel}
        >
          <img
            src={BackIcon}
            alt="back icon"
            style={{ width: "28px", height: "24px" }}
          />
        </IconButton>
      </Tooltip>
      <Grid container spacing={1} alignItems="center" justifyContent="center">
        <Grid item xs={12}>
          <div style={{
            marginTop: "10px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start"
          }}>
            <InputLabel
              style={{
                fontWeight: 500,
                fontStyle: "normal",
                fontFamily: "Inter, sans-serif",
                marginBottom: 5,
                color: "#000000",
                marginTop: "10px"
              }}
            >
              Remarks
            </InputLabel>
            <TextField
              style={{ width: "100%", marginTop: 10, background: "#FFFFFF" }}
              value={implementData.implement_remarks}
              onChange={(e) => handleInputChange(e, "implement_remarks")}
              id="outlined-multiline-static"
              multiline
              rows={4}
              // error={stepError && !trials.implement_remarks ? true : false}
              disabled={disableCondition}
            />
          </div>

          <Grid item xs={12}>
            <Typography variant="h5">Customer Approval *:</Typography>
          </Grid>
          <RadioGroup
            row
            // value={requirement}
            value={implementData.customer_approval}
            onChange={handleRadioChange}
          >
            <FormControlLabel
              value="required"
              control={<Radio />}
              label="Required"
              disabled={disableCondition}
            />
            <FormControlLabel
              value="not_required"
              control={<Radio />}
              label="Not Required"
              disabled={disableCondition}
            />
          </RadioGroup>
        </Grid>
        {showFileUpload && (
          <>
            <Grid item xs={12}>
              <Typography variant="body1" gutterBottom>
                If required, attach approval sheet *:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" alignItems="center">

                <input
                  id="upload-image"
                  multiple
                  type="file"
                  style={{ display: "none" }}
                  // onChange={setUploadedFile}
                  ref={fileInputRef}
                />
                <Button
                  variant="outlined"
                  component="span"
                  // startIcon={<CloudUploadIcon />}
                  onClick={() => {
                    if (!uploadedDocument) {
                      handleFileUpload();
                    }
                  }}
                >
                  Upload Document
                </Button>
                {uploadedDocument && (
                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                    <CloudDownloadIcon style={{ width: '30px', height: 'auto', marginRight: '10px', cursor: "pointer" }} onClick={handleDownloadDocument} />
                    <span>{uploadedDocument}</span>
                    <button
                      onClick={() => handleDelete()}
                      style={{
                        marginLeft: '10px',
                        background: 'none',
                        border: 'none',
                        color: 'red',
                        fontSize: '20px',
                        cursor: 'pointer'
                      }}
                    >
                      &times;
                    </button>
                  </div>
                )}

              </Box>
            </Grid>
          </>
        )}
        <Grid item xs={12}>

          <div style={{
            marginTop: "10px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start"
          }}>
            {uploadedDocument && !showFileUpload && (
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <CloudDownloadIcon style={{ width: '50px', height: 'auto', marginRight: '10px', cursor: "pointer" }} onClick={handleDownloadDocument} />
                <span>{uploadedDocument}</span>
                {/* <button
          onClick={() => handleDelete()}
          style={{
            marginLeft: '10px',
            background: 'none',
            border: 'none',
            color: 'red',
            fontSize: '20px',
            cursor: 'pointer'
          }}
        >
          &times;
        </button> */}
              </div>
            )}
            <InputLabel
              style={{
                fontWeight: 500,
                fontStyle: "normal",
                fontFamily: "Inter, sans-serif",
                marginBottom: 5,
                color: "#000000",
                marginTop: "10px"
              }}
            >
              Approving Authority Name (N+1) *
            </InputLabel>
            <TextField
              style={{ width: "100%", background: "#FFFFFF" }}
              value={isEditMoc ? mocAdminName : sponsorName}
              id="name"
              variant="outlined"
              InputLabelProps={{ style: { fontWeight: 500, fontStyle: "normal" } }}
              disabled={true}
            />
          </div>
          {
            isEditMoc ? (
              <div style={{ marginTop: "10px" }}>
                <InputLabel
                  style={{
                    fontWeight: 500,
                    fontStyle: "normal",
                    fontFamily: "Inter, sans-serif",
                    color: "#000000",
                  }}
                >
                  (N+1) Remarks
                </InputLabel>
                <TextField
                  style={{ width: "100%", marginTop: 10, background: "#FFFFFF" }}
                  value={implementData.admin_remarks}
                  onChange={(e) => handleInputChange(e, "admin_remarks")}
                  id="outlined-multiline-static"
                  multiline
                  rows={4}
                  disabled={isEditMoc && (userSGID === mocDetData?.mocForm?.admin ? false : true)}
                />
              </div>
            ) : (
              <></>
            )
          }
        </Grid>
        <Grid item xs={12}>
          <InputLabel
            style={{
              fontWeight: 500,
              fontStyle: "normal",
              fontFamily: "Inter, sans-serif",
              marginTop: "10px",
              color: "#000000",
            }}
          >
            Whether this change affects product compliance requirements
          </InputLabel>
          <RadioGroup
            row
            value={implementData.product_compliance_check}
            onChange={(e) => handleInputChange(e, "product_compliance_check")}
          >
            <FormControlLabel
              value="Yes"
              control={<Radio sx={{ color: '#B51849' }} />}
              label="Yes"
              disabled={isEditMoc}
            />
            <FormControlLabel
              value="No"
              control={<Radio sx={{ color: '#B51849' }} />}
              label="No"
              disabled={isEditMoc}
            />
          </RadioGroup>
          {implementData.product_compliance_check === "Yes" &&
            <TextField
            style={{ width: "100%", marginTop: 10, textAlign: "left" }}
            value={implementData.product_compliance}
            onChange={(e) => handleInputChange(e, "product_compliance")}
            id="outlined-select-currency"
            select
            InputLabelProps={{
              shrink: true,
            }}
            disabled={isEditMoc}>
            {productcomplainceNames.map((type,index) => (
              <MenuItem key={index} value={type}>
                {type}
              </MenuItem>
            ))}
          </TextField>
          }
        </Grid>
        <Grid item xs={12}>
          {isEditMoc &&
            mocDetData?.mocForm?.conclusion === "Quality Head" && mocDetData?.changeImplementation?.admin_approval_status === true?
            <div style={{
              marginTop: "10px",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start"
            }}>
              <InputLabel
                style={{
                  fontWeight: 500,
                  fontStyle: "normal",
                  fontFamily: "Inter, sans-serif",
                  marginBottom: 5,
                  color: "#000000",
                  marginTop: "10px"
                }}
              >
                Approving Authority Name (Quality Head) *
              </InputLabel>
              <TextField
                style={{ width: "100%", background: "#FFFFFF" }}
                value={qualityHeadName}
                id="name"
                variant="outlined"
                InputLabelProps={{ style: { fontWeight: 500, fontStyle: "normal" } }}
                disabled={true}
              />
              <InputLabel
                style={{
                  fontWeight: 500,
                  fontStyle: "normal",
                  fontFamily: "Inter, sans-serif",
                  color: "#000000",
                  marginTop: "10px"
                }}
              >
                (Quality Head) Remarks
              </InputLabel>
              <TextField
                style={{ width: "100%", marginTop: 10, background: "#FFFFFF" }}
                value={implementData.quality_remarks}
                onChange={(e) => handleInputChange(e, "quality_remarks")}
                id="outlined-multiline-static"
                multiline
                rows={4}
                disabled={isEditMoc && (storedUserData.name === mocDetData?.mocForm?.quality_name && mocDetData.changeImplementation.quality_approval_status === false ? false : true)}
              />
                <FormControl component="fieldset" style={{ marginTop: "10px" }}>
                <Typography>
                  Whether Plant Head approval required *
                </Typography>
                <RadioGroup
                  value={implementData.plant_check}
                  onChange={(event) =>
                    handleRadioPlantChange(event.target.value)
                  }
                  row
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio sx={{ color: "#B51849" }} />}
                    label="Yes"
                    disabled={disablePlant}
                  />
                  <FormControlLabel
                    value="No"
                    control={<Radio sx={{ color: "#B51849" }} />}
                    label="No"
                    disabled={disablePlant}
                  />
                </RadioGroup>
              </FormControl>
              {mocDetData?.changeImplementation?.plant_check === "Yes" &&
                <>
                  <InputLabel
                    style={{
                      fontWeight: 500,
                      fontStyle: "normal",
                      fontFamily: "Inter, sans-serif",
                      marginBottom: 5,
                      marginTop: "10px",
                      color: "#000000",
                    }}
                  >
                    Approving Authority Name (Plant Head) *
                  </InputLabel>
                  <TextField
                    style={{ width: "100%", background: "#FFFFFF" }}
                    value={plantHeadName}
                    id="name"
                    variant="outlined"
                    InputLabelProps={{ style: { fontWeight: 500, fontStyle: "normal" } }}
                    disabled={true}
                  />
                  <InputLabel
                    style={{
                      fontWeight: 500,
                      fontStyle: "normal",
                      fontFamily: "Inter, sans-serif",
                      marginTop: "10px",
                      color: "#000000",
                    }}
                  >
                    (Plant Head) Remarks
                  </InputLabel>
                  <TextField
                    style={{ width: "100%", marginTop: 10, background: "#FFFFFF" }}
                    value={implementData.plant_remarks}
                    onChange={(e) => handleInputChange(e, "plant_remarks")}
                    id="outlined-multiline-static"
                    multiline
                    rows={4}
                    disabled={isEditMoc && (storedUserData.name === mocDetData?.mocForm?.plant_name && mocDetData.changeImplementation.plant_approval_status === false ? false : true)}
                  />
                </>
              }
            </div>
            :
            mocDetData?.mocForm?.conclusion === "Safety Head" && mocDetData?.changeImplementation?.admin_approval_status === true ?
              <div>
                <div style={{ marginTop: "10px" }}>
                  <InputLabel
                    style={{
                      fontWeight: 500,
                      fontStyle: "normal",
                      fontFamily: "Inter, sans-serif",
                      marginBottom: 5,
                      marginTop: "10px",
                      color: "#000000",
                    }}
                  >
                    Approving Authority Name (Safety Head) *
                  </InputLabel>
                  <TextField
                    style={{ width: "100%", background: "#FFFFFF" }}
                    value={safetyHeadName}
                    id="name"
                    variant="outlined"
                    InputLabelProps={{ style: { fontWeight: 500, fontStyle: "normal" } }}
                    disabled={true}
                  />
                </div>
                <div style={{ marginTop: "10px" }}>
                  <InputLabel
                    style={{
                      fontWeight: 500,
                      fontStyle: "normal",
                      fontFamily: "Inter, sans-serif",
                      color: "#000000",
                    }}
                  >
                    (Safety Head) Remarks
                  </InputLabel>
                  <TextField
                    style={{ width: "100%", marginTop: 10, background: "#FFFFFF" }}
                    value={implementData.safety_remarks}
                    onChange={(e) => handleInputChange(e, "safety_remarks")}
                    id="outlined-multiline-static"
                    multiline
                    rows={4}
                    disabled={isEditMoc && (storedUserData.name === mocDetData?.mocForm?.safety_name && mocDetData.changeImplementation.safety_approval_status === false ? false : true)}
                  />
                   <FormControl component="fieldset" style={{ marginTop: "10px" }}>
                <Typography>
                  Whether Plant Head approval required *
                </Typography>
                <RadioGroup
                  value={implementData.plant_check}
                  onChange={(event) =>
                    handleRadioPlantChange(event.target.value)
                  }
                  row
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio sx={{ color: "#B51849" }} />}
                    label="Yes"
                    disabled={disablePlant}
                  />
                  <FormControlLabel
                    value="No"
                    control={<Radio sx={{ color: "#B51849" }} />}
                    label="No"
                    disabled={disablePlant}
                  />
                </RadioGroup>
              </FormControl>
                  {mocDetData?.changeImplementation?.plant_check === "Yes" &&
                    <>
                      <InputLabel
                        style={{
                          fontWeight: 500,
                          fontStyle: "normal",
                          fontFamily: "Inter, sans-serif",
                          marginBottom: 5,
                          marginTop: "10px",
                          color: "#000000",
                        }}
                      >
                        Approving Authority Name (Plant Head) *
                      </InputLabel>
                      <TextField
                        style={{ width: "100%", background: "#FFFFFF" }}
                        value={plantHeadName}
                        id="name"
                        variant="outlined"
                        InputLabelProps={{ style: { fontWeight: 500, fontStyle: "normal" } }}
                        disabled={true}
                      />
                      <InputLabel
                        style={{
                          fontWeight: 500,
                          fontStyle: "normal",
                          fontFamily: "Inter, sans-serif",
                          marginTop: "10px",
                          color: "#000000",
                        }}
                      >
                        (Plant Head) Remarks
                      </InputLabel>
                      <TextField
                        style={{ width: "100%", marginTop: 10, background: "#FFFFFF" }}
                        value={implementData.plant_remarks}
                        onChange={(e) => handleInputChange(e, "plant_remarks")}
                        id="outlined-multiline-static"
                        multiline
                        rows={4}
                        disabled={isEditMoc && (storedUserData.name === mocDetData?.mocForm?.plant_name && mocDetData.changeImplementation.plant_approval_status === false ? false : true)}
                      />
                    </>
                  }
                </div>
              </div>
              :
              mocDetData?.mocForm?.conclusion === "Safety & Quality" && mocDetData?.changeImplementation?.admin_approval_status === true ?
                <div>
                  <div style={{
                    marginTop: "10px",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start"
                  }}>
                    <InputLabel
                      style={{
                        fontWeight: 500,
                        fontStyle: "normal",
                        fontFamily: "Inter, sans-serif",
                        marginBottom: 5,
                        color: "#000000",
                        marginTop: "10px"
                      }}
                    >
                      Approving Authority Name (Quality Head) *
                    </InputLabel>
                    <TextField
                      style={{ width: "100%", background: "#FFFFFF" }}
                      value={qualityHeadName}
                      id="name"
                      variant="outlined"
                      InputLabelProps={{ style: { fontWeight: 500, fontStyle: "normal" } }}
                      disabled={true}
                    />
                    <InputLabel
                      style={{
                        fontWeight: 500,
                        fontStyle: "normal",
                        fontFamily: "Inter, sans-serif",
                        color: "#000000",
                        marginTop: "10px"
                      }}
                    >
                      (Quality Head) Remarks
                    </InputLabel>
                    <TextField
                      style={{ width: "100%", marginTop: 10, background: "#FFFFFF" }}
                      value={implementData.quality_remarks}
                      onChange={(e) => handleInputChange(e, "quality_remarks")}
                      id="outlined-multiline-static"
                      multiline
                      rows={4}
                      // error={stepError && !trials.quality_remarks ? true : false}
                      disabled={isEditMoc && (storedUserData.name === mocDetData?.mocForm?.quality_name && mocDetData.changeImplementation.quality_approval_status === false ? false : true)}
                    />
                    <InputLabel
                      style={{
                        fontWeight: 500,
                        fontStyle: "normal",
                        fontFamily: "Inter, sans-serif",
                        marginBottom: 5,
                        marginTop: "10px",
                        color: "#000000",
                      }}
                    >
                      Approving Authority Name (Safety Head) *
                    </InputLabel>
                    <TextField
                      style={{ width: "100%", background: "#FFFFFF" }}
                      value={safetyHeadName}
                      id="name"
                      variant="outlined"
                      InputLabelProps={{ style: { fontWeight: 500, fontStyle: "normal" } }}
                      disabled={true}
                    />
                    <InputLabel
                      style={{
                        fontWeight: 500,
                        fontStyle: "normal",
                        fontFamily: "Inter, sans-serif",
                        color: "#000000",
                        marginTop: "10px",
                      }}
                    >
                      (Safety Head) Remarks
                    </InputLabel>
                    <TextField
                      style={{ width: "100%", marginTop: 10, background: "#FFFFFF" }}
                      value={implementData.safety_remarks}
                      onChange={(e) => handleInputChange(e, "safety_remarks")}
                      id="outlined-multiline-static"
                      multiline
                      rows={4}
                      // error={stepError && !trials.safety_remarks ? true : false}
                      disabled={isEditMoc && (storedUserData.name === mocDetData?.mocForm?.safety_name && mocDetData.changeImplementation.safety_approval_status === false ? false : true)}
                    />
                     <FormControl component="fieldset" style={{ marginTop: "10px" }}>
                <Typography>
                  Whether Plant Head approval required *
                </Typography>
                <RadioGroup
                  value={implementData.plant_check}
                  onChange={(event) =>
                    handleRadioPlantChange(event.target.value)
                  }
                  row
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio sx={{ color: "#B51849" }} />}
                    label="Yes"
                    disabled={disablePlant}
                  />
                  <FormControlLabel
                    value="No"
                    control={<Radio sx={{ color: "#B51849" }} />}
                    label="No"
                    disabled={disablePlant}
                  />
                </RadioGroup>
              </FormControl>
                    {mocDetData?.changeImplementation?.plant_check === "Yes" &&
                      <>
                        <InputLabel
                          style={{
                            fontWeight: 500,
                            fontStyle: "normal",
                            fontFamily: "Inter, sans-serif",
                            marginBottom: 5,
                            marginTop: "10px",
                            color: "#000000",
                          }}
                        >
                          Approving Authority Name (Plant Head) *
                        </InputLabel>
                        <TextField
                          style={{ width: "100%", background: "#FFFFFF" }}
                          value={plantHeadName}
                          id="name"
                          variant="outlined"
                          InputLabelProps={{ style: { fontWeight: 500, fontStyle: "normal" } }}
                          disabled={true}
                        />
                        <InputLabel
                          style={{
                            fontWeight: 500,
                            fontStyle: "normal",
                            fontFamily: "Inter, sans-serif",
                            marginTop: "10px",
                            color: "#000000",
                          }}
                        >
                          (Plant Head) Remarks
                        </InputLabel>
                        <TextField
                          style={{ width: "100%", marginTop: 10, background: "#FFFFFF" }}
                          value={implementData.plant_remarks}
                          onChange={(e) => handleInputChange(e, "plant_remarks")}
                          id="outlined-multiline-static"
                          multiline
                          rows={4}
                          disabled={isEditMoc && (storedUserData.name === mocDetData?.mocForm?.plant_name && mocDetData.changeImplementation.plant_approval_status === false ? false : true)}
                        />
                      </>
                    }
                  </div>
                </div> : null
          }
        </Grid>
      </Grid>
      {errors ? (
        <Typography variant="subtitle2" color="red">
          {""}
          Please select the customer approval
        </Typography>
      ) : null}
      {documentError ? (
        <Typography variant="subtitle2" color="red">
          {""}
          Please upload the document
        </Typography>
      ) : null}
      {saveMessage ? (
        <Typography variant="subtitle2" color="red">
          {""}
          Please Save before going to Next
        </Typography>
      ) : null}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        {initialLoad ? (
          <div className="popup-overlay">
            <div className="popup">
              <CircularProgress />
            </div>
          </div>
        ) : showSuccessPopup ? (
          <div className="popup-overlay">
            <div className="popup">
              <p>Data saved successfully!</p>
            </div>
          </div>
        ) : null}
        {initialLoad ? (
          <div className="popup-overlay">
            <div className="popup">
              <CircularProgress />
            </div>
          </div>
        ) : showUpdatePopup ? (
          <div className="popup-overlay">
            <div className="popup">
              <p>Data updated successfully!</p>
            </div>
          </div>
        ) : null}
        {initialLoad ? (
          <div className="popup-overlay">
            <div className="popup">
              <CircularProgress />
            </div>
          </div>
        ) : showSubmitPopup ? (
          <div className="popup-overlay">
            <div className="popup">
              <p>MOC Implement created successfully!</p>
            </div>
          </div>
        ) : null}
        {initialLoad ? (
          <div className="popup-overlay">
            <div className="popup">
              <CircularProgress />
            </div>
          </div>
        ) : showApprovePopup ? (
          <div className="popup-overlay">
            <div className="popup">
              <p>MOC Implement Approved successfully!</p>
            </div>
          </div>
        ) : null}
        {initialLoad ? (
          <div className="popup-overlay">
            <div className="popup">
              <CircularProgress />
            </div>
          </div>
        ) : showRejectPopup ? (
          <div className="popup-overlay">
            <div className="popup">
              <p>MOC Implement Rejected successfully!</p>
            </div>
          </div>
        ) : null}
        {initialLoad ? (
          <div className="popup-overlay">
            <div className="popup">
              <CircularProgress />
            </div>
          </div>
        ) : showResubmitPopup ? (
          <div className="popup-overlay">
            <div className="popup">
              <p>MOC Implement Resubmitted successfully!</p>
            </div>
          </div>
        ) : null}
        {!isEditMoc ?
          <Button
            variant="contained"
            style={{
              backgroundColor: "#fff",
              fontSize: 20,
              color: "#B1174B",
              border: "1px solid #B1174B",
              textTransform: "none",
              width: "100%",
              marginTop: 100,
              marginRight: 5,
              marginBottom: 10,
              boxShadow: "none",
            }}
            onClick={handleImpSaveClick}>
            Save
          </Button>
          :
          isEditMoc && userSGID === mocDetData?.mocForm?.user_id ?
            <></> :
            <>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#fff",
                  fontSize: 20,
                  color: "#B1174B",
                  border: "1px solid #B1174B",
                  textTransform: "none",
                  width: "100%",
                  marginTop: 20,
                  marginRight: 5,
                  marginBottom: 10,
                  boxShadow: "none",
                }}
                onClick={handleImpRejectClick}
                disabled={mocDetData.changeImplementation.implement_status === "rejected"}
              >
                Reject
              </Button>
              {/* {anchorE2 && <EmailPopup anchorE2={anchorE2} setAnchorE2={setAnchorE2} closePopup={handleCloseEmailPopup} />} */}
            </>
        }
        {(isEditMoc && userSGID === mocDetData?.mocForm?.user_id)
          // && !(userRole === "Quality Head" || userRole === "Safety Head" || userRole === "Plant Head") 
          ?
          <>
            <Button
              variant="contained"
              style={{
                backgroundColor: "#B1174B",
                fontSize: 20,
                color: "white",
                textTransform: "none",
                width: "100%",
                marginTop: 20,
                marginRight: 5,
              }}
              onClick={mocDetData?.changeImplementation?.implement_status === "rejected" ? handleImpReSubmitClick : handleImpCancel}
            >
              {mocDetData?.changeImplementation?.implement_status === "rejected" ? "Resubmit" : "Cancel"}
            </Button>
            {/* {anchorE2 && <EmailPopup anchorE2={anchorE2} setAnchorE2={setAnchorE2} closePopup={handleCloseEmailPopup} />} */}
          </>
          :
          (isEditMoc) && (
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#B1174B",
                  fontSize: 20,
                  color: "white",
                  textTransform: "none",
                  width: "100%",
                  marginTop: 20,
                  marginRight: 5,
                  marginBottom: 10,
                }}
                onClick={handleImpApproveClick}
                disabled={mocDetData?.changeImplementation?.implement_status === "rejected"}
              >
                Approve
              </Button>
          )
        }
      </div>
    </div>
  );
};

export default ImplementMOC;