import React, { useState, useEffect } from "react";
import { Typography, Button, Dialog, DialogTitle, DialogContent, Box, useTheme, useMediaQuery, IconButton, CircularProgress, TextField } from "@mui/material";
import API from "../Services/API";
import axios from "axios";
import { useRecoilState } from "recoil";
import { notification } from "../recoil/atoms";
import CloseIcon from "@mui/icons-material/Close";
import { handleSend } from "./EmailPopupCms";

const ViewApproval = ({ isOpen, onClose, approveData }) => {
    const [notificationData, setNotificationData] = useRecoilState(notification);
    const [showUpdatePopup, setShowUpdatePopup] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [initialLoad, setInitialLoad] = useState(false);
    const access_token = JSON.parse(localStorage.getItem("accesstoken"));
    const storedUserData = JSON.parse(localStorage.getItem("userDatas"));
    const userSGID = storedUserData.SGID;
    console.log(userSGID, "userSGID19")

    useEffect(() => {
        const handlePopup = (setter, delay) => {
            setter(true);
            const timer = setTimeout(() => {
                setter(false);
            }, delay);
            return () => clearTimeout(timer);
        };

        const handlePopups = (showPopup, setShowPopup) => {
            if (showPopup) {
                setInitialLoad(true);
                setTimeout(() => {
                    setInitialLoad(false);
                    handlePopups(setShowPopup, 3000); // Increased to 3000 milliseconds (3 seconds)
                }, 5000); // Increased to 5000 milliseconds (5 seconds) before showing the popup again
            }
        };
        handlePopups(showUpdatePopup, setShowUpdatePopup);
    }, [showUpdatePopup]);

    useEffect(() => {
        if (showUpdatePopup) {
            onClose();
        }
    }, [showUpdatePopup]);

    const onHandleChange = (event, field) => {
        let updatedForm = { ...notificationData };
        const value = event.target.value;
        updatedForm[field] = value;
        setNotificationData(updatedForm);
    };

    const handleNotifyHeaderUpdate = async (functionalStatus, approvalStatus) => {
        console.log(approvalStatus, "approvalStatus48")
        const storedCmsDataString = localStorage.getItem("cmsDataByID");
        const storedCmsData = JSON.parse(storedCmsDataString);
        const notificationHeaderID = storedCmsData?.cmsForm?.notification_header_id;
        try {
            const notifyHeaderData = {
                updated_at: new Date()
            }
            const response = await axios.put(
                `${API.UPDATE_NOTIFICATION_HEADER}?id=${notificationHeaderID}`,
                notifyHeaderData,
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`
                    }
                }
            );
            if (response.status === 200) {
                await handleNotifyUpdate(functionalStatus, approvalStatus, notificationHeaderID);
            }
        } catch (error) {
            console.error("An error occurred in update trials:", error);
        }
    }

    const handleNotifyUpdate = async (functionalStatus, approvalStatus, notificationHeaderID) => {
        const storedCmsDataString = localStorage.getItem("cmsDataByID");
        const storedCmsData = JSON.parse(storedCmsDataString);
        const notificationID = storedCmsData?.cmsForm?.notificationHeader?.id;
        console.log(notificationID, "notificationID430")
        const storeCmsID = storedCmsData?.cmsForm?.id;
        const functionalSGID = storedCmsData?.cmsForm?.notificationHeader?.cmsForms?.find(cmsForm =>
            cmsForm?.functional_sgid?.includes(userSGID)
        )?.functional_sgid;
        const approverSGID = storedCmsData?.cmsForm?.notificationHeader?.cmsForms?.find(cmsForm =>
            cmsForm?.approver_sgid?.includes(userSGID)
        )?.approver_sgid;
        console.log(functionalSGID, "functionalSGID87")
        try {
            const updateData = {
                approver_comments: notificationData.approver_comments,
                approval_status: approverSGID && Array.isArray(approverSGID) && approverSGID.includes(userSGID) && approvalStatus,
                functional_comments: notificationData.functional_comments,
                functional_status: functionalSGID && Array.isArray(functionalSGID) && functionalSGID.includes(userSGID) && functionalStatus,
                functional_sgid: functionalSGID,
                approver_sgid: approverSGID,
                ...((functionalStatus === "approved" && functionalSGID && Array.isArray(functionalSGID) && functionalSGID.includes(userSGID))
                    ? { functional_approval_status: true }
                    : {}),
                ...((approvalStatus === "approved" && approverSGID && Array.isArray(approverSGID) && approverSGID.includes(userSGID))
                    ? { cft_approval_status: true }
                    : {}),

            };
            const response = await axios.put(
                `${API.UPDATE_NOTIFICATION}?id=${notificationID}`,
                updateData,
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`
                    }
                }
            );
            console.log("updateData469", updateData);
            if (response.status === 200) {
                await cmsForm(functionalStatus, approvalStatus, storeCmsID);
                setShowUpdatePopup(true);
                const storedUserByData = JSON.parse(localStorage.getItem("userDatas"));
                let from_email = [storedUserByData?.MailId];
                console.log(storedCmsData?.cmsForm?.notificationHeader?.cmsForms, "storedCmsData471")
                let to_email = [
                    storedCmsData?.cmsForm?.user_mail,
                    ...new Set(storedCmsData?.cmsForm?.notificationHeader?.cmsForms?.map(cmsForm => cmsForm?.functional_mail_id).filter(Boolean)),
                    ...storedCmsData?.cmsForm?.notificationHeader?.cmsForms?.map(cmsForm => cmsForm?.approver_mail_id).filter(Boolean)
                ].filter(email => email);
                console.log(to_email, "to_email484");
                let cc_email = [];
                console.log(functionalStatus, "appStatus.value498")
                if (functionalStatus === "approved") {
                    cc_email = [...storedCmsData?.cmsForm?.notificationHeader?.cmsForms?.map(cmsForm => cmsForm?.information_cft_mail_id).filter(Boolean)];
                }
                handleSend(from_email, to_email, cc_email.length > 0 ? cc_email : undefined
                );
            }
        } catch (error) {
            console.error("An error occurred in update trials:", error);
        }
    }

    const cmsForm = async (functionalStatus, approvalStatus, storeCmsID) => {
        const updateCmsDet = localStorage.getItem("cmsID");
        const storedCmsDataString = localStorage.getItem("cmsDataByID");
        const storedCmsData = JSON.parse(storedCmsDataString);
        const takeCmsID = storedCmsData?.cms_id;
        localStorage.setItem("takeCmsID", takeCmsID);
        const requestID = storedCmsData?.cmsForm?.change_request_id;
        const changeRequireID = storedCmsData?.cmsForm?.change_required_id;
        const impactID = storedCmsData?.cmsForm?.impact_change_id;
        const notifyHeaderID = storedCmsData?.cmsForm?.notification_header_id;
        const userID = storedCmsData?.cmsForm?.user_id;
        const storedUserData = JSON.parse(localStorage.getItem("userDatas"));
        const userSGID = storedUserData.SGID;
        const userName = storedCmsData?.cmsForm?.created_by;
        const cmsNumber = storedCmsData?.cmsForm?.cms_no;
        localStorage.setItem("cmsNumber", cmsNumber);
        const isEditCmsNo = storedCmsData?.cmsForm?.cms_no;
        const userMail = storedCmsData?.cmsForm?.user_mail;
        const updateTakeCmsDet = localStorage.getItem("takeCmsID");
        console.log(updateTakeCmsDet, "updateTakeCmsDet130")
        try {
            const cmsData = {
                id: updateTakeCmsDet,
                user_id: storedCmsData?.cmsForm?.user_id,
                updated_at: new Date(),
                created_by: userName,
                doc_ref: "F/CFT/SGSI/01",
                cms_no: isEditCmsNo,
                change_request_id: requestID,
                change_required_id: changeRequireID,
                impact_change_id: impactID,
                notification_header_id: notifyHeaderID,
                submission_status: getSubmissionStatus(),
                user_mail: userMail
            };
            console.log(cmsData, "cmsData147")
            function getSubmissionStatus() {
                let submissionStatus;
                const cmsDetData = JSON.parse(localStorage.getItem("cmsDataByID"));
                const submittedbackforqueries = cmsDetData?.cmsForm?.submission_status === "submittedbackforqueries";

                if (JSON.parse(localStorage.getItem("cmsDataByID"))?.cmsForm?.notification_header_id !== null && userSGID === userID) {
                    submissionStatus = "pending";
                }
                else if (userSGID !== userID && cmsDetData?.cmsForm?.notificationHeader?.cmsForms.some((approver) =>
                    approver?.functional_sgid?.includes(userSGID))) {
                    submissionStatus = functionalStatus;
                }
                else if (submittedbackforqueries && userSGID === userID) {
                    submissionStatus = "resubmitted";
                }
                else if (cmsDetData?.cmsForm?.submission_status === "approved" && userSGID === userID) {
                    submissionStatus = "resubmitted";
                }
                else if (userSGID !== userID && cmsDetData?.cmsForm?.notificationHeader?.cmsForms.some((approver) =>
                    approver?.approver_sgid?.includes(userSGID))) {
                    submissionStatus = approvalStatus;
                }
                return submissionStatus;
            }
            const cmsResponse = await axios.put(
                `${API.UPDATE_CMS_FORM}?id=${updateTakeCmsDet}`,
                cmsData,
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`
                    }
                }
            );
            console.log(cmsResponse, "cmsResponse183")
            if (cmsResponse.status === 200) {
                await insertCmsApprovals(functionalStatus, approvalStatus, updateCmsDet);
            }
        } catch (error) {
            console.error("An error occurred in insertIntoMocTable:", error);
        }
    };

    const insertCmsApprovals = async (functionalStatus, approvalStatus, updateCmsDet) => {
        const storedUserData = JSON.parse(localStorage.getItem("userDatas"));
        const userSGID = storedUserData.SGID;
        const userAppName = storedUserData.FName + " " + storedUserData.LName;
        const cmsDetData = JSON.parse(localStorage.getItem("cmsDataByID"));
        const cmsIds = localStorage.getItem("takeCmsID");
        const userID = cmsDetData?.cmsForm?.user_id;
        try {
            const cmsData = {
                cms_id: cmsIds,
                user_sgid: userSGID,
                user_approval_name: userAppName,
                status: getStatus()
            };
            function getStatus() {
                let status;
                const cmsDetData = JSON.parse(localStorage.getItem("cmsDataByID"));
                const submittedbackforqueries = cmsDetData?.cmsForm?.submission_status === "submittedbackforqueries";
                if (userSGID !== userID && cmsDetData?.cmsForm?.notificationHeader?.cmsForms.some((approver) =>
                    approver?.functional_sgid?.includes(userSGID))) {
                    status = functionalStatus;
                }
                else if (submittedbackforqueries && userSGID === userID) {
                    status = "resubmitted";
                }
                else if (cmsDetData?.cmsForm?.submission_status === "approved" && userSGID === userID) {
                    status = "resubmitted";
                }
                else if (userSGID !== userID && cmsDetData?.cmsForm?.notificationHeader?.cmsForms.some((approver) =>
                    approver?.approver_sgid?.includes(userSGID))) {
                    status = approvalStatus;
                }
                return status;
            }
            const mocResponse = await axios.post(API.INSERT_CMS_APPROVALS, cmsData,
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`
                    }
                });
            if (mocResponse.status === 200) {
                const cmsApprovalID = mocResponse.data["identifiers"][0].id;
                localStorage.setItem("cmsApprovalID", cmsApprovalID);
            }
        } catch (error) {
            console.error("An error occurred in insertIntoMocTable:", error);
        }
    };

    const cmsDetData = JSON.parse(localStorage.getItem("cmsDataByID"));
    let disableUserCondition;
    disableUserCondition = userSGID === cmsDetData?.cmsForm?.user_id;
    let cftSettings = cmsDetData?.cmsForm?.notificationHeader?.cmsForms?.some(cmsForm =>
        cmsForm?.approver_sgid?.includes(userSGID));
    let functionalSGIDArray = cmsDetData?.cmsForm?.notificationHeader?.cmsForms[0]?.functional_sgid
    let functionalSettings = Array.isArray(functionalSGIDArray) && functionalSGIDArray.includes(userSGID);
    console.log(userSGID, "userSGID280")
    console.log(functionalSGIDArray, "functionalSGIDArray281")

    return (
        <Dialog
            open={isOpen}
            fullScreen={fullScreen}
            fullWidth
            maxWidth="sm"
            onClose={(event, reason) => {
                if (reason === 'backdropClick') {
                    event.stopPropagation();
                } else {
                    onClose();
                }
            }}>
            <DialogTitle>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6">Requested Change Details</Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={onClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                <React.Fragment>
                    {console.log(approveData, "approveData310")}
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                            CMS NO.
                        </Typography>
                        <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                            {approveData?.cmsForm?.cms_no}
                        </Typography>
                    </Box>

                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                            DATE
                        </Typography>
                        <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                            {approveData?.cmsForm?.changeRequest?.change_request[0]?.date}
                        </Typography>
                    </Box>

                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                            REQUEST INITIATOR
                        </Typography>
                        <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                            {approveData?.cmsForm?.changeRequest?.change_request[0]?.request_initiator}
                        </Typography>
                    </Box>

                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                            DEPARTMENT
                        </Typography>
                        <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                            {approveData?.cmsForm?.changeRequest?.change_request[0]?.department}
                        </Typography>
                    </Box>

                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                            PLANT
                        </Typography>
                        <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                            {approveData?.cmsForm?.changeRequest?.change_request[0]?.plant}
                        </Typography>
                    </Box>

                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                            TYPE OF DESIGN CHANGE
                        </Typography>
                        <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                            {approveData?.cmsForm?.changeRequest?.change_request[0]?.type_design_change}
                        </Typography>
                    </Box>

                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                            CHANGE CATEGORY
                        </Typography>
                        <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                            {approveData?.cmsForm?.changeRequest?.change_request[0]?.change_category}
                        </Typography>
                    </Box>

                    <div>
                        <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                            {(() => {
                                const { change_category, design_change, process_change } = approveData?.cmsForm?.changeRequest?.change_request[0] || {};

                                if (change_category === "Design Change") {
                                    if (design_change === "Internal") {
                                        return (
                                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                    {approveData?.cmsForm?.changeRequest?.change_request[0]?.design_int_customer ? "CUSTOMER" : ""}
                                                </Typography>
                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                    {approveData?.cmsForm?.changeRequest?.change_request[0]?.design_int_customer || ""}
                                                </Typography>
                                            </Box>
                                        );
                                    } else if (design_change === "External") {
                                        return (
                                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                    CUSTOMER
                                                </Typography>
                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                    {approveData?.cmsForm?.changeRequest?.change_request[0]?.design_ext_customer || ""}
                                                </Typography>
                                            </Box>
                                        );
                                    }
                                } else if (change_category === "Process Change") {
                                    if (process_change === "Internal") {
                                        return (
                                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                    {approveData?.cmsForm?.changeRequest?.change_request[0]?.process_int_customer ? "CUSTOMER" : ""}
                                                </Typography>
                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                    {approveData?.cmsForm?.changeRequest?.change_request[0]?.process_int_customer || ""}
                                                </Typography>
                                            </Box>
                                        );
                                    } else if (process_change === "External") {
                                        return (
                                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                    CUSTOMER
                                                </Typography>
                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                    {approveData?.cmsForm?.changeRequest?.change_request[0]?.process_ext_customer || ""}
                                                </Typography>
                                            </Box>
                                        );
                                    }
                                }

                                return null; // If no condition matches, return null or handle accordingly
                            })()}
                        </Typography>
                    </div>

                    <div>
                        <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                            {(() => {
                                const { change_category, design_change, process_change } = approveData?.cmsForm?.changeRequest?.change_request[0] || {};

                                if (change_category === "Design Change") {
                                    if (design_change === "Internal") {
                                        return (
                                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                    {approveData?.cmsForm?.changeRequest?.change_request[0]?.design_int_model ? "MODEL" : ""}
                                                </Typography>
                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                    {approveData?.cmsForm?.changeRequest?.change_request[0]?.design_int_model || ""}
                                                </Typography>
                                            </Box>
                                        );
                                    } else if (design_change === "External") {
                                        return (
                                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                    MODEL
                                                </Typography>
                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                    {approveData?.cmsForm?.changeRequest?.change_request[0]?.design_ext_model || ""}
                                                </Typography>
                                            </Box>
                                        );
                                    }
                                } else if (change_category === "Process Change") {
                                    if (process_change === "Internal") {
                                        return (
                                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                    {approveData?.cmsForm?.changeRequest?.change_request[0]?.process_int_model ? "MODEL" : ""}
                                                </Typography>
                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                    {approveData?.cmsForm?.changeRequest?.change_request[0]?.process_int_model || ""}
                                                </Typography>
                                            </Box>
                                        );
                                    } else if (process_change === "External") {
                                        return (
                                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                    MODEL
                                                </Typography>
                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                    {approveData?.cmsForm?.changeRequest?.change_request[0]?.process_ext_model || ""}
                                                </Typography>
                                            </Box>
                                        );
                                    }
                                }

                                return null; // If no condition matches, return null or handle accordingly
                            })()}
                        </Typography>
                    </div>

                    <div>
                        <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                            {(() => {
                                const { change_category, design_change, process_change } = approveData?.cmsForm?.changeRequest?.change_request[0] || {};

                                if (change_category === "Design Change") {
                                    if (design_change === "Internal") {
                                        return (
                                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                    {approveData?.cmsForm?.changeRequest?.change_request[0]?.design_int_glass_reference ? "GLASS REFERENCE" : ""}
                                                </Typography>
                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                    {approveData?.cmsForm?.changeRequest?.change_request[0]?.design_int_glass_reference || ""}
                                                </Typography>
                                            </Box>
                                        );
                                    } else if (design_change === "External") {
                                        return (
                                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                    GLASS REFERENCE
                                                </Typography>
                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                    {approveData?.cmsForm?.changeRequest?.change_request[0]?.design_ext_glass_reference || ""}
                                                </Typography>
                                            </Box>
                                        );
                                    }
                                } else if (change_category === "Process Change") {
                                    if (process_change === "Internal") {
                                        return (
                                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                    {approveData?.cmsForm?.changeRequest?.change_request[0]?.process_int_glass_reference ? "GLASS REFERENCE" : ""}
                                                </Typography>
                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                    {approveData?.cmsForm?.changeRequest?.change_request[0]?.process_int_glass_reference || ""}
                                                </Typography>
                                            </Box>
                                        );
                                    } else if (process_change === "External") {
                                        return (
                                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                    GLASS REFERENCE
                                                </Typography>
                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                    {approveData?.cmsForm?.changeRequest?.change_request[0]?.process_ext_glass_reference || ""}
                                                </Typography>
                                            </Box>
                                        );
                                    }
                                }

                                return null; // If no condition matches, return null or handle accordingly
                            })()}
                        </Typography>
                    </div>

                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                            DATE OF RECEIVING CUSTOMER REQUIREMENT:
                        </Typography>
                        <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                            {approveData?.cmsForm?.changeRequest?.change_request[0]?.receiveing_customer_date}
                        </Typography>
                    </Box>

                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                            DATE OF CUSTOMER REQUIREMENT FOR IMPLEMENT :
                        </Typography>
                        <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                            {approveData?.cmsForm?.changeRequest?.change_request[0]?.customer_req_imple_date}
                        </Typography>
                    </Box>

                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                            DESCRIPTION OF CHANGE REQUIRED :
                        </Typography>
                        <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                            {approveData?.cmsForm?.changeRequest?.change_request[0]?.des_change_required}
                        </Typography>
                    </Box>

                    {
                        approveData?.cmsForm?.changeRequest?.change_request[0]?.other_comments &&
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                OTHERS :
                            </Typography>
                            <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                {approveData?.cmsForm?.changeRequest?.change_request[0]?.other_comments}
                            </Typography>
                        </Box>
                    }

                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                            DETAILS OF CHANGE REQURIED :
                        </Typography>
                        <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                            {approveData?.cmsForm?.changeRequired?.change_required_remarks}
                        </Typography>
                    </Box>

                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                            REASON FOR CHANGE REQUEST :
                        </Typography>
                        <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                            {approveData?.cmsForm?.changeRequired?.change_request_remarks}
                        </Typography>
                    </Box>

                    {approveData?.cmsForm?.notificationHeader?.cmsForms[0]?.functional_comments &&
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                FUNCTIONAL HEAD COMMENTS :
                            </Typography>
                            <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                {approveData?.cmsForm?.notificationHeader?.cmsForms[0]?.functional_comments}
                            </Typography>
                        </Box>
                    }

                    {approveData?.cmsForm?.notificationHeader?.cmsForms[0]?.functional_status &&
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                FUNCTIONAL HEAD APPROVAL :
                            </Typography>
                            <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                {approveData?.cmsForm?.notificationHeader?.cmsForms[0]?.functional_status}
                            </Typography>
                        </Box>
                    }

                    {approveData?.cmsForm?.notificationHeader?.cmsForms?.map((cmsForm, index) => (
                        <Box key={index} display="flex" justifyContent="space-between" alignItems="center">
                            {cmsForm?.approver_comments && (
                                <>
                                    <Typography
                                        style={{
                                            color: "#000",
                                            fontFamily: "inherit",
                                            fontSize: { xl: '16px', md: '14px' },
                                            fontWeight: 600,
                                            flex: 1
                                        }}
                                    >
                                        CFT HEAD COMMENTS :
                                    </Typography>
                                    <Typography
                                        style={{
                                            color: "#616161",
                                            fontFamily: "inherit",
                                            fontSize: { xl: '14px', md: '12px' },
                                            marginTop: "2px",
                                            textAlign: 'right'
                                        }}
                                    >
                                        {cmsForm?.approver_comments}
                                    </Typography>
                                </>
                            )}
                        </Box>
                    ))}

                    {approveData?.cmsForm?.notificationHeader?.cmsForms?.map((cmsForm, index) => (
                        <Box key={index} display="flex" justifyContent="space-between" alignItems="center">
                            {cmsForm?.approval_status && (
                                <>
                                    <Typography
                                        style={{
                                            color: "#000",
                                            fontFamily: "inherit",
                                            fontSize: { xl: '16px', md: '14px' },
                                            fontWeight: 600,
                                            flex: 1
                                        }}
                                    >
                                        CFT HEAD APPROVAL :
                                    </Typography>
                                    <Typography
                                        style={{
                                            color: "#616161",
                                            fontFamily: "inherit",
                                            fontSize: { xl: '14px', md: '12px' },
                                            marginTop: "2px",
                                            textAlign: 'right'
                                        }}
                                    >
                                        {cmsForm?.approval_status}
                                    </Typography>
                                </>
                            )}
                        </Box>
                    ))}

                    {functionalSettings &&
                        <div style={{ marginTop: "10px" }}>
                            <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                FUNCTIONAL HEAD COMMENTS :
                            </Typography>
                            <TextField
                                style={{ width: "100%", marginTop: 10, background: "#FFFFFF" }}
                                value={notificationData.functional_comments}
                                onChange={(e) => onHandleChange(e, "functional_comments")}
                                id="outlined-multiline-static"
                                multiline
                                rows={4}
                            />
                        </div>

                    }

                    {cftSettings &&
                        <div style={{ marginTop: "10px" }}>
                            <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                CFT HEAD COMMENTS :
                            </Typography>
                            <TextField
                                style={{ width: "100%", marginTop: 10, background: "#FFFFFF" }}
                                value={notificationData.approver_comments}
                                onChange={(e) => onHandleChange(e, "approver_comments")}
                                id="outlined-multiline-static"
                                multiline
                                rows={4}
                                disabled={functionalSettings}
                            />
                        </div>
                    }
                </React.Fragment>
                {
                    userSGID !== cmsDetData?.cmsForm?.user_id &&
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "10px" }}>
                        <Button
                            variant="contained"
                            style={{
                                background: '#B51849',
                                width: '230px',
                                border: 0,
                                borderRadius: 3,
                                color: 'white',
                                height: '38px',
                                fontSize: '14px',
                                textTransform: 'capitalize',
                                margin: '0 8px',
                                padding: '4px 0',
                            }}
                            onClick={() => { handleNotifyHeaderUpdate("approved", "approved") }}
                        >
                            Approve
                        </Button>
                        <Button
                            variant="contained"
                            style={{
                                background: '#B51849',
                                width: '230px',
                                border: 0,
                                borderRadius: 3,
                                color: 'white',
                                height: '38px',
                                fontSize: '14px',
                                textTransform: 'capitalize',
                                margin: '0 8px',
                                padding: '4px 0',
                            }}
                            onClick={() => { handleNotifyHeaderUpdate("rejected", "rejected") }}
                        >
                            Reject
                        </Button>
                        <Button
                            variant="contained"
                            style={{
                                background: '#B51849',
                                width: '230px',
                                border: 0,
                                borderRadius: 3,
                                color: 'white',
                                height: '38px',
                                fontSize: '14px',
                                textTransform: 'capitalize',
                                margin: '0 8px',
                                padding: '4px 0',
                            }}
                            onClick={() => { handleNotifyHeaderUpdate("submittedbackforqueries", "submittedbackforqueries") }}
                        >
                            Return for Queries
                        </Button>
                    </div>
                }

                {initialLoad ? (
                    <div className="popup-overlay">
                        <div className="popup">
                            <CircularProgress />
                        </div>
                    </div>
                ) : showUpdatePopup ? (
                    <div className="popup-overlay">
                        <div className="popup">
                            <p>Data updated successfully!</p>
                        </div>
                    </div>
                ) : null}
            </DialogContent>
        </Dialog>
    );
};

export default ViewApproval;