import React, { useState, useEffect } from "react";
import { Typography, FormControl, Radio, RadioGroup, FormControlLabel, Divider, Checkbox, InputLabel, Button, CircularProgress, Dialog, DialogContent, IconButton, TextField, Tooltip } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import Header from "../app/TopAppBar";
import { useRecoilState } from "recoil";
import { changeAssessmentData, editMocData } from "../recoil/atoms";
import axios from "axios";
import API from "../Services/API";
import { getQuestionsForAffect } from "./EHSF";
import BackIcon from "../Asset/Icons/back_icon.svg";

const ChangeAssessment = ({ dataAssessment, onGoBackAssessment }) => {
  const navigate = useNavigate();
  const [changeAssessment, setChangeAssessment] = useRecoilState(changeAssessmentData);
  const [newDialogOpens, setNewDialogRequestOpen] = useState('true');
  const [assessmentDialogOpen, setAssessmentDialogOpen] = useState(false);
  const [isEditMoc] = useRecoilState(editMocData);
  const [errors, setErrors] = useState(false);
  const [save, setSave] = useState(false);
  const [saveMessage, setSaveMessage] = useState(false);
  const [, setCheckedQuestions] = useState("");
  const [, setShowRelatedQuestions] = useState("");
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showUpdatePopup, setShowUpdatePopup] = useState(false);
  const [questionAssess, setQuestionAssess] = useState([]);
  const [stepError, setStepError] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [textErrors, setTextErrors] = useState(Array(changeAssessment.checkpoints_remarks.length).fill(false));
  const [value, setValue] = useState("");
  const [error, setError] = useState(false);

  const access_token = JSON.parse(localStorage.getItem("accesstoken"));

  useEffect(() => {
    const handlePopup = (setter, delay) => {
      setter(true);
      const timer = setTimeout(() => {
        setter(false);
      }, delay);
      return () => clearTimeout(timer);
    };
    const handlePopups = (showPopup, setShowPopup) => {
      if (showPopup) {
        setInitialLoad(true);
        setTimeout(() => {
          setInitialLoad(false);
          handlePopup(setShowPopup, 1000);
        }, 2000);
      }
    };
    handlePopups(showSuccessPopup, setShowSuccessPopup);
    handlePopups(showUpdatePopup, setShowUpdatePopup);
  }, [showSuccessPopup, showUpdatePopup]);

  const handleAffectsChange = (event) => {
    const selectedOptions = event.target.value;
    localStorage.setItem("isAssessmentSave", false);
    setChangeAssessment((prev) => ({
      ...prev,
      change_affects: selectedOptions,
    }));
    setCheckedQuestions(selectedOptions);
    setShowRelatedQuestions(false);
    setErrors(false);
  };

  const handleChange = (e) => {
    const inputValue = e.target.value;

    // Allow only integers
    if (/^\d*$/.test(inputValue)) {
      const numericValue = inputValue === "" ? 0 : parseInt(inputValue, 10); // Parse to integer or default to 0
      setValue(inputValue);
      setError(false); // Clear the error if the input is valid
    } else {
      setError(true); // Show error if invalid input
    }
  };
console.log("valkjs",typeof(value))

  const CPQuestionsStyle = {
    fontWeight: 550,
    fontSize: 16,
    fontStyle: "normal",
    fontFamily: "Inter, sans-serif",
    color: "black",
    lineHeight: 1.5,
    textTransform: "capitalize",
    marginTop: "10px",
  };
  console.log("changeAssessment",changeAssessment)

  // const questions = [
  //   "Change affect or create deviation in product conformance requirements or EHS legal compliance?",
  //   "Any change in Product specifications or can be met with existing specifications & control plan (product changes)?",
  //   "Any change in measurement instruments/inspection method?",
  //   "Any change in the mother nature in production field, including temperature, humidity, noise disturbance, vibrancy, lightening, and indoor pollution which influence the products or service?",
  //   "Machine/facility capacity/ ability to produce product will not be affected?",
  //   "Equipment addition or major modification is not required?",
  //   "Can the change be without incurring any:",
  //   "a) Cost of capital equipment / change in methods?",
  //   "b) New or additional raw materials /chemicals?",
  //   "c) Any additional resources/training required ?",
  //   "d) Cost of new machine or technology improvement?",
  //   "E) EHS Legal requirement status changes?",
  //   "F) Change affects or related to Environment, Health, Safety & Fire",
  // ];

  useEffect(() => {
    const fetchAssessQuestion = async () => {
      try {
        const response = await axios.get(`${API.GET_QUESTIONS}`, {
          headers: {
            Authorization: `Bearer ${access_token}`
          }
        });
        const data = response.data.filter((item) => item.tag === "checkpoints");
        // Predefined order for question_no
        const predefinedOrder = ['1', '2', '3', '4', '5', '6', null, '6A', '6B', '6C', '6D', '6E', '6F'];
        // Sort questions based on predefined order
        const sortedData = predefinedOrder.map((qNo) => data.find((item) => item.question_no === qNo)).filter(Boolean);
        setQuestionAssess(sortedData);
        if(!isEditMoc){
          setChangeAssessment((prev) => ({
            ...prev,
            results:Array(sortedData.length).fill("No") ,
          }));
        }
      } catch (error) {
        console.error("Error fetching questions:", error);
      }
      setInitialLoad(false);
    };
    fetchAssessQuestion();
  }, []);

  const handleRadioChange = (index, answer) => {
    const updatedAnswers = [...changeAssessment.results];
    updatedAnswers[index] = answer;
    localStorage.setItem("isAssessmentSave", false);
    setChangeAssessment((prev) => ({
      ...prev,
      results: updatedAnswers,
    }));
    setErrors(false);
  };

  // Assuming 'questions' is an array of question objects
  const lastQuestionId = "6F";
  const lastQuestionIndex = questionAssess.findIndex(
    (q) => q.question_no === lastQuestionId
  );

  // Check if the last question is answered "No"
  const showChangeAffects = changeAssessment.results[lastQuestionIndex] !== "No";

  // const handleRemarksChange = (index, event) => {
  //   let updatedRemarks = event.target.value;
  //   localStorage.setItem("isAssessmentSave", false);
  //   if (updatedRemarks.length > 200) {
  //     updatedRemarks = updatedRemarks.slice(0, 200);
  //     setTextErrors((prevTextErrors) => {
  //       const updatedErrors = [...prevTextErrors];
  //       updatedErrors[index] = true;
  //       return updatedErrors;
  //     });
  //   } else {
  //     setTextErrors((prevTextErrors) => {
  //       const updatedErrors = [...prevTextErrors];
  //       updatedErrors[index] = false;
  //       return updatedErrors;
  //     });
  //   }
  //   setChangeAssessment((prev) => ({
  //     ...prev,
  //     checkpoints_remarks: [
  //       ...prev.checkpoints_remarks.slice(0, index),
  //       updatedRemarks,
  //       ...prev.checkpoints_remarks.slice(index + 1),
  //     ],
  //   }));
  //   setErrors(false);
  // };

  const handleRemarksChange = (index, event) => {
    let updatedRemarks = event.target.value;
    localStorage.setItem("isAssessmentSave", false);
    // Limit remark length to 200 characters
    if (updatedRemarks.length > 200) {
      updatedRemarks = updatedRemarks.slice(0, 200);
      setTextErrors((prevTextErrors) => {
        const updatedErrors = [...prevTextErrors];
        updatedErrors[index] = true;
        return updatedErrors;
      });
    } else {
      setTextErrors((prevTextErrors) => {
        const updatedErrors = [...prevTextErrors];
        updatedErrors[index] = false;
        return updatedErrors;
      });
    }

    // Update the remarks array
    setChangeAssessment((prev) => {
      const updatedRemarksArray = [...prev.checkpoints_remarks];
      updatedRemarksArray[index] = updatedRemarks;
      return {
        ...prev,
        checkpoints_remarks: updatedRemarksArray,
      };
    });

    setErrors(false);
  };

  const assessmentData = {
    checkpoints: [],
    budget: value === "" ? 0 : parseInt(value, 10),
  };

  const change_affects_json = [];
  const assChangeAffects = changeAssessment.change_affects ?? [];
  assChangeAffects.map((affect,index) => {
    // //console.log("changeAssessment.change_affects_json[0][0]",changeAssessment.change_affects_json[0][0][affect][0]);
    //console.log("affect",typeof affect)
  // Safely check if the structure exists and contains the required data
  const affectData = changeAssessment.change_affects_json?.[0]?.[index]?.[affect];
  
  // If affectData exists and contains results, use it; otherwise, get questions
  const questionsForAffect = affectData && affectData[0]?.results
    ? affectData
    : getQuestionsForAffect(affect);
    change_affects_json.push({ [affect]: questionsForAffect });
    return null;
  });

  questionAssess.forEach((checkpoint, index) => {
    if (
      checkpoint.questions ===
      "Change affects or related to Environment, Health, Safety & Fire"
    ) {
      assessmentData.checkpoints.push({
        checkpoint,
        results: changeAssessment.results[index],
        checkpoints_remarks: changeAssessment.checkpoints_remarks[index],
        assChangeAffects,
        change_affects_json,
      });
    } else {
      assessmentData.checkpoints.push({
        checkpoint,
        results: changeAssessment.results[index],
        checkpoints_remarks: changeAssessment.checkpoints_remarks[index],
      });
    }
  });
  //console.log("assesmentdata",assessmentData)
  //console.log("changeassessment1",changeAssessment)
  // //console.log("change21",changeAssessment.change_affects_json[0][0].Environment[0].results)

  const handleAssessmentSaveClick = async () => {
    const filteredResults = questionAssess
      .map((question, index) => (question.question_no !== null ? changeAssessment.results[index] : null))
      .filter((result) => result !== null);
      const isAnyAnswerMissing =
      !filteredResults ||
      filteredResults.includes(undefined) || 
      filteredResults.includes("#B51849") ||
      (showChangeAffects && assChangeAffects.length === 0) ||
      changeAssessment.results.some(
        (result, index) =>
          result === "Yes" &&
          (!changeAssessment.checkpoints_remarks[index] || 
           changeAssessment.checkpoints_remarks[index].trim() === "")
      );
    
    if (isAnyAnswerMissing || value===""){
      setErrors(true);
      setStepError(true);
      setSaveMessage(false);
      return;
    } else {
      setErrors(false);
      setStepError(false);
      setSaveMessage(false);
    }
    try {
      const response = await axios.post(API.INSERT_ASSESSMENT, assessmentData, {
        headers: {
          Authorization: `Bearer ${access_token}`
        }
      });
      if (response.status === 200) {
        const assessmentID = response.data.identifiers[0].id;
        localStorage.setItem("assessmentID", assessmentID);
        await mocForm(assessmentID);
        localStorage.setItem("isAssessmentSave", true);
        setSave(true);
        setShowSuccessPopup(true);
      }
    } catch (error) {
      console.error("An error occurred in update trials:", error);
    }
  };

  const mocForm = async () => {
    const updateMocDet = localStorage.getItem("mocID");
    const requestformID = localStorage.getItem("requestformID");
    const assessmentID = localStorage.getItem("assessmentID");
    const storedUserData = JSON.parse(localStorage.getItem("userDatas"));
    const userSGID = updateMocDet ? storedUserData.SGID : "";
    const userName = storedUserData.FName + " " + storedUserData.LName;
    try {
      const mocData = {
        id: updateMocDet,
        user_id: userSGID,
        updated_at: new Date(),
        created_by: userName,
        doc_ref: "SGI-IMS-FOR-19",
        rev_date: new Date(),
        request_form_id: requestformID,
        assessment_id: assessmentID,
        submission_status: requestformID && assessmentID ? "pending" : "",
      };
      const mocResponse = await axios.put(
        `${API.UPDATE_MOC_FORM}?id=${updateMocDet}`,
        mocData,
        {
          headers: {
            Authorization: `Bearer ${access_token}`
          }
        }
      );
      if (mocResponse.status === 200) {
        await updateMocApprovals(updateMocDet);
        setSave(true);
        setShowSuccessPopup(true);
      }
    } catch (error) {
      console.error("An error occurred in insertIntoMocTable:", error);
    }
  };

  const updateMocApprovals = async () => {
    const updateMocDet = localStorage.getItem("mocID");
    const storedUserData = JSON.parse(localStorage.getItem("userDatas"));
    const approverSGID = storedUserData.N1SGID;
    const userSGID = updateMocDet ? storedUserData.SGID : "";
    const mocApprovalID = localStorage.getItem("mocApprovalID");
    const userAppName = updateMocDet ? storedUserData.FName + " " + storedUserData.LName : "";
    try {
      const mocData = {
        id: mocApprovalID,
        moc_id: updateMocDet,
        approver_sgid: approverSGID,
        user_sgid: userSGID,
        user_approval_name: userAppName,
        status: "pending",
      };
      const mocResponse = await axios.put(`${API.UPDATE_MOC_APPROVALS}?id=${mocApprovalID}`, mocData,
        {
          headers: {
            Authorization: `Bearer ${access_token}`
          }
        }
      );
      if (mocResponse.status === 200) {
        setSave(true);
        setShowSuccessPopup(true);
      }
    } catch (error) {
      console.error("An error occurred in insertIntoMocTable:", error);
    }
  };

  useEffect(() => {
    if (isEditMoc && questionAssess.length>0) {
      const getAssessment = async () => {
        const storedMocDataString = localStorage.getItem("mocDataByID");
        const storedMocData = JSON.parse(storedMocDataString);
        const assessmentID = storedMocData.mocForm.assessment_id;
        if(assessmentID===null){
          console.log("questionAssess.length",questionAssess,questionAssess.length)
          setChangeAssessment((prev) => ({
            ...prev,
            results:Array(questionAssess.length).fill("No") ,
          }));
        }
        else{
          try {
            const response = await axios.get(
              `${API.GET_ASSESSMENT}?id=${assessmentID}`,
              {
                headers: {
                  Authorization: `Bearer ${access_token}`
                }
              });
            const assessData = await response.data;
            let result = [];
            let checkpoint = [];
            let checkpoints_remarks = [];
            let change_affects = [];
            let change_affects_json = [];
            assessData.checkpoints.map((data) => {
              if (data.change_affects_json) {
                result.push(data.results);
                checkpoint.push(data.checkpoint);
                checkpoints_remarks.push(data.checkpoints_remarks);
                change_affects = data.assChangeAffects;
                change_affects_json.push(data.change_affects_json);
              } else {
                result.push(data.results);
                checkpoint.push(data.checkpoint);
                checkpoints_remarks.push(data.checkpoints_remarks);
              }
              return null;
            });
            setChangeAssessment((prev) => {
              return {
                ...prev,
                results: result,
                checkpoint: checkpoint,
                checkpoints_remarks: checkpoints_remarks,
                change_affects: change_affects,
                change_affects_json: change_affects_json,
              };
            });
            setValue(assessData.budget)
          } catch (error) {
            console.error("Error fetching MOC details:", error);
          }
        }
      
      };
      getAssessment();
    }
  }, [setChangeAssessment, isEditMoc,questionAssess]);

  const handleAssessmentUpdateClick = async () => {
    const storedMocDataString = localStorage.getItem("mocDataByID");
    const storedMocData = JSON.parse(storedMocDataString);
    const assessmentID = storedMocData.mocForm.assessment_id;
    const filteredResults = questionAssess
      .map((question, index) => (question.question_no !== null ? changeAssessment.results[index] : null))
      .filter((result) => result !== null);
    const isAnyAnswerMissing =
      !filteredResults || filteredResults.includes(undefined) ||
      filteredResults.includes("#B51849") ||
      (showChangeAffects && assChangeAffects.length === 0) ||
      changeAssessment.results.some(
        (result, index) =>
          result === "Yes" &&
          (!changeAssessment.checkpoints_remarks[index] || 
           changeAssessment.checkpoints_remarks[index].trim() === "")
      );
    if (isAnyAnswerMissing) {
      setErrors(true);
      setStepError(true);
      setSaveMessage(false);
      return true;
    }
    try {
      const response = await axios.put(
        `${API.UPDATE_ASSESSMENT}?id=${assessmentID}`,
        assessmentData,
        {
          headers: {
            Authorization: `Bearer ${access_token}`
          }
        }
      );
      if (response.status === 200) {
        await mocForm(assessmentID);
        setSave(true);
        setShowUpdatePopup(true);
      }
    } catch (error) {
      console.error("An error occurred in update trials:", error);
    }
  };

  const handleAssessmentNextClick = () => {
    const isAssessmentSave = JSON.parse(localStorage.getItem("isAssessmentSave"));

    const isAnyAnswerMissing =
      changeAssessment.results.includes("#B51849") ||
      showChangeAffects && assChangeAffects.length === 0 ||
      changeAssessment.results.some(
        (result, index) =>
          result === "Yes" &&
          (!changeAssessment.checkpoints_remarks[index] || 
           changeAssessment.checkpoints_remarks[index].trim() === "")
      );
    if (isAnyAnswerMissing) {
      setErrors(true);
      setStepError(true);
      setSaveMessage(false);
      return true;
    }
    setErrors(false);
    setSaveMessage(false);
    if (save || isAssessmentSave || isEditMoc) {
      navigate(showChangeAffects ? "/ehsf" : "/Conclusion", {
        state: { changeAssessment: assChangeAffects, assessmentData },
      });
    } else {
      setSaveMessage("Failed to save. Please try again.");
    }
  };
  //console.log(assChangeAffects, "assChangeAffects466")
  const newDialogRequestClose = () => {
    setAssessmentDialogOpen(false);
  }

  const mocDetData = JSON.parse(localStorage.getItem("mocDataByID"));
  const storedUserData = JSON.parse(localStorage.getItem("userDatas"));
  const userSGID = storedUserData.SGID;
  let disableCondition;
  let updateDisable;
  if (isEditMoc) {
    disableCondition = userSGID === mocDetData.mocForm.user_id ? false : true;
    updateDisable = (mocDetData.mocForm.submission_status === "pending" && mocDetData.mocForm.conclusion!==null)? true : false;
  }

  const handleGoBack = () => {
    window.history.back();
  };
  console.log("questionAssess",questionAssess)

  return (
    <DialogContent>
      <div>
        {/* <Header pageTitle="New Change Request Form" /> */}
        <Typography style={{ fontSize: 18, color: "#B51849", fontWeight: 600 }}>
          6M CHECK POINTS
        </Typography>
        <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
        <Tooltip title="Back">
          <IconButton
            edge="start"
            color="inherit"
            aria-label="back"
            onClick={onGoBackAssessment}
          >
            <img
              src={BackIcon}
              alt="back icon"
              style={{ width: "28px", height: "24px" }}
            />
          </IconButton>
        </Tooltip>
        {initialLoad ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "45px"
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <React.Fragment>
          {questionAssess.map((question, index) => (
  <div
    key={index}
    style={{
      display: "flex",
      alignItems: "flex-start",
      marginBottom: "5px",
      backgroundColor: "#F9F9F9",
    }}
  >
    <span
      style={{
        marginRight: "5px",
        flexShrink: 0,
        fontWeight: 550,
        backgroundColor: "#F9F9F9",
      }}
    >
      {question.question_no}
    </span>
    <div style={{ backgroundColor: "#F9F9F9", width: "100%" }}>
      <Typography style={{ ...CPQuestionsStyle, margin: 0 }}>
        {question.questions}
      </Typography>

      {question.question_no !== null && (
        <FormControl component="fieldset">
          <RadioGroup
            value={
              changeAssessment.results && changeAssessment.results[index]
                ? changeAssessment.results[index]
                : null
            }
            onChange={(event) =>
              handleRadioChange(index, event.target.value)
            }
            row
          >
            <FormControlLabel
              value="Yes"
              control={<Radio sx={{ color: "#B51849" }} />}
              label="Yes"
              disabled={disableCondition}
            />
            <FormControlLabel
              value="No"
              control={<Radio sx={{ color: "#B51849" }} />}
              label="No"
              disabled={disableCondition}
            />
            <FormControlLabel
              value="NA"
              control={<Radio sx={{ color: "#B51849" }} />}
              label="NA"
              disabled={disableCondition}
            />
          </RadioGroup>
        </FormControl>
      )}

      {/* Conditional Rendering for Remarks */}
      {question.question_no !== null &&
        changeAssessment.results &&
        changeAssessment.results[index] === "Yes" && (
          <div
            style={{
              width: "100%",
              marginTop: 10,
              borderRadius: 4,
              boxSizing: "border-box",
              padding: "10px",
            }}
          >
            <InputLabel
              style={{
                fontWeight: 500,
                fontStyle: "normal",
                fontFamily: "Inter, sans-serif",
              }}
            >
              Remarks *
            </InputLabel>
            <TextField
              style={{
                width: "100%",
                marginTop: 10,
                background: "#FFFFFF",
                borderRadius: 4,
              }}
              id="outlined-multiline-static"
              multiline
              rows={4}
              value={
                changeAssessment.checkpoints_remarks
                  ? changeAssessment.checkpoints_remarks[index]
                  : ""
              }
              onChange={(event) => handleRemarksChange(index, event)}
              error={
                stepError &&
                (!changeAssessment.checkpoints_remarks ||
                  !changeAssessment.checkpoints_remarks[index])
              }
              helperText={
                textErrors[index] && (
                  <span style={{ color: "#FF0000" }}>
                    Only 200 characters allowed
                  </span>
                )
              }
              disabled={disableCondition}
            />
          </div>
        )}
    </div>
  </div>
))}

          </React.Fragment>
        )}
        {showChangeAffects &&
          <div
            style={{
              marginTop: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "#F9F9F9",
            }}
          >
            <InputLabel
              style={{
                fontWeight: 500,
                fontStyle: "normal",
                fontFamily: "Inter, sans-serif",
              }}
            >
              Change Affects *
            </InputLabel>
            <TextField
              style={{
                width: "96%",
                marginTop: 10,
                background: "#FFFFF",
                borderRadius: 4,
              }}
              select
              value={assChangeAffects}
              onChange={handleAffectsChange}
              error={
                stepError && showChangeAffects && changeAssessment.change_affects.length === 0
                  ? true
                  : false
              }
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                multiple: true,
                renderValue: (selected) => {
                  return selected.join(", ");
                },
                renderOption: (props, option) => (
                  <MenuItem {...props}>
                    {/* <Checkbox checked={assChangeAffects.indexOf(option) > -1} /> */}
                    {isEditMoc ? (
                      <Checkbox
                        checked={
                          assChangeAffects ? assChangeAffects.includes(option) : ""
                        }
                      />
                    ) : (
                      <Checkbox checked={assChangeAffects.indexOf(option) > -1} />
                    )}
                    {option}
                  </MenuItem>
                ),
              }}
              disabled={disableCondition}
            >
              {["Environment", "Health", "Safety", "Fire"].map((option) => (
                <MenuItem key={option} value={option}>
                  {/* <Checkbox checked={assChangeAffects.indexOf(option) > -1} /> */}
                  {isEditMoc ? (
                    <Checkbox
                      checked={
                        assChangeAffects ? assChangeAffects.includes(option) : ""
                      }
                    />
                  ) : (
                    <Checkbox checked={assChangeAffects.indexOf(option) > -1} />
                  )}
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </div>
        }
          <Typography style={{ ...CPQuestionsStyle, margin: 0 }}>
        Budget Required (INR)
      </Typography>
      <TextField
        style={{ width: "100%", background: "#FFFFFF" }}
        id="integer-input"
        variant="outlined"
        value={value} // Display an empty field for 0
        onChange={handleChange}
        disabled={disableCondition}
        InputLabelProps={{ style: { fontWeight: 500, fontStyle: "normal" } }}
      />
      {error && (
        <div style={{ color: "red", marginTop: "10px", textAlign: "center" }}>
        Please enter numbers only
      </div>
      )}
        {errors && (
          <div style={{ color: "red", marginTop: "10px", textAlign: "center" }}>
            Please answer all the questions
          </div>
        )}
        {saveMessage ? (
          <Typography variant="subtitle2" color="red">
            {""}
            Please Save before going to Next
          </Typography>
        ) : null}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
            // backgroundColor: "#F9F9F9",
          }}
        >
          {initialLoad ? (
            <div className="popup-overlay">
              <div className="popup">
                <CircularProgress />
              </div>
            </div>
          ) : showSuccessPopup ? (
            <div className="popup-overlay">
              <div className="popup">
                <p>Data saved successfully!</p>
              </div>
            </div>
          ) : null}
          {initialLoad ? (
            <div className="popup-overlay">
              <div className="popup">
                <CircularProgress />
              </div>
            </div>
          ) : showUpdatePopup ? (
            <div className="popup-overlay">
              <div className="popup">
                <p>Data updated successfully!</p>
              </div>
            </div>
          ) : null}
          {isEditMoc && !(disableCondition) && !initialLoad &&(JSON.parse(localStorage.getItem("mocDataByID"))).mocForm.assessment_id!==null? (
            <Button
              variant="contained"
              style={{
                backgroundColor: "#B1174B",
                fontSize: 20,
                color: "white",
                textTransform: "none",
                width: "75%",
                marginTop: 20,
                marginRight: 5,
                marginBottom: 10
              }}
              disabled={updateDisable}
              onClick={handleAssessmentUpdateClick}
            >
              Update
            </Button>
          ) :
            ((!isEditMoc || (JSON.parse(localStorage.getItem("mocDataByID"))).mocForm.assessment_id===null) && !initialLoad &&
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#fff",
                  fontSize: 20,
                  color: "#B1174B",
                  border: "1px solid #B1174B",
                  textTransform: "none",
                  width: "75%",
                  marginTop: 20,
                  marginRight: 5,
                  marginBottom: 10,
                  boxShadow: "none"
                }}
                onClick={handleAssessmentSaveClick}
              >
                Save
              </Button>
            )}
          {!initialLoad && (
            <Button
              variant="contained"
              style={{
                backgroundColor: "#B1174B",
                fontSize: 20,
                color: "white",
                textTransform: "none",
                width: "75%",
                marginTop: 20,
                marginRight: 5,
                marginBottom: 10
              }}
              onClick={handleAssessmentNextClick}
            >
              Next
            </Button>
          )}
        </div>
      </div>
    </DialogContent>
  );
};

export default ChangeAssessment;