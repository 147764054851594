import React from "react";
import { Typography, Grid, Card } from "@mui/material";
import * as XLSX from "xlsx-js-style";


const Cards = ({
    allMocCount,
    pendingMocCount,
    resubmittedMocCount,
    completedMocCount,
    rejectedMocCount,
    allMocData,
}) => {
    const mocData = [
        { id: 1, name: "All MOC" },
        { id: 2, name: "Pending MOC" },
        { id: 3, name: "Resubmitted MOC" },
        { id: 4, name: "Completed MOC" },
        { id: 5, name: "Rejected MOC" },
    ];

    const valueMap = {
        "All MOC": allMocCount,
        "Pending MOC": pendingMocCount,
        "Resubmitted MOC": resubmittedMocCount,
        "Completed MOC": completedMocCount,
        "Rejected MOC": rejectedMocCount,
    };

    // Function to generate and download the CSV
    const handleDownloadCSV = (name) => {
        // Header for the file
        const csvHeaders = ["MOC", "Date", "Requisition By","Change Request Description","Reason for change Request","status","PROGRESS  OF PENDING AND APPROVED CHANGES / REASON FOR REJECTION",];

        // Adding rows from allMocData
        const mocDataRows =
            allMocData?.length > 0
                ? allMocData.map((item) => [item.mocForm.moc_no, item.mocForm.created_at, item.mocForm.requestForm.requisition_by,item.mocForm.requestForm.brief_description,item.mocForm.requestForm.reason_change_required,item.mocForm.requestForm.current_status,"entry test" || "N/A"])
                : [];

        // Combine everything into a single CSV data array
        const csvData = [csvHeaders, ...mocDataRows];

        const csvContent =
            "data:text/csv;charset=utf-8," +
            csvData.map((row) => row.join(",")).join("\n");

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", `${name.replace(/\s+/g, "_")}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    const handleDownloadExcel = (name) => {
        // Define the title and headers
        const title = ["Management of Change"];
        const csvHeaders = [
            "MOC",
            "Date",
            "Requisition By",
            "Change Request Description",
            "Reason for Change Request",
            "Status",
            "Progress of Pending and Approved Changes / Reason for Rejection",
        ];
    
        // Format the `created_at` field to remove the timestamp
        const formatDate = (dateString) => {
            const date = new Date(dateString);
            return date.toISOString().split("T")[0]; // Extracts only the date in YYYY-MM-DD format
        };
    
        // Add rows from `allMocData`
        const mocDataRows =
            allMocData?.length > 0
                ? allMocData.map((item) => [
                      item.moc_no,
                      formatDate(item.created_at), // Format date here
                      item.requisition_by,
                      item.brief_description,
                      item.reason_change_required,
                      item.current_status,
                      item.final_status || "N/A",
                  ])
                : [];
    
        // Combine everything into a single data array
        const excelData = [[title[0]], [], csvHeaders, ...mocDataRows];
    
        // Create a worksheet from the data
        const worksheet = XLSX.utils.aoa_to_sheet(excelData);
    
        // Merge cells for the title
        worksheet["!merges"] = [
            {
                s: { r: 0, c: 0 }, // Start cell: Row 1, Column A (0-based index)
                e: { r: 0, c: csvHeaders.length - 1 }, // End cell: Row 1, Last Column
            },
        ];
    
        // Add borders to all cells
        const addBorders = (cell) => {
            if (cell) {
                cell.s = cell.s || {};
                cell.s.border = {
                    top: { style: "thin", color: { rgb: "000000" } },
                    bottom: { style: "thin", color: { rgb: "000000" } },
                    left: { style: "thin", color: { rgb: "000000" } },
                    right: { style: "thin", color: { rgb: "000000" } },
                };
            }
        };
    
        // Iterate over all cells and apply borders
        Object.keys(worksheet).forEach((key) => {
            if (key[0] === "!") return; // Skip metadata keys
            addBorders(worksheet[key]);
        });
    
        // Style the title
        const titleCell = worksheet["A1"]; // First cell of the title
        if (titleCell) {
            titleCell.s = {
                font: { bold: true, size: 16, color: { rgb: "000000" } }, // Enlarged black text
                alignment: { horizontal: "center", vertical: "center" }, // Center alignment
            };
        }
    
        // Style headers with blue background and bold text
        csvHeaders.forEach((_, colIndex) => {
            const cellAddress = XLSX.utils.encode_cell({ r: 2, c: colIndex }); // Header is at row 3 (index 2 in 0-based indexing)
            if (!worksheet[cellAddress]) return;
            worksheet[cellAddress].s = {
                font: { bold: true, color: { rgb: "FFFFFF" } }, // White bold text
                fill: { fgColor: { rgb: "00448B" } }, // Blue background
                alignment: { horizontal: "center", vertical: "center" }, // Center alignment
                border: {
                    top: { style: "thin", color: { rgb: "000000" } },
                    bottom: { style: "thin", color: { rgb: "000000" } },
                    left: { style: "thin", color: { rgb: "000000" } },
                    right: { style: "thin", color: { rgb: "000000" } },
                },
            };
        });
    
        // Adjust column widths dynamically
        const calculateMaxWidth = (data, columnIndex) => {
            return Math.max(
                ...data.map((row) => (row[columnIndex] ? row[columnIndex].toString().length : 0)),
                csvHeaders[columnIndex]?.length || 0
            );
        };
    
        const columnWidths = csvHeaders.map((_, colIndex) => ({
            wch: calculateMaxWidth([csvHeaders, ...mocDataRows], colIndex),
        }));
    
        worksheet["!cols"] = columnWidths;
    
        // Create a workbook and add the worksheet
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "MOC Data");
    
        // Write the workbook and trigger download
        XLSX.writeFile(workbook, `${name.replace(/\s+/g, "_")}.xlsx`);
    };
    
    
    
    
    
    

    return (
        <Grid
            container
            spacing={2}
            style={{ display: "flex", marginBottom: "10px" }}
        >
            {mocData.map((e) => (
                <Grid item key={e.id} md sm={6} xs={12}>
                    <Card
                        style={{
                            padding: "16px",
                            background: "#B1174B",
                            cursor: "pointer",
                        }}
                        onClick={() => handleDownloadExcel(e.name)}
                    >
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                        >
                            <Typography
                                style={{
                                    fontSize: 14,
                                    fontWeight: 400,
                                    color: "#fff",
                                }}
                            >
                                {e.name}
                            </Typography>
                        </div>
                        <div>
                            <div style={{ float: "left", paddingRight: 7 }}>
                                <Typography
                                    style={{
                                        fontSize: 28,
                                        fontWeight: 400,
                                        color: "#fff",
                                    }}
                                >
                                    {valueMap[e.name] || e.value}
                                </Typography>
                            </div>
                        </div>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
};

export default Cards;