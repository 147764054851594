import React, { useState, useEffect, useRef } from "react";
import { Typography, InputLabel, Divider, TextField, MenuItem, Button, CircularProgress, Grid, Box, DialogTitle, DialogContent, IconButton } from "@mui/material";
import Header from "../app/TopAppBar";
import { useRecoilState } from "recoil";
import { changeReqData, editCMSData } from "../recoil/atoms";
import "../index.css";
import axios from "axios";
import API from "../Services/API";
import CloudUpload from "../Asset/Icons/file_upload_icon.svg";
import ImageSlider from "../Inputs/ImageSlider";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import configParam from "../config";
import BackIcon from "../Asset/Icons/back_icon.svg";
import CloseIcon from "@mui/icons-material/Close";

const ChangeRequired = ({ dataRequired, onBack, onClose }) => {
    const [changeRequireData, setChangeRequireData] = useRecoilState(changeReqData);
    const [newDialogRequiredOpen, setNewDialogRequiredOpen] = useState('true');
    const [isEditCms] = useRecoilState(editCMSData);
    const [errors, setErrors] = useState(false);
    const [stepError, setStepError] = useState(false);
    const [imageBeforeError, setImageBeforeError] = useState(false);
    const [imageAfterError, setImageAfterError] = useState(false);
    const [save, setSave] = useState(false);
    const [saveMessage, setSaveMessage] = useState(false);
    const [initialLoad, setInitialLoad] = useState(false);
    const [selectedBeforeFiles, setSelectedBeforeFiles] = useState(null);
    const changeRequirefileBefore = useRef(null);
    const changeRequirefileAfter = useRef(null);
    const [changeRequireBeforeImage, setChangeRequireBeforeImage] = useState([]);
    const [changeRequireAfterImage, setChangeRequireAfterImage] = useState([]);
    const [changeRequireUploadFileBefore, setChangeRequireUploadFileBefore] = useState([]);
    const [changeRequireUploadFileAfter, setChangeRequireUploadFileAfter] = useState([]);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [showUpdatePopup, setShowUpdatePopup] = useState(false);
    const [uploadDocumentChangeRequire, setUploadDocumentChangeRequire] = useState("");
    const [uploadDocumentChangeRequest, setUploadDocumentChangeRequest] = useState("");
    const changeRequiredFileInputRef = useRef(null);
    const changeRequestFileInputRef = useRef(null);
    const navigate = useNavigate();

    const access_token = JSON.parse(localStorage.getItem("accesstoken"));

    useEffect(() => {
        const handlePopup = (setter, delay) => {
            setter(true);
            const timer = setTimeout(() => {
                setter(false);
            }, delay);
            return () => clearTimeout(timer);
        };
        const handlePopups = (showPopup, setShowPopup) => {
            if (showPopup) {
                setInitialLoad(true);
                setTimeout(() => {
                    setInitialLoad(false);
                    handlePopup(setShowPopup, 1000);
                }, 2000);
            }
        };
        handlePopups(showSuccessPopup, setShowSuccessPopup);
        handlePopups(showUpdatePopup, setShowUpdatePopup);
    }, [showSuccessPopup, showUpdatePopup]);

    const onHandleChange = (event, field) => {
        let updatedForm = { ...changeRequireData };
        const value = event.target.value;
        updatedForm[field] = value;
        setChangeRequireData(updatedForm);
        setErrors(false);
    };

    const handleFileChange = (event) => {
        setSelectedBeforeFiles(event.target.files);
    };

    const captureChangeRequireBeforeGallery = () => {
        // Open the gallery for image selection
        if (changeRequirefileBefore.current) {
            changeRequirefileBefore.current.captureAttribute = 'gallery'; // Specify 'gallery' to open the gallery
            changeRequirefileBefore.current.click(); // Trigger the input element to open the gallery
            changeRequirefileBefore.current.onchange = (e) => {
                const selectedFiles = e.target.files;
                if (changeRequireBeforeImage.length + selectedFiles.length > 5) {
                    setImageBeforeError(true);
                    return; // Prevent further file selection
                }
                if (selectedFiles.length > 0) {
                    // Process the selected images using the setUploadChangeRequireBeforeFile function
                    setUploadChangeRequireBeforeFile({ target: { files: selectedFiles } });
                }
            };
        }
    };

    const changeRequireDisplayImageBefore = (files) => {
        const imageBeforeArray = [];
        Array.from(files).forEach(file => {
            const reader = new FileReader();
            reader.onload = (e) => {
                // Set the selected image URLs
                imageBeforeArray.push(e.target.result);
                if (imageBeforeArray.length === files.length) {
                    setChangeRequireBeforeImage((prevBeforeImage) => [...prevBeforeImage, ...imageBeforeArray])
                }
            };
            reader.readAsDataURL(file);
        });
    };

    const setUploadChangeRequireBeforeFile = async (event) => {
        const files = event.target.files;
        changeRequireDisplayImageBefore(files);
        if (files.length > 0) {
            const form = new FormData();
            Array.from(files).forEach(file => {
                form.append('file', file, file.name);
            });

            axios
                .post(API.CHANGE_REQUIRED_UPLOAD_IMAGES, form, {
                    headers: {
                        Authorization: `Bearer ${access_token}`
                    }
                }) // Replace API.UPLOAD_IMAGES with your backend API endpoint to save the area data
                .then((response) => {
                    const result = response.data.filePaths;
                    setChangeRequireUploadFileBefore((UploadFileBefore) => [...UploadFileBefore, ...result]);
                })
                .catch((error) => {
                    // Handle errors
                    console.error('Failed to upload images:', error);
                });
        }
    };

    const handleDeleteChangeRequireBefore = (imageUrl, index) => {
        //console.log("dexin", index)
        Swal.fire({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this image!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "red",
            cancelButtonColor: "gray",
            confirmButtonText: "Yes, delete it",
        }).then((result) => {
            if (result.isConfirmed) {
                setChangeRequireBeforeImage((prevImages) => {
                    const newImageList = [...prevImages];
                    newImageList.splice(index, 1);
                    return newImageList;
                });
                setChangeRequireUploadFileBefore((prevImages) => {
                    const newImageList = [...prevImages];
                    newImageList.splice(index, 1);
                    return newImageList;
                });

                Swal.fire("Deleted!", "Your image has been deleted.", "success");
            }
        });
    };

    const changeRequireDisplayImageAfter = (files) => {
        const imageAfterArray = [];
        Array.from(files).forEach(file => {
            const reader = new FileReader();
            reader.onload = (e) => {
                // Set the selected image URLs
                imageAfterArray.push(e.target.result);
                if (imageAfterArray.length === files.length) {
                    setChangeRequireAfterImage((prevBeforeImage) => [...prevBeforeImage, ...imageAfterArray])
                }
            };
            reader.readAsDataURL(file);
        });
    };

    const setUploadChangeRequireAfterFile = async (event) => {
        const files = event.target.files;
        changeRequireDisplayImageAfter(files);
        if (files.length > 0) {
            const form = new FormData();
            Array.from(files).forEach(file => {
                form.append('file', file, file.name);
            });

            axios
                .post(API.CHANGE_REQUIRED_UPLOAD_IMAGES, form, {
                    headers: {
                        Authorization: `Bearer ${access_token}`
                    }
                }) // Replace API.UPLOAD_IMAGES with your backend API endpoint to save the area data
                .then((response) => {
                    const result = response.data.filePaths;
                    setChangeRequireUploadFileAfter((UploadFileAfter) => [...UploadFileAfter, ...result]);
                })
                .catch((error) => {
                    // Handle errors
                    console.error('Failed to upload images:', error);
                });
        }
    };

    const captureChangeRequireAfterGallery = () => {
        if (changeRequirefileAfter.current) {
            changeRequirefileAfter.current.captureAttribute = 'gallery'; // Specify 'gallery' to open the gallery
            changeRequirefileAfter.current.click(); // Trigger the input element to open the gallery
            changeRequirefileAfter.current.onchange = (e) => {
                const selectedFiles = e.target.files;
                if (changeRequireAfterImage.length + selectedFiles.length > 5) {
                    setImageAfterError(true);
                    return; // Prevent further file selection
                }
                if (selectedFiles.length > 0) {
                    // Process the selected images using the setUploadChangeRequireBeforeFile function
                    setUploadChangeRequireAfterFile({ target: { files: selectedFiles } });
                }
            };
        }
    };

    const handleDeleteChangeRequireAfter = (imageUrl, index) => {
        Swal.fire({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this image!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "red",
            cancelButtonColor: "gray",
            confirmButtonText: "Yes, delete it",
        }).then((result) => {
            if (result.isConfirmed) {
                setChangeRequireAfterImage((prevImages) => {
                    const newImageList = [...prevImages];
                    newImageList.splice(index, 1);
                    return newImageList;
                });
                setChangeRequireUploadFileAfter((prevImages) => {
                    const newImageList = [...prevImages];
                    newImageList.splice(index, 1);
                    return newImageList;
                });

                Swal.fire("Deleted!", "Your image has been deleted.", "success");
            }
        });
    };

    const handleChangeSave = async () => {
        try {
            if (
                !changeRequireData.change_required_remarks ||
                !changeRequireData.change_request_remarks
            ) {
                setErrors(true);
                setStepError(true);
                setSaveMessage(false);
                return;
            } else {
                setErrors(false);
                setStepError(false);
                setSaveMessage(false);
            }
            const requireData = {
                change_required_remarks: changeRequireData.change_required_remarks,
                change_request_remarks: changeRequireData.change_request_remarks,
                change_required_before_image: changeRequireUploadFileBefore,
                change_required_after_image: changeRequireUploadFileAfter,
                change_required_document: uploadDocumentChangeRequire,
                change_request_document: uploadDocumentChangeRequest
            };
            const response = await axios.post(API.INSERT_CHANGE_REQUIRED, requireData, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
            //console.log(requireData, "requireData418");
            //console.log(response, "response419");
            if (response.status === 200) {
                //console.log("insert response", response);
                const changeRequiredData = response.config.data;
                localStorage.setItem("changeRequiredData", changeRequiredData);
                const changeRequireID = response.data.identifiers[0].id;
                localStorage.setItem("changeRequireID", changeRequireID);
                localStorage.setItem("isChangeRequireSave", true);
                await cmsForm(changeRequireID);
                //console.log("changeRequireID", changeRequireID);
                setSave(true);
                setShowSuccessPopup(true);
            }
        } catch (error) {
            console.error("An error occurred in update trials:", error);
        }
    };

    const cmsForm = async () => {
        const updateCmsDet = localStorage.getItem("cmsID");
        //console.log(updateCmsDet, "updateMocDet228");
        const requestID = localStorage.getItem("requestID");
        const changeRequireID = localStorage.getItem("changeRequireID");
        //console.log(changeRequireID, "changeRequireID");
        const storedUserData = JSON.parse(localStorage.getItem("userDatas"));
        const userSGID = updateCmsDet ? storedUserData.SGID : "";
        const userName = storedUserData.FName + " " + storedUserData.LName;
        const userMail = storedUserData.MailId;
        try {
            //console.log("check98");
            const cmsData = {
                id: updateCmsDet,
                user_id: userSGID,
                updated_at: new Date(),
                created_by: userName,
                doc_ref: "F/CFT/SGSI/01",
                // rev_no: "03",
                // rev_date: new Date(),
                change_request_id: requestID,
                change_required_id: changeRequireID,
                submission_status: requestID && changeRequireID ? "pending" : "",
                user_mail: userMail
            };
            const mocResponse = await axios.put(
                `${API.UPDATE_CMS_FORM}?id=${updateCmsDet}`,
                cmsData,
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`
                    }
                }
            );
            if (mocResponse.status === 200) {
                //console.log("update conclusion", mocResponse);
                await updateCmsApprovals(updateCmsDet);
                setSave(true);
                setShowSuccessPopup(true);
            }
        } catch (error) {
            console.error("An error occurred in insertIntoMocTable:", error);
        }
    };

    const updateCmsApprovals = async () => {
        const updateCmsDet = localStorage.getItem("cmsID");
        const storedUserData = JSON.parse(localStorage.getItem("userDatas"));
        //console.log(storedUserData, "storedUserData79999");
        //console.log(updateCmsDet, "updateCmsDet");
        const userSGID = updateCmsDet ? storedUserData.SGID : "";
        const userAppName = updateCmsDet ? storedUserData.FName + " " + storedUserData.LName : "";
        const cmsApprovalID = localStorage.getItem("cmsApprovalID");
        //console.log(cmsApprovalID, "cmsApprovalID310")
        try {
            const cmsData = {
                id: cmsApprovalID,
                cms_id: updateCmsDet,
                user_sgid: userSGID,
                user_approval_name: userAppName,
                status: "pending",
            };
            const cmsResponse = await axios.put(`${API.UPDATE_CMS_APPROVALS}?id=${cmsApprovalID}`, cmsData,
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`
                    }
                });
            //console.log(cmsData, "cmsData")
            if (cmsResponse.status === 200) {
                //console.log("Inserted into moc table:", cmsResponse);
                setSave(true);
                setShowSuccessPopup(true);
            }
        } catch (error) {
            console.error("An error occurred in insertIntoMocTable:", error);
        }
    };

    const handleChangeUpdate = async () => {
        const storedCmsDataString = localStorage.getItem("cmsDataByID");
        const storedCmsData = JSON.parse(storedCmsDataString);
        const changeRequiredID = storedCmsData?.cmsForm?.change_required_id;
        try {
            if (
                !changeRequireData.change_required_remarks ||
                !changeRequireData.change_request_remarks
            ) {
                setErrors(true);
                setStepError(true);
                setSaveMessage(false);
                return;
            } else {
                setErrors(false);
                setStepError(false);
                setSaveMessage(false);
            }
            const updateData = {
                change_required_remarks: changeRequireData.change_required_remarks,
                change_request_remarks: changeRequireData.change_request_remarks,
                change_required_before_image: changeRequireUploadFileBefore,
                change_required_after_image: changeRequireUploadFileAfter,
                change_required_document: uploadDocumentChangeRequire,
                change_request_document: uploadDocumentChangeRequest
            };
            const response = await axios.put(
                `${API.UPDATE_CHANGE_REQUIRED}?id=${changeRequiredID}`,
                updateData,
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`
                    }
                }
            );
            if (response.status === 200) {
                //console.log("update response", response);
                await cmsForm(changeRequiredID);
                setSave(true);
                setShowUpdatePopup(true);
            }
        } catch (error) {
            console.error("An error occurred in update trials:", error);
        }
    };

    const handleChangeNext = () => {
        try {
            const isChangeRequireSave = JSON.parse(localStorage.getItem("isChangeRequireSave"));
            if (
                !changeRequireData.change_required_remarks ||
                !changeRequireData.change_request_remarks
            ) {
                setErrors(true);
                setStepError(true);
                setSaveMessage(false);
                return;
            } else {
                setErrors(false);
                setStepError(false);
                setSaveMessage(false);
            }
            if (save || isChangeRequireSave || isEditCms) {
                dataRequired(newDialogRequiredOpen);
                // navigate("/impactchange");
            } else {
                setSaveMessage("Failed to save. Please try again.");
            }
        } catch (error) {
            console.error("An error occurred in update trials:", error);
        }
    };

    const handleFileUploadChangeRequire = () => {
        if (changeRequiredFileInputRef.current) {
            changeRequiredFileInputRef.current.click();
            changeRequiredFileInputRef.current.onchange = (e) => {
                const selectedFiles = e.target.files;
                setUploadedFileChangeRequire({ target: { files: selectedFiles } });

                // Clear the input value to allow re-selection of the same file(s)
                changeRequiredFileInputRef.current.value = '';
            };
        }
    };

    const displayImageChangeRequire = (files) => {
        const imageBeforeArray = [];
        Array.from(files).forEach(file => {
            const reader = new FileReader();
            reader.onload = (e) => {
                // Set the selected image URLs
                imageBeforeArray.push(e.target.result);
                if (imageBeforeArray.length === files.length) {
                    // setBeforeImage((prevBeforeImage) => [...prevBeforeImage, ...imageBeforeArray])
                }
            };
            reader.readAsDataURL(file);
        });
    };

    const setUploadedFileChangeRequire = async (event) => {
        const files = event.target.files;
        if (files.length > 0) {
            const form = new FormData();
            Array.from(files).forEach(file => {
                form.append('file', file, file.name);
            });
            try {
                const response = await axios.post(API.UPLOAD_DOCUMENTS_CHANGE, form, {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                });
                // Get the file paths from the response
                const newFilePaths = response.data.filePaths;
                // Update state to include new uploaded files
                setUploadDocumentChangeRequire(prevFiles => [...prevFiles, ...newFilePaths]);
            } catch (error) {
                console.error('Failed to upload documents:', error);
            }
        }
    };

    // const handleDeleteChangeRequire = () => {
    //     // setBeforeImage((prevFiles) => prevFiles.filter((_, i) => i !== index));
    //     setUploadDocumentChangeRequire("");
    // };

    const handleDeleteChangeRequire = (filePath) => {
        // Remove the file from the list and also delete from server if needed
        setUploadDocumentChangeRequire(prevFiles => prevFiles.filter(file => file !== filePath));
    };

    const handleDownloadDocChangeRequire = async (filePath) => {
        try {
            // Extract the file name from the file path
            const fileName = filePath.split(/[\\/]/).pop(); // This will get the file name from the path

            const response = await axios.get(API.DOWNLOAD_DOCUMENTS_CHANGE + `/${fileName}`, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                },
                responseType: 'blob' // Ensure the response type is set to 'blob'
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            //console.log("url1", url);

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName); // Set the correct filename
            document.body.appendChild(link);
            link.click();
            link.remove();

            // Revoke the object URL after the file is downloaded to free up resources
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Error downloading the file", error);
        }
    };

    const handleFileUploadChangeRequest = () => {
        if (changeRequestFileInputRef.current) {
            changeRequestFileInputRef.current.click();
            changeRequestFileInputRef.current.onchange = (e) => {
                const selectedFiles = e.target.files;
                setUploadedFileChangeRequest({ target: { files: selectedFiles } });
                changeRequestFileInputRef.current.value = '';
            };
        };
    };

    const displayImageChangeRequest = (files) => {
        const imageBeforeArray = [];
        Array.from(files).forEach(file => {
            const reader = new FileReader();
            reader.onload = (e) => {
                // Set the selected image URLs
                imageBeforeArray.push(e.target.result);
                if (imageBeforeArray.length === files.length) {
                    // setBeforeImage((prevBeforeImage) => [...prevBeforeImage, ...imageBeforeArray])
                }
            };
            reader.readAsDataURL(file);
        });
    };

    const setUploadedFileChangeRequest = async (event) => {
        const files = event.target.files;
        if (files.length > 0) {
            const form = new FormData();
            Array.from(files).forEach(file => {
                form.append('file', file, file.name);
            });
            try {
                const response = await axios.post(API.UPLOAD_DOCUMENTS_CHANGE, form, {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                });
                // Get the file paths from the response
                const newFilePaths = response.data.filePaths;
                // Update state to include new uploaded files
                setUploadDocumentChangeRequest(prevFiles => [...prevFiles, ...newFilePaths]);
            } catch (error) {
                console.error('Failed to upload documents:', error);
            }
        }
    };

    const handleDeleteChangeRequest = (filePath) => {
        // Remove the file from the list and also delete from server if needed
        setUploadDocumentChangeRequest(prevFiles => prevFiles.filter(file => file !== filePath));
    };

    const handleDownloadDocChangeRequest = async (filePath) => {
        try {
            // Extract the file name from the file path
            const fileName = filePath.split(/[\\/]/).pop(); // This will get the file name from the path

            const response = await axios.get(API.DOWNLOAD_DOCUMENTS_CHANGE + `/${fileName}`, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                },
                responseType: 'blob' // Ensure the response type is set to 'blob'
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            //console.log("url1", url);

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName); // Set the correct filename
            document.body.appendChild(link);
            link.click();
            link.remove();

            // Revoke the object URL after the file is downloaded to free up resources
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Error downloading the file", error);
        }
    };

    useEffect(() => {
        if (isEditCms) {
            const getChangeRequired = async () => {
                const storedCmsDataString = localStorage.getItem("cmsDataByID");
                const storedCmsData = JSON.parse(storedCmsDataString);
                //console.log(storedCmsData, "storedMocData319")
                const requiredID = storedCmsData?.cmsForm?.change_required_id;
                //console.log(requiredID, "requiredID")
                try {
                    const response = await axios.get(
                        `${API.GET_CMS_CHANGE_REQUIRED}?id=${requiredID}`,
                        {
                            headers: {
                                Authorization: `Bearer ${access_token}`
                            }
                        }
                    );
                    const requiredFormDetails = await response.data;
                    setChangeRequireData(requiredFormDetails);

                    const changeRequireBefore = requiredFormDetails.change_required_before_image.map(path => path.split(/[\\/]/).pop());
                    const changeRequireAfter = requiredFormDetails.change_required_after_image.map(path => path.split(/[\\/]/).pop());
                    if (changeRequireBefore) {
                        try {
                            const changeRequireReponseBefore = await axios.post(API.SHOW_IMAGES_CLICK, {
                                beforeimagename: changeRequireBefore
                            },
                                {
                                    headers: {
                                        Authorization: `Bearer ${access_token}`
                                    }
                                }
                            );
                            setChangeRequireBeforeImage(changeRequireReponseBefore.data)
                            setChangeRequireUploadFileBefore(requiredFormDetails.change_required_before_image)
                        } catch (error) {
                            console.error("Error fetching changeRequireBefore images:", error);
                        }
                    }

                    if (changeRequireAfter) {
                        try {
                            const changeRequireReponseAfter = await axios.post(API.SHOW_IMAGES_CLICK, {
                                beforeimagename: changeRequireAfter
                            },
                                {
                                    headers: {
                                        Authorization: `Bearer ${access_token}`
                                    }
                                }
                            );
                            setChangeRequireAfterImage(changeRequireReponseAfter.data)
                            setChangeRequireUploadFileAfter(requiredFormDetails.change_required_after_image)
                        } catch (error) {
                            console.error("Error fetching changeRequireAfter images:", error);
                        }
                    }

                    setUploadDocumentChangeRequire(requiredFormDetails.change_required_document)
                    setUploadDocumentChangeRequest(requiredFormDetails.change_request_document)
                } catch (error) {
                    console.error("Error fetching MOC details:", error);
                }
            };
            getChangeRequired();
        }
    }, [setChangeRequireData, isEditCms]);

    const storedUserData = JSON.parse(localStorage.getItem("userDatas"));
    const userSGID = storedUserData.SGID;
    const cmsDetData = JSON.parse(localStorage.getItem("cmsDataByID"));
    let disableCondition;
    if (isEditCms) {
        disableCondition = userSGID !== cmsDetData?.cmsForm?.user_id;
    }

    return (
        <>
            <DialogTitle>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6">Details of the Change</Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={onClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
            </DialogTitle>
            <img
                src={BackIcon}
                alt="menu icon"
                style={{ width: "28px", height: "24px", cursor: "pointer", marginLeft: "13px" }}
                onClick={onBack}
            />
            <DialogContent>
                {/* <Header pageTitle="Details of the Change" isNewUser={false} /> */}
                <div style={{ marginTop: "10px" }}>
                    <InputLabel
                        style={{
                            fontWeight: 600,
                            fontStyle: "normal",
                            fontFamily: "Roboto, sans-serif",
                            color: "#000",
                            fontSize: 14,
                        }}
                    >
                        Details Of Change Required *
                    </InputLabel>
                    <TextField
                        style={{ width: "100%", marginTop: 10, background: "#FFFFFF" }}
                        value={changeRequireData.change_required_remarks}
                        onChange={(e) => onHandleChange(e, "change_required_remarks")}
                        id="outlined-multiline-static"
                        multiline
                        rows={4}
                        error={stepError && !changeRequireData.change_required_remarks ? true : false}
                        disabled={disableCondition}
                    />
                </div>
                <div
                    style={{
                        marginTop: "10px",
                        width: "90%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        paddingLeft: 17,
                    }}
                >
                    <input
                        id="upload-image"
                        multiple
                        type="file"
                        name="images"
                        style={{ display: "none" }}
                        onChange={handleFileChange}
                        ref={changeRequirefileBefore}
                        disabled={disableCondition}
                    />
                    <InputLabel
                        style={{
                            fontWeight: 600,
                            fontStyle: "normal",
                            fontFamily: "Inter, sans-serif",
                            color: "#000",
                            fontSize: 16,
                        }}
                    >
                        Before
                    </InputLabel>
                    {!disableCondition && imageBeforeError && (
                        <Typography variant="subtitle2" color="error">
                            Allows maximum 5 images to upload in before
                        </Typography>
                    )}
                    <div
                        style={{
                            border: "2px dashed #B1174B",
                            flex: 1,
                            display: "flex",
                            width: "100%",
                            height: 300,
                            marginTop: 5,
                            marginRight: 20,
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "6px",
                        }}
                    >
                        <input
                            type="file"
                            accept="image/*"
                            // onChange={handleImageSelect}
                            style={{ display: "none" }}
                            id="image-upload"
                            multiple
                        />
                        {!changeRequireBeforeImage.length > 0 ? (
                            <>
                                <label htmlFor="image-upload">
                                    <button
                                        style={{
                                            width: 30,
                                            height: 30,
                                            marginLeft: 10,
                                            marginBottom: 10,
                                            cursor: "pointer",
                                            padding: 0,
                                            border: "none",
                                            background: "none",
                                        }}
                                        aria-label="Upload File"
                                        disabled={disableCondition}
                                    >
                                        <img
                                            src={CloudUpload}
                                            alt="Upload File"
                                            style={{ width: "100%", height: "100%" }}
                                            onClick={captureChangeRequireBeforeGallery}
                                        />
                                    </button>
                                </label>
                                <Typography
                                    onClick={captureChangeRequireBeforeGallery}
                                    style={{
                                        textAlign: "center",
                                        color: "black",
                                        fontWeight: 600,
                                        fontSize: 16,
                                    }}
                                    disabled={disableCondition}
                                >
                                    Click to Upload Images
                                </Typography>
                            </>
                        ) : (
                            <></>
                        )}
                    </div>

                    <div style={{ marginTop: "30px" }}>
                        {changeRequireBeforeImage.length > 0 && (
                            <div>
                                <ImageSlider
                                    images={changeRequireBeforeImage}
                                    isEdit={true}
                                    onDeleteIconClick={handleDeleteChangeRequireBefore}
                                    handlePopover={captureChangeRequireBeforeGallery}
                                />
                            </div>
                        )}
                    </div>
                </div>

                <div
                    style={{
                        marginTop: "10px",
                        width: "90%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        paddingLeft: 17,
                    }}
                >
                    <input
                        id="upload-image"
                        multiple
                        type="file"
                        style={{ display: "none" }}
                        // onChange={setUploadChangeRequireAfterFile}
                        ref={changeRequirefileAfter}
                        disabled={disableCondition}
                    />
                    <InputLabel
                        style={{
                            fontWeight: 600,
                            fontStyle: "normal",
                            fontFamily: "Inter, sans-serif",
                            color: "#000",
                            fontSize: 16,
                        }}
                    >
                        After
                    </InputLabel>
                    {!disableCondition && imageAfterError && (
                        <Typography variant="subtitle2" color="error">
                            Allows maximum 5 images to upload in after
                        </Typography>
                    )}
                    <div
                        style={{
                            border: "2px dashed #B1174B",
                            flex: 1,
                            display: "flex",
                            width: "100%",
                            height: 300,
                            marginTop: 5,
                            marginRight: 20,
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "6px",
                        }}
                    >
                        <input
                            type="file"
                            accept="image/*"
                            // onChange={handleImageSelect}
                            style={{ display: "none" }}
                            id="image-upload"
                            multiple
                        />
                        {!changeRequireAfterImage.length > 0 ? (
                            <>
                                <label htmlFor="image-upload">
                                    <button
                                        style={{
                                            width: 30,
                                            height: 30,
                                            marginLeft: 10,
                                            marginBottom: 10,
                                            cursor: "pointer",
                                            padding: 0,
                                            border: "none",
                                            background: "none",
                                        }}
                                        aria-label="Upload File"
                                        disabled={disableCondition}
                                    >
                                        <img
                                            src={CloudUpload}
                                            alt="Upload File"
                                            style={{ width: "100%", height: "100%" }}
                                            onClick={captureChangeRequireAfterGallery}
                                        />
                                    </button>
                                </label>
                                <Typography
                                    onClick={captureChangeRequireAfterGallery}
                                    style={{
                                        textAlign: "center",
                                        color: "black",
                                        fontWeight: 600,
                                        fontSize: 16,
                                    }}
                                    disabled={disableCondition}
                                >
                                    Click to Upload Images
                                </Typography>
                            </>
                        ) : (
                            <></>
                        )}
                    </div>

                    <div style={{ marginTop: "30px" }}>
                        {changeRequireAfterImage.length > 0 && changeRequireBeforeImage.length <= 5 && (
                            <div>
                                <ImageSlider
                                    images={changeRequireAfterImage}
                                    isEdit={true}
                                    handlePopover={captureChangeRequireAfterGallery}
                                    onDeleteIconClick={handleDeleteChangeRequireAfter}
                                />
                            </div>
                        )}
                    </div>
                </div>

                <Grid item xs={12}>
                    <Box display="flex" alignItems="center">
                        <input
                            id="upload-image"
                            multiple
                            type="file"
                            style={{ display: "none" }}
                            onChange={setUploadedFileChangeRequire}
                            ref={changeRequiredFileInputRef}
                            disabled={disableCondition}
                        />
                        <Button
                            variant="outlined"
                            component="span"
                            onClick={handleFileUploadChangeRequire}
                            disabled={uploadDocumentChangeRequire.length >= 5}
                        >
                            Upload Document
                        </Button>

                        {uploadDocumentChangeRequire.length > 0 && (
                            <div style={{ marginTop: '10px' }}>
                                {uploadDocumentChangeRequire.map((file, index) => (
                                    <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                        <CloudDownloadIcon
                                            style={{ width: '30px', height: 'auto', marginRight: '10px', cursor: 'pointer' }}
                                            onClick={() => handleDownloadDocChangeRequire(file)} // Add download functionality
                                        />
                                        <span>{file}</span>
                                        <button
                                            onClick={() => handleDeleteChangeRequire(file)}
                                            style={{
                                                marginLeft: '10px',
                                                background: 'none',
                                                border: 'none',
                                                color: 'red',
                                                fontSize: '20px',
                                                cursor: 'pointer',
                                            }}
                                        >
                                            &times;
                                        </button>
                                    </div>
                                ))}
                            </div>
                        )}
                    </Box>

                    {/* Show error message if more than 5 files are selected */}
                    {!disableCondition && uploadDocumentChangeRequire.length >= 5 && (
                        <Typography variant="subtitle2" color="error">
                            Allows maximum 5 documents to upload
                        </Typography>
                    )}
                </Grid>



                {/* <div style={{
                marginTop: "10px",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start"
            }}>
                {uploadDocumentChangeRequire && (
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                        <CloudDownloadIcon style={{ width: '50px', height: 'auto', marginRight: '10px', cursor: "pointer" }} onClick={handleDownloadDocChangeRequire} />
                        <span>{uploadDocumentChangeRequire}</span>
                    </div>
                )}
            </div> */}

                <div style={{ marginTop: "10px" }}>
                    <InputLabel
                        style={{
                            fontWeight: 600,
                            fontStyle: "normal",
                            fontFamily: "Roboto, sans-serif",
                            color: "#000",
                            fontSize: 14,
                        }}
                    >
                        Reason For Change Request *
                    </InputLabel>
                    <TextField
                        style={{ width: "100%", marginTop: 10, background: "#FFFFFF" }}
                        value={changeRequireData.change_request_remarks}
                        onChange={(e) => onHandleChange(e, "change_request_remarks")}
                        id="outlined-multiline-static"
                        multiline
                        rows={4}
                        error={stepError && !changeRequireData.change_request_remarks ? true : false}
                        disabled={disableCondition}
                    />
                </div>

                <Grid item xs={12} style={{ marginTop: "20px" }}>
                    <Box display="flex" alignItems="center">

                        <input
                            id="upload-image"
                            multiple
                            type="file"
                            style={{ display: "none" }}
                            // onChange={setUploadedFileChangeRequire}
                            ref={changeRequestFileInputRef}
                            disabled={disableCondition}
                        />
                        <Button
                            variant="outlined"
                            component="span"
                            onClick={handleFileUploadChangeRequest}
                            disabled={uploadDocumentChangeRequest.length >= 5}
                        >
                            Upload Document
                        </Button>
                        {uploadDocumentChangeRequest.length > 0 && (
                            <div style={{ marginTop: '10px' }}>
                                {uploadDocumentChangeRequest.map((file, index) => (
                                    <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                        <CloudDownloadIcon
                                            style={{ width: '30px', height: 'auto', marginRight: '10px', cursor: 'pointer' }}
                                            onClick={() => handleDownloadDocChangeRequest(file)} // Add download functionality
                                        />
                                        <span>{file}</span>
                                        <button
                                            onClick={() => handleDeleteChangeRequest(file)}
                                            style={{
                                                marginLeft: '10px',
                                                background: 'none',
                                                border: 'none',
                                                color: 'red',
                                                fontSize: '20px',
                                                cursor: 'pointer',
                                            }}
                                        >
                                            &times;
                                        </button>
                                    </div>
                                ))}
                            </div>
                        )}
                    </Box>
                    {!disableCondition && uploadDocumentChangeRequest.length >= 5 && (
                        <Typography variant="subtitle2" color="error">
                            Allows maximum 5 documents to upload
                        </Typography>
                    )}
                </Grid>

                {initialLoad ? (
                    <div className="popup-overlay">
                        <div className="popup">
                            <CircularProgress />
                        </div>
                    </div>
                ) : showSuccessPopup ? (
                    <div className="popup-overlay">
                        <div className="popup">
                            <p>Data saved successfully!</p>
                        </div>
                    </div>
                ) : null}
                {initialLoad ? (
                    <div className="popup-overlay">
                        <div className="popup">
                            <CircularProgress />
                        </div>
                    </div>
                ) : showUpdatePopup ? (
                    <div className="popup-overlay">
                        <div className="popup">
                            <p>Data updated successfully!</p>
                        </div>
                    </div>
                ) : null}
                {errors ? (
                    <Typography variant="subtitle2" color="red">
                        {""}
                        Please fill all the fields
                    </Typography>
                ) : null}
                {saveMessage ? (
                    <Typography variant="subtitle2" color="red">
                        {""}
                        Please Save before going to Next
                    </Typography>
                ) : null}

                <div
                    style={{
                        display: "flex",
                        flexDirection: "row", // Keep it as row to align buttons horizontally
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "20px",
                    }}
                >
                    {(isEditCms && !(disableCondition) && (JSON.parse(localStorage.getItem("cmsDataByID")))?.cmsForm?.change_required_id !== null) ?
                        <Button
                            variant="contained"
                            style={{
                                backgroundColor: "#fff",
                                fontSize: 20,
                                color: "#B1174B",
                                border: "1px solid #B1174B",
                                textTransform: "none",
                                width: "75%",
                                marginTop: 20,
                                marginRight: 5,
                                marginBottom: 10,
                                boxShadow: "none"
                            }}
                            onClick={handleChangeUpdate}
                        >
                            Update
                        </Button>
                        :
                        ((!isEditCms || (JSON.parse(localStorage.getItem("cmsDataByID")))?.cmsForm?.change_required_id === null) &&
                            <Button
                                variant="contained"
                                style={{
                                    backgroundColor: "#fff",
                                    fontSize: 20,
                                    color: "#B1174B",
                                    border: "1px solid #B1174B",
                                    textTransform: "none",
                                    width: "75%",
                                    marginTop: 20,
                                    marginRight: 5,
                                    marginBottom: 10,
                                    boxShadow: "none"
                                }}
                                onClick={handleChangeSave}
                            >
                                Save
                            </Button>
                        )}
                    <Button
                        variant="contained"
                        style={{
                            backgroundColor: "#B1174B",
                            fontSize: 20,
                            color: "white",
                            textTransform: "none",
                            width: "75%",
                            marginTop: 20,
                            marginRight: 5,
                            marginBottom: 10
                        }}
                        onClick={handleChangeNext}
                    >
                        Next
                    </Button>
                </div>

            </DialogContent>
        </>
    );
};
export default ChangeRequired;