import React, { useState, useEffect } from "react";
import { InputLabel, TextField, MenuItem, Button, Typography, CircularProgress, Dialog, DialogTitle, DialogContent, useTheme, useMediaQuery } from "@mui/material";
import { useRecoilState } from "recoil";
import { userData, editMocData } from "../recoil/atoms";
import axios from "axios";
import API from "../Services/API";
import configParam from "../config";

const roleList = configParam.ROLE_LIST;
const rolesList = configParam.ROLES_LIST;

const NewUser = ({ isOpen, onClose }) => {
  const [userList, setUserList] = useRecoilState(userData);
  const [errors, setErrors] = useState(false);
  const [stepError, setStepError] = useState(false);
  const [, setSave] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showUpdatePopup, setShowUpdatePopup] = useState(false);
  const [initialLoad, setInitialLoad] = useState(false);
  const [phoneNoError, setPhoneNoError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isEditMoc] = useRecoilState(editMocData);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const access_token = JSON.parse(localStorage.getItem("accesstoken"));

  useEffect(() => {
    const handlePopup = (setter, delay) => {
      setter(true);
      const timer = setTimeout(() => {
        setter(false);
      }, delay);
      return () => clearTimeout(timer);
    };
    const handlePopups = (showPopup, setShowPopup) => {
      if (showPopup) {
        setInitialLoad(true);
        setTimeout(() => {
          setInitialLoad(false);
          handlePopup(setShowPopup, 1000);
        }, 2000);
      }
    };
    handlePopups(showSuccessPopup, setShowSuccessPopup);
    handlePopups(showUpdatePopup, setShowUpdatePopup);
  }, [showSuccessPopup, showUpdatePopup]);

  const onHandleChange = async (event, field) => {
    let updatedForm = { ...userList };
    const value = field==="SGID"?event.target.value.trim():event.target.value;
    setErrors(false);
    if (
      (field === "name" && value.length > 30) ||
      // (field === "LName" && value.length > 30) ||
      (field === "SGID" && value.length > 10) ||
      (field === "phone_number" && value.length > 10) ||
      (field === "plant" && value.length > 30)
      // ||
      // (field === "admin_name" && value.length > 30) ||
      // (field === "admin_sgid" && value.length > 10)

    ) {
      return;
    }
    let specificNumError = "";
    if (field === "phone_number") {
      const containsNonNumeric = /[^0-9()-\s]/.test(value);
      specificNumError = containsNonNumeric ? "Only numbers are allowed" : "";
      const numericValue = value.replace(/[^0-9()-\s]/g, "");
      updatedForm[field] = numericValue;
      setPhoneNoError(specificNumError);
    }
    else {
      updatedForm[field] = value;
    }
    setUserList(updatedForm);

    if (field === "SGID" && value.trim() !== "") {
      try {
        const cleanedSgid = value.trim();
        const userDetailsServiceURL = `${configParam.API_URL}users/${cleanedSgid}`;
        const response = await axios.get(userDetailsServiceURL);
        const apiUserData = response.data.userDetails;
        setUserList((prevUserData) => ({
          ...prevUserData,
          name: apiUserData[0].name || prevUserData.name,
          // LName: apiUserData[0].LName || prevUserData.LName,
          plant: apiUserData[0].plant || prevUserData.plant
        }));
      } catch (error) {
        console.error("Error fetching SGID data:", error);
      }
    }
  };
  console.log("isEditMoc4333", isEditMoc)
  useEffect(() => {
    console.log("isEditMoc", isEditMoc)
    if (isEditMoc) {
      const getNewUserForm = async () => {
        const getUserID = JSON.parse(localStorage.getItem("userDataByID"));
        const userID = getUserID.id;
        try {
          const response = await axios.get(`${API.GET_USER_BYID}?id=${userID}`,
            {
              headers: {
                Authorization: `Bearer ${access_token}`
              }
            });
          const UserDetails = response.data;
          console.log("UserDetailsUserDetails", UserDetails)
          setUserList((prevUserData) => ({
            ...prevUserData,
            ...UserDetails, // Make sure UserDetails contains the necessary fields
          }));
        } catch (error) {
          console.error("Error fetching user details:", error);
        }
      };
      getNewUserForm();
    }
  }, [setUserList, isEditMoc]);

  const handleUserSaveClick = async () => {
    const storedUserData = JSON.parse(localStorage.getItem("userDatas"));
    const userName = storedUserData.name;
    try {
      if (
        !userList.name ||
        // !userList.LName ||
        !userList.SGID ||
        !userList.MailId ||
        !userList.phone_number ||
        !userList.plant ||
        !userList.role
        //  ||
        // !userList.admin_sgid ||
        // !userList.admin_name ||
        // !userList.admin_email
      ) {
        setErrors(true);
        setStepError(true);
        return;
      } else {
        setErrors(false);
        setStepError(false);
      }
      // Validate email format
      // const emailValidate = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const emailValidate = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!emailValidate.test(userList.MailId)) {
        setEmailError("Invalid email format");
        return;
      } else {
        setEmailError("");
      }

      // Validate phone number length
      if (userList.phone_number.length !== 10) {
        setPhoneNoError("Phone number must be exactly 10 characters");
        return;
      }
      const userDatas = {
        name: userList.name,
        FName: userList.FName,
        LName: userList.LName,
        SGID: userList.SGID,
        MailId: userList.MailId,
        phone_number: userList.phone_number,
        plant: userList.plant,
        role: userList.role,
        // admin_sgid: userList.admin_sgid,
        // admin_name: userList.admin_name,
        // admin_email: userList.admin_email,
        updated_at: new Date(),
        created_by: userName,
      };
      const response = await axios.post(API.INSERT_USER_FORM, userDatas, {
        headers: {
          Authorization: `Bearer ${access_token}`
        }
      });
      console.log(userDatas, "userDatas178")
      if (response.status === 200) {
        setSave(true);
        setShowSuccessPopup(true);
        setTimeout(() => {
          onClose();
          window.location.reload();
        }, 1000);
      }
    } catch (error) {
      console.error("An error occurred in update trials:", error);
    }
  };

  const handleUserUpdateClick = async () => {
    const getUserID = JSON.parse(localStorage.getItem("userDataByID"));
    const userID = getUserID.id;
    try {
      if (
        !userList.name ||
        // !userList.LName ||
        !userList.SGID ||
        !userList.MailId ||
        !userList.phone_number ||
        !userList.plant ||
        !userList.role
        //  ||
        // !userList.admin_sgid ||
        // !userList.admin_name ||
        // !userList.admin_email
      ) {
        setErrors(true);
        setStepError(true);
        return;
      } else {
        setErrors(false);
        setStepError(false);
      }
      // Validate email format
      const emailValidate = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!emailValidate.test(userList.MailId)) {
        setEmailError("Invalid email format");
        return;
      } else {
        setEmailError("");
      }

      // Validate phone number length
      if (userList.phone_number.length !== 10) {
        setPhoneNoError("Phone number must be exactly 10 characters");
        return;
      }
      const updateUserData = {
        id: userID,
        MailId: userList.MailId,
        phone_number: userList.phone_number,
        plant: userList.plant,
        role: userList.role,
        // admin_sgid: userList.admin_sgid,
        // admin_name: userList.admin_name,
        // admin_email: userList.admin_email,
      };
      const response = await axios.put(
        `${API.UPDATE_USER}?id=${userID}`,
        updateUserData,
        {
          headers: {
            Authorization: `Bearer ${access_token}`
          }
        }
      );
      if (response.status === 200) {
        setSave(true);
        setShowUpdatePopup(true);
        setTimeout(() => {
          onClose();
          window.location.reload();
        }, 1000);

      }
    } catch (error) {
      console.error("An error occurred in update trials:", error);
    }
  };

  const handleUserCancelClick = () => {
    onClose();
  }

  let popupContent = null;

  if (initialLoad) {
    popupContent = (
      <div className="popup-overlay">
        <div className="popup">
          <CircularProgress />
        </div>
      </div>
    );
  } else if (showSuccessPopup) {
    popupContent = (
      <div className="popup-overlay">
        <div className="popup">
          <p>User added successfully!</p>
        </div>
      </div>
    );
  } else if (showUpdatePopup) {
    popupContent = (
      <div className="popup-overlay">
        <div className="popup">
          <p>User updated successfully!</p>
        </div>
      </div>
    );
  }
  const storedUserData = JSON.parse(localStorage.getItem("userDatas"));

  return (
    <>
      <Dialog open={isOpen}
        onClose={onClose}
        fullScreen={fullScreen}
        fullWidth
        maxWidth="sm">
        <DialogTitle>New User</DialogTitle>
        <DialogContent>
          <div style={{ background: "#F7F7F7", minHeight: "90vh" }}>
            <div style={{ padding: 10 }}>
              <InputLabel
                style={{
                  fontWeight: 500,
                  fontStyle: "normal",
                  fontFamily: "Inter, sans-serif",
                  marginBottom: 5,
                  marginTop: "10px",
                  color: "#000000",
                }}
              >
                SGID *
              </InputLabel>
              <TextField
                style={{ width: "100%", background: "#FFFFFF" }}
                value={userList.SGID}
                onChange={(e) => onHandleChange(e, "SGID")}
                id="SGID"
                variant="outlined"
                InputLabelProps={{
                  style: { fontWeight: 500, fontStyle: "normal" },
                }}
                error={stepError && !userList.SGID ? true : false}
                disabled={isEditMoc ? true : false}
                autoComplete="off"
              />
            </div>
            <div style={{ padding: 10 }}>
              <InputLabel
                style={{
                  fontWeight: 500,
                  fontStyle: "normal",
                  fontFamily: "Inter, sans-serif",
                  marginBottom: 5,
                  marginTop: "10px",
                  color: "#000000",
                }}
              >
                Name *
              </InputLabel>
              <TextField
                style={{ width: "100%", background: "#FFFFFF" }}
                value={userList.name}
                onChange={(e) => onHandleChange(e, "name")}
                id="name"
                error={stepError && !userList.name ? true : false}
                disabled={isEditMoc ? true : false}
                variant="outlined"
                InputLabelProps={{
                  style: { fontWeight: 500, fontStyle: "normal" },
                }}
                autoComplete="off"
              />
            </div>
            {/* <div style={{ padding: 10 }}>
              <InputLabel
                style={{
                  fontWeight: 500,
                  fontStyle: "normal",
                  fontFamily: "Inter, sans-serif",
                  marginBottom: 5,
                  marginTop: "10px",
                  color: "#000000",
                }}
              >
                Last Name *
              </InputLabel>
              <TextField
                style={{ width: "100%", background: "#FFFFFF" }}
                value={userList.LName}
                onChange={(e) => onHandleChange(e, "LName")}
                id="LName"
                error={stepError && !userList.LName ? true : false}
                disabled={isEditMoc ? true : false}
                variant="outlined"
                InputLabelProps={{
                  style: { fontWeight: 500, fontStyle: "normal" },
                }}
              />
            </div> */}
            <div style={{ padding: 10 }}>
              <InputLabel
                style={{
                  fontWeight: 500,
                  fontStyle: "normal",
                  fontFamily: "Inter, sans-serif",
                  marginBottom: 5,
                  marginTop: "10px",
                  color: "#000000",
                }}
              >
                Email ID *
              </InputLabel>
              <TextField
                style={{ width: "100%", background: "#FFFFFF" }}
                value={userList.MailId}
                onChange={(e) => onHandleChange(e, "MailId")}
                id="MailId"
                variant="outlined"
                InputLabelProps={{
                  style: { fontWeight: 500, fontStyle: "normal" },
                }}
                error={(Boolean(emailError) || (stepError && !userList.MailId)) ? true : false}
                helperText={
                  <span style={{ color: "#FF0000" }}>{emailError || " "}</span>
                }
                disabled={isEditMoc ? true : false}
                autoComplete="off"
              />
            </div>
            <div style={{ padding: 10 }}>
              <InputLabel
                style={{
                  fontWeight: 500,
                  fontStyle: "normal",
                  fontFamily: "Inter, sans-serif",
                  marginBottom: 5,
                  marginTop: "10px",
                  color: "#000000",
                }}
              >
                Phone Number *
              </InputLabel>
              <TextField
                style={{ width: "100%", background: "#FFFFFF" }}
                value={userList.phone_number}
                onChange={(e) => onHandleChange(e, "phone_number")}
                id="phone_number"
                variant="outlined"
                InputLabelProps={{
                  style: { fontWeight: 500, fontStyle: "normal" },
                }}
                error={
                  Boolean(phoneNoError) || (stepError && !userList.phone_number)
                    ? true
                    : false
                }
                helperText={
                  <span style={{ color: "#FF0000" }}>{phoneNoError || " "}</span>
                }
                autoComplete="off"
              />
            </div>
            <div style={{ padding: 10 }}>
              <InputLabel
                style={{
                  fontWeight: 500,
                  fontStyle: "normal",
                  fontFamily: "Inter, sans-serif",
                  marginBottom: 5,
                  marginTop: "10px",
                  color: "#000000",
                }}
              >
                Plant/Business *
              </InputLabel>
              <TextField
                style={{ width: "100%", background: "#FFFFFF" }}
                value={userList.plant}
                onChange={(e) => onHandleChange(e, "plant")}
                id="outlined-select-currency"
                select
                InputLabelProps={{
                  shrink: true,
                }}
                error={stepError && !userList.plant ? true : false}
              >
                <MenuItem value="Glass">Glass</MenuItem>
                <MenuItem value="Sekurit">Sekurit</MenuItem>
              </TextField>
            </div>
            <div style={{ padding: 10 }}>
              <InputLabel
                style={{
                  fontWeight: 500,
                  fontStyle: "normal",
                  fontFamily: "Inter, sans-serif",
                  marginBottom: 5,
                  marginTop: "10px",
                  color: "#000000",
                }}
              >
                Role *
              </InputLabel>
              <TextField
                style={{ width: "100%", background: "#FFFFFF" }}
                value={userList.role}
                onChange={(e) => onHandleChange(e, "role")}
                id="outlined-select-currency"
                select
                InputLabelProps={{
                  shrink: true,
                }}
                error={stepError && !userList.role}
              >
                {storedUserData.plant === "Glass"
                  ? roleList.map((type) => (
                    <MenuItem key={type.value} value={type.value}>
                      {type.label}
                    </MenuItem>
                  ))
                  : rolesList.map((type) => (
                    <MenuItem key={type.value} value={type.value}>
                      {type.label}
                    </MenuItem>
                  ))}
              </TextField>
            </div>

            {errors ? (
              <Typography variant="subtitle2" color="red">
                {""}
                Please fill all the fields
              </Typography>
            ) : null}
            <>{popupContent}</>
            <div
              style={{
                display: "flex",
                flexDirection: "row", // Keep it as row to align buttons horizontally
                justifyContent: "center",
                alignItems: "center",
                padding: 10
              }}
            >
              {isEditMoc ? (
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#B1174B",
                    fontSize: 20,
                    color: "#fff",
                    border: "1px solid #B1174B",
                    textTransform: "none",
                    width: "75%",
                    marginTop: 20,
                    marginBottom: 10,
                    marginRight: 5,
                    boxShadow: "none",
                  }}
                  onClick={handleUserUpdateClick}
                >
                  Update
                </Button>
              ) : (
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#fff",
                    fontSize: 20,
                    color: "#B1174B",
                    border: "1px solid #B1174B",
                    textTransform: "none",
                    width: "75%",
                    marginTop: 20,
                    marginBottom: 10,
                    marginRight: 5,
                    boxShadow: "none",
                  }}
                  onClick={handleUserSaveClick}
                >
                  Save
                </Button>
              )}
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#B1174B",
                  fontSize: 20,
                  color: "#fff",
                  border: "1px solid #B1174B",
                  textTransform: "none",
                  width: "75%",
                  marginTop: 20,
                  marginBottom: 10,
                  boxShadow: "none",
                }}
                onClick={handleUserCancelClick}
              >
                Cancel
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default NewUser;