import React, { useState, useEffect, useRef } from "react";
import { Tabs, Tab, Card, CardContent, Typography, Tooltip, Popover, Grid, Box, Button, Accordion, AccordionDetails, AccordionSummary, Container, TextField, CircularProgress, AvatarGroup, Avatar, IconButton } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import menu from "../Asset/Icons/menu_managemoc.svg";
import Edit from "../Asset/Icons/edit.svg";
import SearchIcon from "../Asset/Icons/search_icon.svg";
import FilterIcon from "../Asset/Icons/filter_icon.svg";
import CloseIcon from "../Asset/Icons/close_icon.svg";
import { useNavigate } from "react-router-dom";
import API from "../Services/API";
import axios from "axios";
import moment from "moment";
import { useRecoilState } from "recoil";
import { getCmsDataID, editCMSData, userData, changeRequestData, changeReqData, impactChangeData, notification } from "../recoil/atoms";
import TimeLine from "../Asset/Icons/Arrows.svg";
import DeleteDialog from "../settings/DeleteDialog";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import NewChangeRequest from "./NewChangeRequest";
import Alternation from "./Alternation";
import PlusIcon from "../Asset/Icons/addnew_icon.svg";
import ChangeRequest from "./ChangeRequest";

const ManageCmsMobile = () => {
    const [selectedTab, setSelectedTab] = useState(0);
    const [cmsData, setCmsData] = useState([]);
    const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
    const [showMenu, setShowMenu] = useState(false);
    const [cmsDataID, setCmsDataID] = useRecoilState(getCmsDataID);
    const [isEditCms, setIsEditCms] = useRecoilState(editCMSData);
    const [recordData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [coordinates, setCoordinates] = useState([]);
    const [initialLoad, setInitialLoad] = useState(true);
    const [, setLoading] = useState(false);
    const [, setError] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [conclusionAccordionExpand, setConclusionAccordionExpand] = useState(false);
    const [dateAccordionExpand, setDateAccordionExpand] = useState(false);
    const [fromDate, setFromDate] = useState([]);
    const [toDate, setToDate] = useState([]);
    const [filterCardData, setFilterCardData] = useState([]);
    const [filterProgressData, setFilterProgressData] = useState([]);
    const [filterDraftData, setFilterDraftData] = useState([]);
    const [filterRejectData, setFilterRejectData] = useState([]);
    const [filterApprovalData, setFilterApprovalData] = useState([]);
    const [filterCompleteData, setFilterCompleteData] = useState([]);
    const [cardData, setCardData] = useState([]);
    const [progressData, setProgressData] = useState([]);
    const [draftData, setDraftData] = useState([]);
    const [rejectedData, setRejectedData] = useState([]);
    const [approvalData, setApprovalData] = useState([]);
    const [completedData, setCompletedData] = useState([]);
    const [expanded, setExpanded] = useState(false);
    const [, setApprovalMocID] = useState([]);
    const openFilterPopover = Boolean(anchorEl);
    const [deleteMocId, setDeleteMocId] = useState(null);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [, setApproved] = useState('');
    const [, setPending] = useState('');
    const [, setRejected] = useState('');
    const [, setResubmitted] = useState('');
    const [, setConditionApprove] = useState('');
    const [, setSubmitBackQueries] = useState('');
    const filterId = openFilterPopover ? "simple-popover" : undefined;
    const navigate = useNavigate();
    const [expandedCardId, setExpandedCardId] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [, setUserList] = useRecoilState(userData);
    const [, setChangeRequest] = useRecoilState(changeRequestData);
    const [, setChangeRequireData] = useRecoilState(changeReqData);
    const [, setImpactData] = useRecoilState(impactChangeData);
    const [, setNotificationData] = useRecoilState(notification);
    const [timeline, setTimeline] = useState([]);
    const [showTab, setShowTab] = useState(false);
    const [newDialogRequestsOpen, setNewDialogRequestsOpen] = useState(false);
    const [newDialogAlterOpen, setNewDialogAlterOpen] = useState(false);
    const [openedMenuId, setOpenedMenuId] = useState(null);
    const [showAlterNote, setShowAlterNote] = useState([]);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const menuRef = useRef(null);

    const access_token = JSON.parse(localStorage.getItem("accesstoken"));

    useEffect(() => {
    }, [editDialogOpen]);

    const toggleAccordionTimeline = async (event, id) => {
        const response = await axios.get(
            `${API.GET_APPROVAL_BYCMSID}?id=${id}`,
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
        const appCmsID = response.data;
        const withStatus = [];
        appCmsID.forEach(item => {
            withStatus.push(item);
        });
        setTimeline(withStatus);
        function groupByStatus(data) {
            const result = {
                approved: new Map(),
                rejected: new Map(),
                pending: new Map(),
                resubmitted: new Map(),
                conditionallyapproved: new Map(),
                submittedbackforqueries: new Map(),
            };
            data.forEach(item => {
                const { id, status, updated_at } = item;
                const updatedAt = new Date(updated_at);

                if (!result[status]?.has(id) || result[status]?.get(id).updated_at < updatedAt) {
                    result[status]?.set(id, { ...item, updated_at: updatedAt });
                }
            });

            // Convert Maps to arrays
            return {
                approved: Array.from(result.approved.values()),
                rejected: Array.from(result.rejected.values()),
                pending: Array.from(result.pending.values()),
                resubmitted: Array.from(result.resubmitted.values()),
                conditionallyapproved: Array.from(result.conditionallyapproved.values()),
                submittedbackforqueries: Array.from(result.submittedbackforqueries.values()),
            };
        }

        const groupedData = groupByStatus(appCmsID);
        if (groupedData.approved && groupedData.approved.length > 0) {
            const latestApproved = groupedData.approved.reduce((latest, current) => {
                return new Date(latest.updated_at) > new Date(current.updated_at) ? latest : current;
            });
            setApproved((new Date(latestApproved.updated_at)).toISOString().split('T')[0])
        } else {
            setApproved("");
        }
        if (groupedData.rejected && groupedData.rejected.length > 0) {
            const latestApproved = groupedData.rejected.reduce((latest, current) => {
                return new Date(latest.updated_at) > new Date(current.updated_at) ? latest : current;
            });
            setRejected((new Date(latestApproved.updated_at)).toISOString().split('T')[0])
        } else {
            setRejected("");
        }
        if (groupedData.pending && groupedData.pending.length > 0) {
            const latestApproved = groupedData.pending.reduce((latest, current) => {
                return new Date(latest.updated_at) > new Date(current.updated_at) ? latest : current;
            });
            setPending((new Date(latestApproved.updated_at)).toISOString().split('T')[0])
        } else {
            setPending("");
        }
        if (groupedData.resubmitted && groupedData.resubmitted.length > 0) {
            const latestApproved = groupedData.resubmitted.reduce((latest, current) => {
                return new Date(latest.updated_at) > new Date(current.updated_at) ? latest : current;
            });
            setResubmitted((new Date(latestApproved.updated_at)).toISOString().split('T')[0])
        } else {
            setResubmitted("");
        }
        if (groupedData.conditionallyapproved && groupedData.conditionallyapproved.length > 0) {
            const latestApproved = groupedData.conditionallyapproved.reduce((latest, current) => {
                return new Date(latest.updated_at) > new Date(current.updated_at) ? latest : current;
            });
            setConditionApprove((new Date(latestApproved.updated_at)).toISOString().split('T')[0])
        } else {
            setConditionApprove("");
        }
        if (groupedData.submittedbackforqueries && groupedData.submittedbackforqueries.length > 0) {
            const latestApproved = groupedData.submittedbackforqueries.reduce((latest, current) => {
                return new Date(latest.updated_at) > new Date(current.updated_at) ? latest : current;
            });
            setSubmitBackQueries((new Date(latestApproved.updated_at)).toISOString().split('T')[0])
        } else {
            setSubmitBackQueries("");
        }
        setApprovalMocID(appCmsID);
        setExpandedCardId(expandedCardId === id ? null : id);
    };

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
        setShowMenu(false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setShowMenu(false);
            }
        };
        if (showMenu) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showMenu]);

    const handleAddClick = () => {
        setUserList([]);
        setIsEditCms(false);
        setChangeRequest({
            date: '',
            request_initiator: '',
            department: '',
            cms_no: '',
            type_design_change: '',
            plant: '',
            change_category: '',
            ecn_type: '',
            process_type: '',
            ecn_int_customer: '',
            ecn_int_model: '',
            ecn_int_glass_reference: '',
            ecn_int_part_number_rev_no: '',
            ecn_int_part_number: '',
            ecn_int_part_name: '',
            ecn_int_drawing_no_rev_no: '',
            ecn_int_drawing_number: '',
            ecn_int_process_reference: '',
            ecn_ext_customer: '',
            ecn_ext_model: '',
            ecn_ext_glass_reference: '',
            ecn_ext_part_number_rev_no: '',
            ecn_ext_part_number: '',
            ecn_ext_part_name: '',
            ecn_ext_drawing_no_rev_no: '',
            ecn_ext_drawing_number: '',
            ecn_ext_process_reference: '',
            pro_int_customer: '',
            pro_int_model: '',
            pro_int_glass_reference: '',
            pro_int_part_number: '',
            pro_int_part_number_rev_no: '',
            pro_int_part_name: '',
            pro_int_drawing_no_rev_no: '',
            pro_int_drawing_number: '',
            pro_int_process_reference: '',
            pro_ext_customer: '',
            pro_ext_model: '',
            pro_ext_glass_reference: '',
            pro_ext_part_number_rev_no: '',
            pro_ext_part_number: '',
            pro_ext_part_name: '',
            pro_ext_drawing_no_rev_no: '',
            pro_ext_drawing_number: '',
            pro_ext_process_reference: '',
            des_change_required: '',
            other_comments: '',
            receiveing_customer_date: '',
            customer_req_imple_date: '',
            feas_con_customer_date: '',
            remarks: '',
        });
        setChangeRequireData({
            change_required_remarks: "",
            change_request_remarks: ""
        });
        setImpactData({
            affects: "",
            actions: "",
            responsibility: "",
            date: "",
            remarks: "",
            document: []
        });
        setNotificationData({
            approver_mail_id: "",
            approval_status: "",
        })
        setNewDialogRequestsOpen(true);
        setNewDialogAlterOpen(false);
        localStorage.removeItem("isChangeRequestSave");
        localStorage.removeItem("isChangeRequireSave");
        localStorage.removeItem("isImpactChangeSave");
    };

    const dialogClose = () => {
        setEditDialogOpen(false);
    };

    const newDialogRequestClose = () => {
        setNewDialogRequestsOpen(false);
    }

    const newDialogAlterClose = () => {
        setNewDialogAlterOpen(false);
    }

    const handleMenuClick = async (event, cmsId) => {
        try {
            const cardRect = event.currentTarget.getBoundingClientRect();
            const menuTop = cardRect.bottom + window.scrollY - 30;
            const menuLeft = cardRect.left + window.scrollX - 125;
            const response = await axios.get(
                `${API.GET_APPROVAL_CMS_BY_ID}?id=${cmsId}`,
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`
                    }
                });
            const cmsDataByID = response.data;
            localStorage.setItem("cmsDataByID", JSON.stringify(cmsDataByID));
            setCmsDataID(cmsId);
            const bothApprovalStatus = cmsDataByID?.cmsForm?.notification?.functional_approval_status === true && cmsDataByID?.cmsForm?.notification?.cft_approval_status === true
            const showAlterNote = (
                (cmsDataByID?.cmsForm?.submission_status === "approved" && bothApprovalStatus ||
                    cmsDataByID?.cmsForm?.submission_status === "conditionallyapproved" && bothApprovalStatus)
            );
            setShowAlterNote(showAlterNote);
            setMenuPosition({ top: menuTop, left: menuLeft });
            setShowMenu(true);
            setOpenedMenuId(cmsId);
        } catch (error) {
            console.error("Error fetching Moc data by ID:", error);
        }
    };

    const editCmsData = async (cmsId) => {
        try {
            const response = await axios.get(`${API.EDIT_APPROVAL_CMS}?id=${cmsId}`,
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`
                    }
                });
            const cmsDetails = await response.data;
            localStorage.setItem("cmsDetails", JSON.stringify(cmsDetails));
            setNewDialogRequestsOpen(true);
            setIsEditCms(true);
        } catch (error) {
            console.error("Error fetching MOC details:", error);
        }
    };

    const alternationNoteSet = async () => {
        const cmsFormData = JSON.parse(localStorage.getItem("cmsDataByID"));
        const alternationNoteId = cmsFormData.cmsForm.alternation_note_id;
        try {
            if (!alternationNoteId) {
                const emptyAlternationNote = {
                    description: [],
                };
                localStorage.setItem("emptyAlternationNote", JSON.stringify(emptyAlternationNote));
                setNewDialogAlterOpen(true);
                setIsEditCms(false);
                return;
            }
            const response = await axios.get(
                `${API.GET_CMS_ALTERNATION}?id=${alternationNoteId}`,
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`
                    }
                });
            const alternationNoteDetails = await response.data;
            localStorage.setItem("alternationNoteDetails", JSON.stringify(alternationNoteDetails));
            setNewDialogAlterOpen(true);
            if (!isEditCms) {
                setIsEditCms(true);
            }
        } catch (error) {
            console.error("Error fetching MOC details:", error);
        }
    };

    // const deleteCMS = async () => {
    //     try {
    //         const response = await axios.delete(
    //             `${API.DELETE_CMS}?id=${cmsDataID}`,
    //             {
    //                 headers: {
    //                     Authorization: `Bearer ${access_token}`
    //                 }
    //             });
    //         const updatedCmsData = cmsData.filter((cms) => cms.id !== cmsDataID);
    //         setDraftData(updatedCmsData)
    //         setCmsData(updatedCmsData);
    //         setShowMenu(false);
    //         loadCmsData();
    //     } catch (error) {
    //         console.error("Error fetching MOC details:", error);
    //     }
    // };

    // Function to open delete dialog
    const openDeleteConfirmation = (userId) => {
        setDeleteMocId(userId);
        setOpenDeleteDialog(true);
    };

    // Function to close delete dialog
    const closeDeleteConfirmation = () => {
        setOpenDeleteDialog(false);
    };

    const handleMenuItemClick = async (menuItem, cms) => {
        setIsEditCms(true);
        switch (menuItem) {
            case "edit":
                editCmsData(cms.id);
                break;
            case "alterationNote":
                alternationNoteSet(cms.cmsForm.alternation_note_id);
                break;
            case "delete":
                openDeleteConfirmation(cms.id);
                break;
            default:
                break;
        }
        setShowMenu(false);
    };

    const storedUserData = JSON.parse(localStorage.getItem("userDatas"));
    const user = storedUserData.SGID;

    const loadCmsData = () => {
        try {
            axios
                .get(API.GET_ALL_CMS_FOR_APPROVALS, {
                    headers: {
                        Authorization: `Bearer ${access_token}`
                    }
                })
                .then(function (response) {
                    if (response.status === 200) {
                        if (response.data && response.data.length > 0) {

                            const cmsData = response.data;
                            setCardData(cmsData);
                            setFilterCardData(cmsData);
                            const filteredConclusionData = response.data.filter((item) => item.cmsForm.cms_no === null);
                            setDraftData(filteredConclusionData);
                            setFilterDraftData(filteredConclusionData);
                            // Assuming response.data is an array
                            const filteredApprovalData = response.data.filter((item) => {
                                const status = item?.cmsForm?.submission_status;
                                const cmsNo = item?.cmsForm?.cms_no;
                                const isPending = status === "pending" && cmsNo !== null;
                                const isResubmitted = status === "resubmitted" && cmsNo !== null;
                                return isPending || isResubmitted;
                            });
                            // Set the filtered data in state
                            setApprovalData(filteredApprovalData); // Set the state for all approval data
                            setFilterApprovalData(filteredApprovalData); // Set the state for filtered approval data

                            const filteredInProgressData = response.data.filter((item) =>
                                item?.cmsForm?.alternation_note_id === null && item?.cmsForm?.submission_status === "approved" || item?.cmsForm?.submission_status === "conditionallyapproved"
                            );
                            setProgressData(filteredInProgressData);
                            setFilterProgressData(filteredInProgressData);

                            const filteredCompleteData = response.data.filter((item) =>
                                item?.cmsForm?.alternation_note_id !== null && (item?.cmsForm?.submission_status === "approved" || item?.cmsForm?.submission_status === "conditionallyapproved")
                            );
                            setCompletedData(filteredCompleteData);
                            setFilterCompleteData(filteredCompleteData);

                            const filteredRejectData = response.data.filter((item) => {
                                const status = item?.cmsForm?.submission_status;
                                const isRejected = status === "rejected";
                                const isSubmittedBackForQueries = status === "submittedbackforqueries";
                                return isRejected || isSubmittedBackForQueries;
                            });
                            setRejectedData(filteredRejectData);
                            setFilterRejectData(filteredRejectData);

                            const completedCms = filteredCompleteData.length;
                            localStorage.setItem("completedCms", completedCms);
                            const pendingCms = filteredApprovalData.length;
                            localStorage.setItem("pendingCms", pendingCms);
                            const inProgressCms = filteredInProgressData.length;
                            localStorage.setItem("inProgressCms", inProgressCms);
                            const rejectedCms = filteredRejectData.length;
                            localStorage.setItem("rejectedCms", rejectedCms);
                            const allCms = cmsData.length;
                            localStorage.setItem("allCms", allCms);
                        }
                    }
                })
                .catch(function (error) {
                    console.log("ERROR", error);
                })
                .finally(() => {
                    setInitialLoad(false);
                });
        } catch (error) {
            console.error("An error occurred:", error);
        }
    };

    useEffect(
        () => {
            loadCmsData();
        },
        [
            //cmsData
        ]
    );

    const handleSearchChange = (event) => {
        setSearchText(event.target.value);
        if (selectedTab === 0) {
            filterAllCms(event.target.value);
        } else if (selectedTab === 1) {
            filterProgress(event.target.value);
        } else if (selectedTab === 2) {
            filterDraft(event.target.value);
        } else if (selectedTab === 3) {
            filterApproval(event.target.value);
        } else if (selectedTab === 4) {
            filterCompleted(event.target.value);
        } else {
            filteredRejected(event.target.value);
        }
    };

    const filterAllCms = (searchText) => {
        const filtCms = filterCmsData(filterCardData, searchText);
        setFilterCardData(filtCms);
        if (searchText) {
            setCardData(filtCms);
        } else {
            loadCmsData();
        }
    };
    const filterCmsData = (data, search) => {
        const filtCms = data.filter((cms) => {
            const lowerCaseQuery = search.toLowerCase();
            const cms_no = (cms?.cmsForm?.cms_no ? cms?.cmsForm?.cms_no : "").toLowerCase().includes(lowerCaseQuery);
            const updated_at = moment(cms.updated_at).format("DD-MMM-YYYY").toLowerCase().includes(lowerCaseQuery);
            return cms_no || updated_at;
        });
        return filtCms;
    };

    const filterProgress = (searchText) => {
        const filtProgress = filterProg(filterProgressData, searchText);
        setFilterProgressData(filtProgress);
        if (searchText) {
            setProgressData(filtProgress);
        } else {
            loadCmsData();
        }
    };
    const filterProg = (data, search) => {
        const lowerCaseQuery = search.toLowerCase();

        const filtProgress = data.filter((cms) => {
            // Ensure each property exists before calling toLowerCase()
            const conclusion = cms?.cmsForm?.conclusion?.toLowerCase().includes(lowerCaseQuery) || false;
            const cms_no = cms?.cmsForm?.cms_no ? cms?.cmsForm?.cms_no.toLowerCase().includes(lowerCaseQuery) : false;
            const created_at = moment(cms.created_at).format("DD-MMM-YYYY").toLowerCase().includes(lowerCaseQuery);
            const change_required = cms?.cmsForm?.requestForm?.change_required ? cms?.cmsForm?.requestForm.change_required.toLowerCase().includes(lowerCaseQuery) : false;

            return conclusion || cms_no || created_at || change_required;
        });

        return filtProgress;
    };

    const filterDraft = (searchText) => {
        const filtDraft = filterDraf(filterDraftData, searchText);
        setFilterDraftData(filtDraft);
        if (searchText) {
            setDraftData(filtDraft);
        } else {
            loadCmsData();
        }
    };
    const filterDraf = (data, search) => {
        const filtDraft = data.filter((cms) => {
            const lowerCaseQuery = search.toLowerCase();
            const created_at = moment(cms.created_at).format("DD-MMM-YYYY").toLowerCase().includes(lowerCaseQuery);
            const change_required = (cms?.cmsForm?.requestForm.change_required ? cms?.cmsForm?.requestForm.change_required : "").toLowerCase().includes(lowerCaseQuery);
            return created_at || change_required;
        });
        return filtDraft;
    };

    const filterApproval = (searchText) => {
        const filtApproval = filterApprov(filterApprovalData, searchText);
        setFilterApprovalData(filtApproval);
        if (searchText) {
            setApprovalData(filtApproval);
        } else {
            loadCmsData();
        }
    };
    const filterApprov = (data, search) => {
        const lowerCaseQuery = search.toLowerCase();

        const filtApproval = data.filter((cms) => {
            // Check if conclusion exists before calling toLowerCase()
            const conclusion = cms?.cmsForm?.conclusion
                ? cms?.cmsForm?.conclusion.toLowerCase().includes(lowerCaseQuery)
                : false;

            // Check if cms_no exists and use it, otherwise default to an empty string
            const cms_no = cms?.cmsForm?.cms_no
                ? cms?.cmsForm?.cms_no.toLowerCase().includes(lowerCaseQuery)
                : false;

            // Ensure created_at is a valid date and format it properly
            const created_at = moment(cms.created_at).format("DD-MMM-YYYY").toLowerCase().includes(lowerCaseQuery);

            // Check if change_required exists before calling toLowerCase()
            const change_required = cms?.cmsForm?.requestForm?.change_required
                ? cms?.cmsForm?.requestForm.change_required.toLowerCase().includes(lowerCaseQuery)
                : false;

            return conclusion || cms_no || created_at || change_required;
        });

        return filtApproval;
    };

    const filterCompleted = (searchText) => {
        const filtCompleted = filterCompletedData(filterCompleteData, searchText);
        setFilterCompleteData(filtCompleted);
        if (searchText) {
            setCompletedData(filtCompleted);
        } else {
            loadCmsData();
        }
    };
    const filterCompletedData = (data, search) => {
        const lowerCaseQuery = search.toLowerCase();

        const filtCompleted = data.filter((cms) => {
            // Check if conclusion exists before calling toLowerCase()
            const conclusion = cms?.cmsForm?.conclusion
                ? cms?.cmsForm?.conclusion.toLowerCase().includes(lowerCaseQuery)
                : false;

            // Check if cms_no exists and use it, otherwise default to an empty string
            const cms_no = cms?.cmsForm?.cms_no
                ? cms?.cmsForm?.cms_no.toLowerCase().includes(lowerCaseQuery)
                : false;

            // Ensure created_at is formatted correctly
            const created_at = moment(cms.created_at).format("DD-MMM-YYYY").toLowerCase().includes(lowerCaseQuery);

            // Check if change_required exists before calling toLowerCase()
            const change_required = cms?.cmsForm?.requestForm?.change_required
                ? cms?.cmsForm?.requestForm.change_required.toLowerCase().includes(lowerCaseQuery)
                : false;

            return conclusion || cms_no || created_at || change_required;
        });

        return filtCompleted;
    };

    const filteredRejected = (searchText) => {
        const filtRejected = filteredRejectedData(filterRejectData, searchText);
        setFilterRejectData(filtRejected);
        if (searchText) {
            setRejectedData(filtRejected);
        } else {
            loadCmsData();
        }
    };
    const filteredRejectedData = (data, search) => {
        const lowerCaseQuery = search.toLowerCase();

        const filtRejected = data.filter((cms) => {
            // Check if conclusion exists and is a string before calling toLowerCase()
            const conclusion = cms?.cmsForm?.conclusion
                ? cms?.cmsForm?.conclusion.toLowerCase().includes(lowerCaseQuery)
                : false;

            // Check if cms_no exists and is a string, or default to empty string
            const cms_no = cms?.cmsForm?.cms_no
                ? cms?.cmsForm?.cms_no.toLowerCase().includes(lowerCaseQuery)
                : false;

            // Ensure created_at is formatted correctly and exists
            const created_at = cms.created_at
                ? moment(cms.created_at).format("DD-MMM-YYYY").toLowerCase().includes(lowerCaseQuery)
                : false;

            // Check if change_required exists and is a string before calling toLowerCase()
            const change_required = cms?.cmsForm?.requestForm?.change_required
                ? cms?.cmsForm?.requestForm.change_required.toLowerCase().includes(lowerCaseQuery)
                : false;

            return conclusion || cms_no || created_at || change_required;
        });

        return filtRejected;
    };

    const openFilter = async (event) => {
        setCoordinates([event.pageX, event.pageY]);
        setAnchorEl(event.currentTarget);
    };

    const handleFilter = async () => {
        try {
            setError("");
            setLoading(true);
            handleCloseFilter();
            const filteredNewRecord = await getFilteredNewRecordData();
            if (filteredNewRecord.length === 0) {
                setError("No completed MOC");
            } else {
                setError("");
                setFilteredData(filteredNewRecord);
                setFilterCardData(filteredNewRecord);
                setSnackbarOpen(true);
            }
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const getFilteredNewRecordData = async () => {
        try {
            const from = new Date(fromDate);
            const to = new Date(toDate);
            to.setHours(23, 59, 59, 999); // Set the end of the toDate to the end of the day

            const finalFilteredData = cardData.filter((record) => {
                const recordCreatedAt = new Date(record.cmsForm.created_at);
                return recordCreatedAt >= from && recordCreatedAt <= to;
            });

            return finalFilteredData;
        } catch (error) {
            console.error("Error filtering records:", error);
            throw error;
        }
    };

    const handleResetFilter = () => {
        setConclusionAccordionExpand(false);
        setFilteredData(recordData);
        setFilterCardData(cardData);
        setFilterApprovalData(approvalData);
        handleCloseFilter();
        setFromDate("");
        setToDate("");
    };

    const handleFromDateChange = (event) => {
        setFromDate(event.target.value);
    };

    const handleToDateChange = (event) => {
        setToDate(event.target.value);
    };

    const handleCloseFilter = () => {
        setAnchorEl(null);
    };

    const closeFilter = () => {
        setAnchorEl(null);
    };

    const handleAccordionChange = (accordionType) => {
        if (accordionType === "conclusion") {
            setConclusionAccordionExpand(!conclusionAccordionExpand);
        } else if (accordionType === "date") {
            setDateAccordionExpand(!dateAccordionExpand);
        }
    };

    const approvalLabel = cardData.map(data => data.user_sgid);

    return (
        <>
            <div style={{ justifyContent: "center", padding: 10 }}>
                <DeleteDialog
                    open={openDeleteDialog}
                    onClose={closeDeleteConfirmation}
                    handleDelete={() => {
                        // deleteCMS(deleteMocId);
                        closeDeleteConfirmation(); // Close dialog after deletion
                    }}
                />
                <Tabs
                    value={selectedTab}
                    onChange={handleTabChange}
                    centered
                    indicatorColor="primary"
                    textColor="primary"
                    sx={{
                        // maxWidth: "400px",
                        "& .MuiTab-textColorPrimary.Mui-selected": {
                            color: "#B51849",
                        },
                        "& .MuiTabs-indicator": {
                            backgroundColor: "#B51849",
                            display: {
                                xs: "none",
                            },
                        },
                        "& .MuiTabs-flexContainer": {
                            display: {
                                xs: "block",
                            },
                            overflow: {
                                xs: "auto",
                            },
                        },
                    }}
                >
                    <Tab
                        label="All CMS"
                        sx={{
                            minWidth: "100px",
                            width: "auto",
                            textTransform: "capitalize",
                            fontSize: "16px",
                        }}
                    />
                    <Tab
                        label="In Progress"
                        sx={{
                            minWidth: "100px",
                            width: "auto",
                            textTransform: "capitalize",
                            fontSize: "16px",
                        }}
                    />
                    <Tab
                        label={approvalLabel.includes(user) ? "Awaiting Approval" : "Pending Approval"}
                        sx={{
                            minWidth: "100px",
                            width: "auto",
                            textTransform: "capitalize",
                            fontSize: "16px",
                        }}
                    />
                    <Tab
                        label="In Draft"
                        sx={{
                            minWidth: "100px",
                            width: "auto",
                            textTransform: "capitalize",
                            fontSize: "16px",
                        }}
                    />
                    <Tab
                        label="Approved"
                        sx={{
                            minWidth: "100px",
                            width: "auto",
                            textTransform: "capitalize",
                            fontSize: "16px",
                        }}
                    />
                    <Tab
                        label="Rejected"
                        sx={{
                            minWidth: "100px",
                            width: "auto",
                            textTransform: "capitalize",
                            fontSize: "16px",
                        }}
                    />
                </Tabs>

                <div style={{ position: "relative", display: "flex", width: "100%", marginTop: "10px", justifyContent: "space-between" }}>
                    <img src={SearchIcon} alt="Search Icon" style={{ position: "absolute", top: "50%", left: "10px", transform: "translateY(-50%)", cursor: "pointer", marginLeft: 12 }} />
                    <input type="text" placeholder="Search..." style={{ width: "65%", padding: "10px", paddingLeft: "40px", fontSize: "14px", borderRadius: "5px", border: "1px solid #8F908F", marginLeft: 12 }} value={searchText} onChange={handleSearchChange} />
                    {/* Filter Tooltip */}
                    <Tooltip onClick={openFilter} title="Filter">
                        <Box style={{ border: "1px solid #8F908F", borderRadius: 5, width: 60, height: 38, display: "flex", justifyContent: "center", alignItems: "center", cursor: 'pointer' }}>
                            <img src={FilterIcon} alt="Filter Icon" />
                        </Box>
                    </Tooltip>
                    <Tooltip title={"New MOC"} arrow>
                        <IconButton
                            edge="end"
                            color="inherit"
                            aria-label="add"
                            onClick={handleAddClick}
                        >
                            <img
                                src={PlusIcon}
                                alt="add icon"
                                style={{ width: "28px", height: "24px" }}
                            />
                        </IconButton>
                    </Tooltip>
                    {/* <Tooltip onClick={handleAPIClick} title="Filter">
          <div style={{ position: "absolute", top: "50%", right: "0px", transform: "translateY(-50%)", cursor: "pointer", zIndex: 1 }}>
            <div style={{ border: "1px solid #8F908F", borderRadius: 5, width: 40, height: 40, display: "flex", justifyContent: "center", alignItems: "center" }}>
              <img src={FilterIcon} alt="Filter Icon" />
            </div>
          </div>
        </Tooltip> */}
                    <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
                        <MuiAlert elevation={6} variant="filled" severity="success" onClose={() => setSnackbarOpen(false)}>
                            Filter Applied
                        </MuiAlert>
                    </Snackbar>
                    <Popover
                        id={filterId}
                        open={openFilterPopover}
                        anchorEl={anchorEl}
                        anchorReference="anchorPosition"
                        anchorPosition={{ top: coordinates[1], left: coordinates[0] }}
                        onClose={handleCloseFilter}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "left",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                        }}>
                        <div style={{ maxHeight: "500px", overflowY: "auto" }}>
                            {" "}
                            <Grid container style={{ borderBottom: "1px solid #282A2D", display: "flex", alignItems: "center" }}>
                                <Grid item xs={11}>
                                    <Box p={1} style={{ display: "flex", alignItems: "center", marginLeft: "10px" }}>
                                        <Button onClick={handleFilter} style={{ fontWeight: "bold", backgroundColor: "#00448b", color: "#ffffff", borderRadius: "5px", height: "35px" }}>
                                            Apply
                                        </Button>
                                        <Typography style={{ fontWeight: 800, color: "#FAAD14", lineHeight: "16px", marginLeft: "25px", cursor: "pointer", fontSize: "18px" }} onClick={handleResetFilter}>
                                            Reset
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={1}>
                                    <img
                                        onClick={closeFilter}
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter" || e.key === " ") {
                                                closeFilter();
                                            }
                                        }}
                                        style={{ cursor: "pointer", marginRight: "10px" }}
                                        src={CloseIcon}
                                        alt="Close"
                                        tabIndex="0" // Ensure the element is focusable
                                    />
                                </Grid>
                            </Grid>
                            <Card style={{ width: "300px", padding: "10px" }}>
                                <Container component="main" maxWidth="xs">
                                    {/* Date Range Options Accordion */}
                                    <Accordion
                                        expanded={dateAccordionExpand}
                                        onChange={() => handleAccordionChange("date")}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header"
                                        >
                                            <Typography style={{ fontSize: 16, fontWeight: "bold", color: "#000000" }}>
                                                CMS Date
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div>
                                                {/* From Date */}
                                                <TextField
                                                    id="fromDate"
                                                    label="From Date"
                                                    type="date"
                                                    value={fromDate}
                                                    onChange={handleFromDateChange}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    style={{ width: "220px" }}
                                                />
                                                {/* To Date */}
                                                <TextField
                                                    id="toDate"
                                                    label="To Date"
                                                    type="date"
                                                    value={toDate}
                                                    onChange={handleToDateChange}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    style={{ marginTop: "20px", width: "220px" }}
                                                />
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                </Container>
                            </Card>
                        </div>
                    </Popover>
                </div>

                <div style={{ height: "16px", padding: "10px 6px" }}>
                    {initialLoad ? (
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <CircularProgress />
                        </div>
                    ) :
                        (
                            <div>

                                {selectedTab === 0 && (
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", marginTop: 10 }}>
                                        {filterCardData
                                            .map((cms, index) =>
                                                (user === cms.user_sgid || cms?.cmsForm?.notification?.approver_sgid[0]?.includes(user)) ?
                                                    (<React.Fragment key={cms.id}>
                                                        <Card key={cms.id} style={{ width: "100%", position: 'relative', borderRadius: "8px", border: "1px solid #e2e2e2", background: "#FFF", marginBottom: "20px", boxShadow: "0px 2px 12px 0px rgba(0, 0, 0, 0.15)" }}>
                                                            <CardContent style={{ display: "flex", flexDirection: "column", padding: '0' }}>
                                                                <Box sx={{ display: 'flex', alignItems: 'start', justifyContent: cms?.cmsForm?.changeRequired === null || cms?.cmsForm?.changeRequired === undefined ? "none" : "space-between", padding: "12px" }}>
                                                                    {cms?.cmsForm?.cms_no ?
                                                                        <Box>
                                                                            <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                CMS NO.
                                                                            </Typography>
                                                                            <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                {cms?.cmsForm?.cms_no}
                                                                            </Typography>
                                                                        </Box> : <></>
                                                                    }
                                                                    <div style={{ display: "flex", alignItems: "right", gap: "10px" }}>
                                                                        <img
                                                                            src={menu}
                                                                            alt="menu"
                                                                            style={{ width: "28px", position: "absolute", right: 50, height: "20px", cursor: "pointer" }}
                                                                            onClick={(event) => handleMenuClick(event, cms.id)} />
                                                                    </div>
                                                                    {openedMenuId === cms.id && (
                                                                        <div
                                                                            ref={menuRef}
                                                                            style={{
                                                                                position: "absolute",
                                                                                top: 30,
                                                                                right: 20,
                                                                                width: 150,
                                                                                backgroundColor: "white",
                                                                                boxShadow: "none",
                                                                                borderRadius: 4,
                                                                                border: "1px solid #B51849",
                                                                                zIndex: 10,
                                                                            }}
                                                                        >
                                                                            {!showAlterNote ? (
                                                                                <div
                                                                                    onClick={() => handleMenuItemClick("edit", cms)}
                                                                                    style={{
                                                                                        padding: "10px",
                                                                                        cursor: "pointer",
                                                                                        display: "flex",
                                                                                        alignItems: "center"
                                                                                    }}>
                                                                                    <img
                                                                                        src={Edit}
                                                                                        alt="Edit Icon"
                                                                                        style={{ verticalAlign: "middle", marginRight: "5px", marginBottom: "5px", cursor: "pointer" }}
                                                                                    />
                                                                                    {"Review"}
                                                                                </div>
                                                                            ) : null}
                                                                            {showAlterNote ? (
                                                                                <div
                                                                                    onClick={() => handleMenuItemClick("alterationNote", cms)}
                                                                                    style={{
                                                                                        padding: "10px",
                                                                                        cursor: "pointer",
                                                                                        display: "flex",
                                                                                        alignItems: "center"
                                                                                    }}>
                                                                                    <img
                                                                                        src={Edit}
                                                                                        alt="Edit Icon"
                                                                                        style={{ verticalAlign: "middle", marginRight: "5px", marginBottom: "5px", cursor: "pointer" }}
                                                                                    />
                                                                                    {"Alternation Note"}
                                                                                </div>
                                                                            ) : null}
                                                                        </div>
                                                                    )}
                                                                </Box>
                                                                <Box sx={{ display: 'flex', alignItems: 'start', justifyContent: cms?.cmsForm?.changeRequired === null || cms?.cmsForm?.changeRequired === undefined ? "none" : "space-between", padding: "12px" }}>
                                                                    <div>
                                                                        <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                            Change Category
                                                                        </Typography>
                                                                        <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                            {cms?.cmsForm?.changeRequest?.change_request[0]?.change_category || ""}
                                                                        </Typography>
                                                                    </div>
                                                                    <div>
                                                                        <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                            {(() => {
                                                                                const { change_category, design_change, process_change } = cms?.cmsForm?.changeRequest?.change_request[0] || {};

                                                                                if (change_category === "Design Change") {
                                                                                    if (design_change === "Internal") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_int_customer ? "Customer" : ""}
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_int_customer || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    } else if (design_change === "External") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    Customer
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_ext_customer || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                } else if (change_category === "Process Change") {
                                                                                    if (process_change === "Internal") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_int_customer ? "Customer" : ""}
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_int_customer || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    } else if (process_change === "External") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    Customer
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_ext_customer || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                }

                                                                                return null; // If no condition matches, return null or handle accordingly
                                                                            })()}
                                                                        </Typography>
                                                                    </div>
                                                                </Box>
                                                                <Box sx={{ display: 'flex', alignItems: 'start', justifyContent: cms?.cmsForm?.changeRequired === null || cms?.cmsForm?.changeRequired === undefined ? "none" : "space-between", padding: "12px" }}>
                                                                    <div>
                                                                        <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                            {(() => {
                                                                                const { change_category, design_change, process_change } = cms?.cmsForm?.changeRequest?.change_request[0] || {};

                                                                                if (change_category === "Design Change") {
                                                                                    if (design_change === "Internal") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_int_model ? "Model" : ""}
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_int_model || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    } else if (design_change === "External") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    Model
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_ext_model || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                } else if (change_category === "Process Change") {
                                                                                    if (process_change === "Internal") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_int_model ? "Model" : ""}
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_int_model || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    } else if (process_change === "External") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    Model
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_ext_model || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                }

                                                                                return null; // If no condition matches, return null or handle accordingly
                                                                            })()}
                                                                        </Typography>
                                                                    </div>
                                                                    <div>
                                                                        <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                            {(() => {
                                                                                const { change_category, design_change, process_change } = cms?.cmsForm?.changeRequest?.change_request[0] || {};

                                                                                if (change_category === "Design Change") {
                                                                                    if (design_change === "Internal") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_int_glass_reference ? "Glass Reference" : ""}
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_int_glass_reference || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    } else if (design_change === "External") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    Glass Reference
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_ext_glass_reference || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                } else if (change_category === "Process Change") {
                                                                                    if (process_change === "Internal") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_int_glass_reference ? "Glass Reference" : ""}
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_int_glass_reference || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    } else if (process_change === "External") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    Glass Reference
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_ext_glass_reference || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                }

                                                                                return null; // If no condition matches, return null or handle accordingly
                                                                            })()}
                                                                        </Typography>
                                                                    </div>
                                                                </Box>
                                                                {cms?.cmsForm?.changeRequired?.change_required_remarks ?
                                                                    <Box sx={{ padding: '0 12px 12px' }}>
                                                                        <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                            Change Required
                                                                        </Typography>
                                                                        <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "0" }}>
                                                                            {cms?.cmsForm?.changeRequired?.change_required_remarks}
                                                                        </Typography>
                                                                    </Box> : null
                                                                }
                                                                <Box style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', width: "100%", cursor: "pointer", borderTop: '1px solid #f4f4f4', padding: '4px 12px' }}>
                                                                    <Box style={{ display: "flex" }}>
                                                                        <img
                                                                            src={TimeLine}
                                                                            alt="Timeline"
                                                                            style={{ marginRight: "10px" }}
                                                                            onClick={(event) => toggleAccordionTimeline(event, cms.cms_id)}
                                                                        />
                                                                        <Typography
                                                                            style={{
                                                                                color: "#616161",
                                                                                fontFamily: "inherit",
                                                                                fontSize: "14px",
                                                                                fontWeight: 500,
                                                                            }}
                                                                        >
                                                                            {moment(cms.updated_at).format("DD-MMM-YYYY")}
                                                                        </Typography>
                                                                    </Box>

                                                                    <AvatarGroup>

                                                                        {cms?.cmsForm?.created_by ?
                                                                            <div style={{ position: "relative" }}>
                                                                                <Tooltip title={cms?.cmsForm?.created_by}>
                                                                                    <Avatar style={{ width: "30px", height: "30px", fontSize: "14px", marginBottom: "10px", marginLeft: "50px" }}>
                                                                                        {`${cms?.cmsForm?.created_by.split(" ").pop().charAt(0)}${cms?.cmsForm?.created_by.charAt(0)}`}
                                                                                    </Avatar>
                                                                                </Tooltip>
                                                                            </div> : null
                                                                        }

                                                                    </AvatarGroup>
                                                                </Box>
                                                                {expandedCardId === cms.cms_id && (
                                                                    <Timeline sx={{
                                                                        [`& .${timelineItemClasses.root}:before`]: {
                                                                            flex: 0,
                                                                            padding: 0,
                                                                        },
                                                                    }}>
                                                                        {timeline.map((action, index) => (
                                                                            <TimelineItem key={index}>
                                                                                <TimelineSeparator>
                                                                                    <TimelineDot color="primary" />
                                                                                    {index !== timeline.length - 1 && <TimelineConnector />}
                                                                                </TimelineSeparator>
                                                                                <TimelineContent>
                                                                                    <Typography style={{ color: '#616161', fontFamily: 'inherit', fontSize: '14px', fontWeight: 500 }}>
                                                                                        {action.status === "conditionallyapproved" ? "conditionally approved" + " " + "by" + " " + action.user_approval_name :
                                                                                            action.status === "submittedbackforqueries" ? "submitted back for queries" + " " + "by" + " " + action.user_approval_name :
                                                                                                action.status + " " + "by" + " " + action.user_approval_name}
                                                                                    </Typography>
                                                                                    <Typography style={{ color: '#616161', fontFamily: 'inherit', fontSize: '14px', fontWeight: 500 }}>
                                                                                        {(new Date(action.updated_at)).toISOString().split('T')[0]}
                                                                                    </Typography>
                                                                                </TimelineContent>
                                                                            </TimelineItem>
                                                                        ))}
                                                                    </Timeline>
                                                                )}
                                                            </CardContent>
                                                        </Card>
                                                    </React.Fragment>) : null
                                            )}
                                        {filterCardData.length === 0 && <p>No records found.</p>}
                                    </div>
                                )}

                                {selectedTab === 1 && (
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", marginTop: 10 }}>
                                        {filterProgressData
                                            .map((cms, index) =>
                                                (user === cms.user_sgid || cms?.cmsForm?.notification?.approver_sgid[0]?.includes(user)) ?
                                                    (<React.Fragment key={cms.id}>
                                                        <Card key={cms.id} style={{ width: "100%", position: 'relative', borderRadius: "8px", border: "1px solid #e2e2e2", background: "#FFF", marginBottom: "20px", boxShadow: "0px 2px 12px 0px rgba(0, 0, 0, 0.15)" }}>
                                                            <CardContent style={{ display: "flex", flexDirection: "column", padding: '0' }}>
                                                                <Box sx={{ display: 'flex', alignItems: 'start', justifyContent: cms?.cmsForm?.changeRequired === null || cms?.cmsForm?.changeRequired === undefined ? "none" : "space-between", padding: "12px" }}>
                                                                    {cms?.cmsForm?.cms_no ?
                                                                        <Box>
                                                                            <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                CMS NO.
                                                                            </Typography>
                                                                            <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                {cms?.cmsForm?.cms_no}
                                                                            </Typography>
                                                                        </Box> : <></>
                                                                    }
                                                                    <div style={{ display: "flex", alignItems: "right", gap: "10px" }}>
                                                                        <img
                                                                            src={menu}
                                                                            alt="menu"
                                                                            style={{ width: "28px", position: "absolute", right: 50, height: "20px", cursor: "pointer" }}
                                                                            onClick={(event) => handleMenuClick(event, cms.id)} />
                                                                    </div>
                                                                    {showMenu && openedMenuId === cms.id && (
                                                                        <div
                                                                            ref={menuRef}
                                                                            style={{
                                                                                position: "absolute",
                                                                                top: 30,
                                                                                right: 20,
                                                                                width: 150,
                                                                                backgroundColor: "white",
                                                                                boxShadow: "none",
                                                                                borderRadius: 4,
                                                                                border: "1px solid #B51849",
                                                                                zIndex: 10,
                                                                            }}
                                                                        >
                                                                            {!showAlterNote ? (
                                                                                <div
                                                                                    onClick={() => handleMenuItemClick("edit", cms)}
                                                                                    style={{
                                                                                        padding: "10px",
                                                                                        cursor: "pointer",
                                                                                        display: "flex",
                                                                                        alignItems: "center"
                                                                                    }}>
                                                                                    <img
                                                                                        src={Edit}
                                                                                        alt="Edit Icon"
                                                                                        style={{ verticalAlign: "middle", marginRight: "5px", marginBottom: "5px", cursor: "pointer" }}
                                                                                    />
                                                                                    {"Review"}
                                                                                </div>
                                                                            ) : null}
                                                                            {showAlterNote ? (
                                                                                <div
                                                                                    onClick={() => handleMenuItemClick("alterationNote", cms)}
                                                                                    style={{
                                                                                        padding: "10px",
                                                                                        cursor: "pointer",
                                                                                        display: "flex",
                                                                                        alignItems: "center"
                                                                                    }}>
                                                                                    <img
                                                                                        src={Edit}
                                                                                        alt="Edit Icon"
                                                                                        style={{ verticalAlign: "middle", marginRight: "5px", marginBottom: "5px", cursor: "pointer" }}
                                                                                    />
                                                                                    {"Alternation Note"}
                                                                                </div>
                                                                            ) : null}
                                                                        </div>
                                                                    )}
                                                                </Box>
                                                                <Box sx={{ display: 'flex', alignItems: 'start', justifyContent: cms?.cmsForm?.changeRequired === null || cms?.cmsForm?.changeRequired === undefined ? "none" : "space-between", padding: "12px" }}>
                                                                    <div>
                                                                        <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                            Change Category
                                                                        </Typography>
                                                                        <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                            {cms?.cmsForm?.changeRequest?.change_request[0]?.change_category || ""}
                                                                        </Typography>
                                                                    </div>
                                                                    <div>
                                                                        <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                            {(() => {
                                                                                const { change_category, design_change, process_change } = cms?.cmsForm?.changeRequest?.change_request[0] || {};

                                                                                if (change_category === "Design Change") {
                                                                                    if (design_change === "Internal") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_int_customer ? "Customer" : ""}
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_int_customer || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    } else if (design_change === "External") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    Customer
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_ext_customer || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                } else if (change_category === "Process Change") {
                                                                                    if (process_change === "Internal") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_int_customer ? "Customer" : ""}
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_int_customer || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    } else if (process_change === "External") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    Customer
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_ext_customer || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                }

                                                                                return null; // If no condition matches, return null or handle accordingly
                                                                            })()}
                                                                        </Typography>
                                                                    </div>
                                                                </Box>
                                                                <Box sx={{ display: 'flex', alignItems: 'start', justifyContent: cms?.cmsForm?.changeRequired === null || cms?.cmsForm?.changeRequired === undefined ? "none" : "space-between", padding: "12px" }}>
                                                                    <div>
                                                                        <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                            {(() => {
                                                                                const { change_category, design_change, process_change } = cms?.cmsForm?.changeRequest?.change_request[0] || {};

                                                                                if (change_category === "Design Change") {
                                                                                    if (design_change === "Internal") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_int_model ? "Model" : ""}
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_int_model || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    } else if (design_change === "External") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    Model
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_ext_model || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                } else if (change_category === "Process Change") {
                                                                                    if (process_change === "Internal") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_int_model ? "Model" : ""}
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_int_model || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    } else if (process_change === "External") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    Model
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_ext_model || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                }

                                                                                return null; // If no condition matches, return null or handle accordingly
                                                                            })()}
                                                                        </Typography>
                                                                    </div>
                                                                    <div>
                                                                        <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                            {(() => {
                                                                                const { change_category, design_change, process_change } = cms?.cmsForm?.changeRequest?.change_request[0] || {};

                                                                                if (change_category === "Design Change") {
                                                                                    if (design_change === "Internal") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_int_glass_reference ? "Glass Reference" : ""}
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_int_glass_reference || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    } else if (design_change === "External") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    Glass Reference
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_ext_glass_reference || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                } else if (change_category === "Process Change") {
                                                                                    if (process_change === "Internal") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_int_glass_reference ? "Glass Reference" : ""}
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_int_glass_reference || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    } else if (process_change === "External") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    Glass Reference
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_ext_glass_reference || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                }

                                                                                return null; // If no condition matches, return null or handle accordingly
                                                                            })()}
                                                                        </Typography>
                                                                    </div>
                                                                </Box>
                                                                {cms?.cmsForm?.changeRequired?.change_required_remarks ?
                                                                    <Box sx={{ padding: '0 12px 12px' }}>
                                                                        <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                            Change Required
                                                                        </Typography>
                                                                        <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "0" }}>
                                                                            {cms?.cmsForm?.changeRequired?.change_required_remarks}
                                                                        </Typography>
                                                                    </Box> : null
                                                                }
                                                                <Box style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', width: "100%", cursor: "pointer", borderTop: '1px solid #f4f4f4', padding: '4px 12px' }}>
                                                                    <Box style={{ display: "flex" }}>
                                                                        <img
                                                                            src={TimeLine}
                                                                            alt="Timeline"
                                                                            style={{ marginRight: "10px" }}
                                                                            onClick={(event) => toggleAccordionTimeline(event, cms.cms_id)}
                                                                        />
                                                                        <Typography
                                                                            style={{
                                                                                color: "#616161",
                                                                                fontFamily: "inherit",
                                                                                fontSize: "14px",
                                                                                fontWeight: 500,
                                                                            }}
                                                                        >
                                                                            {moment(cms.updated_at).format("DD-MMM-YYYY")}
                                                                        </Typography>
                                                                    </Box>

                                                                    <AvatarGroup>

                                                                        {cms?.cmsForm?.created_by ?
                                                                            <div style={{ position: "relative" }}>
                                                                                <Tooltip title={cms?.cmsForm?.created_by}>
                                                                                    <Avatar style={{ width: "30px", height: "30px", fontSize: "14px", marginBottom: "10px", marginLeft: "50px" }}>
                                                                                        {`${cms?.cmsForm?.created_by.split(" ").pop().charAt(0)}${cms?.cmsForm?.created_by.charAt(0)}`}
                                                                                    </Avatar>
                                                                                </Tooltip>
                                                                            </div> : null
                                                                        }

                                                                    </AvatarGroup>
                                                                </Box>
                                                                {expandedCardId === cms.cms_id && (
                                                                    <Timeline sx={{
                                                                        [`& .${timelineItemClasses.root}:before`]: {
                                                                            flex: 0,
                                                                            padding: 0,
                                                                        },
                                                                    }}>
                                                                        {timeline.map((action, index) => (
                                                                            <TimelineItem key={index}>
                                                                                <TimelineSeparator>
                                                                                    <TimelineDot color="primary" />
                                                                                    {index !== timeline.length - 1 && <TimelineConnector />}
                                                                                </TimelineSeparator>
                                                                                <TimelineContent>
                                                                                    <Typography style={{ color: '#616161', fontFamily: 'inherit', fontSize: '14px', fontWeight: 500 }}>
                                                                                        {action.status === "conditionallyapproved" ? "conditionally approved" + " " + "by" + " " + action.user_approval_name :
                                                                                            action.status === "submittedbackforqueries" ? "submitted back for queries" + " " + "by" + " " + action.user_approval_name :
                                                                                                action.status + " " + "by" + " " + action.user_approval_name}
                                                                                    </Typography>
                                                                                    <Typography style={{ color: '#616161', fontFamily: 'inherit', fontSize: '14px', fontWeight: 500 }}>
                                                                                        {(new Date(action.updated_at)).toISOString().split('T')[0]}
                                                                                    </Typography>
                                                                                </TimelineContent>
                                                                            </TimelineItem>
                                                                        ))}
                                                                    </Timeline>
                                                                )}
                                                            </CardContent>
                                                        </Card>
                                                    </React.Fragment>) : null
                                            )}
                                        {filterApprovalData.length === 0 && <p>No records found.</p>}
                                    </div>
                                )}

                                {selectedTab === 2 && (
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", marginTop: 10 }}>
                                        {filterApprovalData
                                            .map((cms, index) =>
                                                (user === cms.user_sgid || cms?.cmsForm?.notification?.approver_sgid[0]?.includes(user)) ?
                                                    (<React.Fragment key={cms.id}>
                                                        <Card key={cms.id} style={{ width: "100%", position: 'relative', borderRadius: "8px", border: "1px solid #e2e2e2", background: "#FFF", marginBottom: "20px", boxShadow: "0px 2px 12px 0px rgba(0, 0, 0, 0.15)" }}>
                                                            <CardContent style={{ display: "flex", flexDirection: "column", padding: '0' }}>
                                                                <Box sx={{ display: 'flex', alignItems: 'start', justifyContent: cms?.cmsForm?.changeRequired === null || cms?.cmsForm?.changeRequired === undefined ? "none" : "space-between", padding: "12px" }}>
                                                                    {cms?.cmsForm?.cms_no ?
                                                                        <Box>
                                                                            <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                CMS NO.
                                                                            </Typography>
                                                                            <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                {cms?.cmsForm?.cms_no}
                                                                            </Typography>
                                                                        </Box> : <></>
                                                                    }
                                                                    <div style={{ display: "flex", alignItems: "right", gap: "10px" }}>
                                                                        <img
                                                                            src={menu}
                                                                            alt="menu"
                                                                            style={{ width: "28px", position: "absolute", right: 50, height: "20px", cursor: "pointer" }}
                                                                            onClick={(event) => handleMenuClick(event, cms.id)} />
                                                                    </div>
                                                                    {showMenu && openedMenuId === cms.id && (
                                                                        <div
                                                                            ref={menuRef}
                                                                            style={{
                                                                                position: "absolute",
                                                                                top: 30,
                                                                                right: 20,
                                                                                width: 150,
                                                                                backgroundColor: "white",
                                                                                boxShadow: "none",
                                                                                borderRadius: 4,
                                                                                border: "1px solid #B51849",
                                                                                zIndex: 10,
                                                                            }}
                                                                        >
                                                                            {!showAlterNote ? (
                                                                                <div
                                                                                    onClick={() => handleMenuItemClick("edit", cms)}
                                                                                    style={{
                                                                                        padding: "10px",
                                                                                        cursor: "pointer",
                                                                                        display: "flex",
                                                                                        alignItems: "center"
                                                                                    }}>
                                                                                    <img
                                                                                        src={Edit}
                                                                                        alt="Edit Icon"
                                                                                        style={{ verticalAlign: "middle", marginRight: "5px", marginBottom: "5px", cursor: "pointer" }}
                                                                                    />
                                                                                    {"Review"}
                                                                                </div>
                                                                            ) : null}
                                                                            {showAlterNote ? (
                                                                                <div
                                                                                    onClick={() => handleMenuItemClick("alterationNote", cms)}
                                                                                    style={{
                                                                                        padding: "10px",
                                                                                        cursor: "pointer",
                                                                                        display: "flex",
                                                                                        alignItems: "center"
                                                                                    }}>
                                                                                    <img
                                                                                        src={Edit}
                                                                                        alt="Edit Icon"
                                                                                        style={{ verticalAlign: "middle", marginRight: "5px", marginBottom: "5px", cursor: "pointer" }}
                                                                                    />
                                                                                    {"Alternation Note"}
                                                                                </div>
                                                                            ) : null}
                                                                        </div>
                                                                    )}
                                                                </Box>
                                                                <Box sx={{ display: 'flex', alignItems: 'start', justifyContent: cms?.cmsForm?.changeRequired === null || cms?.cmsForm?.changeRequired === undefined ? "none" : "space-between", padding: "12px" }}>
                                                                    <div>
                                                                        <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                            Change Category
                                                                        </Typography>
                                                                        <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                            {cms?.cmsForm?.changeRequest?.change_request[0]?.change_category || ""}
                                                                        </Typography>
                                                                    </div>
                                                                    <div>
                                                                        <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                            {(() => {
                                                                                const { change_category, design_change, process_change } = cms?.cmsForm?.changeRequest?.change_request[0] || {};

                                                                                if (change_category === "Design Change") {
                                                                                    if (design_change === "Internal") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_int_customer ? "Customer" : ""}
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_int_customer || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    } else if (design_change === "External") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    Customer
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_ext_customer || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                } else if (change_category === "Process Change") {
                                                                                    if (process_change === "Internal") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_int_customer ? "Customer" : ""}
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_int_customer || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    } else if (process_change === "External") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    Customer
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_ext_customer || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                }

                                                                                return null; // If no condition matches, return null or handle accordingly
                                                                            })()}
                                                                        </Typography>
                                                                    </div>
                                                                </Box>
                                                                <Box sx={{ display: 'flex', alignItems: 'start', justifyContent: cms?.cmsForm?.changeRequired === null || cms?.cmsForm?.changeRequired === undefined ? "none" : "space-between", padding: "12px" }}>

                                                                    <div>
                                                                        <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                            {(() => {
                                                                                const { change_category, design_change, process_change } = cms?.cmsForm?.changeRequest?.change_request[0] || {};

                                                                                if (change_category === "Design Change") {
                                                                                    if (design_change === "Internal") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_int_model ? "Model" : ""}
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_int_model || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    } else if (design_change === "External") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    Model
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_ext_model || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                } else if (change_category === "Process Change") {
                                                                                    if (process_change === "Internal") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_int_model ? "Model" : ""}
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_int_model || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    } else if (process_change === "External") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    Model
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_ext_model || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                }

                                                                                return null; // If no condition matches, return null or handle accordingly
                                                                            })()}
                                                                        </Typography>
                                                                    </div>
                                                                    <div>
                                                                        <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                            {(() => {
                                                                                const { change_category, design_change, process_change } = cms?.cmsForm?.changeRequest?.change_request[0] || {};

                                                                                if (change_category === "Design Change") {
                                                                                    if (design_change === "Internal") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_int_glass_reference ? "Glass Reference" : ""}
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_int_glass_reference || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    } else if (design_change === "External") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    Glass Reference
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_ext_glass_reference || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                } else if (change_category === "Process Change") {
                                                                                    if (process_change === "Internal") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_int_glass_reference ? "Glass Reference" : ""}
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_int_glass_reference || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    } else if (process_change === "External") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    Glass Reference
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_ext_glass_reference || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                }

                                                                                return null; // If no condition matches, return null or handle accordingly
                                                                            })()}
                                                                        </Typography>
                                                                    </div>
                                                                </Box>
                                                                {cms?.cmsForm?.changeRequired?.change_required_remarks ?
                                                                    <Box sx={{ padding: '0 12px 12px' }}>
                                                                        <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                            Change Required
                                                                        </Typography>
                                                                        <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "0" }}>
                                                                            {cms?.cmsForm?.changeRequired?.change_required_remarks}
                                                                        </Typography>
                                                                    </Box> : null
                                                                }
                                                                <Box style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', width: "100%", cursor: "pointer", borderTop: '1px solid #f4f4f4', padding: '4px 12px' }}>
                                                                    <Box style={{ display: "flex" }}>
                                                                        <img
                                                                            src={TimeLine}
                                                                            alt="Timeline"
                                                                            style={{ marginRight: "10px" }}
                                                                            onClick={(event) => toggleAccordionTimeline(event, cms.cms_id)}
                                                                        />
                                                                        <Typography
                                                                            style={{
                                                                                color: "#616161",
                                                                                fontFamily: "inherit",
                                                                                fontSize: "14px",
                                                                                fontWeight: 500,
                                                                            }}
                                                                        >
                                                                            {moment(cms.updated_at).format("DD-MMM-YYYY")}
                                                                        </Typography>
                                                                    </Box>

                                                                    <AvatarGroup>

                                                                        {cms?.cmsForm?.created_by ?
                                                                            <div style={{ position: "relative" }}>
                                                                                <Tooltip title={cms?.cmsForm?.created_by}>
                                                                                    <Avatar style={{ width: "30px", height: "30px", fontSize: "14px", marginBottom: "10px", marginLeft: "50px" }}>
                                                                                        {`${cms?.cmsForm?.created_by.split(" ").pop().charAt(0)}${cms?.cmsForm?.created_by.charAt(0)}`}
                                                                                    </Avatar>
                                                                                </Tooltip>
                                                                            </div> : null
                                                                        }

                                                                    </AvatarGroup>
                                                                </Box>
                                                                {expandedCardId === cms.cms_id && (
                                                                    <Timeline sx={{
                                                                        [`& .${timelineItemClasses.root}:before`]: {
                                                                            flex: 0,
                                                                            padding: 0,
                                                                        },
                                                                    }}>
                                                                        {timeline.map((action, index) => (
                                                                            <TimelineItem key={index}>
                                                                                <TimelineSeparator>
                                                                                    <TimelineDot color="primary" />
                                                                                    {index !== timeline.length - 1 && <TimelineConnector />}
                                                                                </TimelineSeparator>
                                                                                <TimelineContent>
                                                                                    <Typography style={{ color: '#616161', fontFamily: 'inherit', fontSize: '14px', fontWeight: 500 }}>
                                                                                        {action.status === "conditionallyapproved" ? "conditionally approved" + " " + "by" + " " + action.user_approval_name :
                                                                                            action.status === "submittedbackforqueries" ? "submitted back for queries" + " " + "by" + " " + action.user_approval_name :
                                                                                                action.status + " " + "by" + " " + action.user_approval_name}
                                                                                    </Typography>
                                                                                    <Typography style={{ color: '#616161', fontFamily: 'inherit', fontSize: '14px', fontWeight: 500 }}>
                                                                                        {(new Date(action.updated_at)).toISOString().split('T')[0]}
                                                                                    </Typography>
                                                                                </TimelineContent>
                                                                            </TimelineItem>
                                                                        ))}
                                                                    </Timeline>
                                                                )}
                                                            </CardContent>
                                                        </Card>
                                                    </React.Fragment>) : null
                                            )}
                                        {filterProgressData.length === 0 && <p>No records found.</p>}
                                    </div>
                                )}

                                {selectedTab === 3 && (
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", marginTop: 10 }}>
                                        {filterDraftData
                                            .map((cms, index) =>
                                                (user === cms.user_sgid) ? (
                                                    <React.Fragment key={cms.id}>
                                                        <Card key={cms.id} style={{ width: "100%", position: 'relative', borderRadius: "8px", border: "1px solid #e2e2e2", background: "#FFF", marginBottom: "20px", boxShadow: "0px 2px 12px 0px rgba(0, 0, 0, 0.15)" }}>
                                                            <CardContent style={{ display: "flex", flexDirection: "column", padding: '0' }}>
                                                                <Box sx={{ display: 'flex', alignItems: 'start', justifyContent: cms?.cmsForm?.changeRequired === null || cms?.cmsForm?.changeRequired === undefined ? "none" : "space-between", padding: "12px" }}>
                                                                    {cms?.cmsForm?.cms_no ?
                                                                        <Box>
                                                                            <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                CMS NO.
                                                                            </Typography>
                                                                            <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                {cms?.cmsForm?.cms_no}
                                                                            </Typography>
                                                                        </Box> : <></>
                                                                    }
                                                                    <div style={{ display: "flex", alignItems: "right", gap: "10px" }}>
                                                                        <img
                                                                            src={menu}
                                                                            alt="menu"
                                                                            style={{ width: "28px", position: "absolute", right: 50, height: "20px", cursor: "pointer" }}
                                                                            onClick={(event) => handleMenuClick(event, cms.id)} />
                                                                    </div>
                                                                    {showMenu && (
                                                                        <div
                                                                            ref={menuRef}
                                                                            style={{
                                                                                position: "absolute",
                                                                                top: menuPosition.top,
                                                                                left: menuPosition.left,
                                                                                width: 150,
                                                                                backgroundColor: "white",
                                                                                boxShadow: "none",
                                                                                borderRadius: 4,
                                                                                border: "1px solid #B51849",
                                                                                zIndex: 10,
                                                                            }}
                                                                        >
                                                                            <div
                                                                                onClick={() => handleMenuItemClick("edit", cms)}
                                                                                style={{
                                                                                    padding: "10px",
                                                                                    cursor: "pointer",
                                                                                    display: "flex",
                                                                                    alignItems: "center"
                                                                                }}>
                                                                                <img
                                                                                    src={Edit}
                                                                                    alt="Edit Icon"
                                                                                    style={{ verticalAlign: "middle", marginRight: "5px", marginBottom: "5px", cursor: "pointer" }}
                                                                                />
                                                                                {"Review"}
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </Box>
                                                                <Box sx={{ display: 'flex', alignItems: 'start', justifyContent: cms?.cmsForm?.changeRequired === null || cms?.cmsForm?.changeRequired === undefined ? "none" : "space-between", padding: "12px" }}>
                                                                    <div>
                                                                        <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                            Change Category
                                                                        </Typography>
                                                                        <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                            {cms?.cmsForm?.changeRequest?.change_request[0]?.change_category || ""}
                                                                        </Typography>
                                                                    </div>
                                                                </Box>
                                                                <Box sx={{ display: 'flex', alignItems: 'start', justifyContent: cms?.cmsForm?.changeRequired === null || cms?.cmsForm?.changeRequired === undefined ? "none" : "space-between", padding: "12px" }}>
                                                                    <div>
                                                                        <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                            {(() => {
                                                                                const { change_category, design_change, process_change } = cms?.cmsForm?.changeRequest?.change_request[0] || {};

                                                                                if (change_category === "Design Change") {
                                                                                    if (design_change === "Internal") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_int_customer ? "Customer" : ""}
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_int_customer || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    } else if (design_change === "External") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    Customer
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_ext_customer || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                } else if (change_category === "Process Change") {
                                                                                    if (process_change === "Internal") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_int_customer ? "Customer" : ""}
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_int_customer || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    } else if (process_change === "External") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    Customer
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_ext_customer || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                }

                                                                                return null; // If no condition matches, return null or handle accordingly
                                                                            })()}
                                                                        </Typography>
                                                                    </div>
                                                                </Box>
                                                                {cms?.cmsForm?.changeRequired?.change_required_remarks ?
                                                                    <Box sx={{ padding: '2px' }}>
                                                                        <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                            Change Required
                                                                        </Typography>
                                                                        <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "0" }}>
                                                                            {cms?.cmsForm?.changeRequired?.change_required_remarks}
                                                                        </Typography>
                                                                    </Box> : null
                                                                }
                                                                <Box style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', width: "100%", cursor: "pointer", borderTop: '1px solid #f4f4f4', padding: '4px 12px' }}>
                                                                    <Box style={{ display: "flex" }}>
                                                                        <img
                                                                            src={TimeLine}
                                                                            alt="Timeline"
                                                                            style={{ marginRight: "10px" }}
                                                                            onClick={(event) => toggleAccordionTimeline(event, cms.cms_id)}
                                                                        />
                                                                        <Typography
                                                                            style={{
                                                                                color: "#616161",
                                                                                fontFamily: "inherit",
                                                                                fontSize: "14px",
                                                                                fontWeight: 500,
                                                                            }}
                                                                        >
                                                                            {moment(cms.updated_at).format("DD-MMM-YYYY")}
                                                                        </Typography>
                                                                    </Box>

                                                                    <AvatarGroup>

                                                                        {cms?.cmsForm?.created_by ?
                                                                            <div style={{ position: "relative" }}>
                                                                                <Tooltip title={cms?.cmsForm?.created_by}>
                                                                                    <Avatar style={{ width: "30px", height: "30px", fontSize: "14px", marginBottom: "10px", marginLeft: "50px" }}>
                                                                                        {`${cms?.cmsForm?.created_by.split(" ").pop().charAt(0)}${cms?.cmsForm?.created_by.charAt(0)}`}
                                                                                    </Avatar>
                                                                                </Tooltip>
                                                                            </div> : null
                                                                        }

                                                                    </AvatarGroup>
                                                                </Box>
                                                                {expandedCardId === cms.cms_id && (
                                                                    <Timeline sx={{
                                                                        [`& .${timelineItemClasses.root}:before`]: {
                                                                            flex: 0,
                                                                            padding: 0,
                                                                        },
                                                                    }}>
                                                                        {timeline.map((action, index) => (
                                                                            <TimelineItem key={index}>
                                                                                <TimelineSeparator>
                                                                                    <TimelineDot color="primary" />
                                                                                    {index !== timeline.length - 1 && <TimelineConnector />}
                                                                                </TimelineSeparator>
                                                                                <TimelineContent>
                                                                                    <Typography style={{ color: '#616161', fontFamily: 'inherit', fontSize: '14px', fontWeight: 500 }}>
                                                                                        {action.status === "conditionallyapproved" ? "conditionally approved" + " " + "by" + " " + action.user_approval_name :
                                                                                            action.status === "submittedbackforqueries" ? "submitted back for queries" + " " + "by" + " " + action.user_approval_name :
                                                                                                action.status + " " + "by" + " " + action.user_approval_name}
                                                                                    </Typography>
                                                                                    <Typography style={{ color: '#616161', fontFamily: 'inherit', fontSize: '14px', fontWeight: 500 }}>
                                                                                        {(new Date(action.updated_at)).toISOString().split('T')[0]}
                                                                                    </Typography>
                                                                                </TimelineContent>
                                                                            </TimelineItem>
                                                                        ))}
                                                                    </Timeline>
                                                                )}
                                                            </CardContent>
                                                        </Card>
                                                    </React.Fragment>) : null
                                            )}
                                        {filterDraftData.length === 0 && <p>No records found.</p>}
                                    </div>
                                )}

                                {selectedTab === 4 && (
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", marginTop: 10 }}>
                                        {filterCompleteData
                                            .map((cms, index) =>
                                                (user === cms.user_sgid || cms?.cmsForm?.notification?.approver_sgid[0]?.includes(user)) ?
                                                    (<React.Fragment key={cms.id}>
                                                        <Card key={cms.id} style={{ width: "100%", position: 'relative', borderRadius: "8px", border: "1px solid #e2e2e2", background: "#FFF", marginBottom: "20px", boxShadow: "0px 2px 12px 0px rgba(0, 0, 0, 0.15)" }}>
                                                            <CardContent style={{ display: "flex", flexDirection: "column", padding: '0' }}>
                                                                <Box sx={{ display: 'flex', alignItems: 'start', justifyContent: cms?.cmsForm?.changeRequired === null || cms?.cmsForm?.changeRequired === undefined ? "none" : "space-between", padding: "12px" }}>
                                                                    {cms?.cmsForm?.cms_no ?
                                                                        <Box>
                                                                            <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                CMS NO.
                                                                            </Typography>
                                                                            <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                {cms?.cmsForm?.cms_no}
                                                                            </Typography>
                                                                        </Box> : <></>
                                                                    }
                                                                    <div style={{ display: "flex", alignItems: "right", gap: "10px" }}>
                                                                        <img
                                                                            src={menu}
                                                                            alt="menu"
                                                                            style={{ width: "28px", position: "absolute", right: 50, height: "20px", cursor: "pointer" }}
                                                                            onClick={(event) => handleMenuClick(event, cms.id)} />
                                                                    </div>
                                                                    {showMenu && openedMenuId === cms.id && (
                                                                        <div
                                                                            ref={menuRef}
                                                                            style={{
                                                                                position: "absolute",
                                                                                top: 30,
                                                                                right: 20,
                                                                                width: 150,
                                                                                backgroundColor: "white",
                                                                                boxShadow: "none",
                                                                                borderRadius: 4,
                                                                                border: "1px solid #B51849",
                                                                                zIndex: 10,
                                                                            }}
                                                                        >
                                                                            <div
                                                                                onClick={() => handleMenuItemClick("edit", cms)}
                                                                                style={{
                                                                                    padding: "10px",
                                                                                    cursor: "pointer",
                                                                                    display: "flex",
                                                                                    alignItems: "center"
                                                                                }}>
                                                                                <img
                                                                                    src={Edit}
                                                                                    alt="Edit Icon"
                                                                                    style={{ verticalAlign: "middle", marginRight: "5px", marginBottom: "5px", cursor: "pointer" }}
                                                                                />
                                                                                {"Review"}
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </Box>  <Box sx={{ display: 'flex', alignItems: 'start', justifyContent: cms?.cmsForm?.changeRequired === null || cms?.cmsForm?.changeRequired === undefined ? "none" : "space-between", padding: "12px" }}>
                                                                    <div>
                                                                        <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                            Change Category
                                                                        </Typography>
                                                                        <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                            {cms?.cmsForm?.changeRequest?.change_request[0]?.change_category || ""}
                                                                        </Typography>
                                                                    </div>
                                                                    <div>
                                                                        <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                            {(() => {
                                                                                const { change_category, design_change, process_change } = cms?.cmsForm?.changeRequest?.change_request[0] || {};

                                                                                if (change_category === "Design Change") {
                                                                                    if (design_change === "Internal") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_int_customer ? "Customer" : ""}
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_int_customer || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    } else if (design_change === "External") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    Customer
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_ext_customer || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                } else if (change_category === "Process Change") {
                                                                                    if (process_change === "Internal") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_int_customer ? "Customer" : ""}
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_int_customer || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    } else if (process_change === "External") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    Customer
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_ext_customer || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                }

                                                                                return null; // If no condition matches, return null or handle accordingly
                                                                            })()}
                                                                        </Typography>
                                                                    </div>
                                                                </Box>
                                                                <Box sx={{ display: 'flex', alignItems: 'start', justifyContent: cms?.cmsForm?.changeRequired === null || cms?.cmsForm?.changeRequired === undefined ? "none" : "space-between", padding: "12px" }}>

                                                                    <div>
                                                                        <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                            {(() => {
                                                                                const { change_category, design_change, process_change } = cms?.cmsForm?.changeRequest?.change_request[0] || {};

                                                                                if (change_category === "Design Change") {
                                                                                    if (design_change === "Internal") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_int_model ? "Model" : ""}
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_int_model || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    } else if (design_change === "External") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    Model
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_ext_model || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                } else if (change_category === "Process Change") {
                                                                                    if (process_change === "Internal") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_int_model ? "Model" : ""}
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_int_model || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    } else if (process_change === "External") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    Model
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_ext_model || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                }

                                                                                return null; // If no condition matches, return null or handle accordingly
                                                                            })()}
                                                                        </Typography>
                                                                    </div>
                                                                    <div>
                                                                        <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                            {(() => {
                                                                                const { change_category, design_change, process_change } = cms?.cmsForm?.changeRequest?.change_request[0] || {};

                                                                                if (change_category === "Design Change") {
                                                                                    if (design_change === "Internal") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_int_glass_reference ? "Glass Reference" : ""}
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_int_glass_reference || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    } else if (design_change === "External") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    Glass Reference
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_ext_glass_reference || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                } else if (change_category === "Process Change") {
                                                                                    if (process_change === "Internal") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_int_glass_reference ? "Glass Reference" : ""}
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_int_glass_reference || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    } else if (process_change === "External") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    Glass Reference
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_ext_glass_reference || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                }

                                                                                return null; // If no condition matches, return null or handle accordingly
                                                                            })()}
                                                                        </Typography>
                                                                    </div>
                                                                </Box>
                                                                {cms?.cmsForm?.changeRequired?.change_required_remarks ?
                                                                    <Box sx={{ padding: '0 12px 12px' }}>
                                                                        <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                            Change Required
                                                                        </Typography>
                                                                        <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "0" }}>
                                                                            {cms?.cmsForm?.changeRequired?.change_required_remarks}
                                                                        </Typography>
                                                                    </Box> : null
                                                                }
                                                                <Box style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', width: "100%", cursor: "pointer", borderTop: '1px solid #f4f4f4', padding: '4px 12px' }}>
                                                                    <Box style={{ display: "flex" }}>
                                                                        <img
                                                                            src={TimeLine}
                                                                            alt="Timeline"
                                                                            style={{ marginRight: "10px" }}
                                                                            onClick={(event) => toggleAccordionTimeline(event, cms.cms_id)}
                                                                        />
                                                                        <Typography
                                                                            style={{
                                                                                color: "#616161",
                                                                                fontFamily: "inherit",
                                                                                fontSize: "14px",
                                                                                fontWeight: 500,
                                                                            }}
                                                                        >
                                                                            {moment(cms.updated_at).format("DD-MMM-YYYY")}
                                                                        </Typography>
                                                                    </Box>

                                                                    <AvatarGroup>

                                                                        {cms?.cmsForm?.created_by ?
                                                                            <div style={{ position: "relative" }}>
                                                                                <Tooltip title={cms?.cmsForm?.created_by}>
                                                                                    <Avatar style={{ width: "30px", height: "30px", fontSize: "14px", marginBottom: "10px", marginLeft: "50px" }}>
                                                                                        {`${cms?.cmsForm?.created_by.split(" ").pop().charAt(0)}${cms?.cmsForm?.created_by.charAt(0)}`}
                                                                                    </Avatar>
                                                                                </Tooltip>
                                                                            </div> : null
                                                                        }

                                                                    </AvatarGroup>
                                                                </Box>
                                                                {expandedCardId === cms.cms_id && (
                                                                    <Timeline sx={{
                                                                        [`& .${timelineItemClasses.root}:before`]: {
                                                                            flex: 0,
                                                                            padding: 0,
                                                                        },
                                                                    }}>
                                                                        {timeline.map((action, index) => (
                                                                            <TimelineItem key={index}>
                                                                                <TimelineSeparator>
                                                                                    <TimelineDot color="primary" />
                                                                                    {index !== timeline.length - 1 && <TimelineConnector />}
                                                                                </TimelineSeparator>
                                                                                <TimelineContent>
                                                                                    <Typography style={{ color: '#616161', fontFamily: 'inherit', fontSize: '14px', fontWeight: 500 }}>
                                                                                        {action.status === "conditionallyapproved" ? "conditionally approved" + " " + "by" + " " + action.user_approval_name :
                                                                                            action.status === "submittedbackforqueries" ? "submitted back for queries" + " " + "by" + " " + action.user_approval_name :
                                                                                                action.status + " " + "by" + " " + action.user_approval_name}
                                                                                    </Typography>
                                                                                    <Typography style={{ color: '#616161', fontFamily: 'inherit', fontSize: '14px', fontWeight: 500 }}>
                                                                                        {(new Date(action.updated_at)).toISOString().split('T')[0]}
                                                                                    </Typography>
                                                                                </TimelineContent>
                                                                            </TimelineItem>
                                                                        ))}
                                                                    </Timeline>
                                                                )}
                                                            </CardContent>
                                                        </Card>
                                                    </React.Fragment>) : null
                                            )}
                                        {filterCompleteData.length === 0 && <p>No records found.</p>}
                                    </div>
                                )}

                                {selectedTab === 5 && (
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", marginTop: 10 }}>
                                        {filterRejectData
                                            .map((cms, index) =>
                                                (user === cms.user_sgid || cms?.cmsForm?.notification?.approver_sgid[0]?.includes(user)) ?
                                                    (<React.Fragment key={cms.id}>
                                                        <Card key={cms.id} style={{ width: "100%", position: 'relative', borderRadius: "8px", border: "1px solid #e2e2e2", background: "#FFF", marginBottom: "20px", boxShadow: "0px 2px 12px 0px rgba(0, 0, 0, 0.15)" }}>
                                                            <CardContent style={{ display: "flex", flexDirection: "column", padding: '0' }}>
                                                                <Box sx={{ display: 'flex', alignItems: 'start', justifyContent: cms?.cmsForm?.changeRequired === null || cms?.cmsForm?.changeRequired === undefined ? "none" : "space-between", padding: "12px" }}>
                                                                    {cms?.cmsForm?.cms_no ?
                                                                        <Box>
                                                                            <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                CMS NO.
                                                                            </Typography>
                                                                            <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                {cms?.cmsForm?.cms_no}
                                                                            </Typography>
                                                                        </Box> : <></>
                                                                    }
                                                                    <div style={{ display: "flex", alignItems: "right", gap: "10px" }}>
                                                                        <img
                                                                            src={menu}
                                                                            alt="menu"
                                                                            style={{ width: "28px", position: "absolute", right: 50, height: "20px", cursor: "pointer" }}
                                                                            onClick={(event) => handleMenuClick(event, cms.id)} />
                                                                    </div>
                                                                    {showMenu && openedMenuId === cms.id && (
                                                                        <div
                                                                            ref={menuRef}
                                                                            style={{
                                                                                position: "absolute",
                                                                                top: 30,
                                                                                right: 20,
                                                                                width: 150,
                                                                                backgroundColor: "white",
                                                                                boxShadow: "none",
                                                                                borderRadius: 4,
                                                                                border: "1px solid #B51849",
                                                                                zIndex: 10,
                                                                            }}
                                                                        >
                                                                            <div
                                                                                onClick={() => handleMenuItemClick("edit", cms)}
                                                                                style={{
                                                                                    padding: "10px",
                                                                                    cursor: "pointer",
                                                                                    display: "flex",
                                                                                    alignItems: "center"
                                                                                }}>
                                                                                <img
                                                                                    src={Edit}
                                                                                    alt="Edit Icon"
                                                                                    style={{ verticalAlign: "middle", marginRight: "5px", marginBottom: "5px", cursor: "pointer" }}
                                                                                />
                                                                                {"Review"}
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </Box>
                                                                <Box sx={{ display: 'flex', alignItems: 'start', justifyContent: cms?.cmsForm?.changeRequired === null || cms?.cmsForm?.changeRequired === undefined ? "none" : "space-between", padding: "12px" }}>

                                                                    <div>
                                                                        <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                            Change Category
                                                                        </Typography>
                                                                        <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                            {cms?.cmsForm?.changeRequest?.change_request[0]?.change_category || ""}
                                                                        </Typography>
                                                                    </div>
                                                                    <div>
                                                                        <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                            {(() => {
                                                                                const { change_category, design_change, process_change } = cms?.cmsForm?.changeRequest?.change_request[0] || {};

                                                                                if (change_category === "Design Change") {
                                                                                    if (design_change === "Internal") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_int_customer ? "Customer" : ""}
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_int_customer || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    } else if (design_change === "External") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    Customer
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_ext_customer || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                } else if (change_category === "Process Change") {
                                                                                    if (process_change === "Internal") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_int_customer ? "Customer" : ""}
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_int_customer || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    } else if (process_change === "External") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    Customer
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_ext_customer || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                }

                                                                                return null; // If no condition matches, return null or handle accordingly
                                                                            })()}
                                                                        </Typography>
                                                                    </div>
                                                                </Box>
                                                                <Box sx={{ display: 'flex', alignItems: 'start', justifyContent: cms?.cmsForm?.changeRequired === null || cms?.cmsForm?.changeRequired === undefined ? "none" : "space-between", padding: "12px" }}>

                                                                    <div>
                                                                        <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                            {(() => {
                                                                                const { change_category, design_change, process_change } = cms?.cmsForm?.changeRequest?.change_request[0] || {};

                                                                                if (change_category === "Design Change") {
                                                                                    if (design_change === "Internal") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_int_model ? "Model" : ""}
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_int_model || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    } else if (design_change === "External") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    Model
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_ext_model || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                } else if (change_category === "Process Change") {
                                                                                    if (process_change === "Internal") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_int_model ? "Model" : ""}
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_int_model || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    } else if (process_change === "External") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    Model
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_ext_model || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                }

                                                                                return null; // If no condition matches, return null or handle accordingly
                                                                            })()}
                                                                        </Typography>
                                                                    </div>
                                                                    <div>
                                                                        <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                            {(() => {
                                                                                const { change_category, design_change, process_change } = cms?.cmsForm?.changeRequest?.change_request[0] || {};

                                                                                if (change_category === "Design Change") {
                                                                                    if (design_change === "Internal") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_int_glass_reference ? "Glass Reference" : ""}
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_int_glass_reference || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    } else if (design_change === "External") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    Glass Reference
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_ext_glass_reference || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                } else if (change_category === "Process Change") {
                                                                                    if (process_change === "Internal") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_int_glass_reference ? "Glass Reference" : ""}
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_int_glass_reference || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    } else if (process_change === "External") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    Glass Reference
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_ext_glass_reference || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                }

                                                                                return null; // If no condition matches, return null or handle accordingly
                                                                            })()}
                                                                        </Typography>
                                                                    </div>
                                                                </Box>
                                                                {cms?.cmsForm?.changeRequired?.change_required_remarks ?
                                                                    <Box sx={{ padding: '0 12px 12px' }}>
                                                                        <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                            Change Required
                                                                        </Typography>
                                                                        <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "0" }}>
                                                                            {cms?.cmsForm?.changeRequired?.change_required_remarks}
                                                                        </Typography>
                                                                    </Box> : null
                                                                }
                                                                <Box style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', width: "100%", cursor: "pointer", borderTop: '1px solid #f4f4f4', padding: '4px 12px' }}>
                                                                    <Box style={{ display: "flex" }}>
                                                                        <img
                                                                            src={TimeLine}
                                                                            alt="Timeline"
                                                                            style={{ marginRight: "10px" }}
                                                                            onClick={(event) => toggleAccordionTimeline(event, cms.cms_id)}
                                                                        />
                                                                        <Typography
                                                                            style={{
                                                                                color: "#616161",
                                                                                fontFamily: "inherit",
                                                                                fontSize: "14px",
                                                                                fontWeight: 500,
                                                                            }}
                                                                        >
                                                                            {moment(cms.updated_at).format("DD-MMM-YYYY")}
                                                                        </Typography>
                                                                    </Box>

                                                                    <AvatarGroup>

                                                                        {cms?.cmsForm?.created_by ?
                                                                            <div style={{ position: "relative" }}>
                                                                                <Tooltip title={cms?.cmsForm?.created_by}>
                                                                                    <Avatar style={{ width: "30px", height: "30px", fontSize: "14px", marginBottom: "10px", marginLeft: "50px" }}>
                                                                                        {`${cms?.cmsForm?.created_by.split(" ").pop().charAt(0)}${cms?.cmsForm?.created_by.charAt(0)}`}
                                                                                    </Avatar>
                                                                                </Tooltip>
                                                                            </div> : null
                                                                        }

                                                                    </AvatarGroup>
                                                                </Box>
                                                                {expandedCardId === cms.cms_id && (
                                                                    <Timeline sx={{
                                                                        [`& .${timelineItemClasses.root}:before`]: {
                                                                            flex: 0,
                                                                            padding: 0,
                                                                        },
                                                                    }}>
                                                                        {timeline.map((action, index) => (
                                                                            <TimelineItem key={index}>
                                                                                <TimelineSeparator>
                                                                                    <TimelineDot color="primary" />
                                                                                    {index !== timeline.length - 1 && <TimelineConnector />}
                                                                                </TimelineSeparator>
                                                                                <TimelineContent>
                                                                                    <Typography style={{ color: '#616161', fontFamily: 'inherit', fontSize: '14px', fontWeight: 500 }}>
                                                                                        {action.status === "conditionallyapproved" ? "conditionally approved" + " " + "by" + " " + action.user_approval_name :
                                                                                            action.status === "submittedbackforqueries" ? "submitted back for queries" + " " + "by" + " " + action.user_approval_name :
                                                                                                action.status + " " + "by" + " " + action.user_approval_name}
                                                                                    </Typography>
                                                                                    <Typography style={{ color: '#616161', fontFamily: 'inherit', fontSize: '14px', fontWeight: 500 }}>
                                                                                        {(new Date(action.updated_at)).toISOString().split('T')[0]}
                                                                                    </Typography>
                                                                                </TimelineContent>
                                                                            </TimelineItem>
                                                                        ))}
                                                                    </Timeline>
                                                                )}
                                                            </CardContent>
                                                        </Card>
                                                    </React.Fragment>) : null
                                            )}
                                        {filterRejectData.length === 0 && <p>No records found.</p>}
                                    </div>
                                )}

                            </div>
                        )}

                </div>

            </div>
            {editDialogOpen && <ChangeRequest
                open={editDialogOpen}
                onClose={dialogClose}
            />}
            <NewChangeRequest isOpen={newDialogRequestsOpen} onClose={newDialogRequestClose} />
            <Alternation isOpen={newDialogAlterOpen} onClose={newDialogAlterClose} />
        </>
    );
};

export default ManageCmsMobile;