import React, { useState, useEffect, useRef } from "react";
import { Card, CardContent, Typography, Chip, Tooltip, Popover, Grid, Box, Button, Accordion, AccordionDetails, AccordionSummary, Container, TextField, CircularProgress, AvatarGroup, Avatar, IconButton } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import menu from "../Asset/Icons/menu_managemoc.svg";
import Edit from "../Asset/Icons/edit.svg";
import SearchIcon from "../Asset/Icons/search_icon.svg";
// import FilterIcon from "../Asset/Icons/filter_icon.svg";
import CloseIcon from "../Asset/Icons/close_icon.svg";
import API from "../Services/API";
import axios from "axios";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import { useRecoilState } from "recoil";
import { getCmsDataID, editCMSData, userData, changeRequestData, changeReqData, impactChangeData, notification } from "../recoil/atoms";
import TimeLine from "../Asset/Icons/Arrows.svg";
import DeleteDialog from "../settings/DeleteDialog";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import AddIcon from '@mui/icons-material/Add';
import NewChangeRequest from "./NewChangeRequest";
import ViewApproval from "./ViewApproval";
import Alternation from "./Alternation";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ReportCmsPdf from "../4m_Pages/ReportCmsPdf";
import DownloadIcon from '@mui/icons-material/Download';
import VisibilityIcon from '@mui/icons-material/Visibility';

const ManageCmsDesktop = () => {
    const [, setSelectedTab] = useState(0);
    const [cmsData, setCmsData] = useState([]);
    const [, setMenuPosition] = useState({ top: 0, left: 0 });
    const [, setpdfMenuPosition] = useState({ top: 0, left: 0 });
    const [showMenu, setShowMenu] = useState(false);
    const [showNewMenu, setShowNewMenu] = useState(false);
    const [showPdfMenu, setShowPdfMenu] = useState(false);
    const [cmsPdfData, setCmsPdfData] = useState([]);
    const [cmsDataID, setCmsDataID] = useRecoilState(getCmsDataID);
    const [isEditCms, setIsEditCms] = useRecoilState(editCMSData);
    const [recordData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [coordinates, setCoordinates] = useState([]);
    const [initialLoad, setInitialLoad] = useState(true);
    const [, setLoading] = useState(false);
    const [, setError] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [conclusionAccordionExpand, setConclusionAccordionExpand] = useState(false);
    const [dateAccordionExpand, setDateAccordionExpand] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [fromDate, setFromDate] = useState([]);
    const [toDate, setToDate] = useState([]);
    const [filterCardData, setFilterCardData] = useState([]);
    const [filterProgressData, setFilterProgressData] = useState([]);
    const [filterDraftData, setFilterDraftData] = useState([]);
    const [filterRejectData, setFilterRejectData] = useState([]);
    const [filterApprovalData, setFilterApprovalData] = useState([]);
    const [filterCompleteData, setFilterCompleteData] = useState([]);
    const [cardData, setCardData] = useState([]);
    const [progressData, setProgressData] = useState([]);
    const [draftData, setDraftData] = useState([]);
    const [rejectedData, setRejectedData] = useState([]);
    const [approvalData, setApprovalData] = useState([]);
    const [completedData, setCompletedData] = useState([]);
    const [, setApprovalCmsID] = useState([]);
    const openFilterPopover = Boolean(anchorEl);
    const [deleteMocId, setDeleteMocId] = useState(null);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [, setApproved] = useState('');
    const [, setPending] = useState('');
    const [, setRejected] = useState('');
    const [, setResubmitted] = useState('');
    const [, setConditionApprove] = useState('');
    const [, setSubmitBackQueries] = useState('');
    const filterId = openFilterPopover ? "simple-popover" : undefined;
    const [expandedCardId, setExpandedCardId] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [, setUserList] = useRecoilState(userData);
    const [, setChangeRequest] = useRecoilState(changeRequestData);
    const [, setChangeRequireData] = useRecoilState(changeReqData);
    const [, setImpactData] = useRecoilState(impactChangeData);
    const [, setNotificationData] = useRecoilState(notification);
    const [timeline, setTimeline] = useState([]);
    const [showTab, setShowTab] = useState(true);
    const [newDialogRequestsOpen, setNewDialogRequestsOpen] = useState(false);
    const [newViewApprovalOpen, setNewViewApprovalOpen] = useState(false);
    const [newDialogAlterOpen, setNewDialogAlterOpen] = useState(false);
    const [openedMenuId, setOpenedMenuId] = useState(null);
    const [showAlterNote, setShowAlterNote] = useState([]);
    const [previewForm, setPreviewForm] = useState(false);
    const [, setFunctionalCommentsVisible] = useState({});
    const [, setApproverCommentsVisible] = useState({});
    const [, setHoveredFunctional] = useState(false);
    const [, setHoveredApprover] = useState(false);
    const [approvalViewData, setApprovalViewData] = useState({});
    const menuRef = useRef(null);
    const printRef = useRef();


    const access_token = JSON.parse(localStorage.getItem("accesstoken"));

    const handlePrint = useReactToPrint({
        content: () => printRef.current,
    });

    const printCover = () => {
        setTimeout(() => {
            handlePrint();
        }, 400);
    }

    const portraitOrientation = () => {
        return (
            <style type="text/css">
                {"@media print{@page {size: portrait; margin: 0}}"}
            </style>
        )
    }

    const handlePDF = () => {
        setPreviewForm(true);
        printCover();
    }

    const toggleAccordionTimeline = async (event, id) => {
        const response = await axios.get(
            `${API.GET_APPROVAL_BYCMSID}?id=${id}`,
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
        const appCmsID = response.data;
        const withStatus = [];
        appCmsID.forEach(item => {
            withStatus.push(item);
        });
        setTimeline(withStatus);
        function groupByStatus(data) {
            const result = {
                approved: new Map(),
                rejected: new Map(),
                pending: new Map(),
                resubmitted: new Map(),
                conditionallyapproved: new Map(),
                submittedbackforqueries: new Map(),
            };
            data.forEach(item => {
                const { id, status, updated_at } = item;
                const updatedAt = new Date(updated_at);

                if (!result[status]?.has(id) || result[status]?.get(id).updated_at < updatedAt) {
                    result[status]?.set(id, { ...item, updated_at: updatedAt });
                }
            });

            return {
                approved: Array.from(result.approved.values()),
                rejected: Array.from(result.rejected.values()),
                pending: Array.from(result.pending.values()),
                resubmitted: Array.from(result.resubmitted.values()),
                conditionallyapproved: Array.from(result.conditionallyapproved.values()),
                submittedbackforqueries: Array.from(result.submittedbackforqueries.values()),
            };
        }

        const groupedData = groupByStatus(appCmsID);

        if (groupedData.approved && groupedData.approved.length > 0) {
            const latestApproved = groupedData.approved.reduce((latest, current) => {
                return new Date(latest.updated_at) > new Date(current.updated_at) ? latest : current;
            });
            setApproved((new Date(latestApproved.updated_at)).toISOString().split('T')[0])
        } else {
            setApproved("");
        }
        if (groupedData.rejected && groupedData.rejected.length > 0) {
            const latestApproved = groupedData.rejected.reduce((latest, current) => {
                return new Date(latest.updated_at) > new Date(current.updated_at) ? latest : current;
            });
            setRejected((new Date(latestApproved.updated_at)).toISOString().split('T')[0])
        } else {
            setRejected("");
        }
        if (groupedData.pending && groupedData.pending.length > 0) {
            const latestApproved = groupedData.pending.reduce((latest, current) => {
                return new Date(latest.updated_at) > new Date(current.updated_at) ? latest : current;
            });
            setPending((new Date(latestApproved.updated_at)).toISOString().split('T')[0])
        } else {
            setPending("");
        }
        if (groupedData.resubmitted && groupedData.resubmitted.length > 0) {
            const latestApproved = groupedData.resubmitted.reduce((latest, current) => {
                return new Date(latest.updated_at) > new Date(current.updated_at) ? latest : current;
            });
            setResubmitted((new Date(latestApproved.updated_at)).toISOString().split('T')[0])
        } else {
            setResubmitted("");
        }
        if (groupedData.conditionallyapproved && groupedData.conditionallyapproved.length > 0) {
            const latestApproved = groupedData.conditionallyapproved.reduce((latest, current) => {
                return new Date(latest.updated_at) > new Date(current.updated_at) ? latest : current;
            });
            setConditionApprove((new Date(latestApproved.updated_at)).toISOString().split('T')[0])
        } else {
            setConditionApprove("");
        }
        if (groupedData.submittedbackforqueries && groupedData.submittedbackforqueries.length > 0) {
            const latestApproved = groupedData.submittedbackforqueries.reduce((latest, current) => {
                return new Date(latest.updated_at) > new Date(current.updated_at) ? latest : current;
            });
            setSubmitBackQueries((new Date(latestApproved.updated_at)).toISOString().split('T')[0])
        } else {
            setSubmitBackQueries("");
        }
        setApprovalCmsID(appCmsID);
        setExpandedCardId(expandedCardId === id ? null : id);
    };

    const toggleFunctionalComments = (index) => {
        setFunctionalCommentsVisible((prev) => ({
            ...prev,
            [index]: !prev[index]
        }));
    };

    const toggleApproverComments = (index) => {
        setApproverCommentsVisible((prev) => ({
            ...prev,
            [index]: !prev[index]
        }));
    };

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
        setShowMenu(false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setShowMenu(false);
            }
        };
        if (showMenu) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showMenu]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setShowNewMenu(false);
            }
        };
        if (showNewMenu) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showNewMenu]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setShowPdfMenu(false);
            }
        };
        if (showPdfMenu) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showPdfMenu]);

    const handleAddClick = () => {
        setUserList([]);
        setChangeRequest({
            date: '',
            request_initiator: '',
            department: '',
            cms_no: '',
            type_design_change: '',
            plant: '',
            change_category: '',
            ecn_type: '',
            process_type: '',
            ecn_int_customer: '',
            ecn_int_model: '',
            ecn_int_glass_reference: '',
            ecn_int_part_number_rev_no: '',
            ecn_int_part_number: '',
            ecn_int_part_name: '',
            ecn_int_drawing_no_rev_no: '',
            ecn_int_drawing_number: '',
            ecn_int_process_reference: '',
            ecn_ext_customer: '',
            ecn_ext_model: '',
            ecn_ext_glass_reference: '',
            ecn_ext_part_number_rev_no: '',
            ecn_ext_part_number: '',
            ecn_ext_part_name: '',
            ecn_ext_drawing_no_rev_no: '',
            ecn_ext_drawing_number: '',
            ecn_ext_process_reference: '',
            pro_int_customer: '',
            pro_int_model: '',
            pro_int_glass_reference: '',
            pro_int_part_number: '',
            pro_int_part_number_rev_no: '',
            pro_int_part_name: '',
            pro_int_drawing_no_rev_no: '',
            pro_int_drawing_number: '',
            pro_int_process_reference: '',
            pro_ext_customer: '',
            pro_ext_model: '',
            pro_ext_glass_reference: '',
            pro_ext_part_number_rev_no: '',
            pro_ext_part_number: '',
            pro_ext_part_name: '',
            pro_ext_drawing_no_rev_no: '',
            pro_ext_drawing_number: '',
            pro_ext_process_reference: '',
            des_change_required: '',
            other_comments: '',
            receiveing_customer_date: '',
            customer_req_imple_date: '',
            feas_con_customer_date: '',
            remarks: '',
        });
        setChangeRequireData({
            change_required_remarks: "",
            change_request_remarks: ""
        });
        setImpactData({
            affects: "",
            actions: "",
            responsibility: "",
            date: "",
            remarks: "",
            document: []
        });
        setNotificationData({
            approver_mail_id: "",
            approval_status: "",
        })
        setNewDialogRequestsOpen(true);
        setNewDialogAlterOpen(false);
        setIsEditCms(false);
        localStorage.removeItem("isChangeRequestSave");
        localStorage.removeItem("isChangeRequireSave");
        localStorage.removeItem("isImpactChangeSave");
    };

    const newDialogRequestClose = () => {
        setNewDialogRequestsOpen(false);
    }

    const newDialogAlterClose = () => {
        setNewDialogAlterOpen(false);
    }

    const newDialogApprovalClose = () => {
        setNewViewApprovalOpen(false);
    }

    const handleMenuClick = async (event, cmsId) => {
        try {
            const cardRect = event.currentTarget.getBoundingClientRect();
            const menuTop = cardRect.bottom + window.scrollY - 30;
            const menuLeft = cardRect.left + window.scrollX - 125;
            const response = await axios.get(
                `${API.GET_APPROVAL_CMS_BY_ID}?id=${cmsId}`,
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`
                    }
                });
            const cmsDataByID = response.data;
            localStorage.setItem("cmsDataByID", JSON.stringify(cmsDataByID));
            setCmsDataID(cmsId);
            const bothApprovalStatus = cmsDataByID?.cmsForm?.notificationHeader?.cmsForms?.every(cmsForm =>
                cmsForm?.functional_approval_status === true && cmsForm?.cft_approval_status === true
            );
            const showAlterNote = bothApprovalStatus;
            setShowAlterNote(showAlterNote);
            setMenuPosition({ top: menuTop, left: menuLeft });
            setShowMenu(true);
            setOpenedMenuId(cmsId);
        } catch (error) {
            console.error("Error fetching Moc data by ID:", error);
        }
    };

    const handlePdfMenuClick = async (event, cmsId) => {
        try {
            const cardRect = event.currentTarget.getBoundingClientRect();
            const menuTop = cardRect.bottom + window.scrollY - 30;
            const menuLeft = cardRect.left + window.scrollX - 125;
            const response = await axios.get(
                `${API.SHOW_REPORT_PDF}/${cmsId}`,
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`
                    }
                }
            );
            const cmsDataByID = response.data[0];
            setCmsPdfData(cmsDataByID);
            setOpenedMenuId(cmsId);
            setpdfMenuPosition({ top: menuTop, left: menuLeft });
            setShowPdfMenu(true);
        } catch (error) {
            console.error("Error fetching Moc data by ID:", error);
        }
    };

    const editCmsData = async (cmsId) => {
        try {
            const response = await axios.get(`${API.EDIT_APPROVAL_CMS}?id=${cmsId}`,
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`
                    }
                });
            const cmsDetails = await response.data;
            localStorage.setItem("cmsDetails", JSON.stringify(cmsDetails));
            console.log(cmsDetails, "cmsDetails467")
            setNewDialogRequestsOpen(true);
            if (!isEditCms) {
                setIsEditCms(true);
            }
        } catch (error) {
            console.error("Error fetching MOC details:", error);
        }
    };

    const alternationNoteSet = async () => {
        const cmsFormData = JSON.parse(localStorage.getItem("cmsDataByID"));
        const alternationNoteId = cmsFormData.cmsForm.alternation_note_id;
        try {
            if (!alternationNoteId) {
                const emptyAlternationNote = {
                    description: [],
                };
                localStorage.setItem("emptyAlternationNote", JSON.stringify(emptyAlternationNote));
                setNewDialogAlterOpen(true);
                setIsEditCms(false);
                return;
            }
            const response = await axios.get(
                `${API.GET_CMS_ALTERNATION}?id=${alternationNoteId}`,
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`
                    }
                });
            const alternationNoteDetails = await response.data;
            localStorage.setItem("alternationNoteDetails", JSON.stringify(alternationNoteDetails));
            setNewDialogAlterOpen(true);
            if (!isEditCms) {
                setIsEditCms(true);
            }
        } catch (error) {
            console.error("Error fetching MOC details:", error);
        }
    };

    const viewCmsData = async (cmsId) => {
        console.log(cmsId, "cmsid508")
        try {
            const response = await axios.get(`${API.EDIT_APPROVAL_CMS}?id=${cmsId}`,
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`
                    }
                });
            const cmsDetails = await response.data;
            localStorage.setItem("cmsDetails", JSON.stringify(cmsDetails));
            console.log(cmsDetails, "cmsDetails518")
            setNewViewApprovalOpen(true);
        } catch (error) {
            console.error("Error fetching MOC details:", error);
        }
    };

    const deleteCMS = async () => {
        try {
            const response = await axios.delete(
                `${API.DELETE_CMS}?id=${cmsDataID}`,
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`
                    }
                });
            const updatedCmsData = cmsData.filter((cms) => cms.id !== cmsDataID);
            setDraftData(updatedCmsData)
            setCmsData(updatedCmsData);
            setShowMenu(false);
            loadCmsData();
        } catch (error) {
            console.error("Error fetching MOC details:", error);
        }
    };

    // Function to open delete dialog
    const openDeleteConfirmation = (userId) => {
        setDeleteMocId(userId);
        setOpenDeleteDialog(true);
    };

    // Function to close delete dialog
    const closeDeleteConfirmation = () => {
        setOpenDeleteDialog(false);
    };

    const handleMenuItemClick = async (menuItem, cms) => {
        setApprovalViewData(cms);
        switch (menuItem) {
            case "edit":
                editCmsData(cms.id);
                break;
            case "modify":
                viewCmsData(cms.id);
                break;
            case "alterationNote":
                alternationNoteSet(cms?.cmsForm?.alternation_note_id);
                break;
            case "delete":
                openDeleteConfirmation(cms.id);
                break;
            default:
                break;
        }
        setShowMenu(false);
        setShowNewMenu(false);
    };

    const storedUserData = JSON.parse(localStorage.getItem("userDatas"));
    const user = storedUserData.SGID;

    const loadCmsData = () => {
        try {
            axios
                .get(API.GET_ALL_CMS_FOR_APPROVALS, {
                    headers: {
                        Authorization: `Bearer ${access_token}`
                    }
                })
                .then(function (response) {
                    if (response.status === 200) {
                        if (response.data && response.data.length > 0) {
                            const cmsData = response.data;
                            setCardData(cmsData);
                            setFilterCardData(cmsData);
                            const cmsDatas = response.data.filter((item) => item.cmsForm.cms_no !== null);
                            const filteredConclusionData = response.data.filter((item) => item.cmsForm.cms_no === null);
                            setDraftData(filteredConclusionData);
                            setFilterDraftData(filteredConclusionData);
                            // Assuming response.data is an array
                            const filteredApprovalData = response.data.filter((item) => {
                                const status = item?.cmsForm?.submission_status;
                                const cmsNo = item?.cmsForm?.cms_no;
                                const isPending = status === "pending" && cmsNo !== null;
                                const isResubmitted = status === "resubmitted" && cmsNo !== null;
                                const isApproved = status === "approved" && cmsNo !== null;
                                const isConditionallyApproved = status === "conditionallyapproved" && cmsNo !== null;
                                return isPending || isResubmitted || isApproved || isConditionallyApproved;
                            });
                            setApprovalData(filteredApprovalData);
                            setFilterApprovalData(filteredApprovalData);
                            const filteredInProgressData = response.data.filter((item) => {
                                const functionalStatus = item?.cmsForm?.notificationHeader?.cmsForms?.some(cmsForm => cmsForm?.functional_approval_status === true) && item?.cmsForm?.alternation_note_id === null;
                                const cftStatus = item?.cmsForm?.notificationHeader?.cmsForms?.some(cmsForm => cmsForm?.cft_approval_status === true) && item?.cmsForm?.alternation_note_id === null;
                                return functionalStatus || cftStatus;
                            });
                            setProgressData(filteredInProgressData);
                            setFilterProgressData(filteredInProgressData);
                            const filteredCompleteData = response.data.filter((item) =>
                                item?.cmsForm?.alternation_note_id !== null && (item?.cmsForm?.submission_status === "approved" || item?.cmsForm?.submission_status === "conditionallyapproved")
                            );
                            setCompletedData(filteredCompleteData);
                            setFilterCompleteData(filteredCompleteData);
                            const filteredRejectData = response.data.filter((item) => {
                                const status = item?.cmsForm?.submission_status;
                                const isRejected = status === "rejected";
                                const isSubmittedBackForQueries = status === "submittedbackforqueries";
                                return isRejected || isSubmittedBackForQueries;
                            });
                            setRejectedData(filteredRejectData);
                            setFilterRejectData(filteredRejectData);

                            const completedCms = filteredCompleteData.length;
                            localStorage.setItem("completedCms", completedCms);
                            const pendingCms = filteredApprovalData.length;
                            localStorage.setItem("pendingCms", pendingCms);
                            const inProgressCms = filteredInProgressData.length;
                            localStorage.setItem("inProgressCms", inProgressCms);
                            const rejectedCms = filteredRejectData.length;
                            localStorage.setItem("rejectedCms", rejectedCms);
                            const allCms = cmsDatas.length;
                            localStorage.setItem("allCms", allCms);
                        }
                    }
                })
                .catch(function (error) {
                    console.log("ERROR", error);
                })
                .finally(() => {
                    setInitialLoad(false);
                });
        } catch (error) {
            console.error("An error occurred:", error);
        }
    };

    useEffect(
        () => {
            loadCmsData();
        },
        [
            //cmsData
        ]
    );

    const handleSearchChange = (event) => {
        setSearchText(event.target.value);
        filterAllCms(event.target.value);
        filterProgress(event.target.value);
        filterDraft(event.target.value);
        filterApproval(event.target.value);
        filterCompleted(event.target.value);
        filteredRejected(event.target.value);
    };

    const filterAllCms = (searchText) => {
        const filtCms = filterCmsData(filterCardData, searchText);
        setFilterCardData(filtCms);
        if (searchText) {
            setCardData(filtCms);
        } else {
            loadCmsData();
        }
    };
    const filterCmsData = (data, search) => {
        const filtCms = data.filter((cms) => {
            const lowerCaseQuery = search.toLowerCase();
            const cms_no = (cms?.cmsForm?.cms_no ? cms?.cmsForm?.cms_no : "").toLowerCase().includes(lowerCaseQuery);
            const updated_at = moment(cms.updated_at).format("DD-MMM-YYYY").toLowerCase().includes(lowerCaseQuery);
            return cms_no || updated_at;
        });
        return filtCms;
    };

    const filterProgress = (searchText) => {
        const filtProgress = filterProg(filterProgressData, searchText);
        setFilterProgressData(filtProgress);
        if (searchText) {
            setProgressData(filtProgress);
        } else {
            loadCmsData();
        }
    };
    const filterProg = (data, search) => {
        const lowerCaseQuery = search.toLowerCase();

        const filtProgress = data.filter((cms) => {
            // Ensure each property exists before calling toLowerCase()
            const conclusion = cms?.cmsForm?.conclusion?.toLowerCase().includes(lowerCaseQuery) || false;
            const cms_no = cms?.cmsForm?.cms_no ? cms?.cmsForm?.cms_no.toLowerCase().includes(lowerCaseQuery) : false;
            const created_at = moment(cms.created_at).format("DD-MMM-YYYY").toLowerCase().includes(lowerCaseQuery);
            const change_required = cms?.cmsForm?.requestForm?.change_required ? cms?.cmsForm?.requestForm.change_required.toLowerCase().includes(lowerCaseQuery) : false;

            return conclusion || cms_no || created_at || change_required;
        });

        return filtProgress;
    };

    const filterDraft = (searchText) => {
        const filtDraft = filterDraf(filterDraftData, searchText);
        setFilterDraftData(filtDraft);
        if (searchText) {
            setDraftData(filtDraft);
        } else {
            loadCmsData();
        }
    };
    const filterDraf = (data, search) => {
        const lowerCaseQuery = search.toLowerCase();

        const filtDraft = data.filter((cms) => {
            // Ensure that created_at is a valid date and format it properly
            const created_at = moment(cms.created_at).format("DD-MMM-YYYY").toLowerCase().includes(lowerCaseQuery);

            // Check if cmsForm and requestForm exist before accessing change_required
            const change_required = cms?.cmsForm?.requestForm?.change_required
                ? cms?.cmsForm?.requestForm.change_required.toLowerCase().includes(lowerCaseQuery)
                : false;

            return created_at || change_required;
        });

        return filtDraft;
    };

    const filterApproval = (searchText) => {
        const filtApproval = filterApprov(filterApprovalData, searchText);
        setFilterApprovalData(filtApproval);
        if (searchText) {
            setApprovalData(filtApproval);
        } else {
            loadCmsData();
        }
    };
    const filterApprov = (data, search) => {
        const lowerCaseQuery = search.toLowerCase();

        const filtApproval = data.filter((cms) => {
            // Check if conclusion exists before calling toLowerCase()
            const conclusion = cms?.cmsForm?.conclusion
                ? cms?.cmsForm?.conclusion.toLowerCase().includes(lowerCaseQuery)
                : false;

            // Check if cms_no exists and use it, otherwise default to an empty string
            const cms_no = cms?.cmsForm?.cms_no
                ? cms?.cmsForm?.cms_no.toLowerCase().includes(lowerCaseQuery)
                : false;

            // Ensure created_at is a valid date and format it properly
            const created_at = moment(cms.created_at).format("DD-MMM-YYYY").toLowerCase().includes(lowerCaseQuery);

            // Check if change_required exists before calling toLowerCase()
            const change_required = cms?.cmsForm?.requestForm?.change_required
                ? cms?.cmsForm?.requestForm.change_required.toLowerCase().includes(lowerCaseQuery)
                : false;

            return conclusion || cms_no || created_at || change_required;
        });

        return filtApproval;
    };

    const filterCompleted = (searchText) => {
        const filtCompleted = filterCompletedData(filterCompleteData, searchText);
        setFilterCompleteData(filtCompleted);
        if (searchText) {
            setCompletedData(filtCompleted);
        } else {
            loadCmsData();
        }
    };
    const filterCompletedData = (data, search) => {
        const lowerCaseQuery = search.toLowerCase();

        const filtCompleted = data.filter((cms) => {
            // Check if conclusion exists before calling toLowerCase()
            const conclusion = cms?.cmsForm?.conclusion
                ? cms?.cmsForm?.conclusion.toLowerCase().includes(lowerCaseQuery)
                : false;

            // Check if cms_no exists and use it, otherwise default to an empty string
            const cms_no = cms?.cmsForm?.cms_no
                ? cms?.cmsForm?.cms_no.toLowerCase().includes(lowerCaseQuery)
                : false;

            // Ensure created_at is formatted correctly
            const created_at = moment(cms.created_at).format("DD-MMM-YYYY").toLowerCase().includes(lowerCaseQuery);

            // Check if change_required exists before calling toLowerCase()
            const change_required = cms?.cmsForm?.requestForm?.change_required
                ? cms?.cmsForm?.requestForm.change_required.toLowerCase().includes(lowerCaseQuery)
                : false;

            return conclusion || cms_no || created_at || change_required;
        });

        return filtCompleted;
    };

    const filteredRejected = (searchText) => {
        const filtRejected = filteredRejectedData(filterRejectData, searchText);
        setFilterRejectData(filtRejected);
        if (searchText) {
            setRejectedData(filtRejected);
        } else {
            loadCmsData();
        }
    };
    const filteredRejectedData = (data, search) => {
        const lowerCaseQuery = search.toLowerCase();

        const filtRejected = data.filter((cms) => {
            // Check if conclusion exists and is a string before calling toLowerCase()
            const conclusion = cms?.cmsForm?.conclusion
                ? cms?.cmsForm?.conclusion.toLowerCase().includes(lowerCaseQuery)
                : false;

            // Check if cms_no exists and is a string, or default to empty string
            const cms_no = cms?.cmsForm?.cms_no
                ? cms?.cmsForm?.cms_no.toLowerCase().includes(lowerCaseQuery)
                : false;

            // Ensure created_at is formatted correctly and exists
            const created_at = cms.created_at
                ? moment(cms.created_at).format("DD-MMM-YYYY").toLowerCase().includes(lowerCaseQuery)
                : false;

            // Check if change_required exists and is a string before calling toLowerCase()
            const change_required = cms?.cmsForm?.requestForm?.change_required
                ? cms?.cmsForm?.requestForm.change_required.toLowerCase().includes(lowerCaseQuery)
                : false;

            return conclusion || cms_no || created_at || change_required;
        });

        return filtRejected;
    };

    const openFilter = async (event) => {
        setCoordinates([event.pageX, event.pageY]);
        setAnchorEl(event.currentTarget);
    };

    const handleFilter = async () => {
        try {
            setError("");
            setLoading(true);
            handleCloseFilter();
            const filteredNewRecord = await getFilteredNewRecordData();
            if (filteredNewRecord.length === 0) {
                setError("No completed CMS");
            } else {
                setError("");
                setFilteredData(filteredNewRecord);
                setFilterCardData(filteredNewRecord);
                setFilterProgressData(filteredNewRecord);
                setFilterDraftData(filteredNewRecord);
                setFilterRejectData(filteredNewRecord);
                setFilterApprovalData(filteredNewRecord);
                setFilterCompleteData(filteredNewRecord);
                setSnackbarOpen(true);
            }
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const getFilteredNewRecordData = async () => {
        try {
            // const tabs = selectedTab === 0 ? cardData : selectedTab === 1 ? progressData : selectedTab === 2 ? draftData : selectedTab === 3 ? approvalData : selectedTab === 4 ? completedData : selectedTab === 5 ? rejectedData : [];
            const tabs = filterCardData ? cardData : filterProgressData ? progressData : filterApprovalData ? approvalData : filterDraftData ? draftData : filterCompleteData ? completedData : filterRejectData ? rejectedData : [];
            const from = new Date(fromDate);
            const to = new Date(toDate);
            to.setHours(23, 59, 59, 999);
            const finalFilteredData = tabs.filter((record) => {
                const recordCreatedAt = new Date(record.created_at);
                return (!isNaN(from) && !isNaN(to)) && recordCreatedAt >= from && recordCreatedAt <= to;
            });
            return finalFilteredData;
        } catch (error) {
            console.error("Error filtering data:", error);
            return [];
        }
    };

    const handleResetFilter = () => {
        setConclusionAccordionExpand(false);
        setFilteredData(recordData);
        setFilterCardData(cardData);
        setFilterApprovalData(approvalData);
        setSelectedOptions([]);
        handleCloseFilter();
        setFromDate("");
        setToDate("");
    };

    const handleFromDateChange = (event) => {
        setFromDate(event.target.value);
    };

    const handleToDateChange = (event) => {
        setToDate(event.target.value);
    };

    const handleCloseFilter = () => {
        setAnchorEl(null);
    };

    const closeFilter = () => {
        setAnchorEl(null);
    };

    const handleAccordionChange = (accordionType) => {
        if (accordionType === "conclusion") {
            setConclusionAccordionExpand(!conclusionAccordionExpand);
        } else if (accordionType === "date") {
            setDateAccordionExpand(!dateAccordionExpand);
        }
    };

    // Function to update selected filter options
    const handleFilterOptionSelect = (filterOption) => {
        if (selectedOptions.includes(filterOption)) {
            setSelectedOptions(
                selectedOptions.filter((option) => option !== filterOption)
            );
        } else {
            setSelectedOptions([...selectedOptions, filterOption]);
        }
    };

    const approvalLabel = cardData.map(data => data.user_sgid);

    const handleToggle = () => {
        setShowTab(!showTab);
    };

    const cmsFormData = JSON.parse(localStorage.getItem("cmsDataByID"));
    const userID = cmsFormData?.cmsForm?.user_id;
    return (
        <>
            <Box style={{ justifyContent: "center", position: "relative" }}>
                <DeleteDialog
                    open={openDeleteDialog}
                    onClose={closeDeleteConfirmation}
                    handleDelete={() => {
                        deleteCMS(deleteMocId);
                        closeDeleteConfirmation(); // Close dialog after deletion
                    }}
                />
                <Box style={{ display: "flex", alignItems: 'center', width: "100%", background: '#fff', padding: '8px 16px', justifyContent: 'space-between' }}>
                    <Typography variant="h2" sx={{ color: '#333', fontSize: '21px', fontWeight: '600' }}>Manage CMS</Typography>
                    <Box sx={{ width: '50%', maxWidth: '500px', display: 'flex' }}>
                        <Box sx={{ position: 'relative', marginRight: '16px', width: '100%' }}>
                            <img src={SearchIcon} alt="Search Icon" style={{ position: "absolute", top: "50%", right: "10px", transform: "translateY(-50%)", cursor: "pointer" }} />
                            <input type="text" placeholder="Search..." style={{ width: "100%", padding: "10px", paddingRight: "40px", fontSize: "14px", borderRadius: "5px", border: "1px solid #8F908F" }} value={searchText} onChange={handleSearchChange} />
                        </Box>
                        {/* Filter Tooltip */}
                        {/* <Tooltip onClick={openFilter} title="Filter">
                            <Box style={{ border: "1px solid #8F908F", borderRadius: 5, width: 60, height: 38, display: "flex", justifyContent: "center", alignItems: "center", cursor: 'pointer' }}>
                                <img src={FilterIcon} alt="Filter Icon" />
                            </Box>
                        </Tooltip> */}
                        <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
                            <MuiAlert elevation={6} variant="filled" severity="success" onClose={() => setSnackbarOpen(false)}>
                                Filter Applied
                            </MuiAlert>
                        </Snackbar>
                        <Popover
                            id={filterId}
                            open={openFilterPopover}
                            anchorEl={anchorEl}
                            anchorReference="anchorPosition"
                            anchorPosition={{ top: coordinates[1], left: coordinates[0] }}
                            onClose={handleCloseFilter}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}>
                            <div style={{ maxHeight: "500px", overflowY: "auto" }}>
                                {" "}
                                <Grid container style={{ borderBottom: "1px solid #282A2D", display: "flex", alignItems: "center" }}>
                                    <Grid item xs={11}>
                                        <Box p={1} style={{ display: "flex", alignItems: "center", marginLeft: "10px" }}>
                                            <Button onClick={handleFilter} style={{ fontWeight: "bold", backgroundColor: "#00448b", color: "#ffffff", borderRadius: "5px", height: "35px" }}>
                                                Apply
                                            </Button>
                                            <Typography style={{ fontWeight: 800, color: "#FAAD14", lineHeight: "16px", marginLeft: "25px", cursor: "pointer", fontSize: "18px" }} onClick={handleResetFilter}>
                                                Reset
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <img
                                            onClick={closeFilter}
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter" || e.key === " ") {
                                                    closeFilter();
                                                }
                                            }}
                                            style={{ cursor: "pointer", marginRight: "10px" }}
                                            src={CloseIcon}
                                            alt="Close"
                                            tabIndex="0" // Ensure the element is focusable
                                        />
                                    </Grid>
                                </Grid>
                                <Card style={{ width: "300px", padding: "10px" }}>
                                    <Container component="main" maxWidth="xs">
                                        {/* Date Range Options Accordion */}
                                        <Accordion
                                            expanded={dateAccordionExpand}
                                            onChange={() => handleAccordionChange("date")}
                                        >
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel2a-content"
                                                id="panel2a-header"
                                            >
                                                <Typography style={{ fontSize: 16, fontWeight: "bold", color: "#000000" }}>
                                                    CMS Date
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <div>
                                                    {/* From Date */}
                                                    <TextField
                                                        id="fromDate"
                                                        label="From Date"
                                                        type="date"
                                                        value={fromDate}
                                                        onChange={handleFromDateChange}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        style={{ width: "220px" }}
                                                    />
                                                    {/* To Date */}
                                                    <TextField
                                                        id="toDate"
                                                        label="To Date"
                                                        type="date"
                                                        value={toDate}
                                                        onChange={handleToDateChange}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        style={{ marginTop: "20px", width: "220px" }}
                                                    />
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Container>
                                </Card>
                            </div>
                        </Popover>
                        <Button
                            variant="contained"
                            onClick={handleAddClick}
                            style={{
                                background: '#B51849',
                                width: '230px',
                                border: 0,
                                borderRadius: 3,
                                color: 'white',
                                height: '38px',
                                fontSize: '14px',
                                textTransform: 'capitalize',
                                margin: '0 8px',
                                padding: '4px 0',
                            }}
                        >
                            <AddIcon />
                            New Request
                        </Button>
                        {showTab ? (
                            <IconButton onClick={handleToggle} sx={{ color: '#fff', position: 'fixed', top: '0', right: '20px', bottom: '0', margin: 'auto', background: 'rgba(181, 24, 73, 0.5) !important', height: '50px', width: '50px', zIndex: '1' }}>
                                <ArrowForwardIosIcon fontSize="15px" />
                            </IconButton>
                        ) :
                            <IconButton onClick={handleToggle} sx={{ color: '#fff', left: '20px', margin: 'auto', transform: "rotate(180deg)", background: 'rgba(181, 24, 73, 0.5) !important', height: '50px', width: '50px', zIndex: '1', position: "absolute", top: "250px" }}>
                                <ArrowForwardIosIcon fontSize="15px" />
                            </IconButton>
                        }
                    </Box>
                </Box>

                <Box sx={{ padding: '0 8px' }}>
                    {initialLoad ? (
                        <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <CircularProgress />
                        </Box>
                    ) :
                        (
                            <Grid container sx={{ minHeight: 'calc(100vh - 120px)' }}>
                                {showTab ? (
                                    <Grid item md={4} sx={{ padding: '0 16px', border: '5px solid #fff', borderWidth: '0 5px 0 0' }}>
                                        <Typography variant="h3" sx={{ fontSize: { xl: '16px', md: '14px' }, fontWeight: '600', margin: '12px 0', color: '#B51849' }}>All CMS</Typography>
                                        {filterCardData
                                            .map((cms, index) =>
                                                (user && storedUserData?.plant === "Sekurit") ?
                                                    (<React.Fragment key={cms.id}>
                                                        <Card key={cms.id} style={{ width: "100%", position: 'relative', borderRadius: "8px", border: "1px solid #e2e2e2", background: "#FFF", marginBottom: "20px", boxShadow: "0px 2px 12px 0px rgba(0, 0, 0, 0.15)" }}>
                                                            <CardContent style={{ display: "flex", flexDirection: "column", padding: '12px' }}>
                                                                <Box sx={{ display: 'flex', alignItems: 'start', justifyContent: cms?.cmsForm?.changeRequired === null || cms?.cmsForm?.changeRequired === undefined ? "none" : "space-between", padding: "2px" }}>
                                                                    {cms?.cmsForm?.cms_no ?
                                                                        <Box>
                                                                            <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                CMS NO.
                                                                            </Typography>
                                                                            <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                {cms?.cmsForm?.cms_no}
                                                                            </Typography>
                                                                        </Box> : <></>
                                                                    }
                                                                    {/* {user !== userID ?
                                                                        <></> :
                                                                        <div style={{ display: "flex", alignItems: "right", gap: "10px" }}>
                                                                            <img
                                                                                src={menu}
                                                                                alt="menu"
                                                                                style={{ width: "28px", position: "absolute", right: 50, height: "20px", cursor: "pointer" }}
                                                                                onClick={(event) => handleMenuClick(event, cms.id)} />
                                                                        </div>
                                                                    } */}
                                                                </Box>
                                                                <Box sx={{ display: 'flex', alignItems: 'start', justifyContent: cms?.cmsForm?.changeRequired === null || cms?.cmsForm?.changeRequired === undefined ? "none" : "space-between", padding: "2px" }}>
                                                                    <div>
                                                                        <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                            Change Category
                                                                        </Typography>
                                                                        <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                            {cms?.cmsForm?.changeRequest?.change_request[0]?.change_category || ""}
                                                                        </Typography>
                                                                    </div>
                                                                    <div>
                                                                        <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                            {(() => {
                                                                                const { change_category, design_change, process_change } = cms?.cmsForm?.changeRequest?.change_request[0] || {};

                                                                                if (change_category === "Design Change") {
                                                                                    if (design_change === "Internal") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_int_customer ? "Customer" : ""}
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_int_customer || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    } else if (design_change === "External") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    Customer
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_ext_customer || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                } else if (change_category === "Process Change") {
                                                                                    if (process_change === "Internal") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_int_customer ? "Customer" : ""}
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_int_customer || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    } else if (process_change === "External") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    Customer
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_ext_customer || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                }

                                                                                return null; // If no condition matches, return null or handle accordingly
                                                                            })()}
                                                                        </Typography>
                                                                    </div>
                                                                </Box>
                                                                <Box sx={{ display: 'flex', alignItems: 'start', justifyContent: cms?.cmsForm?.changeRequired === null || cms?.cmsForm?.changeRequired === undefined ? "none" : "space-between", padding: "2px" }}>
                                                                    <div>
                                                                        <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                            {(() => {
                                                                                const { change_category, design_change, process_change } = cms?.cmsForm?.changeRequest?.change_request[0] || {};

                                                                                if (change_category === "Design Change") {
                                                                                    if (design_change === "Internal") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_int_model ? "Model" : ""}
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_int_model || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    } else if (design_change === "External") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    Model
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_ext_model || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                } else if (change_category === "Process Change") {
                                                                                    if (process_change === "Internal") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_int_model ? "Model" : ""}
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_int_model || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    } else if (process_change === "External") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    Model
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_ext_model || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                }

                                                                                return null; // If no condition matches, return null or handle accordingly
                                                                            })()}
                                                                        </Typography>
                                                                    </div>
                                                                    <div>
                                                                        <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                            {(() => {
                                                                                const { change_category, design_change, process_change } = cms?.cmsForm?.changeRequest?.change_request[0] || {};

                                                                                if (change_category === "Design Change") {
                                                                                    if (design_change === "Internal") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_int_glass_reference ? "Glass Reference" : ""}
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_int_glass_reference || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    } else if (design_change === "External") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    Glass Reference
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_ext_glass_reference || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                } else if (change_category === "Process Change") {
                                                                                    if (process_change === "Internal") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_int_glass_reference ? "Glass Reference" : ""}
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_int_glass_reference || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    } else if (process_change === "External") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    Glass Reference
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_ext_glass_reference || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                }

                                                                                return null; // If no condition matches, return null or handle accordingly
                                                                            })()}
                                                                        </Typography>
                                                                    </div>
                                                                </Box>
                                                                {cms?.cmsForm?.changeRequired?.change_required_remarks ?
                                                                    <Box sx={{ padding: '2px' }}>
                                                                        <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                            Change Required
                                                                        </Typography>
                                                                        <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "0" }}>
                                                                            {cms?.cmsForm?.changeRequired?.change_required_remarks}
                                                                        </Typography>
                                                                    </Box> : null
                                                                }
                                                                <Box style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', width: "100%", cursor: "pointer", borderTop: '1px solid #f4f4f4', padding: '2px' }}>
                                                                    <Box style={{ display: "flex" }}>
                                                                        <img
                                                                            src={TimeLine}
                                                                            alt="Timeline"
                                                                            style={{ marginRight: "10px" }}
                                                                            onClick={(event) => toggleAccordionTimeline(event, cms.cms_id)}
                                                                        />
                                                                        <Typography
                                                                            style={{
                                                                                color: "#616161",
                                                                                fontFamily: "inherit",
                                                                                fontSize: "14px",
                                                                                fontWeight: 500,
                                                                            }}
                                                                        >
                                                                            {moment(cms.updated_at).format("DD-MMM-YYYY")}
                                                                        </Typography>
                                                                    </Box>

                                                                    <AvatarGroup>

                                                                        {cms?.cmsForm?.created_by ?
                                                                            <div style={{ position: "relative" }}>
                                                                                <Tooltip title={cms?.cmsForm?.created_by}>
                                                                                    <Avatar style={{ width: "30px", height: "30px", fontSize: "14px", marginBottom: "10px", marginLeft: "50px" }}>
                                                                                        {`${cms?.cmsForm?.created_by.split(" ").pop().charAt(0)}${cms?.cmsForm?.created_by.charAt(0)}`}
                                                                                    </Avatar>
                                                                                </Tooltip>
                                                                            </div> : null
                                                                        }

                                                                    </AvatarGroup>
                                                                </Box>
                                                                {expandedCardId === cms.cms_id && (
                                                                    <Timeline sx={{
                                                                        [`& .${timelineItemClasses.root}:before`]: {
                                                                            flex: 0,
                                                                            padding: 0,
                                                                        },
                                                                    }}>
                                                                        {timeline.map((action, index) => (
                                                                            <TimelineItem key={index}>
                                                                                <TimelineSeparator>
                                                                                    <TimelineDot color="primary" />
                                                                                    {index !== timeline.length - 1 && <TimelineConnector />}
                                                                                </TimelineSeparator>
                                                                                <TimelineContent>
                                                                                    {/* Loop to display each approver's email if present */}
                                                                                    {(() => {
                                                                                        // Set to track displayed approver_mail_id, defined outside the map loop
                                                                                        const displayedApproverMailIds = new Set();

                                                                                        return action?.cmsForm?.notificationHeader?.cmsForms?.map((notification, idx) => {
                                                                                            // Check if the approver_mail_id exists and is not already displayed
                                                                                            if (notification?.approver_mail_id && !displayedApproverMailIds.has(notification.approver_mail_id)) {
                                                                                                // Add to Set to track it and ensure it's displayed only once
                                                                                                displayedApproverMailIds.add(notification.approver_mail_id);

                                                                                                // Extract the username part before "@" and remove anything after "."
                                                                                                const approverUsername = notification.approver_mail_id.split('@')[0].split('.')[0];

                                                                                                // Capitalize the first letter of the username
                                                                                                const formattedUsername = approverUsername.charAt(0).toUpperCase() + approverUsername.slice(1);

                                                                                                return (
                                                                                                    <Typography key={idx} style={{ color: '#616161', fontFamily: 'inherit', fontSize: '14px', fontWeight: 500 }}>
                                                                                                        {action.status === "pending" ? "pending with " + formattedUsername : null}
                                                                                                    </Typography>
                                                                                                );
                                                                                            }

                                                                                            // Return null if approver_mail_id is a duplicate or missing
                                                                                            return null;
                                                                                        });
                                                                                    })()}

                                                                                    {/* Loop to display each functional email if present */}
                                                                                    {(() => {
                                                                                        // Set to track displayed functional_mail_id, defined outside the map loop
                                                                                        const displayedFunctionalMailIds = new Set();

                                                                                        return action?.cmsForm?.notificationHeader?.cmsForms?.map((notification, idx) => {
                                                                                            // Check if the functional_mail_id exists and is not already displayed
                                                                                            if (notification?.functional_mail_id && !displayedFunctionalMailIds.has(notification.functional_mail_id)) {
                                                                                                // Add to Set to track it and ensure it's displayed only once
                                                                                                displayedFunctionalMailIds.add(notification.functional_mail_id);

                                                                                                // Extract the username part before "@" and remove anything after "."
                                                                                                const username = notification.functional_mail_id.split('@')[0].split('.')[0];

                                                                                                // Capitalize the first letter of the username
                                                                                                const formattedUsername = username.charAt(0).toUpperCase() + username.slice(1);

                                                                                                return (
                                                                                                    <Typography key={idx} style={{ color: '#616161', fontFamily: 'inherit', fontSize: '14px', fontWeight: 500 }}>
                                                                                                        {action.status === "pending" ? "pending with " + formattedUsername : null}
                                                                                                    </Typography>
                                                                                                );
                                                                                            }

                                                                                            // Return null if functional_mail_id is a duplicate or missing
                                                                                            return null;
                                                                                        });
                                                                                    })()}

                                                                                    <Typography style={{ color: '#616161', fontFamily: 'inherit', fontSize: '14px', fontWeight: 500 }}>
                                                                                        {action.status === "conditionallyapproved" ? "conditionally approved" + " " + "by" + " " + action.user_approval_name :
                                                                                            action.status === "submittedbackforqueries" ? "submitted back for queries" + " " + "by" + " " + action.user_approval_name :
                                                                                                action.status === "pending" ? "initiated" + " " + "by" + " " + action.user_approval_name :
                                                                                                    action.status + " " + "by" + " " + action.user_approval_name}
                                                                                        <span style={{ marginLeft: '10px', alignItems: 'center' }}>
                                                                                            {/* Functional Comments */}
                                                                                            {action?.cmsForm?.notificationHeader?.cmsForms?.map((notification, idx) => {
                                                                                                if (notification?.functional_sgid?.includes(action?.user_sgid)) {
                                                                                                    return (
                                                                                                        <span
                                                                                                            style={{ marginLeft: '10px', alignItems: 'center' }}
                                                                                                            onMouseEnter={() => setHoveredFunctional(true)}
                                                                                                            onMouseLeave={() => setHoveredFunctional(false)}
                                                                                                        >
                                                                                                            <Tooltip
                                                                                                                title={notification.functional_comments} // Show the comment as tooltip text
                                                                                                                arrow
                                                                                                                enterDelay={500} // Delay before showing tooltip (in ms)
                                                                                                                leaveDelay={200} // Delay before hiding tooltip (in ms)
                                                                                                            >
                                                                                                                <IconButton onClick={() => toggleFunctionalComments(idx)}>
                                                                                                                    <VisibilityIcon fontSize="small" />
                                                                                                                </IconButton>
                                                                                                            </Tooltip>
                                                                                                        </span>
                                                                                                    );
                                                                                                }
                                                                                                return null;
                                                                                            })[0]}
                                                                                        </span>

                                                                                        {/* Approver Comments */}
                                                                                        <span style={{ marginLeft: '10px', alignItems: 'center' }}>
                                                                                            {action?.cmsForm?.notificationHeader?.cmsForms?.map((notification, idx) => (
                                                                                                notification?.approver_sgid.includes(action?.user_sgid) && (
                                                                                                    <span
                                                                                                        style={{ alignItems: 'center' }}
                                                                                                        onMouseEnter={() => setHoveredApprover(true)}
                                                                                                        onMouseLeave={() => setHoveredApprover(false)}
                                                                                                    >
                                                                                                        <Tooltip
                                                                                                            title={notification?.approver_comments} // Show the comment as tooltip text
                                                                                                            arrow
                                                                                                            enterDelay={500} // Delay before showing tooltip (in ms)
                                                                                                            leaveDelay={200} // Delay before hiding tooltip (in ms)
                                                                                                        >
                                                                                                            <IconButton onClick={() => toggleApproverComments(idx)}>
                                                                                                                <VisibilityIcon fontSize="small" />
                                                                                                            </IconButton>
                                                                                                        </Tooltip>
                                                                                                    </span>
                                                                                                )
                                                                                            ))}
                                                                                        </span>
                                                                                    </Typography>
                                                                                    <Typography style={{ color: '#616161', fontFamily: 'inherit', fontSize: '14px', fontWeight: 500 }}>
                                                                                        {(new Date(action.updated_at)).toISOString().split('T')[0]}
                                                                                    </Typography>
                                                                                </TimelineContent>
                                                                            </TimelineItem>
                                                                        ))}
                                                                    </Timeline>
                                                                )}
                                                            </CardContent>
                                                        </Card>
                                                    </React.Fragment>) : null
                                            )}
                                        {filterCardData.length === 0 && <p>No records found.</p>}
                                    </Grid>
                                ) : null}

                                {showTab ? (
                                    <Grid item md={4} sx={{ padding: '0 16px', border: '5px solid #fff', borderWidth: '0 5px 0 0' }}>
                                        <Typography variant="h3" sx={{ fontSize: { xl: '16px', md: '14px' }, fontWeight: '600', margin: '12px 0', color: '#B51849' }}>IN PROGRESS</Typography>
                                        {filterProgressData
                                            .map((cms, index) =>
                                            // (user === cms?.cmsForm?.user_id && cms?.cmsForm?.notification?.functional_approval_status === true && cms?.cmsForm?.notification?.cft_approval_status === true
                                            //     ||
                                            //     (cms?.cmsForm?.notification?.cft_approval_status === true && cms?.cmsForm?.notification?.approver_sgid?.length && cms?.cmsForm?.notification?.approver_sgid[0].includes(user))
                                            //     ||
                                            //     (cms?.cmsForm?.notification?.functional_sgid?.length && cms?.cmsForm?.notification?.functional_sgid[0].includes(user))
                                            //     // && cms?.cmsForm?.notification?.functional_approval_status === true
                                            // )

                                            {
                                                const cmsForm = cms?.cmsForm;
                                                const notificationHeader = cmsForm?.notificationHeader;

                                                // Check if user is the initiator of the form
                                                const userIsFormInitiator = user === cmsForm?.user_id &&
                                                    cms?.cmsForm?.notificationHeader?.cmsForms?.every(cmsForm => cmsForm?.functional_approval_status === true) &&
                                                    cms?.cmsForm?.notificationHeader?.cmsForms?.every(cmsForm => cmsForm?.cft_approval_status === true);

                                                // Check if user is a functional approver and has not yet approved
                                                const userIsFunctionalApprover = notificationHeader?.cmsForms.some((approver) =>
                                                    approver?.functional_sgid?.includes(user) && approver?.functional_approval_status
                                                );

                                                // Check if user is a CFT approver and has not yet approved
                                                const userIsCftApprover = notificationHeader?.cmsForms.some((approver) =>
                                                    approver?.approver_sgid?.includes(user) && approver?.cft_approval_status
                                                );

                                                // Show card if user matches any of the conditions
                                                if (userIsFormInitiator || userIsFunctionalApprover || userIsCftApprover) {
                                                    return (<React.Fragment key={cms.id}>
                                                        <Card key={cms.id} style={{ width: "100%", position: 'relative', borderRadius: "8px", border: "1px solid #e2e2e2", background: "#FFF", marginBottom: "20px", boxShadow: "0px 2px 12px 0px rgba(0, 0, 0, 0.15)" }}>
                                                            <CardContent style={{ display: "flex", flexDirection: "column", padding: '12px' }}>
                                                                <Box sx={{ display: 'flex', alignItems: 'start', justifyContent: cms?.cmsForm?.changeRequired === null || cms?.cmsForm?.changeRequired === undefined ? "none" : "space-between", padding: "2px" }}>
                                                                    {cms?.cmsForm?.cms_no ?
                                                                        <Box>
                                                                            <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                CMS NO.
                                                                            </Typography>
                                                                            <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                {cms?.cmsForm?.cms_no}
                                                                            </Typography>
                                                                        </Box> : <></>
                                                                    }
                                                                    {user === userID &&
                                                                        <div style={{ display: "flex", alignItems: "right", gap: "10px" }}>
                                                                            <Tooltip title="Click to review" arrow>
                                                                                <img
                                                                                    src={menu}
                                                                                    alt="menu"
                                                                                    style={{ width: "28px", position: "absolute", right: 50, height: "20px", cursor: "pointer" }}
                                                                                    onClick={(event) => handleMenuClick(event, cms.id)} />
                                                                            </Tooltip>
                                                                        </div>
                                                                    }
                                                                    {showMenu && openedMenuId === cms.id && (
                                                                        <div
                                                                            ref={menuRef}
                                                                            style={{
                                                                                position: "absolute",
                                                                                top: 30,
                                                                                right: 20,
                                                                                width: 150,
                                                                                backgroundColor: "white",
                                                                                boxShadow: "none",
                                                                                borderRadius: 4,
                                                                                border: "1px solid #B51849",
                                                                                zIndex: 10,
                                                                            }}
                                                                        >
                                                                            {!showAlterNote ? (
                                                                                <div
                                                                                    onClick={() => handleMenuItemClick("edit", cms)}
                                                                                    style={{
                                                                                        padding: "10px",
                                                                                        cursor: "pointer",
                                                                                        display: "flex",
                                                                                        alignItems: "center"
                                                                                    }}>
                                                                                    <img
                                                                                        src={Edit}
                                                                                        alt="Edit Icon"
                                                                                        style={{ verticalAlign: "middle", marginRight: "5px", marginBottom: "5px", cursor: "pointer" }}
                                                                                    />
                                                                                    {"Review"}
                                                                                </div>
                                                                            ) : null}
                                                                            {showAlterNote ? (
                                                                                <div
                                                                                    onClick={() => handleMenuItemClick("alterationNote", cms)}
                                                                                    style={{
                                                                                        padding: "10px",
                                                                                        cursor: "pointer",
                                                                                        display: "flex",
                                                                                        alignItems: "center"
                                                                                    }}>
                                                                                    <img
                                                                                        src={Edit}
                                                                                        alt="Edit Icon"
                                                                                        style={{ verticalAlign: "middle", marginRight: "5px", marginBottom: "5px", cursor: "pointer" }}
                                                                                    />
                                                                                    {"Alternation Note"}
                                                                                </div>
                                                                            ) : null}
                                                                        </div>
                                                                    )}
                                                                </Box>
                                                                <Box sx={{ display: 'flex', alignItems: 'start', justifyContent: cms?.cmsForm?.changeRequired === null || cms?.cmsForm?.changeRequired === undefined ? "none" : "space-between", padding: "2px" }}>
                                                                    <div>
                                                                        <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                            Change Category
                                                                        </Typography>
                                                                        <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                            {cms?.cmsForm?.changeRequest?.change_request[0]?.change_category || ""}
                                                                        </Typography>
                                                                    </div>
                                                                    <div>
                                                                        <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                            {(() => {
                                                                                const { change_category, design_change, process_change } = cms?.cmsForm?.changeRequest?.change_request[0] || {};

                                                                                if (change_category === "Design Change") {
                                                                                    if (design_change === "Internal") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_int_customer ? "Customer" : ""}
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_int_customer || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    } else if (design_change === "External") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    Customer
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_ext_customer || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                } else if (change_category === "Process Change") {
                                                                                    if (process_change === "Internal") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_int_customer ? "Customer" : ""}
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_int_customer || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    } else if (process_change === "External") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    Customer
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_ext_customer || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                }

                                                                                return null; // If no condition matches, return null or handle accordingly
                                                                            })()}
                                                                        </Typography>
                                                                    </div>
                                                                </Box>
                                                                <Box sx={{ display: 'flex', alignItems: 'start', justifyContent: cms?.cmsForm?.changeRequired === null || cms?.cmsForm?.changeRequired === undefined ? "none" : "space-between", padding: "2px" }}>

                                                                    <div>
                                                                        <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                            {(() => {
                                                                                const { change_category, design_change, process_change } = cms?.cmsForm?.changeRequest?.change_request[0] || {};

                                                                                if (change_category === "Design Change") {
                                                                                    if (design_change === "Internal") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_int_model ? "Model" : ""}
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_int_model || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    } else if (design_change === "External") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    Model
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_ext_model || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                } else if (change_category === "Process Change") {
                                                                                    if (process_change === "Internal") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_int_model ? "Model" : ""}
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_int_model || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    } else if (process_change === "External") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    Model
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_ext_model || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                }

                                                                                return null; // If no condition matches, return null or handle accordingly
                                                                            })()}
                                                                        </Typography>
                                                                    </div>
                                                                    <div>
                                                                        <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                            {(() => {
                                                                                const { change_category, design_change, process_change } = cms?.cmsForm?.changeRequest?.change_request[0] || {};

                                                                                if (change_category === "Design Change") {
                                                                                    if (design_change === "Internal") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_int_glass_reference ? "Glass Reference" : ""}
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_int_glass_reference || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    } else if (design_change === "External") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    Glass Reference
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_ext_glass_reference || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                } else if (change_category === "Process Change") {
                                                                                    if (process_change === "Internal") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_int_glass_reference ? "Glass Reference" : ""}
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_int_glass_reference || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    } else if (process_change === "External") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    Glass Reference
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_ext_glass_reference || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                }

                                                                                return null; // If no condition matches, return null or handle accordingly
                                                                            })()}
                                                                        </Typography>
                                                                    </div>
                                                                </Box>
                                                                {cms?.cmsForm?.changeRequired?.change_required_remarks ?
                                                                    <Box sx={{ padding: '2px' }}>
                                                                        <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                            Change Required
                                                                        </Typography>
                                                                        <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "0" }}>
                                                                            {cms?.cmsForm?.changeRequired?.change_required_remarks}
                                                                        </Typography>
                                                                    </Box> : null
                                                                }
                                                                <Box style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', width: "100%", cursor: "pointer", borderTop: '1px solid #f4f4f4', padding: '2px' }}>
                                                                    <Box style={{ display: "flex" }}>
                                                                        <img
                                                                            src={TimeLine}
                                                                            alt="Timeline"
                                                                            style={{ marginRight: "10px" }}
                                                                            onClick={(event) => toggleAccordionTimeline(event, cms.cms_id)}
                                                                        />
                                                                        <Typography
                                                                            style={{
                                                                                color: "#616161",
                                                                                fontFamily: "inherit",
                                                                                fontSize: "14px",
                                                                                fontWeight: 500,
                                                                            }}
                                                                        >
                                                                            {moment(cms.updated_at).format("DD-MMM-YYYY")}
                                                                        </Typography>
                                                                    </Box>

                                                                    <AvatarGroup>

                                                                        {cms?.cmsForm?.created_by ?
                                                                            <div style={{ position: "relative" }}>
                                                                                <Tooltip title={cms?.cmsForm?.created_by}>
                                                                                    <Avatar style={{ width: "30px", height: "30px", fontSize: "14px", marginBottom: "10px", marginLeft: "50px" }}>
                                                                                        {`${cms?.cmsForm?.created_by.split(" ").pop().charAt(0)}${cms?.cmsForm?.created_by.charAt(0)}`}
                                                                                    </Avatar>
                                                                                </Tooltip>
                                                                            </div> : null
                                                                        }

                                                                    </AvatarGroup>
                                                                </Box>
                                                                {expandedCardId === cms.cms_id && (
                                                                    <Timeline sx={{
                                                                        [`& .${timelineItemClasses.root}:before`]: {
                                                                            flex: 0,
                                                                            padding: 0,
                                                                        },
                                                                    }}>
                                                                        {timeline.map((action, index) => (
                                                                            <TimelineItem key={index}>
                                                                                <TimelineSeparator>
                                                                                    <TimelineDot color="primary" />
                                                                                    {index !== timeline.length - 1 && <TimelineConnector />}
                                                                                </TimelineSeparator>
                                                                                <TimelineContent>
                                                                                    {/* Loop to display each approver's email if present */}
                                                                                    {(() => {
                                                                                        // Set to track displayed approver_mail_id, defined outside the map loop
                                                                                        const displayedApproverMailIds = new Set();

                                                                                        return action?.cmsForm?.notificationHeader?.cmsForms?.map((notification, idx) => {
                                                                                            // Check if the approver_mail_id exists and is not already displayed
                                                                                            if (notification?.approver_mail_id && !displayedApproverMailIds.has(notification.approver_mail_id)) {
                                                                                                // Add to Set to track it and ensure it's displayed only once
                                                                                                displayedApproverMailIds.add(notification.approver_mail_id);

                                                                                                // Extract the username part before "@" and remove anything after "."
                                                                                                const approverUsername = notification.approver_mail_id.split('@')[0].split('.')[0];

                                                                                                // Capitalize the first letter of the username
                                                                                                const formattedUsername = approverUsername.charAt(0).toUpperCase() + approverUsername.slice(1);

                                                                                                return (
                                                                                                    <Typography key={idx} style={{ color: '#616161', fontFamily: 'inherit', fontSize: '14px', fontWeight: 500 }}>
                                                                                                        {action.status === "pending" ? "pending with " + formattedUsername : null}
                                                                                                    </Typography>
                                                                                                );
                                                                                            }

                                                                                            // Return null if approver_mail_id is a duplicate or missing
                                                                                            return null;
                                                                                        });
                                                                                    })()}

                                                                                    {/* Loop to display each functional email if present */}
                                                                                    {(() => {
                                                                                        // Set to track displayed functional_mail_id, defined outside the map loop
                                                                                        const displayedFunctionalMailIds = new Set();

                                                                                        return action?.cmsForm?.notificationHeader?.cmsForms?.map((notification, idx) => {
                                                                                            // Check if the functional_mail_id exists and is not already displayed
                                                                                            if (notification?.functional_mail_id && !displayedFunctionalMailIds.has(notification.functional_mail_id)) {
                                                                                                // Add to Set to track it and ensure it's displayed only once
                                                                                                displayedFunctionalMailIds.add(notification.functional_mail_id);

                                                                                                // Extract the username part before "@" and remove anything after "."
                                                                                                const username = notification.functional_mail_id.split('@')[0].split('.')[0];

                                                                                                // Capitalize the first letter of the username
                                                                                                const formattedUsername = username.charAt(0).toUpperCase() + username.slice(1);

                                                                                                return (
                                                                                                    <Typography key={idx} style={{ color: '#616161', fontFamily: 'inherit', fontSize: '14px', fontWeight: 500 }}>
                                                                                                        {action.status === "pending" ? "pending with " + formattedUsername : null}
                                                                                                    </Typography>
                                                                                                );
                                                                                            }

                                                                                            // Return null if functional_mail_id is a duplicate or missing
                                                                                            return null;
                                                                                        });
                                                                                    })()}

                                                                                    <Typography style={{ color: '#616161', fontFamily: 'inherit', fontSize: '14px', fontWeight: 500 }}>
                                                                                        {action.status === "conditionallyapproved" ? "conditionally approved" + " " + "by" + " " + action.user_approval_name :
                                                                                            action.status === "submittedbackforqueries" ? "submitted back for queries" + " " + "by" + " " + action.user_approval_name :
                                                                                                action.status === "pending" ? "initiated" + " " + "by" + " " + action.user_approval_name :
                                                                                                    action.status + " " + "by" + " " + action.user_approval_name}
                                                                                        <span style={{ marginLeft: '10px', alignItems: 'center' }}>
                                                                                            {/* Functional Comments */}
                                                                                            {action?.cmsForm?.notificationHeader?.cmsForms?.map((notification, idx) => {
                                                                                                if (notification?.functional_sgid?.includes(action?.user_sgid)) {
                                                                                                    return (
                                                                                                        <span
                                                                                                            style={{ marginLeft: '10px', alignItems: 'center' }}
                                                                                                            onMouseEnter={() => setHoveredFunctional(true)}
                                                                                                            onMouseLeave={() => setHoveredFunctional(false)}
                                                                                                        >
                                                                                                            <Tooltip
                                                                                                                title={notification.functional_comments} // Show the comment as tooltip text
                                                                                                                arrow
                                                                                                                enterDelay={500} // Delay before showing tooltip (in ms)
                                                                                                                leaveDelay={200} // Delay before hiding tooltip (in ms)
                                                                                                            >
                                                                                                                <IconButton onClick={() => toggleFunctionalComments(idx)}>
                                                                                                                    <VisibilityIcon fontSize="small" />
                                                                                                                </IconButton>
                                                                                                            </Tooltip>
                                                                                                        </span>
                                                                                                    );
                                                                                                }
                                                                                                return null;
                                                                                            })[0]}
                                                                                        </span>

                                                                                        {/* Approver Comments */}
                                                                                        <span style={{ marginLeft: '10px', alignItems: 'center' }}>
                                                                                            {action?.cmsForm?.notificationHeader?.cmsForms?.map((notification, idx) => (
                                                                                                notification?.approver_sgid.includes(action?.user_sgid) && (
                                                                                                    <span
                                                                                                        style={{ alignItems: 'center' }}
                                                                                                        onMouseEnter={() => setHoveredApprover(true)}
                                                                                                        onMouseLeave={() => setHoveredApprover(false)}
                                                                                                    >
                                                                                                        <Tooltip
                                                                                                            title={notification?.approver_comments} // Show the comment as tooltip text
                                                                                                            arrow
                                                                                                            enterDelay={500} // Delay before showing tooltip (in ms)
                                                                                                            leaveDelay={200} // Delay before hiding tooltip (in ms)
                                                                                                        >
                                                                                                            <IconButton onClick={() => toggleApproverComments(idx)}>
                                                                                                                <VisibilityIcon fontSize="small" />
                                                                                                            </IconButton>
                                                                                                        </Tooltip>
                                                                                                    </span>
                                                                                                )
                                                                                            ))}
                                                                                        </span>
                                                                                    </Typography>
                                                                                    <Typography style={{ color: '#616161', fontFamily: 'inherit', fontSize: '14px', fontWeight: 500 }}>
                                                                                        {(new Date(action.updated_at)).toISOString().split('T')[0]}
                                                                                    </Typography>
                                                                                </TimelineContent>
                                                                            </TimelineItem>
                                                                        ))}
                                                                    </Timeline>
                                                                )}
                                                            </CardContent>
                                                        </Card>
                                                    </React.Fragment>)
                                                }
                                                return null;
                                            })}
                                        {filterProgressData.length === 0 && <p>No records found.</p>}
                                    </Grid>
                                ) : null}

                                {showTab ? (
                                    <Grid item md={4} sx={{ padding: '0 16px', border: '5px solid #fff', borderWidth: '0 5px 0 0' }}>
                                        <Typography variant="h3" sx={{ fontSize: { xl: '16px', md: '14px' }, fontWeight: '600', margin: '12px 0', color: '#B51849' }}>{approvalLabel.includes(user) ? "AWAITING APPROVAL" : "PENDING APPROVAL"}</Typography>
                                        {console.log(filterApprovalData, "filterApprovalData2023")}
                                        {filterApprovalData
                                            .map((cms, index) =>
                                            // (
                                            // user === cms?.cmsForm?.user_id
                                            // || user === cms?.cmsForm?.notificationHeader?.cmsForms[0]?.functional_sgid[0] && !cms?.cmsForm?.notificationHeader?.cmsForms[0]?.functional_approval_status

                                            // || user === cms?.cmsForm?.notificationHeader?.cmsForms[0]?.functional_sgid[0]


                                            // user === cms?.cmsForm?.user_id && (cms?.cmsForm?.notification?.functional_approval_status === true || cms?.cmsForm?.notification?.functional_approval_status === false) && cms?.cmsForm?.notification?.cft_approval_status === false ||
                                            // (cms?.cmsForm?.notification?.functional_approval_status === true && cms?.cmsForm?.notification?.cft_approval_status === false && cms?.cmsForm?.notification?.approver_sgid?.length && cms?.cmsForm?.notification?.approver_sgid[0].includes(user))
                                            // ||
                                            // (cms?.cmsForm?.notification?.functional_sgid?.length && cms?.cmsForm?.notification?.functional_sgid[0].includes(user))
                                            // && cms?.cmsForm?.notification?.functional_approval_status !== true && cms?.cmsForm?.notification?.functional_mail_id
                                            // ) 
                                            {
                                                const cmsForm = cms?.cmsForm;
                                                const notificationHeader = cmsForm?.notificationHeader;
                                                // Check if user is the initiator of the form
                                                const userIsFormInitiator = user === cmsForm?.user_id &&
                                                    cms?.cmsForm?.notificationHeader?.cmsForms?.some(cmsForm => cmsForm?.functional_approval_status === true || cmsForm?.functional_approval_status === false) &&
                                                    cms?.cmsForm?.notificationHeader?.cmsForms?.some(cmsForm => cmsForm?.cft_approval_status === false);

                                                // Check if user is a functional approver and has not yet approved
                                                const userIsFunctionalApprover = notificationHeader?.cmsForms.some((approver) =>
                                                    approver?.functional_sgid?.includes(user) && !approver?.functional_approval_status
                                                );

                                                // Check if user is a CFT approver and has not yet approved
                                                const userIsCftApprover = notificationHeader?.cmsForms.some((approver) =>
                                                    approver?.approver_sgid?.includes(user) && !approver?.cft_approval_status && cms?.cmsForm?.notificationHeader?.cmsForms?.some(cmsForm => cmsForm?.functional_approval_status === true)
                                                );

                                                // Show card if user matches any of the conditions
                                                if (userIsFormInitiator || userIsFunctionalApprover || userIsCftApprover) {
                                                    return (<React.Fragment key={cms.id}>
                                                        {console.log(cms, "cms2060")}
                                                        <Card key={cms.id} style={{ width: "100%", position: 'relative', borderRadius: "8px", border: "1px solid #e2e2e2", background: "#FFF", marginBottom: "20px", boxShadow: "0px 2px 12px 0px rgba(0, 0, 0, 0.15)" }}>
                                                            <CardContent style={{ display: "flex", flexDirection: "column", padding: '12px' }}>
                                                                <Box sx={{ display: 'flex', alignItems: 'start', justifyContent: cms?.cmsForm?.changeRequired === null || cms?.cmsForm?.changeRequired === undefined ? "none" : "space-between", padding: "2px" }}>
                                                                    {cms?.cmsForm?.cms_no ?
                                                                        <Box>
                                                                            <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                CMS NO.
                                                                            </Typography>
                                                                            <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                {cms?.cmsForm?.cms_no}
                                                                            </Typography>
                                                                        </Box> : <></>
                                                                    }
                                                                    <div style={{ display: "flex", alignItems: "right", gap: "10px", position: "relative" }}>
                                                                        <Tooltip title="Click to review" arrow>
                                                                            <img
                                                                                src={menu}
                                                                                alt="menu"
                                                                                style={{ width: "28px", position: "absolute", right: 50, height: "20px", cursor: "pointer" }}
                                                                                onClick={(event) => handleMenuClick(event, cms.id)} />
                                                                        </Tooltip>
                                                                    </div>
                                                                    {showMenu && openedMenuId === cms.id && (
                                                                        <div
                                                                            ref={menuRef}
                                                                            style={{
                                                                                position: "absolute",
                                                                                top: 30,
                                                                                right: 20,
                                                                                width: 150,
                                                                                backgroundColor: "white",
                                                                                boxShadow: "none",
                                                                                borderRadius: 4,
                                                                                border: "1px solid #B51849",
                                                                                zIndex: 10,
                                                                            }}
                                                                        >
                                                                            {!showAlterNote ? (
                                                                                <>
                                                                                    <div
                                                                                        onClick={() => handleMenuItemClick("edit", cms)}
                                                                                        style={{
                                                                                            padding: "10px",
                                                                                            cursor: "pointer",
                                                                                            display: "flex",
                                                                                            alignItems: "center"
                                                                                        }}
                                                                                    >
                                                                                        <img
                                                                                            src={Edit}
                                                                                            alt="Edit Icon"
                                                                                            style={{ verticalAlign: "middle", marginRight: "5px", marginBottom: "5px", cursor: "pointer" }}
                                                                                        />
                                                                                        {"Review"}
                                                                                    </div>
                                                                                    <div
                                                                                        onClick={() => handleMenuItemClick("modify", cms)}
                                                                                        style={{
                                                                                            padding: "10px",
                                                                                            cursor: "pointer",
                                                                                            display: "flex",
                                                                                            alignItems: "center"
                                                                                        }}
                                                                                    >
                                                                                        <img
                                                                                            src={Edit}
                                                                                            alt="Edit Icon"
                                                                                            style={{ verticalAlign: "middle", marginRight: "5px", marginBottom: "5px", cursor: "pointer" }}
                                                                                        />
                                                                                        {"Approve/Reject"}
                                                                                    </div>
                                                                                </>
                                                                            ) : null}
                                                                            {showAlterNote ? (
                                                                                <div
                                                                                    onClick={() => handleMenuItemClick("alterationNote", cms)}
                                                                                    style={{
                                                                                        padding: "10px",
                                                                                        cursor: "pointer",
                                                                                        display: "flex",
                                                                                        alignItems: "center"
                                                                                    }}
                                                                                >
                                                                                    <img
                                                                                        src={Edit}
                                                                                        alt="Edit Icon"
                                                                                        style={{ verticalAlign: "middle", marginRight: "5px", marginBottom: "5px", cursor: "pointer" }}
                                                                                    />
                                                                                    {"Alternation Note"}
                                                                                </div>
                                                                            ) : null}
                                                                        </div>
                                                                    )}

                                                                </Box>

                                                                <Box sx={{ display: 'flex', alignItems: 'start', justifyContent: cms?.cmsForm?.changeRequired === null || cms?.cmsForm?.changeRequired === undefined ? "none" : "space-between", padding: "2px" }}>
                                                                    <div>
                                                                        <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                            Change Category
                                                                        </Typography>
                                                                        <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                            {cms?.cmsForm?.changeRequest?.change_request[0]?.change_category || ""}
                                                                        </Typography>
                                                                    </div>
                                                                    <div>
                                                                        <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                            {(() => {
                                                                                const { change_category, design_change, process_change } = cms?.cmsForm?.changeRequest?.change_request[0] || {};

                                                                                if (change_category === "Design Change") {
                                                                                    if (design_change === "Internal") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_int_customer ? "Customer" : ""}
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_int_customer || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    } else if (design_change === "External") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    Customer
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_ext_customer || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                } else if (change_category === "Process Change") {
                                                                                    if (process_change === "Internal") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_int_customer ? "Customer" : ""}
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_int_customer || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    } else if (process_change === "External") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    Customer
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_ext_customer || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                }

                                                                                return null; // If no condition matches, return null or handle accordingly
                                                                            })()}
                                                                        </Typography>
                                                                    </div>
                                                                </Box>
                                                                <Box sx={{ display: 'flex', alignItems: 'start', justifyContent: cms?.cmsForm?.changeRequired === null || cms?.cmsForm?.changeRequired === undefined ? "none" : "space-between", padding: "2px" }}>
                                                                    <div>
                                                                        <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                            {(() => {
                                                                                const { change_category, design_change, process_change } = cms?.cmsForm?.changeRequest?.change_request[0] || {};

                                                                                if (change_category === "Design Change") {
                                                                                    if (design_change === "Internal") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_int_model ? "Model" : ""}
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_int_model || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    } else if (design_change === "External") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    Model
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_ext_model || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                } else if (change_category === "Process Change") {
                                                                                    if (process_change === "Internal") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_int_model ? "Model" : ""}
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_int_model || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    } else if (process_change === "External") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    Model
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_ext_model || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                }

                                                                                return null; // If no condition matches, return null or handle accordingly
                                                                            })()}
                                                                        </Typography>
                                                                    </div>
                                                                    <div>
                                                                        <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                            {(() => {
                                                                                const { change_category, design_change, process_change } = cms?.cmsForm?.changeRequest?.change_request[0] || {};

                                                                                if (change_category === "Design Change") {
                                                                                    if (design_change === "Internal") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_int_glass_reference ? "Glass Reference" : ""}
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_int_glass_reference || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    } else if (design_change === "External") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    Glass Reference
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_ext_glass_reference || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                } else if (change_category === "Process Change") {
                                                                                    if (process_change === "Internal") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_int_glass_reference ? "Glass Reference" : ""}
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_int_glass_reference || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    } else if (process_change === "External") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    Glass Reference
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_ext_glass_reference || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                }

                                                                                return null; // If no condition matches, return null or handle accordingly
                                                                            })()}
                                                                        </Typography>
                                                                    </div>
                                                                </Box>
                                                                {cms?.cmsForm?.changeRequired?.change_required_remarks ?
                                                                    <Box sx={{ padding: '2px' }}>
                                                                        <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                            Change Required
                                                                        </Typography>
                                                                        <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "0" }}>
                                                                            {cms?.cmsForm?.changeRequired?.change_required_remarks}
                                                                        </Typography>
                                                                    </Box> : null
                                                                }
                                                                <Box style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', width: "100%", cursor: "pointer", borderTop: '1px solid #f4f4f4', padding: '2px' }}>
                                                                    <Box style={{ display: "flex" }}>
                                                                        <img
                                                                            src={TimeLine}
                                                                            alt="Timeline"
                                                                            style={{ marginRight: "10px" }}
                                                                            onClick={(event) => toggleAccordionTimeline(event, cms.cms_id)}
                                                                        />
                                                                        <Typography
                                                                            style={{
                                                                                color: "#616161",
                                                                                fontFamily: "inherit",
                                                                                fontSize: "14px",
                                                                                fontWeight: 500,
                                                                            }}
                                                                        >
                                                                            {moment(cms.updated_at).format("DD-MMM-YYYY")}
                                                                        </Typography>
                                                                    </Box>

                                                                    <AvatarGroup>

                                                                        {cms?.cmsForm?.created_by ?
                                                                            <div style={{ position: "relative" }}>
                                                                                <Tooltip title={cms?.cmsForm?.created_by}>
                                                                                    <Avatar style={{ width: "30px", height: "30px", fontSize: "14px", marginBottom: "10px", marginLeft: "50px" }}>
                                                                                        {`${cms?.cmsForm?.created_by.split(" ").pop().charAt(0)}${cms?.cmsForm?.created_by.charAt(0)}`}
                                                                                    </Avatar>
                                                                                </Tooltip>
                                                                            </div> : null
                                                                        }

                                                                    </AvatarGroup>
                                                                </Box>
                                                                {expandedCardId === cms.cms_id && (
                                                                    <Timeline sx={{
                                                                        [`& .${timelineItemClasses.root}:before`]: {
                                                                            flex: 0,
                                                                            padding: 0,
                                                                        },
                                                                    }}>
                                                                        {timeline.map((action, index) => (
                                                                            <TimelineItem key={index}>
                                                                                <TimelineSeparator>
                                                                                    <TimelineDot color="primary" />
                                                                                    {index !== timeline.length - 1 && <TimelineConnector />}
                                                                                </TimelineSeparator>
                                                                                <TimelineContent>
                                                                                    {/* Loop to display each approver's email if present */}
                                                                                    {(() => {
                                                                                        // Set to track displayed approver_mail_id, defined outside the map loop
                                                                                        const displayedApproverMailIds = new Set();

                                                                                        return action?.cmsForm?.notificationHeader?.cmsForms?.map((notification, idx) => {
                                                                                            // Check if the approver_mail_id exists and is not already displayed
                                                                                            if (notification?.approver_mail_id && !displayedApproverMailIds.has(notification.approver_mail_id)) {
                                                                                                // Add to Set to track it and ensure it's displayed only once
                                                                                                displayedApproverMailIds.add(notification.approver_mail_id);

                                                                                                // Extract the username part before "@" and remove anything after "."
                                                                                                const approverUsername = notification.approver_mail_id.split('@')[0].split('.')[0];

                                                                                                // Capitalize the first letter of the username
                                                                                                const formattedUsername = approverUsername.charAt(0).toUpperCase() + approverUsername.slice(1);

                                                                                                return (
                                                                                                    <Typography key={idx} style={{ color: '#616161', fontFamily: 'inherit', fontSize: '14px', fontWeight: 500 }}>
                                                                                                        {action.status === "pending" ? "pending with " + formattedUsername : null}
                                                                                                    </Typography>
                                                                                                );
                                                                                            }

                                                                                            // Return null if approver_mail_id is a duplicate or missing
                                                                                            return null;
                                                                                        });
                                                                                    })()}

                                                                                    {/* Loop to display each functional email if present */}
                                                                                    {(() => {
                                                                                        // Set to track displayed functional_mail_id, defined outside the map loop
                                                                                        const displayedFunctionalMailIds = new Set();

                                                                                        return action?.cmsForm?.notificationHeader?.cmsForms?.map((notification, idx) => {
                                                                                            // Check if the functional_mail_id exists and is not already displayed
                                                                                            if (notification?.functional_mail_id && !displayedFunctionalMailIds.has(notification.functional_mail_id)) {
                                                                                                // Add to Set to track it and ensure it's displayed only once
                                                                                                displayedFunctionalMailIds.add(notification.functional_mail_id);

                                                                                                // Extract the username part before "@" and remove anything after "."
                                                                                                const username = notification.functional_mail_id.split('@')[0].split('.')[0];

                                                                                                // Capitalize the first letter of the username
                                                                                                const formattedUsername = username.charAt(0).toUpperCase() + username.slice(1);

                                                                                                return (
                                                                                                    <Typography key={idx} style={{ color: '#616161', fontFamily: 'inherit', fontSize: '14px', fontWeight: 500 }}>
                                                                                                        {action.status === "pending" ? "pending with " + formattedUsername : null}
                                                                                                    </Typography>
                                                                                                );
                                                                                            }

                                                                                            // Return null if functional_mail_id is a duplicate or missing
                                                                                            return null;
                                                                                        });
                                                                                    })()}

                                                                                    <Typography style={{ color: '#616161', fontFamily: 'inherit', fontSize: '14px', fontWeight: 500 }}>
                                                                                        {action.status === "conditionallyapproved" ? "conditionally approved" + " " + "by" + " " + action.user_approval_name :
                                                                                            action.status === "submittedbackforqueries" ? "submitted back for queries" + " " + "by" + " " + action.user_approval_name :
                                                                                                action.status === "pending" ? "initiated" + " " + "by" + " " + action.user_approval_name :
                                                                                                    action.status + " " + "by" + " " + action.user_approval_name}
                                                                                        <span style={{ marginLeft: '10px', alignItems: 'center' }}>
                                                                                            {/* Functional Comments */}
                                                                                            {action?.cmsForm?.notificationHeader?.cmsForms?.map((notification, idx) => {
                                                                                                if (notification?.functional_sgid?.includes(action?.user_sgid)) {
                                                                                                    return (
                                                                                                        <span
                                                                                                            style={{ marginLeft: '10px', alignItems: 'center' }}
                                                                                                            onMouseEnter={() => setHoveredFunctional(true)}
                                                                                                            onMouseLeave={() => setHoveredFunctional(false)}
                                                                                                        >
                                                                                                            <Tooltip
                                                                                                                title={notification.functional_comments} // Show the comment as tooltip text
                                                                                                                arrow
                                                                                                                enterDelay={500} // Delay before showing tooltip (in ms)
                                                                                                                leaveDelay={200} // Delay before hiding tooltip (in ms)
                                                                                                            >
                                                                                                                <IconButton onClick={() => toggleFunctionalComments(idx)}>
                                                                                                                    <VisibilityIcon fontSize="small" />
                                                                                                                </IconButton>
                                                                                                            </Tooltip>
                                                                                                        </span>
                                                                                                    );
                                                                                                }
                                                                                                return null;
                                                                                            })[0]}
                                                                                        </span>

                                                                                        {/* Approver Comments */}
                                                                                        <span style={{ marginLeft: '10px', alignItems: 'center' }}>
                                                                                            {action?.cmsForm?.notificationHeader?.cmsForms?.map((notification, idx) => (
                                                                                                notification?.approver_sgid.includes(action?.user_sgid) && (
                                                                                                    <span
                                                                                                        style={{ alignItems: 'center' }}
                                                                                                        onMouseEnter={() => setHoveredApprover(true)}
                                                                                                        onMouseLeave={() => setHoveredApprover(false)}
                                                                                                    >
                                                                                                        <Tooltip
                                                                                                            title={notification?.approver_comments} // Show the comment as tooltip text
                                                                                                            arrow
                                                                                                            enterDelay={500} // Delay before showing tooltip (in ms)
                                                                                                            leaveDelay={200} // Delay before hiding tooltip (in ms)
                                                                                                        >
                                                                                                            <IconButton onClick={() => toggleApproverComments(idx)}>
                                                                                                                <VisibilityIcon fontSize="small" />
                                                                                                            </IconButton>
                                                                                                        </Tooltip>
                                                                                                    </span>
                                                                                                )
                                                                                            ))}
                                                                                        </span>
                                                                                    </Typography>
                                                                                    <Typography style={{ color: '#616161', fontFamily: 'inherit', fontSize: '14px', fontWeight: 500 }}>
                                                                                        {(new Date(action.updated_at)).toISOString().split('T')[0]}
                                                                                    </Typography>
                                                                                </TimelineContent>
                                                                            </TimelineItem>
                                                                        ))}
                                                                    </Timeline>
                                                                )}
                                                            </CardContent>
                                                        </Card>
                                                    </React.Fragment>)
                                                }
                                                return null; // Skip rendering this card if no condition matches
                                            })}
                                        {filterApprovalData.length === 0 && <p>No records found.</p>}
                                    </Grid>
                                ) : null}


                                {!showTab ? (
                                    <Grid item md={4} sx={{ padding: '0 16px', border: '5px solid #fff', borderWidth: '0 5px 0 0' }}>
                                        <Typography variant="h3" sx={{ fontSize: { xl: '16px', md: '14px' }, fontWeight: '600', margin: '12px 0', color: '#B51849' }}>IN DRAFT</Typography>
                                        {filterDraftData
                                            .map((cms, index) =>
                                                (user === cms?.cmsForm?.user_id) ? (
                                                    <React.Fragment key={cms.id}>
                                                        <Card key={cms.id} style={{ width: "100%", position: 'relative', borderRadius: "8px", border: "1px solid #e2e2e2", background: "#FFF", marginBottom: "20px", boxShadow: "0px 2px 12px 0px rgba(0, 0, 0, 0.15)" }}>
                                                            <CardContent style={{ display: "flex", flexDirection: "column", padding: '12px' }}>
                                                                <Box sx={{ display: 'flex', alignItems: 'start', justifyContent: cms?.cmsForm?.changeRequired === null || cms?.cmsForm?.changeRequired === undefined ? "none" : "space-between", padding: "2px" }}>
                                                                    {cms?.cmsForm?.cms_no ?
                                                                        <Box>
                                                                            <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                CMS NO.
                                                                            </Typography>
                                                                            <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                {cms?.cmsForm?.cms_no}
                                                                            </Typography>
                                                                        </Box> : <></>
                                                                    }
                                                                    <div style={{ display: "flex", alignItems: "right", gap: "10px" }}>
                                                                        <Tooltip title="Click to review" arrow>
                                                                            <img
                                                                                src={menu}
                                                                                alt="menu"
                                                                                style={{ width: "28px", position: "absolute", right: 50, height: "20px", cursor: "pointer" }}
                                                                                onClick={(event) => handleMenuClick(event, cms.id)} />
                                                                        </Tooltip>
                                                                    </div>
                                                                    {showMenu && openedMenuId === cms.id && (
                                                                        <div
                                                                            ref={menuRef}
                                                                            style={{
                                                                                position: "absolute",
                                                                                top: 30,
                                                                                right: 20,
                                                                                width: 150,
                                                                                backgroundColor: "white",
                                                                                boxShadow: "none",
                                                                                borderRadius: 4,
                                                                                border: "1px solid #B51849",
                                                                                zIndex: 10,
                                                                            }}
                                                                        >
                                                                            <div
                                                                                onClick={() => handleMenuItemClick("edit", cms)}
                                                                                style={{
                                                                                    padding: "10px",
                                                                                    cursor: "pointer",
                                                                                    display: "flex",
                                                                                    alignItems: "center"
                                                                                }}>
                                                                                <img
                                                                                    src={Edit}
                                                                                    alt="Edit Icon"
                                                                                    style={{ verticalAlign: "middle", marginRight: "5px", marginBottom: "5px", cursor: "pointer" }}
                                                                                />
                                                                                {"Review"}
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </Box>
                                                                <Box sx={{ display: 'flex', alignItems: 'start', justifyContent: cms?.cmsForm?.changeRequired === null || cms?.cmsForm?.changeRequired === undefined ? "none" : "space-between", padding: "2px" }}>
                                                                    <div>
                                                                        <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                            Change Category
                                                                        </Typography>
                                                                        <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                            {cms?.cmsForm?.changeRequest?.change_request[0]?.change_category || ""}
                                                                        </Typography>
                                                                    </div>
                                                                </Box>
                                                                <Box sx={{ display: 'flex', alignItems: 'start', justifyContent: cms?.cmsForm?.changeRequired === null || cms?.cmsForm?.changeRequired === undefined ? "none" : "space-between", padding: "2px" }}>
                                                                    <div>
                                                                        <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                            {(() => {
                                                                                const { change_category, design_change, process_change } = cms?.cmsForm?.changeRequest?.change_request[0] || {};

                                                                                if (change_category === "Design Change") {
                                                                                    if (design_change === "Internal") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_int_customer ? "Customer" : ""}
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_int_customer || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    } else if (design_change === "External") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    Customer
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_ext_customer || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                } else if (change_category === "Process Change") {
                                                                                    if (process_change === "Internal") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_int_customer ? "Customer" : ""}
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_int_customer || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    } else if (process_change === "External") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    Customer
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_ext_customer || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                }

                                                                                return null; // If no condition matches, return null or handle accordingly
                                                                            })()}
                                                                        </Typography>
                                                                    </div>
                                                                </Box>
                                                                {cms?.cmsForm?.changeRequired?.change_required_remarks ?
                                                                    <Box sx={{ padding: '2px' }}>
                                                                        <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                            Change Required
                                                                        </Typography>
                                                                        <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "0" }}>
                                                                            {cms?.cmsForm?.changeRequired?.change_required_remarks}
                                                                        </Typography>
                                                                    </Box> : null
                                                                }
                                                                <Box style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', width: "100%", cursor: "pointer", borderTop: '1px solid #f4f4f4', padding: '2px' }}>
                                                                    <Box style={{ display: "flex" }}>
                                                                        <img
                                                                            src={TimeLine}
                                                                            alt="Timeline"
                                                                            style={{ marginRight: "10px" }}
                                                                            onClick={(event) => toggleAccordionTimeline(event, cms.cms_id)}
                                                                        />
                                                                        <Typography
                                                                            style={{
                                                                                color: "#616161",
                                                                                fontFamily: "inherit",
                                                                                fontSize: "14px",
                                                                                fontWeight: 500,
                                                                            }}
                                                                        >
                                                                            {moment(cms.updated_at).format("DD-MMM-YYYY")}
                                                                        </Typography>
                                                                    </Box>

                                                                    <AvatarGroup>

                                                                        {cms?.cmsForm?.created_by ?
                                                                            <div style={{ position: "relative" }}>
                                                                                <Tooltip title={cms?.cmsForm?.created_by}>
                                                                                    <Avatar style={{ width: "30px", height: "30px", fontSize: "14px", marginBottom: "10px", marginLeft: "50px" }}>
                                                                                        {`${cms?.cmsForm?.created_by.split(" ").pop().charAt(0)}${cms?.cmsForm?.created_by.charAt(0)}`}
                                                                                    </Avatar>
                                                                                </Tooltip>
                                                                            </div> : null
                                                                        }

                                                                    </AvatarGroup>
                                                                </Box>
                                                                {expandedCardId === cms.cms_id && (
                                                                    <Timeline sx={{
                                                                        [`& .${timelineItemClasses.root}:before`]: {
                                                                            flex: 0,
                                                                            padding: 0,
                                                                        },
                                                                    }}>
                                                                        {timeline.map((action, index) => (
                                                                            <TimelineItem key={index}>
                                                                                <TimelineSeparator>
                                                                                    <TimelineDot color="primary" />
                                                                                    {index !== timeline.length - 1 && <TimelineConnector />}
                                                                                </TimelineSeparator>
                                                                                <TimelineContent>
                                                                                    <Typography style={{ color: '#616161', fontFamily: 'inherit', fontSize: '14px', fontWeight: 500 }}>
                                                                                        {action.status === "conditionallyapproved" ? "conditionally approved" + " " + "by" + " " + action.user_approval_name :
                                                                                            action.status === "submittedbackforqueries" ? "submitted back for queries" + " " + "by" + " " + action.user_approval_name :
                                                                                                action.status === "pending" ? "initiated" + " " + "by" + " " + action.user_approval_name :
                                                                                                    action.status + " " + "by" + " " + action.user_approval_name}
                                                                                    </Typography>
                                                                                    <Typography style={{ color: '#616161', fontFamily: 'inherit', fontSize: '14px', fontWeight: 500 }}>
                                                                                        {(new Date(action.updated_at)).toISOString().split('T')[0]}
                                                                                    </Typography>
                                                                                </TimelineContent>
                                                                            </TimelineItem>
                                                                        ))}
                                                                    </Timeline>
                                                                )}
                                                            </CardContent>
                                                        </Card>
                                                    </React.Fragment>) : null
                                            )}
                                        {filterDraftData.length === 0 && <p>No records found.</p>}
                                    </Grid>
                                ) : null}

                                {!showTab ? (
                                    <Grid item md={4} sx={{ padding: '0 16px', border: '5px solid #fff', borderWidth: '0 5px 0 0' }}>
                                        <Typography variant="h3" sx={{ fontSize: { xl: '16px', md: '14px' }, fontWeight: '600', margin: '12px 0', color: '#B51849' }}>APPROVED</Typography>
                                        {filterCompleteData
                                            .map((cms, index) =>
                                                <React.Fragment key={cms.id}>
                                                    <Card key={cms.id} style={{ width: "100%", position: 'relative', borderRadius: "8px", border: "1px solid #e2e2e2", background: "#FFF", marginBottom: "20px", boxShadow: "0px 2px 12px 0px rgba(0, 0, 0, 0.15)" }}>
                                                        <CardContent style={{ display: "flex", flexDirection: "column", padding: '12px' }}>
                                                            <Box sx={{ display: 'flex', alignItems: 'start', justifyContent: cms?.cmsForm?.changeRequired === null || cms?.cmsForm?.changeRequired === undefined ? "none" : "space-between", padding: "2px" }}>
                                                                {cms?.cmsForm?.cms_no ?
                                                                    <Box>
                                                                        <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                            CMS NO.
                                                                        </Typography>
                                                                        <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                            {cms?.cmsForm?.cms_no}
                                                                        </Typography>
                                                                    </Box> : <></>
                                                                }
                                                                <Button
                                                                    sx={{
                                                                        padding: '0',
                                                                        minWidth: '30px'
                                                                    }}
                                                                    id="basic-button"
                                                                    onClick={(event) => handlePdfMenuClick(event, cms.id)}
                                                                >
                                                                    <MoreVertIcon sx={{ color: '#000' }} />
                                                                </Button>
                                                                {showPdfMenu && openedMenuId === cms.id && (
                                                                    <Box
                                                                        ref={menuRef}
                                                                        style={{
                                                                            position: "absolute",
                                                                            top: 30,
                                                                            right: 20,
                                                                            width: 180,
                                                                            backgroundColor: "white",
                                                                            boxShadow: "none",
                                                                            borderRadius: 4,
                                                                            border: "1px solid #B51849",
                                                                            zIndex: 10,
                                                                            padding: "10px",
                                                                            cursor: "pointer",
                                                                            display: "flex"
                                                                        }}
                                                                        onClick={handlePDF}
                                                                    >
                                                                        <DownloadIcon />
                                                                        <Box>
                                                                            <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: "16px", fontWeight: 600 }} onClick={handlePDF} >
                                                                                Download PDF
                                                                            </Typography>
                                                                        </Box>
                                                                    </Box>
                                                                )}
                                                                {
                                                                    previewForm ?
                                                                        <>
                                                                            <Box id="cust-print" style={{ display: "none", marginTop: 20 }}>
                                                                                <Box ref={printRef} style={{
                                                                                    margin: "10px",
                                                                                    width: "1150px"
                                                                                }}>
                                                                                    <style>{portraitOrientation()}</style>
                                                                                    <ReportCmsPdf data={cmsPdfData} />

                                                                                </Box>
                                                                            </Box>
                                                                        </>
                                                                        :
                                                                        <></>
                                                                }
                                                                {/* <div style={{ display: "flex", alignItems: "right", gap: "10px" }}>
                                                                        <img
                                                                            src={menu}
                                                                            alt="menu"
                                                                            style={{ width: "28px", position: "absolute", right: 50, height: "20px", cursor: "pointer" }}
                                                                            onClick={(event) => handleMenuClick(event, cms.id)} />
                                                                    </div> */}
                                                                {showMenu && openedMenuId === cms.id && (
                                                                    <div
                                                                        ref={menuRef}
                                                                        style={{
                                                                            position: "absolute",
                                                                            top: 30,
                                                                            right: 20,
                                                                            width: 150,
                                                                            backgroundColor: "white",
                                                                            boxShadow: "none",
                                                                            borderRadius: 4,
                                                                            border: "1px solid #B51849",
                                                                            zIndex: 10,
                                                                        }}
                                                                    >
                                                                        <div
                                                                            onClick={() => handleMenuItemClick("edit", cms)}
                                                                            style={{
                                                                                padding: "10px",
                                                                                cursor: "pointer",
                                                                                display: "flex",
                                                                                alignItems: "center"
                                                                            }}>
                                                                            <img
                                                                                src={Edit}
                                                                                alt="Edit Icon"
                                                                                style={{ verticalAlign: "middle", marginRight: "5px", marginBottom: "5px", cursor: "pointer" }}
                                                                            />
                                                                            {"Review"}
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </Box>
                                                            <Box sx={{ display: 'flex', alignItems: 'start', justifyContent: cms?.cmsForm?.changeRequired === null || cms?.cmsForm?.changeRequired === undefined ? "none" : "space-between", padding: "2px" }}>
                                                                <div>
                                                                    <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                        Change Category
                                                                    </Typography>
                                                                    <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                        {cms?.cmsForm?.changeRequest?.change_request[0]?.change_category || ""}
                                                                    </Typography>
                                                                </div>
                                                                <div>
                                                                    <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                        {(() => {
                                                                            const { change_category, design_change, process_change } = cms?.cmsForm?.changeRequest?.change_request[0] || {};

                                                                            if (change_category === "Design Change") {
                                                                                if (design_change === "Internal") {
                                                                                    return (
                                                                                        <>
                                                                                            <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                {cms?.cmsForm?.changeRequest?.change_request[0]?.design_int_customer ? "Customer" : ""}
                                                                                            </Typography>
                                                                                            <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                {cms?.cmsForm?.changeRequest?.change_request[0]?.design_int_customer || ""}
                                                                                            </Typography>
                                                                                        </>
                                                                                    );
                                                                                } else if (design_change === "External") {
                                                                                    return (
                                                                                        <>
                                                                                            <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                Customer
                                                                                            </Typography>
                                                                                            <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                {cms?.cmsForm?.changeRequest?.change_request[0]?.design_ext_customer || ""}
                                                                                            </Typography>
                                                                                        </>
                                                                                    );
                                                                                }
                                                                            } else if (change_category === "Process Change") {
                                                                                if (process_change === "Internal") {
                                                                                    return (
                                                                                        <>
                                                                                            <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                {cms?.cmsForm?.changeRequest?.change_request[0]?.process_int_customer ? "Customer" : ""}
                                                                                            </Typography>
                                                                                            <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                {cms?.cmsForm?.changeRequest?.change_request[0]?.process_int_customer || ""}
                                                                                            </Typography>
                                                                                        </>
                                                                                    );
                                                                                } else if (process_change === "External") {
                                                                                    return (
                                                                                        <>
                                                                                            <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                Customer
                                                                                            </Typography>
                                                                                            <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                {cms?.cmsForm?.changeRequest?.change_request[0]?.process_ext_customer || ""}
                                                                                            </Typography>
                                                                                        </>
                                                                                    );
                                                                                }
                                                                            }

                                                                            return null; // If no condition matches, return null or handle accordingly
                                                                        })()}
                                                                    </Typography>
                                                                </div>
                                                            </Box>
                                                            <Box sx={{ display: 'flex', alignItems: 'start', justifyContent: cms?.cmsForm?.changeRequired === null || cms?.cmsForm?.changeRequired === undefined ? "none" : "space-between", padding: "2px" }}>

                                                                <div>
                                                                    <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                        {(() => {
                                                                            const { change_category, design_change, process_change } = cms?.cmsForm?.changeRequest?.change_request[0] || {};

                                                                            if (change_category === "Design Change") {
                                                                                if (design_change === "Internal") {
                                                                                    return (
                                                                                        <>
                                                                                            <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                {cms?.cmsForm?.changeRequest?.change_request[0]?.design_int_model ? "Model" : ""}
                                                                                            </Typography>
                                                                                            <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                {cms?.cmsForm?.changeRequest?.change_request[0]?.design_int_model || ""}
                                                                                            </Typography>
                                                                                        </>
                                                                                    );
                                                                                } else if (design_change === "External") {
                                                                                    return (
                                                                                        <>
                                                                                            <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                Model
                                                                                            </Typography>
                                                                                            <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                {cms?.cmsForm?.changeRequest?.change_request[0]?.design_ext_model || ""}
                                                                                            </Typography>
                                                                                        </>
                                                                                    );
                                                                                }
                                                                            } else if (change_category === "Process Change") {
                                                                                if (process_change === "Internal") {
                                                                                    return (
                                                                                        <>
                                                                                            <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                {cms?.cmsForm?.changeRequest?.change_request[0]?.process_int_model ? "Model" : ""}
                                                                                            </Typography>
                                                                                            <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                {cms?.cmsForm?.changeRequest?.change_request[0]?.process_int_model || ""}
                                                                                            </Typography>
                                                                                        </>
                                                                                    );
                                                                                } else if (process_change === "External") {
                                                                                    return (
                                                                                        <>
                                                                                            <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                Model
                                                                                            </Typography>
                                                                                            <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                {cms?.cmsForm?.changeRequest?.change_request[0]?.process_ext_model || ""}
                                                                                            </Typography>
                                                                                        </>
                                                                                    );
                                                                                }
                                                                            }

                                                                            return null; // If no condition matches, return null or handle accordingly
                                                                        })()}
                                                                    </Typography>
                                                                </div>
                                                                <div>
                                                                    <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                        {(() => {
                                                                            const { change_category, design_change, process_change } = cms?.cmsForm?.changeRequest?.change_request[0] || {};

                                                                            if (change_category === "Design Change") {
                                                                                if (design_change === "Internal") {
                                                                                    return (
                                                                                        <>
                                                                                            <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                {cms?.cmsForm?.changeRequest?.change_request[0]?.design_int_glass_reference ? "Glass Reference" : ""}
                                                                                            </Typography>
                                                                                            <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                {cms?.cmsForm?.changeRequest?.change_request[0]?.design_int_glass_reference || ""}
                                                                                            </Typography>
                                                                                        </>
                                                                                    );
                                                                                } else if (design_change === "External") {
                                                                                    return (
                                                                                        <>
                                                                                            <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                Glass Reference
                                                                                            </Typography>
                                                                                            <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                {cms?.cmsForm?.changeRequest?.change_request[0]?.design_ext_glass_reference || ""}
                                                                                            </Typography>
                                                                                        </>
                                                                                    );
                                                                                }
                                                                            } else if (change_category === "Process Change") {
                                                                                if (process_change === "Internal") {
                                                                                    return (
                                                                                        <>
                                                                                            <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                {cms?.cmsForm?.changeRequest?.change_request[0]?.process_int_glass_reference ? "Glass Reference" : ""}
                                                                                            </Typography>
                                                                                            <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                {cms?.cmsForm?.changeRequest?.change_request[0]?.process_int_glass_reference || ""}
                                                                                            </Typography>
                                                                                        </>
                                                                                    );
                                                                                } else if (process_change === "External") {
                                                                                    return (
                                                                                        <>
                                                                                            <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                Glass Reference
                                                                                            </Typography>
                                                                                            <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                {cms?.cmsForm?.changeRequest?.change_request[0]?.process_ext_glass_reference || ""}
                                                                                            </Typography>
                                                                                        </>
                                                                                    );
                                                                                }
                                                                            }

                                                                            return null; // If no condition matches, return null or handle accordingly
                                                                        })()}
                                                                    </Typography>
                                                                </div>
                                                            </Box>
                                                            {cms?.cmsForm?.changeRequired?.change_required_remarks ?
                                                                <Box sx={{ padding: '2px' }}>
                                                                    <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                        Change Required
                                                                    </Typography>
                                                                    <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "0" }}>
                                                                        {cms?.cmsForm?.changeRequired?.change_required_remarks}
                                                                    </Typography>
                                                                </Box> : null
                                                            }
                                                            <Box style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', width: "100%", cursor: "pointer", borderTop: '1px solid #f4f4f4', padding: '2px' }}>
                                                                <Box style={{ display: "flex" }}>
                                                                    <img
                                                                        src={TimeLine}
                                                                        alt="Timeline"
                                                                        style={{ marginRight: "10px" }}
                                                                        onClick={(event) => toggleAccordionTimeline(event, cms.cms_id)}
                                                                    />
                                                                    <Typography
                                                                        style={{
                                                                            color: "#616161",
                                                                            fontFamily: "inherit",
                                                                            fontSize: "14px",
                                                                            fontWeight: 500,
                                                                        }}
                                                                    >
                                                                        {moment(cms.updated_at).format("DD-MMM-YYYY")}
                                                                    </Typography>
                                                                </Box>

                                                                <AvatarGroup>

                                                                    {cms?.cmsForm?.created_by ?
                                                                        <div style={{ position: "relative" }}>
                                                                            <Tooltip title={cms?.cmsForm?.created_by}>
                                                                                <Avatar style={{ width: "30px", height: "30px", fontSize: "14px", marginBottom: "10px", marginLeft: "50px" }}>
                                                                                    {`${cms?.cmsForm?.created_by.split(" ").pop().charAt(0)}${cms?.cmsForm?.created_by.charAt(0)}`}
                                                                                </Avatar>
                                                                            </Tooltip>
                                                                        </div> : null
                                                                    }

                                                                </AvatarGroup>
                                                            </Box>
                                                            {expandedCardId === cms.cms_id && (
                                                                <Timeline sx={{
                                                                    [`& .${timelineItemClasses.root}:before`]: {
                                                                        flex: 0,
                                                                        padding: 0,
                                                                    },
                                                                }}>
                                                                    {timeline.map((action, index) => (
                                                                        <TimelineItem key={index}>
                                                                            <TimelineSeparator>
                                                                                <TimelineDot color="primary" />
                                                                                {index !== timeline.length - 1 && <TimelineConnector />}
                                                                            </TimelineSeparator>
                                                                            <TimelineContent>
                                                                                {/* Loop to display each approver's email if present */}
                                                                                {(() => {
                                                                                    // Set to track displayed approver_mail_id, defined outside the map loop
                                                                                    const displayedApproverMailIds = new Set();

                                                                                    return action?.cmsForm?.notificationHeader?.cmsForms?.map((notification, idx) => {
                                                                                        // Check if the approver_mail_id exists and is not already displayed
                                                                                        if (notification?.approver_mail_id && !displayedApproverMailIds.has(notification.approver_mail_id)) {
                                                                                            // Add to Set to track it and ensure it's displayed only once
                                                                                            displayedApproverMailIds.add(notification.approver_mail_id);

                                                                                            // Extract the username part before "@" and remove anything after "."
                                                                                            const approverUsername = notification.approver_mail_id.split('@')[0].split('.')[0];

                                                                                            // Capitalize the first letter of the username
                                                                                            const formattedUsername = approverUsername.charAt(0).toUpperCase() + approverUsername.slice(1);

                                                                                            return (
                                                                                                <Typography key={idx} style={{ color: '#616161', fontFamily: 'inherit', fontSize: '14px', fontWeight: 500 }}>
                                                                                                    {action.status === "pending" ? "pending with " + formattedUsername : null}
                                                                                                </Typography>
                                                                                            );
                                                                                        }

                                                                                        // Return null if approver_mail_id is a duplicate or missing
                                                                                        return null;
                                                                                    });
                                                                                })()}

                                                                                {/* Loop to display each functional email if present */}
                                                                                {(() => {
                                                                                    // Set to track displayed functional_mail_id, defined outside the map loop
                                                                                    const displayedFunctionalMailIds = new Set();

                                                                                    return action?.cmsForm?.notificationHeader?.cmsForms?.map((notification, idx) => {
                                                                                        // Check if the functional_mail_id exists and is not already displayed
                                                                                        if (notification?.functional_mail_id && !displayedFunctionalMailIds.has(notification.functional_mail_id)) {
                                                                                            // Add to Set to track it and ensure it's displayed only once
                                                                                            displayedFunctionalMailIds.add(notification.functional_mail_id);

                                                                                            // Extract the username part before "@" and remove anything after "."
                                                                                            const username = notification.functional_mail_id.split('@')[0].split('.')[0];

                                                                                            // Capitalize the first letter of the username
                                                                                            const formattedUsername = username.charAt(0).toUpperCase() + username.slice(1);

                                                                                            return (
                                                                                                <Typography key={idx} style={{ color: '#616161', fontFamily: 'inherit', fontSize: '14px', fontWeight: 500 }}>
                                                                                                    {action.status === "pending" ? "pending with " + formattedUsername : null}
                                                                                                </Typography>
                                                                                            );
                                                                                        }

                                                                                        // Return null if functional_mail_id is a duplicate or missing
                                                                                        return null;
                                                                                    });
                                                                                })()}

                                                                                <Typography style={{ color: '#616161', fontFamily: 'inherit', fontSize: '14px', fontWeight: 500 }}>
                                                                                    {action.status === "conditionallyapproved" ? "conditionally approved" + " " + "by" + " " + action.user_approval_name :
                                                                                        action.status === "submittedbackforqueries" ? "submitted back for queries" + " " + "by" + " " + action.user_approval_name :
                                                                                            action.status === "pending" ? "initiated" + " " + "by" + " " + action.user_approval_name :
                                                                                                action.status + " " + "by" + " " + action.user_approval_name}
                                                                                    <span style={{ marginLeft: '10px', alignItems: 'center' }}>
                                                                                        {/* Functional Comments */}
                                                                                        {action?.cmsForm?.notificationHeader?.cmsForms?.map((notification, idx) => {
                                                                                            if (notification?.functional_sgid?.includes(action?.user_sgid)) {
                                                                                                return (
                                                                                                    <span
                                                                                                        style={{ marginLeft: '10px', alignItems: 'center' }}
                                                                                                        onMouseEnter={() => setHoveredFunctional(true)}
                                                                                                        onMouseLeave={() => setHoveredFunctional(false)}
                                                                                                    >
                                                                                                        <Tooltip
                                                                                                            title={notification.functional_comments} // Show the comment as tooltip text
                                                                                                            arrow
                                                                                                            enterDelay={500} // Delay before showing tooltip (in ms)
                                                                                                            leaveDelay={200} // Delay before hiding tooltip (in ms)
                                                                                                        >
                                                                                                            <IconButton onClick={() => toggleFunctionalComments(idx)}>
                                                                                                                <VisibilityIcon fontSize="small" />
                                                                                                            </IconButton>
                                                                                                        </Tooltip>
                                                                                                    </span>
                                                                                                );
                                                                                            }
                                                                                            return null;
                                                                                        })[0]}
                                                                                    </span>

                                                                                    {/* Approver Comments */}
                                                                                    <span style={{ marginLeft: '10px', alignItems: 'center' }}>
                                                                                        {action?.cmsForm?.notificationHeader?.cmsForms?.map((notification, idx) => (
                                                                                            notification?.approver_sgid.includes(action?.user_sgid) && (
                                                                                                <span
                                                                                                    style={{ alignItems: 'center' }}
                                                                                                    onMouseEnter={() => setHoveredApprover(true)}
                                                                                                    onMouseLeave={() => setHoveredApprover(false)}
                                                                                                >
                                                                                                    <Tooltip
                                                                                                        title={notification?.approver_comments} // Show the comment as tooltip text
                                                                                                        arrow
                                                                                                        enterDelay={500} // Delay before showing tooltip (in ms)
                                                                                                        leaveDelay={200} // Delay before hiding tooltip (in ms)
                                                                                                    >
                                                                                                        <IconButton onClick={() => toggleApproverComments(idx)}>
                                                                                                            <VisibilityIcon fontSize="small" />
                                                                                                        </IconButton>
                                                                                                    </Tooltip>
                                                                                                </span>
                                                                                            )
                                                                                        ))}
                                                                                    </span>
                                                                                </Typography>
                                                                                <Typography style={{ color: '#616161', fontFamily: 'inherit', fontSize: '14px', fontWeight: 500 }}>
                                                                                    {(new Date(action.updated_at)).toISOString().split('T')[0]}
                                                                                </Typography>
                                                                            </TimelineContent>
                                                                        </TimelineItem>
                                                                    ))}
                                                                </Timeline>
                                                            )}
                                                        </CardContent>
                                                    </Card>
                                                </React.Fragment>
                                            )}
                                        {filterCompleteData.length === 0 && <p>No records found.</p>}
                                    </Grid>
                                ) : null}

                                {!showTab ? (
                                    <Grid item md={4} sx={{ padding: '0 16px', border: '5px solid #fff', borderWidth: '0 5px 0 0' }}>
                                        <Typography variant="h3" sx={{ fontSize: { xl: '16px', md: '14px' }, fontWeight: '600', margin: '12px 0', color: '#B51849' }}>REJECTED/SUBMITTED BACK FOR QUERIES</Typography>
                                        {filterRejectData
                                            .map((cms, index) =>
                                            // (user === cms?.cmsForm?.user_id ||
                                            //     (cms?.cmsForm?.notification?.cft_approval_status === false && cms?.cmsForm?.notification?.approver_sgid?.length && cms?.cmsForm?.notification?.approver_sgid[0].includes(user)) ||
                                            //     (cms?.cmsForm?.notification?.functional_approval_status === false && cms?.cmsForm?.notification?.functional_sgid?.length && cms?.cmsForm?.notification?.functional_sgid[0].includes(user))) 

                                            {
                                                const cmsForm = cms?.cmsForm;
                                                const notificationHeader = cmsForm?.notificationHeader;

                                                // Check if user is the initiator of the form
                                                const userIsFormInitiator = user === cmsForm?.user_id;

                                                // Check if user is a functional approver and has not yet approved
                                                const userIsFunctionalApprover = notificationHeader?.cmsForms.some((approver) =>
                                                    approver?.functional_sgid?.includes(user) && !approver?.functional_approval_status
                                                );

                                                // Check if user is a CFT approver and has not yet approved
                                                const userIsCftApprover = notificationHeader?.cmsForms.some((approver) =>
                                                    approver?.approver_sgid?.includes(user) && !approver?.cft_approval_status && cms?.cmsForm?.notificationHeader?.cmsForms?.some(cmsForm => cmsForm?.functional_approval_status === true)
                                                );

                                                // Show card if user matches any of the conditions
                                                if (userIsFormInitiator || userIsFunctionalApprover || userIsCftApprover) {
                                                    return (<React.Fragment key={cms.id}>
                                                        <Card key={cms.id} style={{ width: "100%", position: 'relative', borderRadius: "8px", border: "1px solid #e2e2e2", background: "#FFF", marginBottom: "20px", boxShadow: "0px 2px 12px 0px rgba(0, 0, 0, 0.15)" }}>
                                                            <CardContent style={{ display: "flex", flexDirection: "column", padding: '12px' }}>
                                                                <Box sx={{ display: 'flex', alignItems: 'start', justifyContent: cms?.cmsForm?.changeRequired === null || cms?.cmsForm?.changeRequired === undefined ? "none" : "space-between", padding: "2px" }}>
                                                                    {cms?.cmsForm?.cms_no ?
                                                                        <Box>
                                                                            <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                CMS NO.
                                                                            </Typography>
                                                                            <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                {cms?.cmsForm?.cms_no}
                                                                            </Typography>
                                                                        </Box> : <></>
                                                                    }
                                                                    {cms?.cmsForm?.submission_status === "submittedbackforqueries" &&
                                                                        <div style={{ display: "flex", alignItems: "right", gap: "10px" }}>
                                                                            <Tooltip title="Click to review" arrow>
                                                                                <img
                                                                                    src={menu}
                                                                                    alt="menu"
                                                                                    style={{ width: "28px", position: "absolute", right: 50, height: "20px", cursor: "pointer" }}
                                                                                    onClick={(event) => handleMenuClick(event, cms.id)} />
                                                                            </Tooltip>
                                                                        </div>
                                                                    }
                                                                    {showMenu && openedMenuId === cms.id && (
                                                                        <div
                                                                            ref={menuRef}
                                                                            style={{
                                                                                position: "absolute",
                                                                                top: 30,
                                                                                right: 20,
                                                                                width: 150,
                                                                                backgroundColor: "white",
                                                                                boxShadow: "none",
                                                                                borderRadius: 4,
                                                                                border: "1px solid #B51849",
                                                                                zIndex: 10,
                                                                            }}
                                                                        >
                                                                            {!showAlterNote ? (
                                                                                <div
                                                                                    onClick={() => handleMenuItemClick("edit", cms)}
                                                                                    style={{
                                                                                        padding: "10px",
                                                                                        cursor: "pointer",
                                                                                        display: "flex",
                                                                                        alignItems: "center"
                                                                                    }}>
                                                                                    <img
                                                                                        src={Edit}
                                                                                        alt="Edit Icon"
                                                                                        style={{ verticalAlign: "middle", marginRight: "5px", marginBottom: "5px", cursor: "pointer" }}
                                                                                    />
                                                                                    {"Review"}
                                                                                </div>
                                                                            ) : null}
                                                                            {showAlterNote ? (
                                                                                <div
                                                                                    onClick={() => handleMenuItemClick("alterationNote", cms)}
                                                                                    style={{
                                                                                        padding: "10px",
                                                                                        cursor: "pointer",
                                                                                        display: "flex",
                                                                                        alignItems: "center"
                                                                                    }}>
                                                                                    <img
                                                                                        src={Edit}
                                                                                        alt="Edit Icon"
                                                                                        style={{ verticalAlign: "middle", marginRight: "5px", marginBottom: "5px", cursor: "pointer" }}
                                                                                    />
                                                                                    {"Alternation Note"}
                                                                                </div>
                                                                            ) : null}
                                                                        </div>
                                                                    )}
                                                                </Box>
                                                                <Box sx={{ display: 'flex', alignItems: 'start', justifyContent: cms?.cmsForm?.changeRequired === null || cms?.cmsForm?.changeRequired === undefined ? "none" : "space-between", padding: "2px" }}>

                                                                    <div>
                                                                        <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                            Change Category
                                                                        </Typography>
                                                                        <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                            {cms?.cmsForm?.changeRequest?.change_request[0]?.change_category || ""}
                                                                        </Typography>
                                                                    </div>
                                                                    <div>
                                                                        <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                            {(() => {
                                                                                const { change_category, design_change, process_change } = cms?.cmsForm?.changeRequest?.change_request[0] || {};

                                                                                if (change_category === "Design Change") {
                                                                                    if (design_change === "Internal") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_int_customer ? "Customer" : ""}
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_int_customer || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    } else if (design_change === "External") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    Customer
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_ext_customer || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                } else if (change_category === "Process Change") {
                                                                                    if (process_change === "Internal") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_int_customer ? "Customer" : ""}
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_int_customer || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    } else if (process_change === "External") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    Customer
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_ext_customer || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                }

                                                                                return null; // If no condition matches, return null or handle accordingly
                                                                            })()}
                                                                        </Typography>
                                                                    </div>
                                                                </Box>
                                                                <Box sx={{ display: 'flex', alignItems: 'start', justifyContent: cms?.cmsForm?.changeRequired === null || cms?.cmsForm?.changeRequired === undefined ? "none" : "space-between", padding: "2px" }}>

                                                                    <div>
                                                                        <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                            {(() => {
                                                                                const { change_category, design_change, process_change } = cms?.cmsForm?.changeRequest?.change_request[0] || {};

                                                                                if (change_category === "Design Change") {
                                                                                    if (design_change === "Internal") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_int_model ? "Model" : ""}
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_int_model || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    } else if (design_change === "External") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    Model
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_ext_model || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                } else if (change_category === "Process Change") {
                                                                                    if (process_change === "Internal") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_int_model ? "Model" : ""}
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_int_model || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    } else if (process_change === "External") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    Model
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_ext_model || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                }

                                                                                return null; // If no condition matches, return null or handle accordingly
                                                                            })()}
                                                                        </Typography>
                                                                    </div>
                                                                    <div>
                                                                        <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                            {(() => {
                                                                                const { change_category, design_change, process_change } = cms?.cmsForm?.changeRequest?.change_request[0] || {};

                                                                                if (change_category === "Design Change") {
                                                                                    if (design_change === "Internal") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_int_glass_reference ? "Glass Reference" : ""}
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_int_glass_reference || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    } else if (design_change === "External") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    Glass Reference
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.design_ext_glass_reference || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                } else if (change_category === "Process Change") {
                                                                                    if (process_change === "Internal") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_int_glass_reference ? "Glass Reference" : ""}
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_int_glass_reference || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    } else if (process_change === "External") {
                                                                                        return (
                                                                                            <>
                                                                                                <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                                    Glass Reference
                                                                                                </Typography>
                                                                                                <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "2px" }}>
                                                                                                    {cms?.cmsForm?.changeRequest?.change_request[0]?.process_ext_glass_reference || ""}
                                                                                                </Typography>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                }

                                                                                return null; // If no condition matches, return null or handle accordingly
                                                                            })()}
                                                                        </Typography>
                                                                    </div>
                                                                </Box>
                                                                <Box sx={{ padding: '2px', position: 'relative' }}>
                                                                    {cms?.cmsForm?.changeRequired?.change_required_remarks && (
                                                                        <Box> {/* Adjust margin as needed */}
                                                                            <Typography style={{ color: "#000", fontFamily: "inherit", fontSize: { xl: '16px', md: '14px' }, fontWeight: 600 }}>
                                                                                Change Required
                                                                            </Typography>
                                                                            <Typography style={{ color: "#616161", fontFamily: "inherit", fontSize: { xl: '14px', md: '12px' }, marginTop: "0" }}>
                                                                                {cms?.cmsForm?.changeRequired?.change_required_remarks}
                                                                            </Typography>
                                                                        </Box>
                                                                    )}
                                                                    {(cms?.cmsForm?.submission_status === "rejected" || cms?.cmsForm?.submission_status === "submittedbackforqueries") && (
                                                                        <Box sx={{ margin: '10px 0' }}> {/* Adjust margin as needed */}
                                                                            <Chip
                                                                                label={cms?.cmsForm?.submission_status === "submittedbackforqueries" ? "Submitted back for queries" : "Rejected"}
                                                                                style={{
                                                                                    backgroundColor: "white",
                                                                                    color: "#B51849",
                                                                                    fontFamily: "inherit",
                                                                                    fontSize: "12px",
                                                                                    padding: "4px 8px",
                                                                                    border: "1px solid #B51849",
                                                                                    fontWeight: 600,
                                                                                    width: "fit-content",
                                                                                    position: "relative",
                                                                                    right: 4,
                                                                                    cursor: "pointer"
                                                                                }}
                                                                            />
                                                                        </Box>
                                                                    )}
                                                                </Box>
                                                                <Box style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', width: "100%", cursor: "pointer", borderTop: '1px solid #f4f4f4', padding: '2px' }}>
                                                                    <Box style={{ display: "flex" }}>
                                                                        <img
                                                                            src={TimeLine}
                                                                            alt="Timeline"
                                                                            style={{ marginRight: "10px" }}
                                                                            onClick={(event) => toggleAccordionTimeline(event, cms.cms_id)}
                                                                        />
                                                                        <Typography
                                                                            style={{
                                                                                color: "#616161",
                                                                                fontFamily: "inherit",
                                                                                fontSize: "14px",
                                                                                fontWeight: 500,
                                                                            }}
                                                                        >
                                                                            {moment(cms.updated_at).format("DD-MMM-YYYY")}
                                                                        </Typography>
                                                                    </Box>

                                                                    <AvatarGroup>

                                                                        {cms?.cmsForm?.created_by ?
                                                                            <div style={{ position: "relative" }}>
                                                                                <Tooltip title={cms?.cmsForm?.created_by}>
                                                                                    <Avatar style={{ width: "30px", height: "30px", fontSize: "14px", marginBottom: "10px", marginLeft: "50px" }}>
                                                                                        {`${cms?.cmsForm?.created_by.split(" ").pop().charAt(0)}${cms?.cmsForm?.created_by.charAt(0)}`}
                                                                                    </Avatar>
                                                                                </Tooltip>
                                                                            </div> : null
                                                                        }

                                                                    </AvatarGroup>
                                                                </Box>
                                                                {expandedCardId === cms.cms_id && (
                                                                    <Timeline sx={{
                                                                        [`& .${timelineItemClasses.root}:before`]: {
                                                                            flex: 0,
                                                                            padding: 0,
                                                                        },
                                                                    }}>
                                                                        {timeline.map((action, index) => (
                                                                            <TimelineItem key={index}>
                                                                                <TimelineSeparator>
                                                                                    <TimelineDot color="primary" />
                                                                                    {index !== timeline.length - 1 && <TimelineConnector />}
                                                                                </TimelineSeparator>
                                                                                <TimelineContent>
                                                                                    {/* Loop to display each approver's email if present */}
                                                                                    {(() => {
                                                                                        // Set to track displayed approver_mail_id, defined outside the map loop
                                                                                        const displayedApproverMailIds = new Set();

                                                                                        return action?.cmsForm?.notificationHeader?.cmsForms?.map((notification, idx) => {
                                                                                            // Check if the approver_mail_id exists and is not already displayed
                                                                                            if (notification?.approver_mail_id && !displayedApproverMailIds.has(notification.approver_mail_id)) {
                                                                                                // Add to Set to track it and ensure it's displayed only once
                                                                                                displayedApproverMailIds.add(notification.approver_mail_id);

                                                                                                // Extract the username part before "@" and remove anything after "."
                                                                                                const approverUsername = notification.approver_mail_id.split('@')[0].split('.')[0];

                                                                                                // Capitalize the first letter of the username
                                                                                                const formattedUsername = approverUsername.charAt(0).toUpperCase() + approverUsername.slice(1);

                                                                                                return (
                                                                                                    <Typography key={idx} style={{ color: '#616161', fontFamily: 'inherit', fontSize: '14px', fontWeight: 500 }}>
                                                                                                        {action.status === "pending" ? "pending with " + formattedUsername : null}
                                                                                                    </Typography>
                                                                                                );
                                                                                            }

                                                                                            // Return null if approver_mail_id is a duplicate or missing
                                                                                            return null;
                                                                                        });
                                                                                    })()}

                                                                                    {/* Loop to display each functional email if present */}
                                                                                    {(() => {
                                                                                        // Set to track displayed functional_mail_id, defined outside the map loop
                                                                                        const displayedFunctionalMailIds = new Set();

                                                                                        return action?.cmsForm?.notificationHeader?.cmsForms?.map((notification, idx) => {
                                                                                            // Check if the functional_mail_id exists and is not already displayed
                                                                                            if (notification?.functional_mail_id && !displayedFunctionalMailIds.has(notification.functional_mail_id)) {
                                                                                                // Add to Set to track it and ensure it's displayed only once
                                                                                                displayedFunctionalMailIds.add(notification.functional_mail_id);

                                                                                                // Extract the username part before "@" and remove anything after "."
                                                                                                const username = notification.functional_mail_id.split('@')[0].split('.')[0];

                                                                                                // Capitalize the first letter of the username
                                                                                                const formattedUsername = username.charAt(0).toUpperCase() + username.slice(1);

                                                                                                return (
                                                                                                    <Typography key={idx} style={{ color: '#616161', fontFamily: 'inherit', fontSize: '14px', fontWeight: 500 }}>
                                                                                                        {action.status === "pending" ? "pending with " + formattedUsername : null}
                                                                                                    </Typography>
                                                                                                );
                                                                                            }

                                                                                            // Return null if functional_mail_id is a duplicate or missing
                                                                                            return null;
                                                                                        });
                                                                                    })()}

                                                                                    <Typography style={{ color: '#616161', fontFamily: 'inherit', fontSize: '14px', fontWeight: 500 }}>
                                                                                        {action.status === "conditionallyapproved" ? "conditionally approved" + " " + "by" + " " + action.user_approval_name :
                                                                                            action.status === "submittedbackforqueries" ? "submitted back for queries" + " " + "by" + " " + action.user_approval_name :
                                                                                                action.status === "pending" ? "initiated" + " " + "by" + " " + action.user_approval_name :
                                                                                                    action.status + " " + "by" + " " + action.user_approval_name}
                                                                                        <span style={{ marginLeft: '10px', alignItems: 'center' }}>
                                                                                            {/* Functional Comments */}
                                                                                            {action?.cmsForm?.notificationHeader?.cmsForms?.map((notification, idx) => {
                                                                                                if (notification?.functional_sgid?.includes(action?.user_sgid)) {
                                                                                                    return (
                                                                                                        <span
                                                                                                            style={{ marginLeft: '10px', alignItems: 'center' }}
                                                                                                            onMouseEnter={() => setHoveredFunctional(true)}
                                                                                                            onMouseLeave={() => setHoveredFunctional(false)}
                                                                                                        >
                                                                                                            <Tooltip
                                                                                                                title={notification.functional_comments} // Show the comment as tooltip text
                                                                                                                arrow
                                                                                                                enterDelay={500} // Delay before showing tooltip (in ms)
                                                                                                                leaveDelay={200} // Delay before hiding tooltip (in ms)
                                                                                                            >
                                                                                                                <IconButton onClick={() => toggleFunctionalComments(idx)}>
                                                                                                                    <VisibilityIcon fontSize="small" />
                                                                                                                </IconButton>
                                                                                                            </Tooltip>
                                                                                                        </span>
                                                                                                    );
                                                                                                }
                                                                                                return null;
                                                                                            })[0]}
                                                                                        </span>

                                                                                        {/* Approver Comments */}
                                                                                        <span style={{ marginLeft: '10px', alignItems: 'center' }}>
                                                                                            {action?.cmsForm?.notificationHeader?.cmsForms?.map((notification, idx) => (
                                                                                                notification?.approver_sgid.includes(action?.user_sgid) && (
                                                                                                    <span
                                                                                                        style={{ alignItems: 'center' }}
                                                                                                        onMouseEnter={() => setHoveredApprover(true)}
                                                                                                        onMouseLeave={() => setHoveredApprover(false)}
                                                                                                    >
                                                                                                        <Tooltip
                                                                                                            title={notification?.approver_comments} // Show the comment as tooltip text
                                                                                                            arrow
                                                                                                            enterDelay={500} // Delay before showing tooltip (in ms)
                                                                                                            leaveDelay={200} // Delay before hiding tooltip (in ms)
                                                                                                        >
                                                                                                            <IconButton onClick={() => toggleApproverComments(idx)}>
                                                                                                                <VisibilityIcon fontSize="small" />
                                                                                                            </IconButton>
                                                                                                        </Tooltip>
                                                                                                    </span>
                                                                                                )
                                                                                            ))}
                                                                                        </span>
                                                                                    </Typography>
                                                                                    <Typography style={{ color: '#616161', fontFamily: 'inherit', fontSize: '14px', fontWeight: 500 }}>
                                                                                        {(new Date(action.updated_at)).toISOString().split('T')[0]}
                                                                                    </Typography>
                                                                                </TimelineContent>
                                                                            </TimelineItem>
                                                                        ))}
                                                                    </Timeline>
                                                                )}
                                                            </CardContent>
                                                        </Card>
                                                    </React.Fragment>)
                                                }
                                                return null; // Skip rendering this card if no condition matches
                                            })}
                                        {filterRejectData.length === 0 && <p>No records found.</p>}
                                    </Grid>
                                ) : null}

                            </Grid>
                        )}

                </Box>

            </Box>
            <NewChangeRequest isOpen={newDialogRequestsOpen} onClose={newDialogRequestClose} />
            <ViewApproval isOpen={newViewApprovalOpen} onClose={newDialogApprovalClose} approveData={approvalViewData} />
            <Alternation isOpen={newDialogAlterOpen} onClose={newDialogAlterClose} />
        </>
    );
};

export default ManageCmsDesktop;